import { createContext, useEffect, useReducer } from "react";
import AuthReducer from "./AuthReducer";

const INITIAL_STATE = {
    currentUser: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
    currentAccountSubject: localStorage.getItem("accountingSubject") ? JSON.parse(localStorage.getItem("accountingSubject")) : null
}

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({children}) => {
    const [ state, dispatch] = useReducer(AuthReducer,INITIAL_STATE);

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(state.currentUser))
        localStorage.setItem("accountingSubject", JSON.stringify(state.currentAccountSubject))
    }, [state.currentUser, state.currentAccountSubject])

    return (
        <AuthContext.Provider value={{currentUser: state.currentUser, currentAccountSubject: state.currentAccountSubject, dispatch}}>
            {children}
        </AuthContext.Provider>
    )
}