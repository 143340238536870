import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import HomeScreen from 'screens/homeScreen/HomeScreen';
import Login from 'screens/auth/Login'
import MainLayout from 'layout/MainLayout'
import { createTheme, ThemeProvider } from '@mui/material';
import apiURL from 'apiURL/apiURL';
import { API } from 'service/AxiosService';
import { useContext, useEffect } from 'react';
import { AuthContext } from 'context/AuthContext';
import axios from 'axios';
import { getDataFromStorage } from 'storage/StorageData';

function App() {
  const { dispatch } = useContext(AuthContext);
  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#4D96FF',
        light: '#7a7a7a',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#FD841F',
        light: '#7a7a7a',
        contrastText: '#ffffff',
      },
      success: {
        main: '#4CAF50',
        light: '#7a7a7a',
        contrastText: '#ffffff',
      },
      error: {
        main: '#EB5353',
        light: '#7a7a7a',
        contrastText: '#ffffff',
      },
    },

    typography: {
      h3: {
        fontSize: '1.5rem',
        fontWeight: '500'
      },

      h4: {
        fontSize: '1.9rem',
        fontWeight: '500'
      },

      h6: {
        fontSize: '16px',
        fontWeight: '400'
      },
    },
  })

  useEffect(() => {
    async function isTokenExpired(){
      const userToken  = await getDataFromStorage("userToken")
    
      await axios.get(apiURL.service_token_expire,
        {
          'headers': {
            'Authorization': userToken
          }
        }
      )
        .then((res) => {
          // console.log("res",res);
        }).catch((error) => {
         console.log("error",error);
          if (error?.response?.data?.data?.isForceLogout)
          dispatch(
            { type: "LOGOUT", payload: null },
            { type: "ACCOUNT_LOGOUT", payload: null }
            
          );
          localStorage.setItem("userToken", null);
          localStorage.setItem("accountingSubject", null);
          localStorage.setItem("user", null);
        });
    
     }

     isTokenExpired();
   }, [])

  return (
    //  <Login/>
    //  <Dashboard/>
    //  <Sidebar/>
    <ThemeProvider theme={customTheme}>
      <Router>
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/*" name="HomeScreen" element={<HomeScreen />} />
            <Route path="/login" name="Login" element={<Login />} />
          </Route>
        </Routes>
      </Router>

    </ThemeProvider>
  )
}

export default App;
