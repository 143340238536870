import React from "react";
import "./LoadingSpinner.css";
import { Backdrop, Box, CircularProgress } from "@mui/material";

export default function LoadingSpinner() {


  return (
    <Box className="spinner-modal">
    {/* <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div> */}

<Backdrop
// transitionDuration={5000}
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open
>
  <CircularProgress color="inherit" />
</Backdrop >
    </Box>
  //   <Backdrop
  //   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //   open={openBackdrop}
  //   // onClick={handleCloseBackdrop}
  // >
  //   <CircularProgress color="inherit" />
  // </Backdrop>
  );
}