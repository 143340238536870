/***
 @author Lokesh Desai
 @description
 */
import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  Divider,
  TextField,
  Stack,
  IconButton,
  InputAdornment,
  Container,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Table,
  TableContainer,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";

import { useTranslation } from "react-i18next";

const MessageDialog = (props) => {
  const { t } = useTranslation();

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&::-webkit-scrollbar": {
          width: "16px",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#fff",
          borderRadius: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#4d96ff",
          borderRadius: "10px",
          border: "4px solid #ffffff",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#2c70cf",
        },
      }}
      open={props.open}
    // onClose={props.handleClose}
    >
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          width: {
            xs: "90%",
            sm: "90%",
            md: "70%",
            lg: "50%",
            xl: "40%",
          },
        }}
      >
        {/* Una volta confermato non sarà più possibile tornare indietro! */}
        {props.filesData && props.filesData.map((item, index) => (
          <TableContainer
            key={index}
            sx={{
              width: "100%", maxHeight: "80vh",
              borderRadius: 2,
              "&::-webkit-scrollbar": {
                width: "0.5em",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#4d96ff",
                borderRadius: "10px",
              },
            }}
          >
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead >
                <TableRow >
                  <TableCell colSpan={2}>
                    <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                      <Typography sx={{ fontWeight: 700 }}>
                        Import XML Fatture Vendita
                      </Typography>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          props.handleClose();
                        }}
                      >
                        {t("annula")}
                      </Button>
                      {/* <Button onClick={() => props.onConfirm}>{t('conferma')}</Button> */}
                      {/* <Button onClick={props.onConfirm}>{t('conferma')}</Button> */}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow >
                  <TableCell style={{ top: 44 }}>{t("filename")}</TableCell>
                  <TableCell style={{ top: 44 }}>{t("errorMessage")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {Object.keys(item.invoice).map((fileName) => (
                  <TableRow key={fileName}>
                    <TableCell align="">{fileName}</TableCell>
                    <TableCell>
                      {item.invoice[fileName].map((error, errorIndex) => (
                        <Box key={errorIndex} sx={{ display: 'flex' }}>
                          {error.errType == "country_err" ? <WarningIcon sx={{ color: "#fecf6d" }} /> : <ErrorIcon sx={{ color: "#ff0000" }} />}
                          <Typography pl="8px" variant="body1">
                            {error.errType == "incorrect_sequance_invoiceno_err" ? t("incorrectSequence") :
                              error.errType == "invoiceno_err" ? t("invoiceNumberAlreadyExistincorrect") :
                                error.errType == "country_err" ? t("countryIsNotIT") :
                                  error.errType == "subject_err" ? t("acccountSubjectMismatch") :
                                    error.errType == "document_err" ? t("documentDateMismatch") :
                                      error.errType == "year_err" ? t("yearIsClosed") :
                                      error.errType == "incorrect_invoiceno_err" && t("incorrectInvoice")
                            }
                          </Typography>
                        </Box>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ))}

      </Box>
    </Modal>
  );
};

export default MessageDialog;
