import React, {useContext, useEffect, useState} from "react";
import Sidebar from 'components/Sidebar/Sidebar';
import { AuthContext } from 'context/AuthContext';
import Login from 'screens/auth/Login'
import { useLocation } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { t } from "i18next";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
function HomeScreen() {
    const { currentUser } = useContext(AuthContext);
    const { state } = useLocation();
    const [open, setOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    useEffect(() => {
        const isModalPopup = state?.isModalPopup
       
        if (isModalPopup) {
          setOpen(true)
          setErrorMsg(t("tokenExpire"))
        }
    
      }, [state?.isModalPopup])


      const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    return (
        <div>
            {
                currentUser ? <Sidebar /> : <Login />
            }
           <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose} sx={{ width: '100%' }}>
        <Alert onClose={handleAlertClose} severity="error" >
          {errorMsg}
        </Alert>
      </Snackbar>
        </div>
    );
}

export default HomeScreen;