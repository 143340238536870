import axios from 'axios';

let token = null;
export const API = axios.create({
    timeout: 60000,
});

API.interceptors.request.use(
    function (_config) {
        _config.headers['Content-Type'] = 'application/json';

        if (token == null ) token=  JSON.parse(localStorage.getItem("userToken"))
        if (token && token !== '') {
            _config.headers.authorization = token;
        }

        return _config;
    },
    function (error) {
        console.log(JSON.stringify(error));
    },
);

export const saveToken = (data) => {
    token = data;
};
