import React from "react";
import styles from "./styles";
import { Text, View } from "@react-pdf/renderer";

const RegistriIVATable = (props) => {
  const renderVatVandita = (item, idnex) => {
    return (
      <View style={styles.viewRowListItem}>
        <View style={[styles.viewInsiderSmall, { flex: 0.7 }]}>
          <Text style={styles.textRight}>
            {item?.taxableIncome === 0
              ? null
              : item?.taxableIncome?.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </Text>
        </View>
        <View style={[styles.viewInsiderLarge, { flex: 0.7 }]}>
          <Text style={[styles.textRight, { textAlign: "center" }]}>
            {item.code}
          </Text>
        </View>
        <View style={[styles.viewInsiderSmall, { flex: 0.7 }]}>
          <Text style={styles.textRight}>
            {item.code == "V 17 ter"
              ? ""
              : item?.vatAmount === 0
              ? null
              : item?.vatAmount?.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </Text>
        </View>
        <View style={[styles.viewInsiderSmall, { flex: 0.7 }]}>
          <Text style={styles.textRight}>
            {/* {item.code == "V 17 ter"
              ? item?.vatAmount
              : null
              ? null
              : item?.vatAmount?.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} */}
            {item.code == "V 17 ter"
              ? item?.vatAmount == 0
                ? null
                : item?.vatAmount?.toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
              : ""}
          </Text>
        </View>
      </View>
    );
  };

  const renderVatAcquisti = (item, idnex) => {
    return (
      <View style={styles.viewRowListItem}>
        <View style={[styles.flexCommon, { flex: 0.7 }]}>
          <Text style={styles.textRight}>
            {item?.taxableIncome === 0
              ? null
              : item?.taxableIncome?.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </Text>
        </View>
        <View style={[styles.flexDouble, { flex: 0.7 }]}>
          <Text style={[styles.textCenter]}>{item.code}</Text>
        </View>
        <View style={[styles.flexCommon, { flex: 0.7 }]}>
          <Text style={styles.textRight}>
            {item.code === "V 17 ter"
              ? ""
              : item?.deductibleVat === 0
              ? null
              : item?.deductibleVat?.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </Text>
        </View>
        <View style={[styles.flexCommon, { flex: 0.7 }]}>
          <Text style={styles.textRight}>
            {item?.nonDeductibleVat === 0
              ? null
              : item?.nonDeductibleVat?.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </Text>
        </View>
      </View>
    );
  };

  return (
    <>
      {props.venditaRows && props.venditaRows && (
        <View style={styles.viewMainContainer}>
          <View style={styles.flexCommon}>
            <Text style={styles.textCenter}>{props.registrationDate}</Text>
          </View>
          <View style={[styles.flexCommon, { flex: 0.7 }]}>
            <Text style={styles.textCenter}>{props.invoiceNo}</Text>
          </View>
          <View style={[styles.flexCommon, { flex: 0.7 }]}>
            <Text style={styles.textCenter}>{props.documentDate}</Text>
          </View>
          <View style={[styles.flexDouble, { flex: 3.2 }]}>
            <Text style={[styles.textLeft, { textAlign: "left" }]}>
              {props.cliente}
            </Text>
          </View>
          <View style={styles.viewRowList}>
            {props.vats.map((item, index) => renderVatVandita(item, index))}
          </View>
          <View style={[styles.flexCommon, { borderRightWidth: "1px" }]}>
            <Text style={styles.textRight}>{props.total}</Text>
          </View>
        </View>
      )}

      {props.acquistiRows &&
        props.acquistiRows &&
        (console.log("props21221123", props),
        (
          <View style={styles.viewMainContainer}>
            <View style={[styles.flexCommon, { flex: 0.702 }]}>
              <Text style={styles.textCenter}>{props.registrationDate}</Text>
            </View>
            <View style={[styles.flexCommon, { flex: 0.695 }]}>
              <Text style={styles.textCenter}>{props.invoiceNo}</Text>
            </View>
            <View style={[styles.flexCommon, { flex: 0.693 }]}>
              <Text style={styles.textCenter}>{props.documentDate}</Text>
            </View>
            <View style={[styles.flexCommon, { flex: 0.693 }]}>
              <Text style={styles.textCenter}>{props.protocol}</Text>
            </View>
            <View
              style={[styles.flexDouble, { flex: 3.2, textAlign: "center" }]}
            >
              <Text style={styles.textLeft}>{props.supplier}</Text>
            </View>
            <View style={styles.viewRowList}>
              {props.vats.map((item, index) => renderVatAcquisti(item, index))}
            </View>
            <View
              style={[
                styles.flexCommon,
                { borderRightWidth: "1px", flex: 0.7 },
              ]}
            >
              <Text style={styles.textRight}>{props.total}</Text>
            </View>
          </View>
        ))}

      {props.rvrseVenditaRows && props.rvrseVenditaRows && (
        <View style={styles.viewMainContainer}>
          <View style={[styles.flexCommon, { flex: 0.702 }]}>
            <Text style={styles.textCenter}>{props.registrationDate}</Text>
          </View>
          <View style={[styles.flexCommon, { flex: 0.695 }]}>
            <Text style={styles.textCenter}>{props.invoiceNo}</Text>
          </View>
          <View style={[styles.flexCommon, { flex: 0.693 }]}>
            <Text style={styles.textCenter}>{props.documentDate}</Text>
          </View>

          <View style={[styles.flexCommon, { flex: 0.695 }]}>
            <Text style={styles.textCenter}>{props.protocol}</Text>
          </View>
          <View style={[styles.flexDouble, { flex: 3.2 }]}>
            <Text style={styles.textLeft}>{props.supplier}</Text>
          </View>
          <View style={styles.viewRowList}>
            {props.vats.map((item, index) => (
              <View style={styles.viewRowListItem}>
                <View style={[styles.flexCommon, { flex: 0.7 }]}>
                  <Text style={styles.textRight}>
                    {item?.taxableIncome === 0
                      ? null
                      : item?.taxableIncome?.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Text>
                </View>
                <View style={[styles.flexDouble, { flex: 0.7 }]}>
                  <Text style={styles.textRight}>{item.code}</Text>
                </View>
                <View style={[styles.flexCommon, { flex: 0.7 }]}>
                  <Text style={styles.textRight}>
                    {item.code == "V 17 ter"
                      ? ""
                      : item?.vatAmount === 0
                      ? null
                      : item?.vatAmount?.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View
            style={[styles.flexCommon, { borderRightWidth: "1px", flex: 0.7 }]}
          >
            <Text style={styles.textRight}>{props.total}</Text>
          </View>
        </View>
      )}

      {props.rvrseAcquistiRows && props.rvrseAcquistiRows && (
        <View style={styles.viewMainContainer}>
          <View style={[styles.flexCommon, { flex: 0.7 }]}>
            <Text style={styles.textCenter}>{props.registrationDate}</Text>
          </View>
          <View style={[styles.flexCommon, { flex: 0.695 }]}>
            <Text style={styles.textCenter}>{props.invoiceNo}</Text>
          </View>
          <View style={[styles.flexCommon, { flex: 0.692 }]}>
            <Text style={styles.textCenter}>{props.documentDate}</Text>
          </View>
          <View style={[styles.flexCommon, { flex: 0.696 }]}>
            <Text style={styles.textCenter}>{props.invoiceNo}</Text>
          </View>
          <View style={[styles.flexDouble, { flex: 3.2 }]}>
            <Text style={styles.textLeft}>{props.supplier}</Text>
          </View>
          <View style={styles.viewRowList}>
            {props.vats.map((item, index) => (
              <View style={styles.viewRowListItem}>
                <View style={[styles.flexCommon, { flex: 0.703 }]}>
                  <Text style={styles.textRight}>
                    {item?.taxableIncome === 0
                      ? null
                      : item?.taxableIncome?.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Text>
                </View>
                <View style={[styles.flexDouble, { flex: 0.7 }]}>
                  <Text style={styles.textRight}>{item.code}</Text>
                </View>
                <View style={styles.flexCommon}>
                  <Text style={styles.textRight}>
                    {item.code == "V 17 ter"
                      ? ""
                      : item?.vatAmount === 0
                      ? null
                      : item?.vatAmount?.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Text>
                </View>
                <View style={[styles.flexCommon, { flex: 0.697 }]}>
                  <Text style={styles.textRight}>
                    {item?.total === 0
                      ? null
                      : item?.total?.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View
            style={[styles.flexCommon, { borderRightWidth: "1px", flex: 0.7 }]}
          >
            <Text style={styles.textRight}>{props.total}</Text>
          </View>
        </View>
      )}
    </>
  );
};

export default RegistriIVATable;
