const AuthReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN": {
            return {
                currentUser: action.payload,
                currentAccountSubject: state.currentAccountSubject ? state.currentAccountSubject : null
            };
        }
        case "LOGOUT": {
            return {
                currentUser: null,
                currentAccountSubject: null,
            };
        }
        case "APRI_CLICKED": {
            return {
                currentAccountSubject: action.payload,
                currentUser: state.currentUser ? state.currentUser : null
            };
        }
        case "ACCOUNT_LOGOUT": {
            return {
                currentAccountSubject: null,
            };
        }
        default:
            return state
    }
};

export default AuthReducer;