import * as React from "react";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";

import { Box, TextField, InputAdornment, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import AddMasto from "../Add/AddMsato";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormik } from "formik";
import * as Yup from "yup";
import apiURL from "apiURL/apiURL";
import defaultString from "../../../../constants/defaultString.json";
import { getDataFromStorage } from "storage/StorageData";

import apiStatusCode from "../../../../constants/apiStatusCode";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import dayjs from "dayjs";
import { API } from "service/AxiosService";
import axios from "axios";
import { useEffect } from "react";
import { AddFattureVendita } from "screens/fatture/fattureVendita/Add";
import { AddFattureAcquisto } from "screens/fatture/fattureAcquisto/Add";
import { AddPrimaNota } from "screens/primaNota/Add";
import { AddFattureReverseCharge } from "screens/fatture/fattureReverseCharge/Add";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import {
  Document,
  Page,
  Text,
  View,
  PDFDownloadLink,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";
import logo from "../../../../assets/image/revisoft_logo.png";

import helveticaNeueBold from "../../../../fonts/HelveticaNeuBold.ttf";
import helveticaNeueLight from "../../../../fonts/HelveticaNeueLight.ttf";
import CalibriRegular from "../../../../fonts/calibriregular.ttf";
import { saveAs } from "file-saver";
import { resetToken } from "apiURL/commonFunctions";
import { AuthContext } from "context/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import { ConfirmStampaTuttoDialog } from "components/ConfirmStampaTuttoDialog";
import { RiFileExcel2Fill } from "react-icons/ri";

Font.register({
  family: "Helvetica Neue1",
  fonts: [{ src: helveticaNeueLight, fontWeight: "400" }],

  family: "Helvetica Neue",
  fonts: [{ src: helveticaNeueBold, fontWeight: "600" }],
});

Font.register({
  family: "Calibri",
  fonts: [
    { src: CalibriRegular },
    { src: CalibriRegular, fontWeight: "bold" },
    // Add additional variations if needed, like italic, bold italic, etc.
  ],
});

let originalData = [];
let selecetdSubAccountId = [];
let zipUrl = [];
let itemsToShow = 10;
const columns = [
  { id: "dataReg", label: t(""), align: "center" },
  { id: "num", label: t("n°"), align: "center" },
  {
    id: "data",
    label: t("data"),
    align: "center",
  },
  {
    id: "prot",
    label: t("prot."),
    align: "center",
  },
  {
    id: "dare",
    label: t("dare"),
    align: "center",
  },
  {
    id: "avere",
    label: t("avere"),
    align: "center",
    textTransform: "uppercase",
  },
  {
    id: "saldo",
    label: t("saldo"),
    align: "center",
    textTransform: "uppercase",
  },
  {
    id: "contropartite",
    label: t(""),
    align: "center",
  },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
export default function ColumnGroupingTable(props) {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openAddUser, setOpenAddUser] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  // const [selecetdSubAccountId, setSelecetdSubAccountId] = useState([]);
  const [selecetdEconomicoSubAccountId, setSelecetdEconomicoSubAccountId] =
    useState("");
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [rows, setRows] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);
  const [isReportButtonClicked, setIsReportButtonClicked] = useState(false);
  const [column, setColumn] = useState(columns);
  const [typeCodeStato, setTypeCodeStato] = useState("");
  // const [typeCodeConto, setTypeCodeConto] = useState("");
  const [totalGiveAmount, setTotalGiveAmount] = useState(0);
  const [totalHaveAmount, setTotalHaveAmount] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [defaultRows, setDefaultRows] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [invoiceObj, setInvoiceObj] = useState(null);
  const [invoiceType, setInvoiceType] = useState(null);
  const [openAddUserMasto, setOpenAddUserMasto] = useState(false);
  const [clientName, setClientName] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [rowsForPDF, setRowsForPDF] = useState([]);
  const [removeStartingBalance, setRemoveStartingBalance] = useState(false);
  const [isPDF, setIsPDF] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  // const [itemsToShow, setItemsToShow] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [objFirstRow, setObjFirstRow] = useState(null);
  const [openStampaTutto, setOpenStampaTutto] = useState(false);

  const itemsPerScroll = 10;

  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    startDate: Yup.date()
      .required(t("startDateRequired"))
      .test(
        t("startDateRequired"),
        t("startDateSmallerThanEndDate"),
        function (startDate) {
          const { endDate } = this.parent;
          if (!endDate || !startDate) {
            return true; // Skip validation if either field is not selected
          }
          return new Date(startDate) <= new Date(endDate);
        }
      ),
    endDate: Yup.date()
      .required(t("endDateRequired"))
      .test(
        t("endDateRequired"),
        t("endDateGreaterThanStartDate"),
        function (endDate) {
          const { startDate } = this.parent;
          if (!startDate || !endDate) {
            return true; // Skip validation if either field is not selected
          }
          return new Date(endDate) >= new Date(startDate);
        }
      ),
  });
  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      // Handle form submission
      // console.log(values);
      setPage(0);
      originalData = [];
      selecetdSubAccountId = [];
      zipUrl = [];
      // await getMastroContibleReport();
    },
  });

  const getAmountRow = (value, startingBalanceValue, showZero) => {
    let result = 0;
    let _value = value;
    if (removeStartingBalance) {
      if (_value > 0) {
        _value = _value - Math.abs(startingBalanceValue);
      } else {
        _value = _value + Math.abs(startingBalanceValue);
      }
    }
    if (_value === 0 && !showZero) {
      result = null;
    } else {
      result = _value?.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return result;
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  // useEffect(() => {
  //   if (
  //     formik?.values?.startDate &&
  //     formik?.values?.endDate &&
  //     selecetdSubAccountId
  //   ) {
  //     setRows([]);
  //     setRowsForPDF([]);
  //     getMastroContibleReport();
  //   }
  // }, [
  //   selecetdSubAccountId,
  //   formik?.values?.startDate,
  //   formik?.values?.endDate,
  // ]);
  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
      navigate("/", { state: { isModalPopup: true } });
    }
  };

  const getMastroContibleReport = async () => {
    // if ((selecetdEconomicoSubAccountId || selecetdSubAccountId) === "") {
    if (selecetdSubAccountId.length == 0) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(t("subAccountRequired"));
      return;
    }
    setIsLoading(true);
    setIsFetchingMore(true);
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    setClientName(accountingSubject.name);
    setVatNumber(accountingSubject.vatNumber);

    if (!!formik.values) {
      const requestObject = {};
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.startDate = dayjs(formik.values.startDate).format(
        "YYYY-MM-DD"
      );
      requestObject.endDate = dayjs(formik.values.endDate).format("YYYY-MM-DD");
      // requestObject.subaccountId =
      //   selecetdSubAccountId || selecetdEconomicoSubAccountId;
      requestObject.subaccounts = selecetdSubAccountId;
      API.post(apiURL.service_upto5subaccounts_report, requestObject)
        .then(async (res) => {
          setIsLoading(false);
          console.log("getMastroContibleReport", res); //
          if (res.status === apiStatusCode.SUCCESS) {
            zipUrl = res?.data?.data?.pdfUrl;
            await generatePDFDocumentStampa();
          } else {
            originalData = [];
            // selecetdSubAccountId = [];
            zipUrl = [];
            setOriginalRows([]);
            setRows([]);
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          originalData = [];
          // selecetdSubAccountId = [];
          zipUrl = [];
          setOriginalRows([]);
          setRows([]);
          setOpen(true);

          setIsPDF(false);
          setIsLoading(false);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.NOTFOUND) {
              setErrorMsg(t("noRecordFound"));
            } else if (
              error.response.data.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout();
            }
          }
        });
    }
  };

  const showMastroContibleReport = async () => {
    // if ((selecetdEconomicoSubAccountId || selecetdSubAccountId) === "") {
    if (selecetdSubAccountId.length == 0) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(t("subAccountRequired"));
      return;
    }
    setIsLoading(true);
    setIsFetchingMore(true);
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    setClientName(accountingSubject.name);
    setVatNumber(accountingSubject.vatNumber);

    if (!!formik.values) {
      const requestObject = {};
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.startDate = dayjs(formik.values.startDate).format(
        "YYYY-MM-DD"
      );
      requestObject.endDate = dayjs(formik.values.endDate).format("YYYY-MM-DD");
      // requestObject.subaccountId =
      //   selecetdSubAccountId || selecetdEconomicoSubAccountId;
      requestObject.subaccounts = selecetdSubAccountId;
      API.post(apiURL.service_subaccount_report, requestObject)
        .then(async (res) => {
          setIsLoading(false);
          console.log("showMastroContibleReport", res); //
          if (res.status === apiStatusCode.SUCCESS) {
            // const pdfUrl = res?.data?.data?.pdfUrl;
            if (selecetdSubAccountId.length == 1) {
              setObjFirstRow(res?.data?.data?.objFirstRow);
              const newData = res?.data?.data?.invoice;
              setTotalGiveAmount(res?.data?.data?.totalGive);
              setTotalHaveAmount(res?.data?.data?.totalHave);
              setTotalBalance(res?.data?.data?.totalBalance);
              if (newData.length === 0) {
                originalData = [];
                // selecetdSubAccountId = [];
                setOriginalRows([]);
                setRows([]);
                setIsLoading(false);
              } else {
                let data = [];
                if (newData.length < 50) {
                  data = [...newData];
                } else {
                  for (let i = 0; i < 50; i++) {
                    data.push(newData[i]);
                  }
                }
                originalData = newData;
                setOriginalRows(newData);
                setRows(data);
                // setIsLoading(false);
              }
              setIsPDF(true);
            }

            // setRowsForPDF(res?.data?.data?.pdfData);
          } else {
            originalData = [];
            // selecetdSubAccountId = [];
            zipUrl = [];
            setOriginalRows([]);
            setRows([]);
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          originalData = [];
          // selecetdSubAccountId = [];
          zipUrl = [];
          setOriginalRows([]);
          setRows([]);
          setOpen(true);

          setIsPDF(false);
          setIsLoading(false);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.NOTFOUND) {
              setErrorMsg(t("noRecordFound"));
            } else if (
              error.response.data.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout();
            }
          }
        });
    }
  };

  const getMastroSubaccountWiseReport = async () => {
    // setIsLoading(true);
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );

    if (!!formik.values) {
      const requestObject = {};
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.startDate = dayjs(formik.values.startDate).format(
        "YYYY-MM-DD"
      );
      requestObject.endDate = dayjs(formik.values.endDate).format("YYYY-MM-DD");
      // requestObject.subaccountId =
      //   selecetdSubAccountId || selecetdEconomicoSubAccountId;
      // requestObject.subaccountId = selecetdSubAccountId;

      const token = JSON.parse(
        localStorage.getItem(defaultString.jsonWebToken)
      );

      // Make the POST request without handling the response
      // const backgroundAPI = await
      axios
        .post(
          apiURL.service_all_subaccount_wise_invoice_report,
          requestObject,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .catch((error) => {
          // Handle any errors here
          console.error("Error:", error);
        });

      setOpen(true);
      setSeverity("success");
      setErrorMsg("Controlla la posta, tra qualche minuto riceverai i pdf");

      // API.post(apiURL.service_all_subaccount_wise_invoice_report, requestObject)
      //   .then(async (res) => {
      //     // console.log("res4", res);

      //     if (res.status === apiStatusCode.SUCCESS) {
      //       await generatePDFDocument(
      //         res?.data?.data?.pdfData,
      //         clientName,
      //         vatNumber
      //       );
      //     } else {
      //       setOpen(true);
      //       setErrorMsg(t("common.internalServerError"));
      //       setSeverity("error");
      //     }
      //   })
      //   .catch((error) => {
      //     setOpen(true);
      //     setIsLoading(false);
      //     setSeverity("error");
      //     if (error.code === defaultString.network_error)
      //       setErrorMsg(t("common.networkError"));
      //     else if (error.code === defaultString.bad_request) {
      //       if (error.response.data.status === apiStatusCode.NOTFOUND) {
      //         setOpen(false);
      //       } else if (
      //         error.response.data.status === apiStatusCode.UNAUTHORIZED
      //       ) {
      //         doLogout();
      //       }
      //     } else {
      //       setErrorMsg(t("Internal server error"));
      //     }
      //   });
    }
  };

  const getExcelReportForMastroContible = async () => {
    // if ((selecetdEconomicoSubAccountId || selecetdSubAccountId) === "") {
    if (selecetdSubAccountId.length == 0) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(t("subAccountRequired"));
      return;
    }
    setIsLoading(true);
    setIsFetchingMore(true);
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    setClientName(accountingSubject.name);
    setVatNumber(accountingSubject.vatNumber);

    if (!!formik.values) {
      const requestObject = {};
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.startDate = dayjs(formik.values.startDate).format(
        "YYYY-MM-DD"
      );
      requestObject.endDate = dayjs(formik.values.endDate).format("YYYY-MM-DD");
      // requestObject.subaccountId =
      //   selecetdSubAccountId || selecetdEconomicoSubAccountId;
      requestObject.subaccounts = selecetdSubAccountId;
      API.post(apiURL.service_excel_mastro_contabile_report, requestObject)
        .then(async (res) => {
          setIsLoading(false);

          if (res.status === apiStatusCode.SUCCESS) {

            const excelUrl = res?.data?.data?.execlUrl;
            console.log("excelUrl::", excelUrl)
            await generateExcelReport(excelUrl);
          } else {
            originalData = [];
            // selecetdSubAccountId = [];
            // zipUrl = [];
            setOriginalRows([]);
            setRows([]);
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          originalData = [];
          // selecetdSubAccountId = [];
          // zipUrl = [];
          setOriginalRows([]);
          setRows([]);
          setOpen(true);

          // setIsPDF(false);
          setIsLoading(false);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.NOTFOUND) {
              setErrorMsg(t("noRecordFound"));
            } else if (
              error.response.data.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout();
            }
          }
        });
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    originalData = [];
    selecetdSubAccountId = [];
    zipUrl = [];
    getDefaultDate();
  }, []);

  // useEffect(() => {
  //   let totalGiveAmount = 0;
  //   let totalHaveAmount = 0;
  //   let totalBalance = 0;
  //   // console.log("Rows length: ",originalData.length)
  //   originalRows.forEach((row) => {
  //     console.log("row",row);
  //     // totalGiveAmount += row.selectedAccountGiveAmt;
  //     // totalHaveAmount += row.selectedAccountHaveAmt;
  //     // totalBalance = totalGiveAmount - totalHaveAmount;
  //     totalGiveAmount = row.totalGive;
  //     totalHaveAmount = row.totalHave;
  //     totalBalance = row.totalBalance;
  //   });

  //   setTotalGiveAmount(isNaN(totalGiveAmount) ? 0 : totalGiveAmount);
  //   setTotalHaveAmount(isNaN(totalHaveAmount) ? 0 : totalHaveAmount);
  //   setTotalBalance(isNaN(totalBalance) ? 0 : totalBalance);
  // }, [invoiceType]);

  const navigateToUpdate = (data) => {
    console.log("Clicked Data = ", data);
    // console.log("type", data.invoiceType);
    setInvoiceType(data.invoiceType);
    setInvoiceObj(data);
    setEdit(true);
    setOpenAddUser(!openAddUser);
    setDefaultRows(false);
  };

  useEffect(() => {
    // console.log("page: ", page)
    // Update the displayed rows based on the current page and rowsPerPage settings
    const startIdx = page * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    const finalRowsToDisplay = originalData.slice(startIdx, endIdx);
    setRows(finalRowsToDisplay);
  }, [page, rowsPerPage, originalData]);

  const handleChangePage = (event, newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when changing rows per page
  };

  const getDefaultDate = async (page) => {
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    const defaultDateId = accountingSubject._id;
    API.get(`${apiURL.service_default_dates}?id=${defaultDateId}`)
      .then(async (res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          formik.setFieldValue(
            "startDate",
            dayjs(
              res?.data?.data?.defaultDates?.startDate,
              "DD-MM-YYYY"
            ).format("YYYY-MM-DD")
          );
          formik.setFieldValue(
            "endDate",
            dayjs(res?.data?.data?.defaultDates?.endDate, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            )
          );
        } else {
          console.log("In else portion ", res.data);
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(false);
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout();
          }
        }
      });
  };

  const generatePDFDocument = async (pdfData, clientName, vatNumber) => {
    const blob = await pdf(
      <Document>
        <Page size="A3" style={{ padding: "24px" }}>
          <View fixed>
            <View
              style={{
                fontSize: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "70%",
                }}
              >
                {/* <Image src={logo} style={{ width: "240px" }} /> */}
              </View>

              <View
                style={{
                  width: "30%",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingTop: 16,
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    marginRight: "16px",
                    alignSelf: "baseline",
                  }}
                >
                  {t("printDate")}
                </Text>

                <Text
                  style={{
                    fontSize: "10px",
                    alignSelf: "baseline",
                  }}
                >
                  {dayjs().format("DD/MM/YYYY")}
                </Text>
              </View>
            </View>

            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <View style={{ width: "25%" }}>
                <Text
                  style={{
                    fontSize: 10,
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("mastroContabile")}
                </Text>
              </View>

              <View style={{ width: "45%" }}>
                <Text
                  style={{
                    fontSize: 10,
                    textTransform: "uppercase",
                  }}
                >
                  {t("soggetto") + " " + t("contabile")} :{" "}
                  <Text
                    style={{
                      fontSize: 10,
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                      textTransform: "uppercase",
                    }}
                  >
                    {clientName}{" "}
                  </Text>{" "}
                  - {vatNumber}
                </Text>
              </View>
              <View
                style={{
                  width: "30%",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Text
                  style={{
                    fontSize: 9,
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    border: "1px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {t("periodo")}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    border: "1px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    borderLeft: "none",
                  }}
                >
                  {formik?.values?.startDate
                    ? dayjs(formik?.values?.startDate).format("DD/MM/YYYY")
                    : "DD/MM/YYYY"}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    border: "1px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    borderLeft: "none",
                  }}
                >
                  {formik?.values?.endDate
                    ? dayjs(formik?.values?.endDate).format("DD/MM/YYYY")
                    : "DD/MM/YYYY"}
                </Text>
              </View>
            </View>
          </View>

          {/* Table 1 */}

          {pdfData &&
            pdfData.length > 0 &&
            pdfData &&
            pdfData.map((item, index) => {
              const rows = item.subaccountDetails;
              const selecetdSubAccountId = item.subaccountId;
              return (
                <View key={index}>
                  <View
                    style={{
                      width: "58.2%",
                      flexDirection: "row",
                      border: "1px solid black",
                      marginTop: "16px",
                    }}
                  >
                    <Text
                      style={{
                        width: "17.5%",
                        fontFamily: "Helvetica Neue",
                        fontWeight: "600",
                        fontSize: 10,
                        textAlign: "center",
                      }}
                    >
                      {t("conto")}
                    </Text>
                    <Text
                      style={{
                        width: "82.5%",
                        fontFamily: "Calibri",
                        fontSize: 10,
                        paddingTop: 2,
                        borderLeft: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {`${item?.type}.${item?.accountCode}.${item?.subaccountCode} - ${item?.subaccountName}`}
                    </Text>
                  </View>

                  <View>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        marginTop: "16px",
                      }}
                    >
                      <View
                        style={{
                          width: "34%",
                          flexDirection: "row",
                          border: "1px solid black",
                        }}
                      >
                        <View
                          style={{
                            width: "30%",
                            alignSelf: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "Helvetica Neue",
                              fontWeight: "600",
                              fontSize: 10,
                              textAlign: "center",
                            }}
                          >
                            {t("dataReg")}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "70%",
                            flexDirection: "column",
                            borderLeft: "1px solid black",
                          }}
                        >
                          <View
                            style={{
                              borderBottom: "0.7px solid black",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Helvetica Neue",
                                fontWeight: "600",
                                textAlign: "center",
                              }}
                            >
                              {t("fattura")}
                            </Text>
                          </View>
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Helvetica Neue",
                                borderRight: "1px solid black",
                                fontWeight: "600",
                                width: "33%",
                                textAlign: "center",
                              }}
                            >
                              n°
                            </Text>
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Helvetica Neue",
                                borderRight: "1px solid black",
                                fontWeight: "600",
                                width: "33%",
                                textAlign: "center",
                              }}
                            >
                              {t("data")}
                            </Text>
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Helvetica Neue",
                                fontWeight: "600",
                                width: "33%",
                                textAlign: "center",
                              }}
                            >
                              {t("prot")}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          width: "46%",
                          flexDirection: "row",
                          border: "1px solid black",
                          borderLeft: "none,",
                        }}
                      >
                        <View
                          style={{
                            width: "52.61%",
                            alignSelf: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "Helvetica Neue",
                              fontWeight: "600",
                              fontSize: 10,
                              textAlign: "center",
                            }}
                          >
                            {t("descriptionOfTheOperation")}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "47.39%",
                            flexDirection: "column",
                            borderLeft: "1px solid black",
                          }}
                        >
                          <View
                            style={{
                              borderBottom: "0.7px solid black",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Helvetica Neue",
                                fontWeight: "600",
                                textAlign: "center",
                              }}
                            >
                              {t("importi")}
                            </Text>
                          </View>
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Helvetica Neue",
                                borderRight: "1px solid black",
                                fontWeight: "600",
                                width: "33%",
                                textAlign: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              {t("dare")}
                            </Text>
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Helvetica Neue",
                                borderRight: "1px solid black",
                                fontWeight: "600",
                                width: "33%",
                                textAlign: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              {t("avere")}
                            </Text>
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Helvetica Neue",
                                fontWeight: "600",
                                width: "33%",
                                textAlign: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              {t("saldo")}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          flexDirection: "row",
                          border: "1px solid black",
                          borderLeft: "none",
                        }}
                      >
                        <View
                          style={{
                            alignSelf: "center",
                            width: "100%",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "Helvetica Neue",
                              fontWeight: "600",
                              fontSize: 10,
                              textAlign: "center",
                            }}
                          >
                            {t("contropartite")}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  {rows.map((row, index) => {
                    // console.log("row: ",row)
                    return (
                      <View key={index}>
                        <View
                          style={{
                            flexDirection: "row",
                            borderBottom: "0.7px solid black",
                          }}
                        >
                          <View
                            style={{
                              width: "10.35%",
                              borderRight: "1px solid black",
                              borderLeft: "1px solid black",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Calibri",
                                textAlign: "center",
                                paddingTop: 8,
                              }}
                            >
                              {row?.registrationDate
                                ? dayjs(row?.registrationDate).format(
                                    "DD/MM/YYYY"
                                  )
                                : ""}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "7.8%",
                              borderRight: "1px solid black",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Calibri",
                                textAlign: "center",
                                paddingTop: 8,
                              }}
                            >
                              {row?.invoiceNo}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "7.75%",
                              borderRight: "1px solid black",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Calibri",
                                textAlign: "center",
                                paddingTop: 8,
                              }}
                            >
                              {row?.documentDate
                                ? dayjs(row?.documentDate).format("DD/MM/YYYY")
                                : ""}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "8.09%",
                              borderRight: "1px solid black",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Calibri",
                                textAlign: "center",
                                paddingTop: 8,
                              }}
                            >
                              {row?.protocol}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "24.25%",
                              borderRight: "1px solid black",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Calibri",
                                textAlign: "center",
                                paddingTop: 8,
                              }}
                            >
                              {row?.note}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "7.18%",
                              borderRight: "1px solid black",
                              paddingRight: "2px",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Calibri",
                                textAlign: "right",
                                paddingTop: 8,
                              }}
                            >
                              {row?.selectedAccountGiveAmt == 0
                                ? null
                                : row?.selectedAccountGiveAmt?.toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "7.10%",
                              borderRight: "1px solid black",
                              paddingRight: "2px",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Calibri",
                                textAlign: "right",
                                paddingTop: 8,
                              }}
                            >
                              {row?.selectedAccountHaveAmt == 0
                                ? null
                                : row?.selectedAccountHaveAmt?.toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "7.47%",
                              borderRight: "1px solid black",
                              paddingRight: "2px",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                fontFamily: "Calibri",
                                textAlign: "right",
                                paddingTop: 8,
                              }}
                            >
                              {row?.balance?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              {/* {row?.balance == 0
                                ? null
                                : row?.balance?.toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })} */}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "20%",
                              flexDirection: "column",
                              borderRight: "1px solid black",
                              paddingLeft: "3px",
                            }}
                          >
                            {row?.subaccounts.map((item, index) =>
                              // (selecetdSubAccountId ||
                              //   selecetdEconomicoSubAccountId) !==
                              selecetdSubAccountId !== item.subaccountId ? (
                                <Text
                                  key={index}
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Calibri",
                                  }}
                                >{`${item?.accountId?.type}.${item?.accountId?.code}.${item.code} - ${item.name}`}</Text>
                              ) : null
                            )}
                          </View>
                        </View>
                      </View>
                    );
                  })}
                  <View style={{ width: "100%", flexDirection: "row" }}>
                    <View style={{ width: "34%" }}></View>
                    <View style={{ width: "46%", flexDirection: "row" }}>
                      <Text
                        style={{
                          width: "53%",
                          fontSize: 10,
                          fontFamily: "Helvetica Neue",
                          fontWeight: "600",
                          textAlign: "right",
                          borderRight: "1px solid black",
                          paddingRight: "2px",
                        }}
                      >
                        {t("totals")}
                      </Text>
                      <Text
                        style={{
                          width: "15.7%",
                          fontFamily: "Calibri",
                          fontSize: 10,
                          textAlign: "right",
                          borderRight: "1px solid black",
                          borderBottom: "0.7px solid black",
                          paddingTop: 2,
                          paddingRight: "2px",
                        }}
                      >
                        {" "}
                        {item?.totalGiveAmountOfSelectedSubaccount == 0
                          ? null
                          : item?.totalGiveAmountOfSelectedSubaccount?.toLocaleString(
                              "de-DE",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                      </Text>
                      <Text
                        style={{
                          width: "15.55%",
                          fontFamily: "Calibri",
                          fontSize: 10,
                          textAlign: "right",
                          borderRight: "1px solid black",
                          borderBottom: "0.7px solid black",
                          paddingTop: 2,
                          paddingRight: "2px",
                        }}
                      >
                        {item?.totalHaveAmountOfSelectedSubaccount == 0
                          ? null
                          : item?.totalHaveAmountOfSelectedSubaccount?.toLocaleString(
                              "de-DE",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                      </Text>
                      <Text
                        style={{
                          width: "16.35%",
                          fontFamily: "Calibri",
                          fontSize: 10,
                          textAlign: "right",
                          borderRight: "1px solid black",
                          borderBottom: "0.7px solid black",
                          paddingTop: 2,
                          paddingRight: "2px",
                        }}
                      >
                        {item?.totalBalanceOfSelectedSubaccount?.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                        {/* {item?.totalBalanceOfSelectedSubaccount == 0
                          ? null
                          : item?.totalBalanceOfSelectedSubaccount?.toLocaleString(
                              "de-DE",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )} */}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}

          {/* Table 2 */}

          <View
            style={{
              position: "absolute",
              bottom: "20px",
              left: "50%",
            }}
            render={({ pageNumber, totalPages }) => (
              <Text style={{ fontSize: 12 }}>
                Pagina {pageNumber} di {totalPages}
              </Text>
            )}
            fixed
          />
        </Page>
      </Document>
    ).toBlob();

    console.log(blob);

    saveAs(blob, "Print_Mastro_Contabile");
  };
  const generatePDFDocumentStampa = async () => {
    console.log(zipUrl, "zipUrl");

    setIsLoading(true);

    try {
      if (zipUrl.includes(".pdf")) {
        // Fetch the ZIP file containing PDFs
        const response = await fetch(apiURL.siteBaseUrl + zipUrl);

        if (response.ok) {
          // Get the response as a blob
          const blob = await response.blob();

          // Create a URL for the blob data
          const url = window.URL.createObjectURL(blob);

          // Create a temporary <a> element to trigger the download
          const a = document.createElement("a");
          a.href = url;
          let pdfName = zipUrl.split("/");
          // console.log(pdfName);
          a.download = pdfName[pdfName.length - 1]; // Specify the filename for the downloaded ZIP file
          a.style.display = "none";

          // Append the <a> element to the document body and trigger the click event
          document.body.appendChild(a);
          a.click();

          // Clean up by removing the <a> element and revoking the blob URL
          window.URL.revokeObjectURL(url);
        } else {
          console.error("Failed to fetch ZIP file");
        }
      } else {
        const JSZip = require("jszip");
        const zip = new JSZip();

        // Fetch the ZIP file containing PDFs
        const zipResponse = await fetch(apiURL.siteBaseUrl + zipUrl);

        if (!zipResponse.ok) {
          throw new Error(`Failed to fetch ZIP file: ${zipUrl}`);
        }

        // Read the ZIP content
        const zipData = await zipResponse.arrayBuffer();

        // Create a JSZip instance and load the ZIP content
        const zipArchive = await JSZip.loadAsync(zipData);

        // Iterate through the files in the ZIP archive and add them to the new ZIP archive
        zipArchive.forEach((relativePath, zipObject) => {
          if (zipObject.dir) {
            return; // Skip directories
          }

          // Add the file to the new ZIP archive
          zip.file(relativePath, zipObject.async("blob"));
        });

        // Generate and download the ZIP archive
        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, "mastro_contabile.zip");
        });
      }
    } catch (error) {
      console.error("Error generating PDFs ZIP:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const generateExcelReport = async (excelUrl) => {
    console.log(excelUrl, "excelUrl");
    setIsLoading(true);

    try {
      // Fetch the Excel file
      const excelResponse = await fetch(apiURL.siteBaseUrl + excelUrl);

      if (!excelResponse.ok) {
        throw new Error(`Failed to fetch Excel file: ${excelUrl}`);
      }

      // Read the Excel content
      const excelData = await excelResponse.blob();

      // Create a blob and create an object URL for it
      const excelBlob = new Blob([excelData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const excelObjectURL = URL.createObjectURL(excelBlob);

      // Create a link element to trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = excelObjectURL;

      const splitedUrl = excelUrl.split('/');
      
      if (splitedUrl.length > 0 && splitedUrl[splitedUrl.length - 1] != null) {
        downloadLink.download = splitedUrl[splitedUrl.length - 1];
      }

      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);

      // Trigger the download
      downloadLink.click();

      // Clean up the link element and object URL
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(excelObjectURL);
    } catch (error) {
      console.error("Error generating Excel file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const isDataAvailable = !!originalRows.length > 0;

  const handleClosStampaTuttoDialog = () => {
    setOpenStampaTutto(!openStampaTutto);
  };

  const confirmStampaTutto = async () => {
    await getMastroSubaccountWiseReport();
    setOpenStampaTutto(!openStampaTutto);
  };

  return (
    <>
      <Box>
        {isLoading && (
          <Box>
            <LoadingSpinner></LoadingSpinner>
          </Box>
        )}
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              top: "48px",
              position: "fixed",
              backgroundColor: "white",
              width: "calc(100% - 332px)",
              zIndex: 999,
              right: "16px",
              height: "80px",
            }}
          >
            {typeCodeStato && typeCodeStato.split("|").length > 1 ? (
              <Box sx={{ paddingTop: "32px" }}>
                <Typography sx={{ fontSize: "20px" }} fontWeight={600}>
                  {t("Mastro")}
                </Typography>
                <Typography mt={1} fontSize="18px">
                  {typeCodeStato.slice(0, 15)}
                  {typeCodeStato.length > 15 ? "..." : ""}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography sx={{ fontSize: "20px" }} fontWeight={600}>
                  {t("Mastro")}
                  <Typography variant="span" fontWeight={400} fontSize="18px">
                    {typeCodeStato ? "|" : ""}
                    {typeCodeStato.slice(0, 40)}
                    {typeCodeStato.length > 40 ? "..." : ""}
                  </Typography>
                </Typography>
              </Box>
            )}

            <Box sx={{ display: "flex" }}>
              <Box sx={{ marginLeft: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: "none",
                      padding: "none",
                      marginRight: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpenAddUserMasto(true);
                      }}
                      color="primary"
                      size="small"
                    >
                      {t("seleziona")}
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      borderBottom: "0px",
                      // ml: 2,
                      // borderBottom: "1px solid #11111180",
                    }}
                  >
                    <Typography
                      pr={3}
                      sx={{ textTransform: "Uppercase", fontWeight: "700" }}
                    >
                      {t("periodo")}
                    </Typography>
                  </Box>

                  <Box sx={{ borderBottom: "0px" }}>
                    <TextField
                      // sx={{ marginRight: "24px" }}
                      name="startDate"
                      type="date"
                      variant="standard"
                      size="small"
                      value={formik.values.startDate}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.startDate &&
                        Boolean(formik.errors.startDate)
                      }
                      helperText={
                        formik.touched.startDate && formik.errors.startDate
                      }
                    />
                  </Box>

                  <Box sx={{ borderBottom: "0px" }}>
                    <TextField
                      name="endDate"
                      type="date"
                      size="small"
                      variant="standard"
                      value={formik.values.endDate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.endDate && Boolean(formik.errors.endDate)
                      }
                      helperText={
                        formik.touched.endDate && formik.errors.endDate
                      }
                    />
                  </Box>

                  <Box sx={{ borderBottom: "none" }}>
                    {selecetdSubAccountId.length === 1 && (
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={() => {
                          showMastroContibleReport();
                        }}
                        sx={{
                          margin: "0 8px 0 16px",
                        }}
                        color="primary"
                        size="small"
                        disabled={
                          !formik?.values?.startDate ||
                          !formik?.values?.endDate ||
                          !selecetdSubAccountId ||
                          !selecetdSubAccountId.length === 1
                        }
                      >
                        {t("cerca")}
                      </Button>
                    )}

                    <Checkbox
                      checked={removeStartingBalance}
                      onClick={() =>
                        setRemoveStartingBalance(!removeStartingBalance)
                      }
                    />
                    <Typography variant="p" sx={{ marginRight: 3 }}>
                      {t("removeStartingBalance")}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box omponent="form" onSubmit={formik.handleSubmit}>
              <IconButton
                  title={t("Excel")}
                  // variant="contained"
                  size="small"
                  sx={{ marginRight: "2px" }}
                  // startIcon={<RiFileExcel2Fill />}
                  // disabled={!isPDF}
                  disabled={
                    !formik?.values?.startDate || !formik?.values?.endDate
                    // selecetdSubAccountId.length==0
                    // !isDataAvailable
                  }
                  onClick={async () => {
                    if (
                      formik?.values?.startDate &&
                      formik?.values?.endDate &&
                      selecetdSubAccountId
                    ) {
                      await getExcelReportForMastroContible();
                    }
                  }}
                >
                  <RiFileExcel2Fill />
                </IconButton>
                <IconButton
                  title={t("stampa")}
                  // variant="contained"
                  size="small"
                  type="submit"
                  sx={{ marginRight: "2px" }}
                  // startIcon={<LocalPrintshopIcon />}
                  // disabled={!isPDF}
                  disabled={
                    !formik?.values?.startDate || !formik?.values?.endDate
                    // selecetdSubAccountId.length==0
                    // !isDataAvailable
                  }
                  onClick={async () => {
                    if (
                      formik?.values?.startDate &&
                      formik?.values?.endDate &&
                      selecetdSubAccountId
                    ) {
                      await getMastroContibleReport();
                    }
                  }}
                >
                  <LocalPrintshopIcon />
                </IconButton>
                {/* {t("stampa")} */}
                <IconButton 
                  title = {t("stampaTutto")}
                  variant = "contained"
                  size="small"
                  sx={{color: "#e16a0c"}}
                  // startIcon={<LocalPrintshopIcon />}
                  disabled={
                    !formik?.values?.startDate || !formik?.values?.endDate
                  }
                  onClick={() => {
                    setOpenStampaTutto(!openStampaTutto);
                  }}
                  // onClick={()=> getMastroSubaccountWiseReport()}
                >
                  <LocalPrintshopIcon />
                </IconButton >
                {/* {t("stampaTutto")} */}
              </Box>
            </Box>
          </Box>
        </Box>

        <Paper
          sx={{
            width: "100%",
            marginTop: typeCodeStato.split("|").length > 1 ? 16 : 10,
          }}
        >
          {/* <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 2,
                    marginTop: 2,
                  }}
                >
                  <TableCell sx={{ borderBottom: "none", padding: "none" }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpenAddUserMasto(true);
                      }}
                      color="primary"
                      size="small"
                    >
                      {t("selezionaConta")}
                    </Button>
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "0px",
                      // ml: 2,
                      // borderBottom: "1px solid #11111180",
                    }}
                  >
                    <Typography
                      pr={5}
                      sx={{ textTransform: "Uppercase", fontWeight: "700" }}
                    >
                      {t("periodo")}
                    </Typography>
                  </TableCell>

                  <TableCell sx={{ borderBottom: "0px" }}>
                    <TextField
                      // sx={{ marginRight: "24px" }}
                      name="startDate"
                      type="date"
                      variant="standard"
                      size="small"
                      value={formik.values.startDate}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.startDate &&
                        Boolean(formik.errors.startDate)
                      }
                      helperText={
                        formik.touched.startDate && formik.errors.startDate
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ borderBottom: "0px" }}>
                    <TextField
                      name="endDate"
                      type="date"
                      size="small"
                      variant="standard"
                      value={formik.values.endDate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.endDate && Boolean(formik.errors.endDate)
                      }
                      helperText={
                        formik.touched.endDate && formik.errors.endDate
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ borderBottom: "none" }}>
                    {selecetdSubAccountId.length === 1 && (
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={() => {
                          showMastroContibleReport();
                        }}
                        sx={{
                          marginRight: "8px",
                        }}
                        color="primary"
                        size="small"
                        disabled={
                          !formik?.values?.startDate ||
                          !formik?.values?.endDate ||
                          !selecetdSubAccountId ||
                          !selecetdSubAccountId.length === 1
                        }
                      >
                        {t("cerca")}
                      </Button>
                    )}

                    <Checkbox
                      checked={removeStartingBalance}
                      onClick={() =>
                        setRemoveStartingBalance(!removeStartingBalance)
                      }
                    />
                    <Typography variant="p">
                      {t("removeStartingBalance")}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer> */}
          {(selecetdSubAccountId && selecetdSubAccountId.length == 0) ||
          rows.length == 0 ||
          selecetdSubAccountId.length > 1 ? null : (
            <>
              <TableContainer>
                <Box m={2}></Box>

                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={1}>
                        {t("dataReg")}
                      </TableCell>
                      <TableCell align="center" colSpan={3}>
                        {t("fattura/documento")}
                      </TableCell>
                      <TableCell align="center" colSpan={4}>
                        {t("importi")}
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        {t("contropartite")}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">{t("n°")}</TableCell>
                      <TableCell align="center">{t("data")}</TableCell>
                      <TableCell align="center">{t("prot")}</TableCell>
                      <TableCell align="center">{t("note")}</TableCell>
                      <TableCell align="center">{t("dare")}</TableCell>
                      <TableCell align="center">{t("avere")}</TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          textTransform: "uppercase",
                        }}
                      >
                        {t("saldo")}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    {!removeStartingBalance && (
                      <TableRow>
                        <TableCell align="left" colSpan={5}>
                          Saldo Iniziale
                        </TableCell>

                        <TableCell align="center">
                          {objFirstRow?.amountGive == 0
                            ? null
                            : objFirstRow?.amountGive?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {objFirstRow?.amountHave == 0
                            ? null
                            : objFirstRow?.amountHave?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            textTransform: "uppercase",
                          }}
                        >
                          {objFirstRow?.balance?.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          {/* {objFirstRow?.balance == 0
                      ? null
                      : objFirstRow?.balance?.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })} */}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {rows &&
                      rows.length > 0 &&
                      rows.map((row, index) => {
                        return (
                          <TableRow
                            key={row?._id}
                            sx={{ "&:hover": { cursor: "pointer" } }}
                            onClick={() => {
                              navigateToUpdate(row);
                            }}
                          >
                            <TableCell align="center">
                              {row?.registrationDate
                                ? dayjs(row?.registrationDate).format(
                                    "DD/MM/YYYY"
                                  )
                                : ""}
                            </TableCell>

                            <TableCell align="center">
                              {row?.invoiceNo}
                            </TableCell>
                            <TableCell align="center">
                              {row?.documentDate
                                ? dayjs(row?.documentDate).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              {row?.protocol}
                            </TableCell>
                            <TableCell align="center">{row?.note}</TableCell>
                            <TableCell align="center">
                              {row?.selectedAccountGiveAmt == 0
                                ? null
                                : row?.selectedAccountGiveAmt?.toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </TableCell>
                            <TableCell align="center">
                              {row?.selectedAccountHaveAmt == 0
                                ? null
                                : row?.selectedAccountHaveAmt?.toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </TableCell>
                            <TableCell align="center">
                              {getAmountRow(
                                row?.balance,
                                objFirstRow.balance,
                                true
                              )}
                              {/* {row?.balance == 0
                            ? null
                            : row?.balance?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })} */}
                            </TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="left">
                              <ul>
                                {row?.subaccounts.map((item, index) =>
                                  // (selecetdSubAccountId ||
                                  //   selecetdEconomicoSubAccountId) !==
                                  selecetdSubAccountId !== item.subaccountId ? (
                                    <li
                                      key={index}
                                    >{`${item?.accountId?.type}.${item?.accountId?.code}.${item.code} - ${item.name}`}</li>
                                  ) : null
                                )}
                              </ul>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {/* {isLoading && (
                    <TableRow>
                    <TableCell colSpan={12}>
                      <CircularProgress
                        sx={{ display: "block", margin: "auto" }}
                      />
                    </TableCell>
                  </TableRow>
                )}
                   {!hasMore && (
                    <TableRow>
                    <TableCell colSpan={12}>
                      No more data
                    </TableCell>
                  </TableRow>
                )} */}
                    <TableRow
                      sx={{
                        border: "2px solid black",
                      }}
                    >
                      {/* <TableCell></TableCell> */}
                      <TableCell
                        align="center"
                        sx={{
                          borderBottom: "1px solid black",
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "700" }}>
                          {t("totale")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={4}
                        sx={{
                          borderBottom: "1px solid black",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "700" }}
                        ></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        sx={{
                          borderBottom: "1px solid black",
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "700" }}>
                          {getAmountRow(
                            totalGiveAmount,
                            objFirstRow.amountGive,
                            true
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderBottom: "1px solid black",
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "700" }}>
                          {getAmountRow(
                            totalHaveAmount,
                            objFirstRow.amountHave,
                            true
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderBottom: "1px solid black",
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "700" }}>
                          {getAmountRow(
                            totalBalance,
                            objFirstRow.balance,
                            true
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                sx={{ display: "flex", justifyContent: "right" }}
                count={originalData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </>
          )}
          <AddMasto
            open={openAddUserMasto}
            handleClose={async (data) => {
              console.log("data", data);
              const typeCodeStatoArray = data?.data?.map(
                (item) =>
                  `${item?.accountId?.type}.${item?.accountId?.code}.${item?.code} - ${item?.name}`
              );
              const selectedSubAccountId = data?.data.map((item) => item._id);

              const typeCodeContoArray = data?.data1?.map(
                (item) =>
                  `${item?.accountId?.type}.${item?.accountId?.code}.${item?.code} - ${item?.name}`
              );
              const selectedSubAccountContoId = data?.data1.map(
                (item) => item._id
              );
              if (data?.reload) {
                selecetdSubAccountId =
                  selectedSubAccountId.length > 0
                    ? selectedSubAccountId
                    : selectedSubAccountContoId;
                setTypeCodeStato(
                  typeCodeStatoArray.join(" | ") ||
                    typeCodeContoArray.join(" | ")
                );

                if (selecetdSubAccountId && selecetdSubAccountId.length == 1) {
                  setPage(0);
                  await showMastroContibleReport();
                }
              }

              // setEdit(false)
              // setInvoiceObj(null)
              setOpenAddUserMasto(!openAddUserMasto);
              // setDefaultRows(false)
            }}
          />
        </Paper>
        {invoiceType === 1 && (
          <AddFattureVendita
            open={openAddUser}
            isEdit={isEdit}
            stateObj={invoiceObj}
            defaultRows={defaultRows}
            handleClose={(data) => {
              if (data?.reload) {
                showMastroContibleReport();
              }
              setEdit(false);
              setInvoiceObj(null);
              setOpenAddUser(!openAddUser);
              setDefaultRows(false);
            }}
          />
        )}
        {invoiceType === 2 && (
          <AddFattureReverseCharge
            open={openAddUser}
            isEdit={isEdit}
            stateObj={invoiceObj}
            defaultRows={defaultRows}
            handleClose={(data) => {
              if (data?.reload) {
                showMastroContibleReport();
              }
              setEdit(false);
              setInvoiceObj(null);
              setOpenAddUser(!openAddUser);
              setDefaultRows(false);
            }}
          />
        )}
        {invoiceType === 3 && (
          <AddFattureAcquisto
            open={openAddUser}
            isEdit={isEdit}
            stateObj={invoiceObj}
            defaultRows={defaultRows}
            handleClose={(data) => {
              if (data?.reload) {
                showMastroContibleReport();
              }
              setEdit(false);
              setInvoiceObj(null);
              setOpenAddUser(!openAddUser);
              setDefaultRows(false);
            }}
          />
        )}

        {invoiceType === 4 && (
          <AddPrimaNota
            open={openAddUser}
            isEdit={isEdit}
            stateObj={invoiceObj}
            defaultRows={defaultRows}
            handleClose={(data) => {
              if (data?.reload) {
                showMastroContibleReport();
              }
              setEdit(false);
              setInvoiceObj(null);
              setOpenAddUser(!openAddUser);
              setDefaultRows(false);
            }}
          />
        )}
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <ConfirmStampaTuttoDialog
        open={openStampaTutto}
        handleClose={handleClosStampaTuttoDialog}
        onConfirm={confirmStampaTutto}
      />
    </>
  );
}
