import { t } from "i18next";
import React, { useContext, useEffect, useRef } from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import { MessageDialog } from "../MessageDialog";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import apiURL from "apiURL/apiURL";
import { getDataFromStorage } from "storage/StorageData";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import defaultString from "../../../../constants/defaultString.json";
import apiStatusCode from "../../../../constants/apiStatusCode";
import { AuthContext } from "context/AuthContext";
import { resetToken } from "apiURL/commonFunctions";

const UploadXML = () => {
  const [openMessage, setOpenMessage] = useState(false);
  const [filesData, setFilesData] = useState([]);
  const hiddenFileInput = useRef(null);
  const [xmlLoading, setXmlLoading] = useState(false);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleImportXMLButtonClick = () => {
    hiddenFileInput.current.click();
  };


  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  const handleImportXml = async (event) => {
    const files = event.target.files;
    setXmlLoading(true)
    if (files.length > 200) {
      setOpen(true);
      setErrorMsg(t("maxFileUpload"));
      setSeverity("error");
      setXmlLoading(false)
      return
    }

    let accountingSubject = await getDataFromStorage("accountingSubject");
    let userToken = await getDataFromStorage("userToken");
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("xmlFiles", files[i]);
    }
    formData.append("accountSubjectFiscalCode", accountingSubject.fiscalCode);

    await axios
      .post(apiURL.service_importxml_sales_invoice, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: userToken,
        },
      })
      .then((res) => {
        setXmlLoading(false)
        setFilesData([res?.data?.data]);


        if (res?.data?.data?.status == "error") {
          setOpenMessage(true);
        }

        if (
          res?.data?.data?.status == "success" ||
          res?.data?.data?.status == "successwarning"
        ) {
          navigate("/fattureVendita_list", { state: { xmlStatus: res?.data?.data?.status, xmlDataLoading: true, xmlData: [res?.data?.data] } });
        }
        hiddenFileInput.current.value = null;
      })
      .catch((error) => {
        console.log(error?.response);
        setXmlLoading(false)
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(false);
          } 
          else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout()
          }
        }
        else{
          setErrorMsg(t("common.badRequest"));
        }
      });
  };

  const handleCloseDeleteDialog = () => {
    setOpenMessage(!openMessage);
  };



  return (
    <>
      <Link style={{ textDecoration: "none", color: "#000000DE" }}>
        <ListItemButton sx={{ pl: 2 }} onClick={handleImportXMLButtonClick}>
          <ListItemIcon>{/* <ArrowRightIcon /> */}</ListItemIcon>
          <ListItemText >
            {t("importaXMLFattureVendita")}
          </ListItemText>
        </ListItemButton>
      </Link>

      <input
        type="file"
        name="file"
        className="custom-file-input"
        id="inputGroupFile"
        onChange={handleImportXml}
        multiple // Allow multiple file selection
        ref={hiddenFileInput}
        style={{ display: "none" }}
      />
      {xmlLoading ? <LoadingSpinner></LoadingSpinner> : <MessageDialog
        open={openMessage}
        handleClose={handleCloseDeleteDialog}
        filesData={filesData}
      />}

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%", marginTop: 5 }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UploadXML;
