import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { visuallyHidden } from "@mui/utils";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import DeleteDialog from "components/DeleteDialog/DeleteDialog";
import AddPrimaNote from "../Add/AddPrimaNota";
import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import defaultString from "constants/defaultString.json";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { getDataFromStorage } from "storage/StorageData";
import dayjs from "dayjs";
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";
import { Clear, Search } from "@mui/icons-material";
import {
  Autocomplete,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { resetToken } from "apiURL/commonFunctions";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {props.data.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    handleRemoveSearch,
    searchPrimaNotes,
    searchContent,
    setSearchContent,
    setRows,
    originalData,
    setIsSearch,
    fnGetPrimaNotes,
    page,
    isSearch,
    limit,
    setPage,
    listOfSottoConto,
    setListOfSottoConto,
    sottoConto,
    setSottoConto,
    inputSottoConto,
    setInputSottoConto,
    customSort
  } = props;

  return (
    <Toolbar
      sx={{
        pt: { sm: 1 },
        pb: { sm: 1 },
        pl: { sm: 2 },
        pr: { xs: 2, sm: 2 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected == 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionato
        </Typography>
      ) : numSelected > 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionati
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%", display: "flex", alignItems: "center" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
         <TextField
            size="small"
            id="input-with-icon-textfield"
            label={"*" + t("data") + " " + t("reg")}
            // placeholder="gg/mm/aaaa"

            type="date"
            name="regDate"
            sx={{ marginLeft: "8px" }}
            value={searchContent}
            onChange={(e) => {
              setSearchContent(e.target.value)
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AccountCircle /> */}
                </InputAdornment>
              ),
              // readOnly: true,
            }}
          />
          <Autocomplete
            size="small"
            value={sottoConto}
            onChange={(event, newSottoConto) => {
              setSottoConto(newSottoConto);
            }}
            inputValue={inputSottoConto}
            onInputChange={(event, newInputSottoConto) => {
              setInputSottoConto(newInputSottoConto);
            }}
            id="controllable-states-demo"
            options={listOfSottoConto.sort(customSort)}
            getOptionLabel={(option) =>
              option
                ? `${option.type}.${option.accountId.code}.${option.code} - ${option.name}`
                : ""
            }
            isOptionEqualToValue={(option, value) => option._id === value._id}
            sx={{ width: "25pc", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField size="small" {...params} label={t("sottoConto")} />
            )}
          />
          <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "5px",
              marginLeft: "8px",
            }}
            onClick={async (e) => {
              setPage(0);
              if (searchContent != "" || sottoConto != null) {
                setIsSearch(true);
                await searchPrimaNotes(1, limit);
              } else {
                fnGetPrimaNotes(1, limit);
              }
            }}
          >
            <Search />
          </Button>
          <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "5px",
              marginLeft: "8px",
            }}
            onClick={() => handleRemoveSearch()}
          >
            <Clear />
          </Button>
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              props.onDelete();
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          sx={{
            "&:hover": {
              backgroundColor: "#ffffff",
              boxShadow: "none",
            },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "right" }} width="100%">
            <Button
              // href='/registra_prima_nota'
              sx={{
                marginLeft: "16px",
              }}
              onClick={() => {
                props.onAddOpen();
                // handleRemoveSearch();
                // setRows(originalData);
              }}
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
            >
              {t("registraPrimaNota")}
            </Button>
          </Box>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearch: PropTypes.func,
  searchContent: PropTypes.string,
};

const PrimaNoteList = () => {
  const [listOfHeader, setlistOfHeader] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("cognome");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [limit, setLimit] = useState(25);
  const [searchContent, setSearchContent] = useState("");
  const [noteObj, setNoteObj] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [severity, setSeverity] = useState("success");
  const { t } = useTranslation();
  const [defaultRows, setDefaultRows] = useState(false);
  const [filterOriginal, setFilterOriginal] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorIndex, setAnchorIndex] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()
  const [listOfSottoConto, setListOfSottoConto] = useState([]);
  const [sottoConto, setSottoConto] = useState(null);
  const [inputSottoConto, setInputSottoConto] = useState("");

  useEffect(() => {
    const newList = [];
    newList.push({
      id: "dataRegistrazione",
      numeric: false,
      disablePadding: true,
      label: t("dataRegistrazione"),
    });
    newList.push({
      id: "movedAccounts",
      numeric: false,
      disablePadding: true,
      label: t("movedAccounts"),
    });
    newList.push({
      id: "importoDare",
      numeric: true,
      disablePadding: false,
      label: t("importoDare"),
    });
    newList.push({
      id: "importoAvere",
      numeric: true,
      disablePadding: false,
      label: t("importoAvere"),
    });
    newList.push({
      id: "nota",
      numeric: true,
      disablePadding: false,
      label: t("nota"),
    });
    newList.push({
      id: "modifica",
      numeric: true,
      disablePadding: false,
      label: "",
    });
    setlistOfHeader(newList);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    if(isSearch){
      await searchPrimaNotes(newPage + 1, limit)
     }else{
       await fnGetPrimaNotes(newPage+1, limit);
     }
    setSelected([]);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    let newLimit=parseInt(event.target.value, 10)
    setLimit(newLimit);
    let newPage=0;
    setPage(newPage);
    if(isSearch){
      await searchPrimaNotes( newPage + 1,newLimit)
     }else{
       await fnGetPrimaNotes(newPage + 1, newLimit);
     }
    setSelected([]);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const fnGetPrimaNotes = useCallback(
    async (page,limit) => {
      let accountingSubject = await getDataFromStorage("accountingSubject");
      setIsLoading(true);
      API.get(
        `${apiURL.service_get_primanote}?id=${accountingSubject?._id}&page=${page}&limit=${limit}`
      )
        .then((res) => {
          setIsLoading(false);
          if (res.status === apiStatusCode.SUCCESS) {
            // console.log("Response = ", JSON.stringify(res.data.data))
            setRows(res.data.data.note);
            setOriginalData(res.data.data.note);
            setTotalRecords(res.data.data.totalRecords);
          } else {
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.NOTFOUND) {
              setOpen(false);
            } else if (
              error.response.data.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout()
            }
          }
          setRows([]);
          setOriginalData([]);
          setTotalRecords(0);
        });
    },
    [limit]
  );

  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  const searchPrimaNotes = async (pageValue ,newLimit) => {
    setIsLoading(true);
    let accountingSubject = await getDataFromStorage("accountingSubject");
   
    const sottoContoId=sottoConto && sottoConto?._id 
    const queryParams = {
      id: accountingSubject?._id,
      page: pageValue,
      limit: newLimit,
    };
  

    if (searchContent) {
      queryParams.search_text = searchContent
      ? dayjs(searchContent).format("YYYY-MM-DD")
      : "";
    }
  
    if (sottoContoId) {
      queryParams.search_subaccountId = sottoContoId;
    }
    const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&');
    await API.get(
      `${apiURL.service_get_primanote}?${queryString}`
    )
      .then((res) => {
        // console.log("res",res);
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          
          setRows(res.data.data.note);
          setTotalRecords(res.data.data.totalRecords);
          // setFilterOriginal(res.data.data.note);
        } else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(true);
            setErrorMsg(t("noRecordFound"));
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout()
          }
        }
        setRows([]);
        setOriginalData([]);
        setTotalRecords(0);
      });
  };

  const fnDelete = async () => {
    const idsToBeDeleted = JSON.stringify({
      noteIds: selected,
    });

    API.post(apiURL.service_delete_primanote, idsToBeDeleted)
      .then(async (res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(t("common.deleteSuccess"));
          setSeverity("success");
          if(isSearch){
            await searchPrimaNotes(page + 1,limit)
          }else{

            await fnGetPrimaNotes(page + 1,limit);
            handleRemoveSearch();
          }

          // const filteredArr = rows.filter(
          //   (item) => !selected.includes(item._id)
          // );
          // setRows(filteredArr);
          // const filteredOriginalArr = filterOriginal.filter(
          //   (item) => !selected.includes(item._id)
          // );
          // setOriginalData(filteredOriginalArr);
          setSelected([]);
          // handleRemoveSearch();
        } else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout()
          } else {
            setErrorMsg(t("common.badRequest"));
          }
        }
      });
  };

  const confirmDelete = async () => {
    console.log("selected:: ", selected);
    await fnDelete();
    setOpenDelete(!openDelete);
  };

  const getAllAccounts = async () => {
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    API.get(
      apiURL.service_list_all_subaccounts +
        `?id=${accountingSubject._id}`
    )
      .then((res) => {
         console.log("res2",res);
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setListOfSottoConto(res?.data?.data?.subaccounts);
          // props.handleClose({ reload: true, selectedName });
        } else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(false);
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            setOpen(true);
            setErrorMsg(t("common.unAuthorized"));
          }
        }
      });
  };

  useEffect(() => {

    getAllAccounts();
      fnGetPrimaNotes(page + 1, limit);
  }, []);

  const navigateToUpdate = (data) => {
    setNoteObj(data);
    setEdit(true);
    setOpenAddUser(!openAddUser);
    setDefaultRows(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  async function handleRemoveSearch() {
    setSearchContent("");
    setSottoConto(null)
    setIsSearch(false);
    setTotalRecords(0)
    await fnGetPrimaNotes(page +1, limit);
  }

  const handlePopoverOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setAnchorIndex(index);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setAnchorIndex(null);
  };
  const open1 = Boolean(anchorEl);

  function customSort(a, b) {
    const keyA = `${a.type}.${a?.accountId?.code}.${a.code}`;
    const keyB = `${b.type}.${b?.accountId?.code}.${b.code}`;

    const partsA = keyA.split('.').map(part => parseInt(part, 10));
    const partsB = keyB.split('.').map(part => parseInt(part, 10));
  
    for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
      if (partsA[i] !== partsB[i]) {
        return partsA[i] - partsB[i];
      }
    }
    return partsA.length - partsB.length;
  }

  return (
    <Box sx={{ width: "100%", marginTop: "48px" }}>
      {isLoading && (
        <Box>
          <LoadingSpinner></LoadingSpinner>
        </Box>
      )}
      <Box>
        <Typography variant="h3" mb={3}>
          {t("primaNota")}
        </Typography>
      </Box>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onAddOpen={() => {
            setOpenAddUser(!openAddUser);
            setDefaultRows(true);
          }}
          // handleSearch={(event) => {
          //   const search = event.target.value;
          //   setSearchContent(search);
          //   if (search.trim().length > 0) {
          //     const filteredData = filterOriginal.filter((item) =>
          //       item.registrationDate.toString().includes(search.toString())
          //     );
          //     setRows(filteredData);
          //   } else {
          //     setRows(originalData);
          //   }
          // }}
          onDelete={() => {
            setOpenDelete(!openDelete);
          }}
          searchContent={searchContent}
          setSearchContent={setSearchContent}
          handleRemoveSearch={handleRemoveSearch}
          setRows={setRows}
          originalData={originalData}
          searchPrimaNotes={searchPrimaNotes}
          fnGetPrimaNotes={fnGetPrimaNotes}
          page={page}
          isSearch={isSearch}
          setIsSearch={setIsSearch}
          limit={limit}
          setPage={setPage}
          listOfSottoConto={listOfSottoConto}
          setListOfSottoConto={setListOfSottoConto}
          sottoConto={sottoConto}
          setSottoConto={setSottoConto}
          inputSottoConto={inputSottoConto}
          setInputSottoConto={setInputSottoConto}
          customSort={customSort}
        />
        <TableContainer
          sx={{
            maxHeight: "70vh",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4d96ff",
              borderRadius: "10px",
            },
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              data={listOfHeader}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      padding="checkbox"
                      onClick={(event) => handleClick(event, row._id)}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {dayjs(row.registrationDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      <ul
                        style={{
                          paddingLeft: "0px",
                        }}
                      >
                        {row?.subaccounts.map((item, index) =>
                          item.accountId ? (
                            <li
                              key={index}
                            >{`${item?.accountId?.type}.${item?.accountId?.code}.${item.code} - ${item.name}`}</li>
                          ) : null
                        )}
                      </ul>
                    </TableCell>
                    <TableCell align="right">
                      {row.totalAmountGive.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      {row.totalAmountHave.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="left">
                      {/* {row.note} */}
                      {row?.note ? (
                        <>
                          <InfoIcon
                            aria-owns={
                              open1 ? `mouse-over-popover-${index}` : undefined
                            }
                            aria-haspopup="true"
                            onMouseEnter={(e) => handlePopoverOpen(e, index)}
                            onMouseLeave={handlePopoverClose}
                            sx={{ color: "#4D96FF" }}
                          />
                          <Popover
                            id={`mouse-over-popover-${index}`}
                            sx={{
                              pointerEvents: "none",
                            }}
                            open={open1 && index === anchorIndex}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Typography
                              sx={{ p: 1, boxShadow: "0", fontSize: "14px" }}
                            >
                              {row?.note}
                            </Typography>
                          </Popover>
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        type="button"
                        onClick={() => {
                          navigateToUpdate(row);
                          // handleRemoveSearch();
                          // setRows(originalData);
                        }}
                      >
                        {t("edit")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {!isSearch && ( */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("rowsPerPage")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${t("of")} ${count}`
            }
          />
        {/* )} */}
      </Paper>
      <AddPrimaNote
        open={openAddUser}
        isEdit={isEdit}
        stateObj={noteObj}
        defaultRows={defaultRows}
        handleClose={async(data) => {
          if (data?.reload) {
            if(isSearch){
              await searchPrimaNotes(page + 1,limit)
            }else{

             await fnGetPrimaNotes(page + 1,limit);
            }
          }
          setEdit(false);
          setNoteObj(null);
          setOpenAddUser(!openAddUser);
          setDefaultRows(false);
        }}
      />
      <DeleteDialog
        open={openDelete}
        handleClose={() => setOpenDelete(!openDelete)}
        onConfirm={confirmDelete}
      />
      <>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          sx={{ width: "100%" }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            sx={{ color: "#fff" }}
            severity={severity}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      </>
    </Box>
  );

  // return (
  //   <>
  //     {isLoading ? LoadingSpinner : PrimaNoteModal}
  //     <Snackbar
  //       open={open}
  //       autoHideDuration={6000}
  //       onClose={handleAlertClose}
  //       sx={{ width: "100%" }}
  //       anchorOrigin={{ horizontal: "right", vertical: "top" }}
  //     >
  //       <Alert
  //         onClose={handleAlertClose}
  //         sx={{ color: "#fff" }}
  //         severity={severity}
  //       >
  //         {errorMsg}
  //       </Alert>
  //     </Snackbar>
  //   </>
  // );
};

export default PrimaNoteList;
