import React, { useState, useEffect, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import AddIcon from "@mui/icons-material/Add";
import { visuallyHidden } from "@mui/utils";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Stack } from "@mui/material";
import { FormGroup } from "@mui/material";
import { revisoftLogo, welcomeImage } from "../../../assets/image";
import OpenExerciseModal from "../Add/apriEsercizioChiuso/AddEsercizi";
import CloseExerciseModal from "../Add/ChiudiEsercizio/AddEsercizi";
import { t } from "i18next";
import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import defaultString from "constants/defaultString.json";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { getDataFromStorage } from "storage/StorageData";
import AppLogger from "constants/AppLogger";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from "components/DeleteDialog/DeleteDialog";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { resetToken } from "apiURL/commonFunctions";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {props.listHeadData.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const { t } = useTranslation();

  return (
    <Toolbar
      sx={{
        pt: { sm: 1 },
        pb: { sm: 1 },
        pl: { sm: 2 },
        pr: { xs: 2, sm: 2 },

        // ...(numSelected > 0 && {
        //   bgcolor: (theme) =>
        //     alpha(
        //       theme.palette.primary.main,
        //       theme.palette.action.activatedOpacity
        //     ),
        // }),
        display: "flex",
        justifyContent: "end",
      }}
    >
      <Tooltip
          sx={{
            "&:hover": {
              backgroundColor: "#ffffff",
              boxShadow: "none",
            },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "end" }}>
          
            <Button
              sx={{
                marginLeft: "16px",
                display: "flex",
              }}
              onClick={() => {
                props.onAddYear();
              }}
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
            >
              {t("nuovoEsercizio")}
            </Button>
          </Box>
        </Tooltip>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [listOfHeadCell, setListOfHeadCell] = useState([]);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("cognome");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [limit, setLimit] = useState(25);
  const [updateObj, setUpdateObj] = useState({});
  const { i18n, t } = useTranslation();
  const [severity, setSeverity] = useState("success");
  const [openDelete, setOpenDelete] = useState(false);
  const [idToBeDelete, setIdToBeDelete] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()

  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}


  const fnExerciseList = useCallback(
    async (page) => {
      console.log("called in list page ", page);
      let accountingSubject = await getDataFromStorage("accountingSubject");
      API.get(
        `${apiURL.service_get_exercise}?subjectId=${accountingSubject?._id}&page=${page}&limit=${limit}`
      )
        .then(async (res) => {
          setIsLoading(false);
          if (res.status === apiStatusCode.SUCCESS) {
            console.log(res.data.data);
            setRows(res.data.data.exercises);
            setTotalRecords(res.data.data.totalRecords);
          } else {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("common.internalServerError"));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.NOTFOUND) {
              setOpen(false);
            } else if (
              error.response.data.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout()
            }
          }
          setRows([]);
          setTotalRecords(0);
        });
    },
    [limit]
  );

  useEffect(() => {
    const newList = [];
    newList.push({
      id: "nome",
      numeric: false,
      disablePadding: true,
      label: t("stato"),
    });
    newList.push({
      id: "anno",
      numeric: true,
      disablePadding: false,
      label: t("annoTitle"),
    });
    newList.push({
      id: "statusBtn",
      numeric: true,
      disablePadding: false,
      label: "",
    });
    newList.push({
      id: "delete",
      numeric: true,
      disablePadding: false,
      label: "",
    });
    setListOfHeadCell(newList);

    setIsLoading(true);
    fnExerciseList(page + 1);
  }, [fnExerciseList, page]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, nome) => {
    console.log("nome ", nome)
    const selectedIndex = selected.indexOf(nome);
    console.log("index: ",selectedIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, nome);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    // await fnExerciseList(newPage + 1);
    setSelected([]);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
    // await fnExerciseList(page + 1);
    setSelected([]);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (nome) => selected.indexOf(nome) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const fnAddExerise = async () => {
    let accountingSubject = await getDataFromStorage("accountingSubject");

    setIsLoading(true);

    API.post(apiURL.service_add_exercise, {
      accountingSubjectId: accountingSubject?._id,
    })
      .then(async (response) => {
        setIsLoading(false);
        console.log(response.data);
        if (response.status === apiStatusCode.CREATED) {
          setOpen(true);
          setErrorMsg(t("common.addSuccess"));
          setSeverity("success");
          await fnExerciseList(page + 1);
        } else {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("common.internalServerError"));
        }
      })
      .catch((error) => {
        AppLogger.logError(JSON.stringify(error));
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout()
          } else {
            if (error?.response?.data?.data?.error == 1) {
              setErrorMsg(t("excercise.notAllowedToOpen2Exercise"));
            }
            else {
              setErrorMsg(t("common.badRequest"));
            }
          }
        }
      });
  };

  const navigateToOpenDialog = (data) => {
    setOpenAddUser(!openAddUser);
    setUpdateObj(data);
  };

  const navigateToCloseDialog = (data) => {
    setOpenDeleteUser(!openDeleteUser);
    setUpdateObj(data);
  };

  const confirmDelete = async () => {    
    await fnDelete();
    setOpenDelete(!openDelete)
  }

  const fnDelete = async () => {
    console.log("exerciseIds:: ",idToBeDelete);

    await API.post(apiURL.service_delete_exercise, {
        'exerciseIds': [idToBeDelete],
      }
    )
    .then(async (res) => {
      if (res.data.status === apiStatusCode.DELETED) {
        setIdToBeDelete("");
        setOpen(true);
        setErrorMsg(t("common.deleteSuccess"))
        setSeverity("success")
        await fnExerciseList(page + 1);

        const filteredArr = rows.filter(item => !selected.includes(item._id));
        setRows(filteredArr);
        setSelected([]);
      }
      else {
        setIdToBeDelete("");
        setOpen(true);
        setSeverity("error")
        setErrorMsg(t("common.internalServerError"))
      }

    }).catch((error) => {
      setIdToBeDelete("");
      setIsLoading(false)
      setOpen(true);
      setSeverity("error")
        if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout()
          }
          else{
            setErrorMsg(t("common.badRequest"));
          }
        } 
    });
  }

  const exerciseModel = (
    <Box sx={{ width: "100%", marginTop: "48px" }}>
      <Box>
        <Typography variant="h3" mb={3}>
          {t("esercizi")}
        </Typography>
      </Box>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          // onAddOpen={()=>{
          //   setOpenAddUser(!openAddUser)
          // }}
          // onDeleteOpen={()=>{
          //   setOpenDeleteUser(!openDeleteUser)
          // }}
          onAddYear={() => {
            fnAddExerise();
          }}
        />
        <TableContainer>
          <Table
          size="small"
            sx={{ maxWidth: 750, marginX: "auto" }}
            aria-labelledby="tableTitle"
            
          >
            <EnhancedTableHead
              listHeadData={listOfHeadCell}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row._id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell> */}
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align="center"
                    >
                      {/* t('aperto') */}
                      <Chip
                      size="small"
                        label={row.state === "open" ? "Aperto" : "Chiuso"}
                        color={row.state === "open" ? "success" : "error"}
                      />
                    </TableCell>
                    <TableCell align="center">{row.year}</TableCell>
                    <TableCell>
                      {row.state === "open" && (
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            navigateToCloseDialog(row);
                            // setOpenAddUser(!openAddUser)
                          }}
                          sx={{
                            marginLeft: "16px",
                            display: "flex",
                          }}
                          color="error"
                          startIcon={<ReportProblemIcon />}
                        >
                          {t("chiudiEsercizio")}
                        </Button>
                      )}

                      {row.state === "close" && (
                        <Button
                          sx={{
                            marginLeft: "16px",
                            display: "flex",
                          }}
                          onClick={() => {
                            navigateToOpenDialog(row);
                            // setOpenDeleteUser(!openDeleteUser)
                          }}
                          variant="contained"
                          color="secondary"
                          size="small"
                          startIcon={<ReportProblemIcon />}
                        >
                          {t("apriEsercizioChiuso")}
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                    { row.isShowDelete == 1 ?
                      <IconButton onClick={(event) => {
                        //  handleClick(event, row._id);
                        setIdToBeDelete(row._id);
                        setOpenDelete(!openDelete);
                      }}>
                        <DeleteIcon />
                      </IconButton> : ""
                    }
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t("of")} ${count}`
          }
        />
      </Paper>
      <OpenExerciseModal
        open={openAddUser}
        handleClose={(data) => {
          if (data?.reload) {
            fnExerciseList(page + 1);
          }
          setUpdateObj({});
          setOpenAddUser(!openAddUser);
        }}
        stateObj={updateObj}
      />

      <CloseExerciseModal
        open={openDeleteUser}
        handleClose={(data) => {
          if (data?.reload) {
            fnExerciseList(page + 1);
          }
          setUpdateObj({});
          setOpenDeleteUser(!openDeleteUser);
        }}
        stateObj={updateObj}
      />
      <DeleteDialog open={openDelete} handleClose={()=>setOpenDelete(!openDelete)} onConfirm={confirmDelete}/>
    </Box>
  );

  return (
    <>
      {isLoading ? LoadingSpinner : exerciseModel}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ color: "#fff" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
