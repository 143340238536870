import React, { useEffect, useState, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  Autocomplete,
  InputAdornment,
  IconButton,
  Modal,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import AccountCircle from "@mui/icons-material/AccountCircle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import defaultString from "constants/defaultString.json";
import { getDataFromStorage } from "storage/StorageData";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { itIT } from "@mui/x-date-pickers/locales";
import "dayjs/locale/it";
import { debounce } from "lodash";
import { AddFornitori } from "screens/Anagrafiche/fornitori/Add";
import { isValid, parseISO } from "date-fns"; // Import date-fns functions for date validation
import { NumericFormat } from "react-number-format";
import { resetToken } from "apiURL/commonFunctions";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";

function ccyFormat(num) {
  // return num > 0 ? `${num.toFixed(2)}` : 0;
  return `${num.toFixed(2)}`;
}

const documentList = [
  {
    type: 1,
    name: "Nota di Credito",
  },
  {
    type: 2,
    name: "Fattura",
  },
];

function EuroNumberFormat(props) {
  const { inputRef, onChange, onBlur, ...other } = props;
  const [inputValueBlur, setInputValueBlur] = useState(true);
  const handleChange = (value) => {
    setInputValueBlur(false);
    onChange(value);
  };

  const handleBlur = () => {
    setInputValueBlur(true);
    if (onBlur) {
      onBlur();
    }
  };
  return (
    <NumericFormat
      {...other}
      onBlur={handleBlur}
      onChange={handleChange}
      getInputRef={inputRef}
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={2}
      fixedDecimalScale={inputValueBlur}
      style={{
        textAlign: "right",
      }}
    />
  );
}

function readOnlyFormat(props) {
  const { inputRef, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={2}
      style={{
        textAlign: "right",
      }}
    />
  );
}

export default function AddFattureAcquisti(props) {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    supplier: Yup.object().required(t("supplierIsRequired")),
    // regDate: Yup.date().required(t("registrationDateRequired")),
    regDate: Yup.date()
      .required(t("registrationDateRequired"))
      .test(
        t("registrationDateRequired"),
        t("registerDateIsGreaterThanDocuDate"),
        function (regDate) {
          const { date } = this.parent;
          if (!date || !regDate) {
            return true; // Skip validation if either field is not selected
          }
          return new Date(regDate) >= new Date(date);
        }
      ),
    date: Yup.date().required(t("dateRequired")),
    invoiceNumber: Yup.string().required(t("invoiceNumberRequired")),
    // .integer("Must be an integer")
    // .typeError("Must be a number"),
  });

  const formik = useFormik({
    initialValues: {
      supplier: null,
      inputSupplier: "",
      regDate: "",
      date: "",
      invoiceNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setErrors }) => {
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {};
      let isYearOpened = false;
      if (values.regDate != "Invalid Date") {
      requestObject.year =values.regDate ? dayjs(values.regDate).format("YYYY") : "";
      requestObject.accountingSubjectId = accountingSubject?._id;

      const checkYearResponse = await API.post(
        apiURL.service_check_year_closed,
        requestObject
      );
      // console.log("checkYearResponse", checkYearResponse);
      if (checkYearResponse && checkYearResponse?.data?.success) {
        if (
          checkYearResponse?.data?.message?.isClosed == 1 ||
          checkYearResponse?.data?.message?.isClosed == 0
        ) {
          isYearOpened = false;

          if (isChangeDate == true) {
            formik?.setFieldTouched("date", true, false);
            formik?.setErrors({
              date: t("yearIsClosed"),
            });
          }

          if (isChangeRegDate == true) {
            formik?.setFieldTouched("regDate", true, false);
            formik?.setErrors({
              regDate: t("yearIsClosed"),
            });
          }
        } else {
          isYearOpened = true;
        }
      }
    }
      /*
      const requestObjectInvoice = {};
      let isInvoiceNumberUnique = false;
      requestObjectInvoice.invoiceId = props?.isEdit ? props.stateObj?._id : 0;
      requestObjectInvoice.invoiceNo = values.invoiceNumber;
      requestObjectInvoice.accountingSubjectId = accountingSubject?._id;

      const checkInvoiceResponse = await API.post(
        apiURL.service_check_purchase_invoice_number,
        requestObjectInvoice
      );
      if (checkInvoiceResponse && checkInvoiceResponse?.data?.success) {
        // console.log("checkCodeResponse", checkInvoiceResponse);
        if (checkInvoiceResponse?.data?.data?.isExist) {
          isInvoiceNumberUnique = false;
          formik?.setFieldTouched("invoiceNumber", true, false);
          setErrors({
            invoiceNumber: t("invoiceNumberExist"),
          });
        } else {
          isInvoiceNumberUnique = true;
        }
      }
  */

      const requestObjectDocumentDate = {};
      let isDocumentDate = false;
      requestObjectDocumentDate.invoiceId = props?.isEdit
        ? props.stateObj?._id
        : 0;
      requestObjectDocumentDate.registrationDate = dayjs(values.regDate).format(
        "YYYY-MM-DD"
      );
      requestObjectDocumentDate.accountingSubjectId = accountingSubject?._id;
      requestObjectDocumentDate.protocol = protocol;

      const checkPreviousDateResponse = await API.post(
        apiURL.service_check_register_date_purchase,
        requestObjectDocumentDate
      );
      if (
        checkPreviousDateResponse &&
        checkPreviousDateResponse?.data?.success
      ) {
        // console.log("checkCodeResponse", checkInvoiceResponse);
        if (checkPreviousDateResponse?.data?.data?.isAllow == 0) {
          isDocumentDate = false;
          formik?.setFieldTouched("regDate", true, false);
          if (props?.isEdit) {
            setErrors({
              regDate: t("wrongDate"),
            });
          } else {
            setErrors({
              regDate: t("checkPreviousRegDate"),
            });
          }
        } else {
          isDocumentDate = true;
        }
      }

      // if (isYearOpened && isInvoiceNumberUnique && isDocumentDate) {

      if (isYearOpened && isDocumentDate) {
        if (props?.isEdit) {
          await fnEditInvoice();
        } else {
          await fnAddInvoice();
        }
      }
    },
  });

  const [listOfSupplier, setListOfSupplier] = useState([]);
  const [supplier, setSupplier] = useState(listOfSupplier[0]);
  const [inputSupplier, setinputSupplier] = useState("");

  const [listOfDocument, setListOfDocument] = useState([]);
  const [document, setDocument] = useState(
    documentList.find((item) => item.name === "Fattura")
  );
  const [inputDocument, setInputDocument] = useState("");

  const [listOfIVA, setListOfIVA] = useState([]);
  const [iVA, setIVA] = useState(listOfIVA[0]);
  const [inputIVA, setInputIVA] = useState("");

  const [listOfSottoConto, setListOfSottoConto] = useState([]);
  const [sottoConto, setSottoConto] = useState(listOfSottoConto[0]);
  const [inputSottoConto, setInputSottoConto] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [allowCalcOnChangeDocType, setAllowCalcOnChangeDocType] =
    useState(true);

  const [vatNumber, setVatNumber] = useState("");
  const [protocol, setProtocol] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [rows, setRows] = useState([]);
  const [totalTaxableIncome, setTotalTaxableIncome] = useState(0);
  const [totalDeductibleVAT, setTotalDeductibleVAT] = useState(0);
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [totalNonDeductibleVAT, setTotalNonDeductibleVAT] = useState(0);
  const [subaccountRows, setSubaccountRows] = useState([]);
  const [totalAmountHave, setTotalAmountHave] = useState(0);
  const [totalAmountGive, setTotalAmountGive] = useState(0);
  const [squaring, setSquaring] = useState(0);
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);
  const [isSaveNConti, setIsSaveNConti] = useState(false);
  const [note, setNote] = useState("");
  const [openAddUser, setOpenAddUser] = useState(false);
  const [vatAmount, setVatAmount] = useState(0);
  const [reload, setReload] = useState(false);
  const [timePeriod, setTimePeriod] = useState(null);
  // const [isFirstTimeOnly, setIsFirstTimeOnly] = useState(true);
  const [isChangeRegDate, setIsChangeRegDate] = useState(false);
  const [isChangeDate, setIsChangeDate] = useState(false);
  const [editingIndexGive, setEditingIndexGive] = useState(null);
  const [editingIndexHave, setEditingIndexHave] = useState(null);
  const [editingIndexTax, setEditingIndexTax] = useState(null);
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()

  useEffect(() => {
    if (props.open) {
      if (!props.isEdit) {
        getInvoiceNumber();
      }
    }

    getSupplierList();
    getVatsByInvoiceType();
    setListOfDocument(documentList);
    if (props && props?.stateObj) {
      console.log("EDIT OBJECT::: ", props?.stateObj);
      formik.setValues({
        supplier: props?.stateObj?.supplierId
          ? props?.stateObj?.supplierId
          : "",
        inputSupplier: props?.stateObj?.supplierName
          ? props?.stateObj?.supplierName
          : "",
        regDate: props?.stateObj?.registrationDate
          ? dayjs(props?.stateObj?.registrationDate).format("YYYY-MM-DD")
          : "",

        date: props?.stateObj?.documentDate
          ? dayjs(props?.stateObj?.documentDate).format("YYYY-MM-DD")
          : "",
        invoiceNumber: props?.stateObj?.invoiceNo
          ? props?.stateObj?.invoiceNo
          : "",
        deductibility: props?.stateObj?.deductibility
          ? props?.stateObj?.deductibility
          : "",
      });

      const parsedTimePeriod = isValid(parseISO(props?.stateObj?.timePeriod))
        ? parseISO(props?.stateObj?.timePeriod)
        : null;
      setTimePeriod(parsedTimePeriod);

      setSelectedSupplierId(
        props?.stateObj?.supplierId ? props?.stateObj?.supplierId : null
      );
      setVatNumber(
        props?.stateObj?.supplierVatNo ? props?.stateObj?.supplierVatNo : ""
      );

      setProtocol(props?.stateObj?.protocol ? props?.stateObj?.protocol : "");

      if (props?.stateObj?.documentType == 1) {
        setDocument(
          documentList.find((item) => item.name === "Nota di Credito")
        );
        setInputDocument();
      } else {
        setDocument(documentList.find((item) => item.name === "Fattura"));
        setInputDocument();
      }

      setTotalInvoice(
        props?.stateObj?.totalInvoice ? props?.stateObj?.totalInvoice : 0
      );
      setTotalTaxableIncome(
        props?.stateObj?.totalTaxableAmount
          ? props?.stateObj?.totalTaxableAmount
          : 0
      );
      setTotalDeductibleVAT(
        props?.stateObj?.totalDeductibleVAT
          ? props?.stateObj?.totalDeductibleVAT
          : 0
      );
      setTotalNonDeductibleVAT(
        props?.stateObj?.totalNonDeductibleVAT
          ? props?.stateObj?.totalNonDeductibleVAT
          : 0
      );
      // setSubaccountRows(props?.stateObj?.subaccounts ? props?.stateObj?.subaccounts : [])
      setTotalAmountHave(
        props?.stateObj?.totalAmountHave ? props?.stateObj?.totalAmountHave : 0
      );
      setTotalAmountGive(
        props?.stateObj?.totalAmountGive ? props?.stateObj?.totalAmountGive : 0
      );
      setSquaring(props?.stateObj?.squaring ? props?.stateObj?.squaring : 0);
      setNote(props?.stateObj?.note ? props?.stateObj?.note : "");
    }
    if (props.open && !props.isEdit) {
      formik.resetForm();
      setTimePeriod(null);
    }
  }, [props?.stateObj, props.open]);


  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  const getSupplierList = (supplierId = null) => {
    API.get(apiURL.service_get_all_suppliers)
      .then(async (res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setListOfSupplier(res?.data?.data?.suppliers);

          if (supplierId != null) {
            const newSupplier = res?.data?.data?.suppliers.filter(
              (obj) => obj._id === supplierId
            );
            handleSupplierChange(newSupplier[0]);
          }

          if (props?.isEdit) {
            const selectedSupplier = res?.data?.data?.suppliers.filter(
              // (item) => item._id === props?.stateObj?.supplierId
              (item) =>
                item._id ===
                (supplierId === null ? props?.stateObj?.supplierId : supplierId)
            );

            if (selectedSupplier.length > 0) {
              const requestObject = {};
              requestObject.supplierId = selectedSupplier[0]._id;

              // console.log(requestObject)
              const supplierSubaccount = await API.post(
                apiURL.service_get_subaccount_of_supplier,
                requestObject
              );
              // console.log(supplierSubaccount);
              if (supplierSubaccount && supplierSubaccount?.data?.success) {
                const subaccount = supplierSubaccount?.data?.data?.subaccount;
                selectedSupplier[0].subaccounts = subaccount;
              }

              formik.setFieldValue("supplier", selectedSupplier[0]);

              if (props?.stateObj?.subaccounts.length > 0) {
                const selectedSupplierExist =
                  props?.stateObj?.subaccounts.filter(
                    (item) => item.isSupplier === true
                  );
                console.log("selectedClientExist", selectedSupplierExist);

                if (selectedSupplierExist.length > 0) {
                  let currentObject = selectedSupplierExist[0];

                  if (currentObject) {
                    const selectedArray = selectedSupplier[0].subaccounts[0];

                    // console.log('selectedArray=====',selectedArray)

                    let subaccountObject = {};

                    subaccountObject.accountId = selectedArray;
                    subaccountObject.inputAccount = ` ${selectedArray.type}.${selectedArray?.accountId?.code}.${selectedArray.code} - ${selectedArray.name}`;
                    subaccountObject.amountGive = currentObject.amountGive;
                    subaccountObject.amountHave = currentObject.amountHave;
                    subaccountObject.isSupplier = true;

                    setSubaccountRows([subaccountObject, ...subaccountRows]);
                  }
                }
              }
            }
          }
          // getSubAccounts();
          if (supplierId === null) {
            getSubAccounts();
          }
        } else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(false);
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
           doLogout()
          }
        }
        if (listOfSupplier.length == 0) {
          getSubAccounts();
        }
      });
  };

  const checkYearClosed = useCallback(
    debounce(async (regiDate) => {
      // const year = dayjs(regiDate).format("YYYY");
      if (regiDate == "Invalid Date") {
        return;
      }
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {};
      requestObject.year =regiDate ? dayjs(regiDate).format("YYYY") : "";
      requestObject.accountingSubjectId = accountingSubject?._id;
      // console.log(requestObject);
      const checkYearResponse = await API.post(
        apiURL.service_check_year_closed,
        requestObject
      );
      // console.log(checkYearResponse);
      if (checkYearResponse && checkYearResponse?.data?.success) {
        if (
          checkYearResponse?.data?.message?.isClosed == 1 ||
          checkYearResponse?.data?.message?.isClosed == 0
        ) {
          console.log("isChangeDate: ",isChangeDate)
          if (isChangeDate == true) {
            formik?.setFieldTouched("date", true, false);
            formik?.setErrors({
              date: t("yearIsClosed"),
            });
          }

          if (isChangeRegDate == true) {
            formik?.setFieldTouched("regDate", true, false);
            formik?.setErrors({
              regDate: t("yearIsClosed"),
            });
          }
        } else {
        }
      }
    }, 500),
    [isChangeDate,isChangeRegDate]
  );

  const checkPreviousDocumentDate = useCallback(
    debounce(async (date) => {
      const documentDate = dayjs(date).format("YYYY-MM-DD");
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {};
      requestObject.invoiceId = props?.isEdit ? props.stateObj?._id : 0;
      requestObject.registrationDate = documentDate;
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.protocol = protocol;

      const checkPreviousDateResponse = await API.post(
        apiURL.service_check_register_date_purchase,
        requestObject
      );
      if (
        checkPreviousDateResponse &&
        checkPreviousDateResponse?.data?.success
      ) {
        if (checkPreviousDateResponse?.data?.data?.isAllow == 0) {
          formik?.setFieldTouched("regDate", true, false);
          if (props?.isEdit) {
            formik?.setErrors({
              regDate: t("wrongDate"),
            });
          } else {
            formik?.setErrors({
              regDate: t("checkPreviousRegDate"),
            });
          }
          // return 1;
        } else {
          // return 0;
        }
      }
    }, 500),
    [props?.isEdit]
  );

  const getInvoiceNumber = async (yearTyped) => {
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    const currentDate = new Date();

    let regisDate = "";
    if (yearTyped == "Invalid") {
      regisDate = null;
    } else {
      regisDate = yearTyped
        ? dayjs(yearTyped).format("YYYY-MM-DD")
        : formik.values.regDate
        ? dayjs(formik.values.regDate).format("YYYY-MM-DD")
        : dayjs(currentDate).format("YYYY-MM-DD");
    }
    const requestObject = {
      accountingSubjectId: accountingSubject?._id,
      // registrationDate: formik.values.regDate
      //   ? dayjs(formik.values.regDate).format("YYYY-MM-DD")
      //   : dayjs(currentDate).format("YYYY-MM-DD"),

      registrationDate: regisDate,
    
      };

    API.post(apiURL.service_get_protocol_purchase, requestObject)
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setProtocol(res?.data?.data?.invoice?.invoiceNumber);
        } else {
          // setOpen(true);
          // setErrorMsg(t("common.internalServerError"));
          // setSeverity("error");
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        // setOpen(true);
        // setSeverity("error");
        // if (error.code === defaultString.network_error)
        //   setErrorMsg(t("common.networkError"));
        // else if (error.code === defaultString.bad_request) {
        //   if (error.response.data.status === apiStatusCode.NOTFOUND) {
        //     setOpen(false);
        //   } else if (
        //     error.response.data.status === apiStatusCode.UNAUTHORIZED
        //   ) {
        //     setOpen(true);
        //     setErrorMsg(t("common.unAuthorized"));
        //   }
        // }
      });
  };

  const handleAutocompleteChange = (index, newValue) => {
    const updatedRows = [...rows];
    updatedRows[index].iVA = newValue;
    updatedRows[index].rate = newValue ? newValue.rate : "";
    setRows(updatedRows);
    setEditingIndexTax(null);
    calculatePercentage();
  };

  const handleInputChange = (index, newInputValue) => {
    setEditingIndexGive(null);
    setEditingIndexHave(null);
    setEditingIndexTax(null);
    const updatedRows = [...rows];
    updatedRows[index].inputIVA = newInputValue;

    setRows(updatedRows);
  };

  const addRow = () => {
    setRows([
      ...rows,
      {
        iVA: "",
        inputIVA: "",
        deductibility: 100,
        taxableIncome: 0,
        deductibleVAT: 0,
        nonDeductibleVAT: 0,
        rate: 0,
      },
    ]);
  };

  const removeRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);

    let totalInvoice = 0;
    let totalDeductibility = 0;
    let totalTaxableIncome = 0;
    let totalNonDeductibleVAT = 0;
    let newVatAmount = 0;

    const newRows = updatedRows.map((row) => {
      newVatAmount = parseFloat(row.taxableIncome) * parseFloat(row.rate);
      const newTotalDeductibleVATPercent = parseFloat(newVatAmount) / 100;
      const newDeductibilityPercent = parseFloat(
        (parseFloat(row.deductibility) * newTotalDeductibleVATPercent) / 100
      );
      const newTotalNonDeductibleVAT = parseFloat(
        newTotalDeductibleVATPercent - newDeductibilityPercent
      );
      const rowTotalInvoice =
        parseFloat(row.taxableIncome) +
        newDeductibilityPercent +
        newTotalNonDeductibleVAT;
      totalInvoice += rowTotalInvoice;
      totalDeductibility += parseFloat(newDeductibilityPercent);
      totalTaxableIncome += parseFloat(row.taxableIncome);
      totalNonDeductibleVAT += parseFloat(newTotalNonDeductibleVAT);

      return {
        ...row,
        deductibleVAT: isNaN(newDeductibilityPercent)
          ? 0
          : newDeductibilityPercent != 0
          ? parseFloat(newDeductibilityPercent).toFixed(2)
          : "",
        nonDeductibleVAT: isNaN(newTotalNonDeductibleVAT)
          ? 0
          : newTotalNonDeductibleVAT != 0
          ? parseFloat(newTotalNonDeductibleVAT).toFixed(2)
          : "",
      };
    });

    setRows(newRows);
    setTotalDeductibleVAT(
      isNaN(totalDeductibility) ? 0 : parseFloat(totalDeductibility).toFixed(2)
    );
    setTotalInvoice(
      isNaN(totalInvoice) ? 0 : parseFloat(totalInvoice).toFixed(2)
    );
    setTotalTaxableIncome(
      isNaN(totalTaxableIncome) ? 0 : parseFloat(totalTaxableIncome).toFixed(2)
    );
    setTotalNonDeductibleVAT(
      isNaN(totalNonDeductibleVAT)
        ? 0
        : parseFloat(totalNonDeductibleVAT).toFixed(2)
    );

    const data = subaccountRows.map((item) => {
      if (item?.isSupplier == true && document.type === 2) {
        item.amountHave =
          totalInvoice != 0 ? parseFloat(totalInvoice).toFixed(2) : "";
        // item.amountGive = 0;
      } else if (item?.isSupplier == true && document.type === 1) {
        item.amountGive =
          totalInvoice != 0 ? parseFloat(totalInvoice).toFixed(2) : "";
        // item.amountHave = 0;
      } else if (document.type === 2) {
        if (item?.accountId?.accountId?.code == "11" && item?.code == "2") {
          item.amountGive =
            totalDeductibility != 0
              ? parseFloat(totalDeductibility).toFixed(2)
              : "";
          // item.amountHave = 0;
        }
      } else if (document.type === 1) {
        if (item?.accountId?.accountId?.code == "11" && item?.code == "2") {
          item.amountHave =
            totalDeductibility != 0
              ? parseFloat(totalDeductibility).toFixed(2)
              : "";
          // item.amountGive = 0;
        }
      }

      return item;
    });

    setSubaccountRows(data);

    let newHaveAmount = 0;
    let newGiveAmount = 0;

    subaccountRows.map((item) => {
      newHaveAmount = newHaveAmount + Number(item.amountHave);
      newGiveAmount = newGiveAmount + Number(item.amountGive);
    });

    setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);
    setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

    let squaringAmount = newGiveAmount - newHaveAmount;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
  };

  const getVatsByInvoiceType = async () => {
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );

    API.get(
      `${apiURL.service_get_vats_by_invoice_type}?id=${accountingSubject?._id}&type=3`
    )
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setListOfIVA(res?.data?.data?.vat);
          if (props?.isEdit) {
            if (props?.stateObj?.vats.length > 0) {
              const arrayVatTables = [];
              for (let i = 0; i < props?.stateObj?.vats.length; i++) {
                let currentObject = props?.stateObj?.vats[i];

                const selectedArray = res?.data?.data?.vat.filter(
                  (item) => item._id == currentObject.vatId
                );

                let vatObject = {};
                vatObject.iVA = selectedArray[0];
                vatObject.inputIVA = `${selectedArray[0].code} - ${selectedArray[0].description}`;
                vatObject.rate = currentObject.rate;
                vatObject.taxableIncome = currentObject.taxableIncome;
                vatObject.deductibility = currentObject.deductibility;
                vatObject.deductibleVAT = isNaN(currentObject.deductibleVat)
                  ? 0
                  : currentObject.deductibleVat;
                vatObject.nonDeductibleVAT = isNaN(
                  currentObject.nonDeductibleVat
                )
                  ? 0
                  : currentObject.nonDeductibleVat;

                arrayVatTables.push(vatObject);
              }
              setRows(arrayVatTables);
            }
          }
        } else {
          // setOpen(true);
          // setErrorMsg(t("common.internalServerError"));
          // setSeverity("error");
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        // setOpen(true);
        // setSeverity("error");
        // if (error.code === defaultString.network_error)
        //   setErrorMsg(t("common.networkError"));
        // else if (error.code === defaultString.bad_request) {
        //   if (error.response.data.status === apiStatusCode.NOTFOUND) {
        //     setOpen(false);
        //   } else if (
        //     error.response.data.status === apiStatusCode.UNAUTHORIZED
        //   ) {
        //     setOpen(true);
        //     setErrorMsg(t("common.unAuthorized"));
        //   }
        // }
      });
  };

  const getSubAccounts = async () => {
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );

    API.get(
      `${apiURL.service_supplier_sub_accounts_for_invoice}/${accountingSubject?._id}`
    )
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setListOfSottoConto(res?.data?.data?.subaccounts);

          if (props?.isEdit) {
            if (props?.stateObj?.subaccounts.length > 0) {
              const selectedisSupplierExist =
                props?.stateObj?.subaccounts.filter(
                  (item) => item.isSupplier === false
                );

              if (selectedisSupplierExist.length > 0) {
                const newArray = [];
                for (let i = 0; i < selectedisSupplierExist.length; i++) {
                  let currentObject = selectedisSupplierExist[i];

                  const selectedArray = (res?.data?.data?.subaccounts).filter(
                    (item) => item._id === currentObject.subaccountId
                  );

                  let subaccountObject = {};

                  subaccountObject.accountId = selectedArray[0];
                  subaccountObject.inputAccount = ` ${selectedArray[0]?.type}.${selectedArray[0]?.accountId?.code}.${selectedArray[0]?.code} - ${selectedArray[0]?.name}`;
                  subaccountObject.amountGive = currentObject?.amountGive;
                  subaccountObject.amountHave = currentObject?.amountHave;
                  subaccountObject.code = selectedArray[0]?.code;
                  subaccountObject.type = selectedArray[0]?.type;

                  newArray.push(subaccountObject);

                  // setSubaccountRows(prevArray => [...prevArray, subaccountObject]);
                }
                // setSubaccountRows((prevArray) => [...prevArray, ...newArray]);
                setSubaccountRows((prevArray) =>
                  Array.from(
                    new Set([...prevArray, ...newArray].map(JSON.stringify))
                  ).map(JSON.parse)
                );
              }
            }
          }
        } else {
          // setOpen(true);
          // setErrorMsg(t("common.internalServerError"));
          // setSeverity("error");
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        // setOpen(true);
        // setSeverity("error");
        // if (error.code === defaultString.network_error)
        //   setErrorMsg(t("common.networkError"));
        // else if (error.code === defaultString.bad_request) {
        //   if (error.response.data.status === apiStatusCode.NOTFOUND) {
        //     setOpen(false);
        //   } else if (
        //     error.response.data.status === apiStatusCode.UNAUTHORIZED
        //   ) {
        //     setOpen(true);
        //     setErrorMsg(t("common.unAuthorized"));
        //   }
        // }
      });
  };

  const handleVatChange = (event) => {
    formik.handleChange(event);
    // if (event.target.value.length > 0) {
    //   checkInvoiceNumber(event.target.value);
    // }
  };

  const checkInvoiceNumber = useCallback(
    debounce(async (value) => {
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {};
      requestObject.invoiceId = props?.isEdit ? props.stateObj?._id : 0;
      requestObject.invoiceNo = value;
      requestObject.accountingSubjectId = accountingSubject?._id;

      API.post(
        apiURL.service_check_purchase_invoice_number,
        requestObject
      ).then((checkInvoiceResponse) => {
        console.log("checkCodeResponse", checkInvoiceResponse);
        if (checkInvoiceResponse?.data?.data?.isExist) {
          formik?.setFieldTouched("invoiceNumber", true, false);
          formik?.setErrors({
            invoiceNumber: t("invoiceNumberExist"),
          });
        } else {
          formik?.setFieldTouched("invoiceNumber", true, false);
          formik?.setErrors({
            invoiceNumber: null,
          });
        }
      });
    }, 500),
    []
  );

  function convertEuroToIND(euroFormat) {
    if (!euroFormat || typeof euroFormat !== "string") {
      return 0; // or handle the error in an appropriate way
    }

    const withoutThousandsSeparator = euroFormat.replace(/\./g, "");
    const standardFormat = withoutThousandsSeparator.replace(",", ".");
    return Number(standardFormat);
  }

  const handleTaxChange = (index, taxableIncome) => {
    const updatedData = [...rows];
    // const numericTaxableIncome = parseFloat(taxableIncome);
    // updatedData[index].taxableIncome = isNaN(numericTaxableIncome) ? 0 : numericTaxableIncome;
    const numericTaxableIncome = convertEuroToIND(taxableIncome);
   
    let newTaxableIncome = parseFloat(numericTaxableIncome);
    if (isNaN(numericTaxableIncome)) {
      newTaxableIncome = 0;
    } else if (Math.abs(numericTaxableIncome) == 0) {
      newTaxableIncome = 0;
    }
    updatedData[index].taxableIncome = newTaxableIncome;

    setRows(updatedData);
    setEditingIndexGive(null);
    setEditingIndexHave(null);
    setEditingIndexTax(index);
    calculatePercentage();
  };

  const calculatePercentage = () => {
    let totalInvoice = 0;
    let totalDeductibility = 0;
    let totalTaxableIncome = 0;
    let totalNonDeductibleVAT = 0;
    let newVatAmount = 0;

    const updatedData = [...rows];
    const updatedRows = updatedData.map((row) => {
      let newRowAmount =
        document?.type === 1
          ? Math.abs(parseFloat(row.taxableIncome))
          : parseFloat(row.taxableIncome);
      // if (document?.type === 1) {
      //   newVatAmount = Math.abs(
      //     parseFloat(row.taxableIncome) * parseFloat(row.rate)
      //   );
      // } else {
      //   newVatAmount = parseFloat(row.taxableIncome) * parseFloat(row.rate);
      // }
      newVatAmount = newRowAmount * parseFloat(row.rate);

      const newTotalDeductibleVATPercent = parseFloat(newVatAmount) / 100;
      const newDeductibilityPercent = parseFloat(
        (parseFloat(row.deductibility) * newTotalDeductibleVATPercent) / 100
      );
      const newTotalNonDeductibleVAT = parseFloat(
        newTotalDeductibleVATPercent - newDeductibilityPercent
      );
      const rowTotalInvoice =
        newRowAmount + newDeductibilityPercent + newTotalNonDeductibleVAT;
      totalInvoice += rowTotalInvoice;
      totalDeductibility += parseFloat(newDeductibilityPercent);
      totalTaxableIncome += newRowAmount;
      totalNonDeductibleVAT += parseFloat(newTotalNonDeductibleVAT);
      return {
        ...row,
        deductibleVAT: isNaN(newDeductibilityPercent)
          ? 0
          : newDeductibilityPercent != 0
          ? parseFloat(newDeductibilityPercent).toFixed(2)
          : "",
        nonDeductibleVAT: isNaN(newTotalNonDeductibleVAT)
          ? 0
          : newTotalNonDeductibleVAT != 0
          ? parseFloat(newTotalNonDeductibleVAT).toFixed(2)
          : "",
      };
    });

    setRows(updatedRows);
    setTotalDeductibleVAT(
      isNaN(totalDeductibility) ? 0 : parseFloat(totalDeductibility).toFixed(2)
    );
    setTotalInvoice(
      isNaN(totalInvoice) ? 0 : parseFloat(totalInvoice).toFixed(2)
    );
    setTotalTaxableIncome(
      isNaN(totalTaxableIncome) ? 0 : parseFloat(totalTaxableIncome).toFixed(2)
    );
    setTotalNonDeductibleVAT(
      isNaN(totalNonDeductibleVAT)
        ? 0
        : parseFloat(totalNonDeductibleVAT).toFixed(2)
    );

    const data = subaccountRows.map((item) => {
      if (item?.isSupplier == true && document.type === 2) {
        item.amountHave =
          totalInvoice != 0
            ? parseFloat(parseFloat(totalInvoice).toFixed(2))
            : "";
        // item.amountGive = 0;
      } else if (item?.isSupplier == true && document.type === 1) {
        item.amountGive =
          totalInvoice != 0
            ? parseFloat(parseFloat(totalInvoice).toFixed(2))
            : "";
        // item.amountHave = 0;
      } else if (document.type === 2) {
        if (item?.accountId?.accountId?.code == "11" && item?.code == "2") {
          item.amountGive =
            totalDeductibility != 0
              ? parseFloat(parseFloat(totalDeductibility).toFixed(2))
              : "";
          // item.amountHave = 0;
        }
      } else if (document.type === 1) {
        if (item?.accountId?.accountId?.code == "11" && item?.code == "2") {
          item.amountHave =
            totalDeductibility != 0
              ? parseFloat(parseFloat(totalDeductibility).toFixed(2))
              : "";
          // item.amountGive = 0;
        }
        item.amountHave = Math.abs(item.amountHave);
        item.amountGive = Math.abs(item.amountGive);
      }

      return item;
    });

    setSubaccountRows(data);

    let newHaveAmount = 0;
    let newGiveAmount = 0;

    subaccountRows.map((item) => {
      newHaveAmount = newHaveAmount + Number(item.amountHave);
      newGiveAmount = newGiveAmount + Number(item.amountGive);
    });

    setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);
    setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

    let squaringAmount = newGiveAmount - newHaveAmount;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
  };

  const handleDeductibilityChange = (index, deductibility) => {
    setEditingIndexGive(null);
    setEditingIndexHave(null);
    const updatedData = [...rows];
    // const numericDeductibility = parseFloat(deductibility);
    // updatedData[index].deductibility = isNaN(numericDeductibility) ? 0 : numericDeductibility;
    const numericDeductibility = convertEuroToIND(deductibility);
    if (isNaN(numericDeductibility) || numericDeductibility > 100) {
      updatedData[index].deductibility = 100;
      setOpen(true);
      setSeverity("error");
      setErrorMsg(t("deductibiltyValidation"));

      return;
    }
    if (isNaN(numericDeductibility) || numericDeductibility < 0) {
      updatedData[index].deductibility = 0;
      setOpen(true);
      setSeverity("error");
      setErrorMsg(t("deductibiltyValidation"));

      return;
    }
    updatedData[index].deductibility = isNaN(numericDeductibility)
      ? 0
      : Math.abs(numericDeductibility);

    setRows(updatedData);
    calculatePercentage();
  };

  const fnCommonDefaultRows = () => {
    if (props.defaultRows) {
      if (listOfIVA.length > 0) {
        const filteredRowsIVA = listOfIVA.filter((item) => item.code == "22");

        const updatedRowsIVA = filteredRowsIVA.map((row) => ({
          ...row,
          iVA: row,
          inputIVA: "",
          deductibility: 100,
          taxableIncome: 0,
          deductibleVAT: 0,
          nonDeductibleVAT: 0,
          rate: row.rate,
        }));
        setRows(updatedRowsIVA);
      }
    }
    if (props.defaultRows) {
      if (listOfSottoConto.length > 0) {
        const filteredRowsSottoConto = listOfSottoConto.filter(
          (item) =>
            item?.accountId?.code == "11" &&
            item?.code == "2" &&
            item?.type == "1"
        );
        const updatedRowsSottoConto = filteredRowsSottoConto.map((row) => ({
          ...row,
          accountId: row, // Assign a specific item from filteredRows
          inputAccount: "",
          amountGive: 0,
          amountHave: 0,
          isBlock: false,
        }));
        const emptyRowsSottoConto = {
          accountId: null, // Assign a specific item from filteredRows
          inputAccount: "",
          amountGive: 0,
          amountHave: 0,
          isBlock: false,
        };
        setSubaccountRows([...updatedRowsSottoConto, emptyRowsSottoConto]);
      }
    }
  };

  useEffect(() => {
    fnCommonDefaultRows();
  }, [props.defaultRows, listOfIVA, listOfSottoConto]);
  //  }, [listOfIVA, listOfSottoConto, props.defaultRows]);

  const fnAddInvoice = async () => {
    if (!!formik.values) {
      
      let accountingSubject = await getDataFromStorage("accountingSubject");

      if (rows.length == 0 || subaccountRows.length == 0) {
        setOpen(true);
        setErrorMsg(t("requiredInvoiceData"));
        setSeverity("error");
        return
      }

      setIsLoading(true);
      const vatList = [];
      for (let vat of rows) {
        let vatObj = {};
        vatObj.vatId = vat.iVA._id;
        vatObj.name = vat.iVA.description;
        vatObj.code = vat.iVA.code;
        vatObj.rate = vat.iVA.rate;
        
        vatObj.taxableIncome = isNaN(vat.taxableIncome)
          ? 0
          : document.type === 1
          ? Math.abs(parseFloat(vat.taxableIncome)).toFixed(2)
          : parseFloat(vat.taxableIncome).toFixed(2);
       
        vatObj.taxableIncome = isNaN(vatObj.taxableIncome)
          ? 0
          : vatObj.taxableIncome;

        vatObj.vatAmount = isNaN(
          Math.abs(parseFloat(vat.taxableIncome * vat.rate) / 100)
        )
          ? 0
          : document.type === 1
          ? Math.abs(parseFloat((vat.taxableIncome * vat.rate) / 100)).toFixed(
              2
            )
          : parseFloat((vat.taxableIncome * vat.rate) / 100).toFixed(2);

        vatObj.deductibility = isNaN(vat.deductibility)
          ? 0
          : Math.abs(vat.deductibility).toFixed(2);

        vatObj.deductibleVat = isNaN(parseFloat(vat.deductibleVAT))
          ? 0
          : parseFloat(vat.deductibleVAT).toFixed(2);

        vatObj.nonDeductibleVat = isNaN(parseFloat(vat.nonDeductibleVAT))
          ? 0
          : parseFloat(vat.nonDeductibleVAT).toFixed(2);
        if (vat.inputIVA == "") {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("selectIVA"));
          return;
        }
        vatList.push(vatObj);
      }

      const subAccountList = [];
      for (let account of subaccountRows) {
        let accountObj = {};
        accountObj.accountId = account?.accountId?.accountId?._id;
        accountObj.subaccountId = account?.accountId?._id;
        accountObj.name = account?.accountId?.name;
        accountObj.code = account?.accountId?.code;
        accountObj.type = account?.accountId?.type;
        accountObj.amountGive = isNaN(account.amountGive)
          ? 0
          : account.amountGive == ""
          ? 0
          : parseFloat(account.amountGive).toFixed(2);
        accountObj.amountHave = isNaN(account.amountHave)
          ? 0
          : account.amountHave == ""
          ? 0
          : parseFloat(account.amountHave).toFixed(2);
        accountObj.isSupplier = account.isSupplier;

        if (account.inputAccount == "") {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("selectSubaccount"));
          return;
        }
        subAccountList.push(accountObj);
      }

      const requestObject = {};
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.invoiceNo = formik.values.invoiceNumber
        ? formik.values.invoiceNumber
        : "";
      requestObject.protocol = protocol;
      requestObject.supplierId = formik.values.supplier
        ? formik.values.supplier?._id
        : "";
      requestObject.supplierName = formik.values.supplier
        ? formik.values.supplier?.name
        : "";
      requestObject.supplierVatNo = formik.values.supplier
        ? formik.values.supplier?.vatNumber
        : "";
      requestObject.registrationDate = formik.values.regDate
        ? formik.values.regDate
        : "";
      requestObject.documentDate = formik.values.date ? formik.values.date : "";

      requestObject.timePeriod = timePeriod ? timePeriod : "";
      requestObject.documentType = document?.type ? document?.type : "1";
      requestObject.vats = vatList;
      requestObject.totalDeductibleVAT = isNaN(totalDeductibleVAT)
        ? 0
        : Math.abs(parseFloat(totalDeductibleVAT)).toFixed(2);
      requestObject.totalNonDeductibleVAT = isNaN(totalNonDeductibleVAT)
        ? 0
        : Math.abs(parseFloat(totalNonDeductibleVAT)).toFixed(2);
      requestObject.totalTaxableAmount = isNaN(totalTaxableIncome)
        ? 0
        : Math.abs(parseFloat(totalTaxableIncome)).toFixed(2);
      requestObject.totalInvoice = isNaN(totalInvoice)
        ? 0
        : Math.abs(parseFloat(totalInvoice)).toFixed(2);
      requestObject.subaccounts = subAccountList;
      requestObject.totalAmountGive = isNaN(totalAmountGive)
        ? 0
        : Math.abs(parseFloat(totalAmountGive)).toFixed(2);
      requestObject.totalAmountHave = isNaN(totalAmountHave)
        ? 0
        : Math.abs(parseFloat(totalAmountHave)).toFixed(2);
      requestObject.squaring = isNaN(squaring)
        ? 0
        : Math.abs(parseFloat(squaring)).toFixed(2);
      requestObject.note = note ? note : "";

      console.log(requestObject);

      API.post(apiURL.service_add_purchase_invoice, requestObject)
        .then((response) => {
          console.log("response", response);
          setIsLoading(false);
          setReload(true);
          if (response.status === apiStatusCode.CREATED) {
            setOpen(true);
            setSeverity("success");
            setErrorMsg(t("common.addSuccess"));

            const regiDate = formik.values.regDate;
            formik.resetForm();
            formik.setFieldValue("regDate", regiDate);
            setRows([]);
            setSubaccountRows([]);
            // setTotal(0);
            // setTimePeriod(null);

            setTotalTaxableIncome(0);
            setTotalDeductibleVAT(0);
            setTotalInvoice(0);
            setTotalNonDeductibleVAT(0);

            setTotalAmountHave(0);
            setTotalAmountGive(0);
            setSquaring(0);
            setSelectedSupplierId(null);
            setVatNumber("");
            setInvoiceNumber("");
            setDocument(documentList.find((item) => item.name === "Fattura"));
            setNote("");
            setProtocol("");
            // setTimePeriod(null);
            setIsChangeDate(false);
            setIsChangeRegDate(false);
            // setIsFirstTimeOnly(true);

            if (isSaveNConti) {
              setIsSaveNConti(false);
              fnCommonDefaultRows();
              getInvoiceNumber();
            } else {
              props.handleClose({ reload: true });
            }
          } else {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("common.internalServerError"));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
              doLogout()
            } else {
              setErrorMsg(t("common.badRequest"));
            }
          }
        });
    } else {
      setOpen(true);
      setErrorMsg(t("requiredInfo"));
      setSeverity("error");
    }
  };

  const onSaveAndContinueClicked = (e, isContinue) => {
    e.preventDefault();
    // setIsFirstTimeOnly(true);
    if (timePeriod === null) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(t("timePeriodIsRequired"));
    }
    setIsSaveNConti(isContinue);
    formik?.handleSubmit();
  };

  const backToList = (reload) => {
    props.handleClose({ reload: reload });

    formik.resetForm();
    setRows([]);
    setSubaccountRows([]);
    setTotalDeductibleVAT(0);
    setTotalTaxableIncome(0);
    setTotalInvoice(0);
    setTotalAmountHave(0);
    setTotalAmountGive(0);
    setSquaring(0);
    setSelectedSupplierId(null);
    setVatNumber("");
    setInvoiceNumber("");
    setTimePeriod(null);
    setDocument(documentList.find((item) => item.name === "Fattura"));
    setNote("");
    setTotalNonDeductibleVAT(0);
    setIsSaveNConti(false);
    setIsChangeDate(false);
    setIsChangeRegDate(false);
    // setIsFirstTimeOnly(true);
  };

  const fnEditInvoice = async () => {
    if (!!formik.values) {

      let accountingSubject = await getDataFromStorage("accountingSubject");
      
      if (rows.length == 0 || subaccountRows.length == 0) {
        setOpen(true);
        setErrorMsg(t("requiredInvoiceData"));
        setSeverity("error");
        return
      }

      setIsLoading(true);
      const vatList = [];
      for (let vat of rows) {
        let vatObj = {};
        vatObj.vatId = vat.iVA._id;
        vatObj.name = vat.iVA.description;
        vatObj.code = vat.iVA.code;
        vatObj.rate = vat.iVA.rate;
        vatObj.taxableIncome = isNaN(vat.taxableIncome)
          ? 0
          : document.type === 1
          ? Math.abs(parseFloat(vat.taxableIncome)).toFixed(2)
          : parseFloat(vat.taxableIncome).toFixed(2);

        vatObj.taxableIncome = isNaN(vatObj.taxableIncome)
          ? 0
          : vatObj.taxableIncome;

        vatObj.vatAmount = isNaN(
          Math.abs(parseFloat(vat.taxableIncome * vat.rate) / 100)
        )
          ? 0
          : document.type === 1
          ? Math.abs(parseFloat((vat.taxableIncome * vat.rate) / 100)).toFixed(
              2
            )
          : parseFloat((vat.taxableIncome * vat.rate) / 100).toFixed(2);

        vatObj.deductibility = isNaN(vat.deductibility)
          ? 0
          : Math.abs(vat.deductibility).toFixed(2);

        vatObj.deductibleVat = isNaN(parseFloat(vat.deductibleVAT))
          ? 0
          : parseFloat(vat.deductibleVAT).toFixed(2);

        vatObj.nonDeductibleVat = isNaN(parseFloat(vat.nonDeductibleVAT))
          ? 0
          : parseFloat(vat.nonDeductibleVAT).toFixed(2);

        if (vat.inputIVA == "") {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("selectIVA"));
          return;
        }
        vatList.push(vatObj);
      }

      const subAccountList = [];
      for (let account of subaccountRows) {
        let accountObj = {};
        accountObj.accountId = account?.accountId?.accountId?._id;
        accountObj.subaccountId = account?.accountId?._id;
        accountObj.name = account?.accountId?.name;
        accountObj.code = account?.accountId?.code;
        accountObj.type = account?.accountId?.type;
        accountObj.amountGive = isNaN(account.amountGive)
          ? 0
          : account.amountGive == ""
          ? 0
          : parseFloat(account.amountGive).toFixed(2);
        accountObj.amountHave = isNaN(account.amountHave)
          ? 0
          : account.amountHave == ""
          ? 0
          : parseFloat(account.amountHave).toFixed(2);
        accountObj.isSupplier = account.isSupplier;

        if (account.inputAccount == "") {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("selectSubaccount"));
          return;
        }
        subAccountList.push(accountObj);
      }

      const requestObject = {};
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.invoiceNo = formik.values.invoiceNumber
        ? formik.values.invoiceNumber
        : "";
      requestObject.protocol = protocol;
      requestObject.supplierId = formik.values.supplier
        ? formik.values.supplier?._id
        : "";
      requestObject.supplierName = formik.values.supplier
        ? formik.values.supplier?.name
        : "";
      requestObject.supplierVatNo = formik.values.supplier
        ? formik.values.supplier?.vatNumber
        : "";
      requestObject.registrationDate = formik.values.regDate
        ? formik.values.regDate
        : "";
      requestObject.documentDate = formik.values.date ? formik.values.date : "";
      requestObject.timePeriod = timePeriod ? timePeriod : "";

      requestObject.documentType = document?.type ? document?.type : "1";
      requestObject.vats = vatList;
      requestObject.totalDeductibleVAT = isNaN(totalDeductibleVAT)
        ? 0
        : Math.abs(parseFloat(totalDeductibleVAT)).toFixed(2);
      requestObject.totalNonDeductibleVAT = isNaN(totalNonDeductibleVAT)
        ? 0
        : Math.abs(parseFloat(totalNonDeductibleVAT)).toFixed(2);
      requestObject.totalTaxableAmount = isNaN(totalTaxableIncome)
        ? 0
        : Math.abs(parseFloat(totalTaxableIncome)).toFixed(2);
      requestObject.totalInvoice = isNaN(totalInvoice)
        ? 0
        : Math.abs(parseFloat(totalInvoice)).toFixed(2);
      requestObject.subaccounts = subAccountList;
      requestObject.totalAmountGive = isNaN(totalAmountGive)
        ? 0
        : Math.abs(parseFloat(totalAmountGive)).toFixed(2);
      requestObject.totalAmountHave = isNaN(totalAmountHave)
        ? 0
        : Math.abs(parseFloat(totalAmountHave)).toFixed(2);
      requestObject.squaring = isNaN(squaring)
        ? 0
        : Math.abs(parseFloat(squaring)).toFixed(2);
      requestObject.note = note ? note : "";

      // console.log(requestObject);

      API.put(
        `${apiURL.service_update_purchase_invoice}/${props.stateObj?._id}`,
        requestObject
      )
        .then((response) => {
          console.log("response", response);
          setIsLoading(false);
          setReload(true);
          if (response.status === apiStatusCode.SUCCESS) {
            setOpen(true);
            setSeverity("success");
            setErrorMsg(t("common.updateSuccess"));

            formik.resetForm();
            setRows([]);
            setSubaccountRows([]);
            // setTotal(0);
            setTimePeriod(null);

            setTotalTaxableIncome(0);
            setTotalDeductibleVAT(0);
            setTotalInvoice(0);
            setTotalNonDeductibleVAT(0);

            setTotalAmountHave(0);
            setTotalAmountGive(0);
            setSquaring(0);
            setSelectedSupplierId(null);
            setVatNumber("");
            setInvoiceNumber("");
            setDocument(documentList.find((item) => item.name === "Fattura"));
            setNote("");
            setProtocol("");
            // setIsFirstTimeOnly(true);
            setIsChangeDate(false);
            setIsChangeRegDate(false);
            props.handleClose({ reload: true });
          } else {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("common.internalServerError"));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
              doLogout()
            } else {
              setErrorMsg(t("common.badRequest"));
            }
          }
        });
    } else {
      setOpen(true);
      setErrorMsg(t("requiredInfo"));
      setSeverity("error");
    }
  };

  useEffect(() => {
    let newHaveAmount = 0;
    let newGiveAmount = 0;

    subaccountRows.forEach((item) => {
      newHaveAmount += Number(item.amountHave);
      newGiveAmount += Number(item.amountGive);
    });

    setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);
    setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

    const squaringAmount = newGiveAmount - newHaveAmount;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
  }, [subaccountRows]);

  useEffect(() => {
    const calculateValues = () => {
      const updatedSubaccountRows = subaccountRows.map((item) => {
        if (item?.isSupplier === true && document && document.type === 2) {
          const originalAmountHave = parseFloat(item.amountHave); // Preserve original amountHave
          item.amountHave = parseFloat(totalInvoice);
          item.amountGive = originalAmountHave ? originalAmountHave : 0; // Set amountGive to the original value
        } else if (
          item?.isSupplier === true &&
          document &&
          document.type === 1
        ) {
          const originalAmountGive = parseFloat(item.amountGive); // Preserve original amountGive

          item.amountGive = Math.abs(parseFloat(totalInvoice));
          item.amountHave = originalAmountGive
            ? Math.abs(originalAmountGive)
            : 0; // Set amountHave to the original value
        } else if (document && document.type === 2) {
          if (item?.accountId?.accountId?.code === "11" && item?.code === "2") {
            const originalAmountGive = parseFloat(item.amountGive); // Preserve original amountGive

            item.amountGive = parseFloat(totalDeductibleVAT);
            item.amountHave = originalAmountGive ? originalAmountGive : 0; // Set amountHave to the original value
          }
        } else if (document && document.type === 1) {
          if (item?.accountId?.accountId?.code === "11" && item?.code === "2") {
            const originalAmountHave = parseFloat(item.amountHave); // Preserve original amountHave

            item.amountHave = Math.abs(parseFloat(totalDeductibleVAT));
            item.amountGive = originalAmountHave
              ? Math.abs(originalAmountHave)
              : 0; // Set amountGive to the original value
          }
        }

        return item;
      });

      setSubaccountRows(updatedSubaccountRows);

      let newHaveAmount = 0;
      let newGiveAmount = 0;

      const data = updatedSubaccountRows.forEach((item) => {
        newHaveAmount += Number(item.amountHave);
        newGiveAmount += Number(item.amountGive);
      });

      if (data) {
        setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);
        setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);
        const squaringAmount = newGiveAmount - newHaveAmount;
        setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
      }
    };
    calculateValues();

    if (allowCalcOnChangeDocType) {
      calculatePercentage();
      setAllowCalcOnChangeDocType(false);
    }
  }, [document]);

  const removeSubaccountRow = (index) => {
    const updatedRows = [...subaccountRows];
    updatedRows.splice(index, 1);
    setSubaccountRows(updatedRows);

    let newGiveAmount = 0;
    updatedRows.map((item) => {
      newGiveAmount = newGiveAmount + Number(item.amountGive);
    });

    setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

    let newHaveAmount = 0;

    updatedRows.map((item) => {
      newHaveAmount = newHaveAmount + Number(item.amountHave);
    });

    setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);

    let squaringAmount = newGiveAmount - newHaveAmount;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
  };

  const handleSubaccountAutocompleteChange = (index, newValue) => {
    if (newValue === null || newValue === undefined) {
      // Clearing the Autocomplete value, no need for duplicate check
      const updatedRows = [...subaccountRows];
      updatedRows[index].accountId = null;
      setSubaccountRows(updatedRows);
    } else {
      const isAccountSelected = subaccountRows.some(
        (row, rowIndex) =>
          rowIndex !== index &&
          row.accountId &&
          row.accountId.name === newValue.name
      );

      if (isAccountSelected) {
        const updatedRows = subaccountRows.filter(
          (_, rowIndex) => rowIndex !== index
        );
        setSeverity("error");
        setOpen(true);
        setErrorMsg(t("noDuplicateRow"));
        setSubaccountRows(updatedRows);
      } else {
        const updatedRows = [...subaccountRows];
        updatedRows[index].accountId = newValue;
        setSubaccountRows(updatedRows);
      }
    }
  };

  const handleSubaccountInputChange = (index, newInputValue) => {
    const updatedRows = [...subaccountRows];
    updatedRows[index].inputAccount = newInputValue;

    setSubaccountRows(updatedRows);
  };

  const addSubaccountRow = () => {
    setSubaccountRows([
      ...subaccountRows,
      {
        accountId: "",
        inputAccount: "",
        amountGive: 0,
        amountHave: 0,
        isBlock: false,
      },
    ]);
  };

  const handleAmountGiveChange = (index, newAmount) => {
    const updatedData = [...subaccountRows];
    const numericNewAmount = convertEuroToIND(newAmount);
    updatedData[index].amountGive = numericNewAmount;

    setSubaccountRows(updatedData);
    // calculatePercentage(updatedData);

    let newGiveAmount = 0;
    updatedData.map((item) => {
      newGiveAmount = newGiveAmount + Number(item.amountGive);
    });
    setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount.toFixed(2));

    let squaringAmount = newGiveAmount - totalAmountHave;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount);
    setEditingIndexGive(index);
  };

  const handleAmountHaveChange = (index, newAmount) => {
    const updatedData = [...subaccountRows];
    const numericNewAmount = convertEuroToIND(newAmount);

    updatedData[index].amountHave = numericNewAmount;

    setSubaccountRows(updatedData);

    let newHaveAmount = 0;
    updatedData.map((item) => {
      newHaveAmount = newHaveAmount + Number(item.amountHave);
    });
    setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);
    let squaringAmount = totalAmountGive - newHaveAmount;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
    setEditingIndexHave(index);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSupplierChange = async (newSupplier) => {
    if (newSupplier != null) {
      const requestObject = {};
      requestObject.supplierId = newSupplier._id;

      // console.log(requestObject)
      const supplierSubaccount = await API.post(
        apiURL.service_get_subaccount_of_supplier,
        requestObject
      );

      // console.log(supplierSubaccount);
      if (supplierSubaccount && supplierSubaccount?.data?.success) {
        const subaccount = supplierSubaccount?.data?.data?.subaccount;
        newSupplier.subaccounts = subaccount;

        formik.setFieldValue("supplier", newSupplier);

        setVatNumber(newSupplier ? newSupplier.vatNumber : "");

        if (newSupplier != null) {
          if (selectedSupplierId !== null) {
            const newArray = [];

            const newSubAccountArr = subaccountRows.filter(
              (object) => object?.accountId?.supplierId !== selectedSupplierId
            );

            let amountHave = 0;
            let amountGive = 0;

            if (document.type === 2) {
              amountHave = parseFloat(totalInvoice);
            }
            if (document.type === 1) {
              amountGive = parseFloat(totalInvoice);
            }

            newArray.push({
              accountId: newSupplier?.subaccounts[0],
              inputAccount: `${newSupplier?.subaccounts[0]?.type}.${newSupplier?.subaccounts[0]?.accountId?.code}.${newSupplier?.subaccounts[0]?.code} - ${newSupplier?.subaccounts[0]?.name}`,
              amountGive: amountGive,
              amountHave: amountHave,
              isBlock: true,
              isSupplier: true,
            });

            newSubAccountArr.map((obj) => {
              newArray.push(obj);
            });

            setSubaccountRows(newArray);

            let newGiveAmount = 0;
            let newHaveAmount = 0;

            newArray.map((item) => {
              newGiveAmount = newGiveAmount + Number(item.amountGive);
              newHaveAmount = newHaveAmount + Number(item.amountHave);
            });

            if (document.type === 2) {
              newHaveAmount = parseFloat(totalInvoice);
            } else if (document.type === 1) {
              newGiveAmount = parseFloat(totalInvoice);
            }

            setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

            setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);

            let squaringAmount = newGiveAmount - newHaveAmount;
            setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
          } else {
            let amountHave = 0;
            let amountGive = 0;
            if (document.type === 2) {
              amountHave = parseFloat(totalInvoice);
            }
            if (document.type === 1) {
              amountGive = parseFloat(totalInvoice);
            }
            setSubaccountRows([
              {
                accountId: newSupplier?.subaccounts[0],
                inputAccount: `${newSupplier?.subaccounts[0]?.type}.${newSupplier?.subaccounts[0]?.accountId?.code}.${newSupplier?.subaccounts[0]?.code} - ${newSupplier?.subaccounts[0]?.name}`,
                amountGive: amountGive,
                amountHave: amountHave,
                isBlock: true,
                isSupplier: true,
              },
              ...subaccountRows,
            ]);

            let newGiveAmount = 0;
            let newHaveAmount = 0;
            subaccountRows.map((item) => {
              newGiveAmount = newGiveAmount + Number(item.amountGive);
              newHaveAmount = newHaveAmount + Number(item.amountHave);
            });

            if (document.type === 2) {
              newHaveAmount = parseFloat(totalInvoice);
            } else if (document.type === 1) {
              newGiveAmount = parseFloat(totalInvoice);
            }
            setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

            setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);

            let squaringAmount = newGiveAmount - newHaveAmount;
            setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
          }

          setSelectedSupplierId(newSupplier?._id);
        }
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  function customSort(a, b) {
    const keyA = `${a.type}.${a?.accountId?.code}.${a.code}`;
    const keyB = `${b.type}.${b?.accountId?.code}.${b.code}`;

    const partsA = keyA.split('.').map(part => parseInt(part, 10));
    const partsB = keyB.split('.').map(part => parseInt(part, 10));
  
    for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
      if (partsA[i] !== partsB[i]) {
        return partsA[i] - partsB[i];
      }
    }
    return partsA.length - partsB.length;
  }

  return (
    <>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={props.open}
      >
        <Box
          sx={{
            width: "95%",
            backgroundColor: "white",
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              borderRadius: 2,
              padding: 1.5,
            }}
          >
            <Typography
              // variant='h3'
              sx={{
                fontSize: 24,
                color: "black",
              }}
            >
              {t("reg") + " " + t("fattureAcquisti")}
            </Typography>

            {/* <Button
            variant="contained"
            sx={{
              marginLeft: "auto",
            }}
          >
            {t("indietro")}
          </Button> */}
            <Box>
              {!props?.isEdit && (
                <Button
                  size="small"
                  sx={{
                    textAlign: "end",
                  }}
                  variant="contained"
                  type="submit"
                  onClick={(e) => {
                    onSaveAndContinueClicked(e, true);
                  }}
                  disabled={squaring != 0}
                >
                  {t("salvaEProsegui")}
                </Button>
              )}

              <Button
                // href="/add_fatture_vendita"
                sx={{
                  marginLeft: "16px",
                  // width: "18pc"
                  textAlign: "end",
                }}
                size="small"
                variant="contained"
                onClick={(e) => {
                  onSaveAndContinueClicked(e, false);
                }}
                // onClick={(e) => {
                //   props?.isEdit
                //     ? fnEditInvoice()
                //     : onSaveAndContinueClicked(e, false);
                // }}
                disabled={squaring != 0}
              >
                {t("salvaEdESCI")}
              </Button>

              <Button
                sx={{
                  marginLeft: "16px",
                  // width: "18pc"
                  textAlign: "end",
                }}
                onClick={() => {
                  backToList(reload);
                }}
                variant="contained"
                size="small"
              >
                {t("esci") + " " + t("senza" + " " + t("salvare"))}
              </Button>
              {/* {
                      props?.isEdit &&
                    <Button variant="contained" color="error"
                      onClick={() => {
                        setOpenDelete(!openDelete)
                      }}>{t('elimina')}</Button>
                  } */}
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              height: "90vh",
              overflowY: "auto",
              p: 2,
              "&::-webkit-scrollbar": {
                width: "16px",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#fff",
                borderRadius: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#4d96ff",
                borderRadius: "10px",
                border: "4px solid #ffffff",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#2c70cf",
              },
            }}
          >
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              onKeyDown={handleKeyPress}
            >
              <Grid container>
                <Grid item xs={9}>
                  <Box
                    sx={{
                      display: "flex",
                      margin: "auto",
                    }}
                  >
                    <Autocomplete
                      size="small"
                      value={formik.values.supplier || null}
                      onChange={(event, newSupplier) => {
                        handleSupplierChange(newSupplier);
                      }}
                      onBlur={formik.handleBlur}
                      inputValue={formik.values.inputSupplier}
                      onInputChange={(event, newInputSupplier) => {
                        formik.setFieldValue("inputSupplier", newInputSupplier);
                      }}
                      id="controllable-states-demo"
                      options={listOfSupplier}
                      sx={{ width: 400 }}
                      getOptionLabel={(option) => option.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          label={"*" + t("fornitore")}
                          error={
                            formik.touched.supplier &&
                            Boolean(formik.errors.supplier)
                          }
                          helperText={
                            formik.touched.supplier && formik.errors.supplier
                          }
                        />
                      )}
                    />
                    <TextField
                      size="small"
                      id="outlined-select-currency"
                      // select
                      disabled
                      label={t("partita") + " " + t("iva")}
                      value={vatNumber}
                      sx={{
                        width: "15pc",
                        marginLeft: "16px",
                      }}
                    ></TextField>
                  </Box>
                </Grid>

                <Grid item xs={3} sx={{ textAlign: "end" }}>
                  <Button
                    size="small"
                    sx={{
                      marginLeft: "16px",
                      width: "15pc",
                    }}
                    onClick={() => {
                      // props.onAddOpen()
                      setOpenAddUser(!openAddUser);
                    }}
                    variant="contained"
                    color="success"
                    startIcon={<AddIcon />}
                  >
                    {t("nuova") + " " + t("anagrafica")}
                  </Button>
                </Grid>

                <Grid>
                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "24px",
                    }}
                  >
                    <TextField
                      size="small"
                      id="input-with-icon-textfield"
                      label={"*" + t("data") + " " + t("fattura")}
                      placeholder="gg/mm/aaaa"
                      type="date"
                      name="date"
                      sx={{
                        width: "12pc",
                      }}
                      value={formik.values.date}
                      onChange={async(event) => {
                        const { name, value } = event.target;
                        if (name === 'date' && value.length > 10) {
                          return;
                        }
                        // setIsChangeRegDate(true);
                        setIsChangeDate(true);

                        
                        formik.setFieldValue(name, value);

                        // if (isFirstTimeOnly) {
                        // formik.setFieldValue("regDate", value);
                        await checkYearClosed(value);
                        // checkPreviousDocumentDate(value);
                        
                        const parsedDate = isValid(parseISO(value))
                          ? parseISO(value)
                          : null;
                        // setTimePeriod(parsedDate);
                        // if (!props.isEdit) {
                        //   await getInvoiceNumber();
                        // }
                        //   setIsFirstTimeOnly(false);
                        // }
                      }}
                      onKeyUp={async(event) => {
                        const { value } = event.target;
    
                        // Check if the year is typed (assuming year is the last 4 characters)
                        const yearTyped = value.slice(0,4);
                        if (yearTyped.length === 4) {
                          setIsChangeDate(true);
                          // Year is typed, you can perform any specific logic here
                          await checkYearClosed(yearTyped);
                        } 
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.date && Boolean(formik.errors.date)}
                      helperText={formik.touched.date && formik.errors.date}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <AccountCircle /> */}
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      size="small"
                      id="outlined-basic"
                      sx={{ marginLeft: "16px" }}
                      label={"*" + t("numeroFattura")}
                      placeholder="100"
                      variant="outlined"
                      name="invoiceNumber"
                      value={formik.values.invoiceNumber}
                      onChange={handleVatChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.invoiceNumber &&
                        Boolean(formik.errors.invoiceNumber)
                      }
                      helperText={
                        formik.touched.invoiceNumber &&
                        formik.errors.invoiceNumber
                      }
                      required
                    />
                    <TextField
                      size="small"
                      id="outlined-basic"
                      sx={{ marginLeft: "16px", width: "9pc" }}
                      label={"*" + t("protocollo")}
                      placeholder="100"
                      variant="outlined"
                      value={protocol}
                    />

                    <TextField
                      size="small"
                      sx={{ marginLeft: "16px", width: "12pc" }}
                      id="input-with-icon-textfield"
                      label={"*" + t("data") + " " + t("reg")}
                      placeholder="gg/mm/aaaa"
                      type="date"
                      name="regDate"
                      value={formik.values.regDate}
                      onChange={async(event) => {
                        const { name, value } = event.target;
                        if (name === 'regDate' && value.length > 10) {
                          return;
                        }
                        setIsChangeRegDate(true);
                        setIsChangeDate(false);
                        
                        formik.setFieldValue(name, value);
                        await checkYearClosed(value);
                        await checkPreviousDocumentDate(value);
                        const parsedDate = isValid(parseISO(value))
                          ? parseISO(value)
                          : null;
                        setTimePeriod(parsedDate);
                        if (!props.isEdit) {
                          await getInvoiceNumber();
                        }
                      }}
                      onKeyUp={async(event) => {
                        const { value } = event.target;
    
                        // Check if the year is typed (assuming year is the last 4 characters)
                        const yearTyped = value.slice(0,4);

                        if (!props.isEdit) {
                          if (!value) {
                            await getInvoiceNumber("Invalid");
                          }
                        }

                        if (yearTyped.length === 4) {
                          // Year is typed, you can perform any specific logic here
                          await checkYearClosed(yearTyped);
                          if (!props.isEdit) {
                            await getInvoiceNumber(value);
                          }
                        } 
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.regDate && Boolean(formik.errors.regDate)
                      }
                      helperText={
                        formik.touched.regDate && formik.errors.regDate
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <AccountCircle /> */}
                          </InputAdornment>
                        ),
                      }}
                    />

                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      localeText={
                        itIT.components.MuiLocalizationProvider.defaultProps
                          .localeText
                      }
                      adapterLocale={"it"}
                    >
                      <DatePicker
                        readOnly
                        slotProps={{
                          textField: {
                            error: false,
                            size: "small",
                          },
                        }}
                        sx={{ ml: 2, width: "9pc" }}
                        label={"*" + t("periodo")}
                        views={["month", "year"]}
                        format="MM/YYYY"
                        value={dayjs(timePeriod)}
                        onChange={setTimePeriod}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            placeholder="Your Custom Placeholder"
                            InputProps={{
                              ...params.InputProps,
                              // placeholder: 'jkhjkhjk'
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>

                    <Autocomplete
                      size="small"
                      value={document}
                      onChange={(event, newDocument) => {
                        setAllowCalcOnChangeDocType(true);
                        setDocument(newDocument);
                      }}
                      inputValue={inputDocument}
                      onInputChange={(event, newInputDocument) => {
                        setInputDocument(newInputDocument);
                      }}
                      id="controllable-states-demo"
                      options={listOfDocument}
                      getOptionLabel={(option) => option.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.type === value.type
                      }
                      sx={{ width: 240, marginLeft: "16px" }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          label={"*" + t("documento")}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Divider sx={{ paddingTop: "20px" }}></Divider>

              <Grid
                container
                sx={{
                  marginTop: "24px",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    paddingRight: "12px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h4">
                      {t("reg") + " " + t("iva")}
                    </Typography>
                    <Button
                      sx={{
                        marginLeft: "16px",
                        // width: "18pc"
                        textAlign: "end",
                      }}
                      // onClick={() => {
                      //   props.onAddOpen()
                      // }}
                      variant="contained"
                      size="small"
                      color="success"
                      startIcon={<AddIcon />}
                      onClick={addRow}
                    >
                      {t("aggiungi")}
                    </Button>
                  </Box>

                  <Divider sx={{ marginY: "16px" }}></Divider>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                      <TableHead>
                        {/* <TableRow>
                        <TableCell align="center" colSpan={3}>
                          Details
                        </TableCell>
                        <TableCell align="right">Price</TableCell>
                      </TableRow> */}
                        <TableRow>
                          <TableCell sx={{ width: "1%" }}></TableCell>
                          <TableCell>{t("tabella") + " " + t("iva")}</TableCell>
                          <TableCell align="right" sx={{ width: "16%" }}>
                            {t("detraibilità") + "%"}
                          </TableCell>
                          <TableCell align="right" sx={{ width: "16%" }}>
                            {t("imponibile")}
                          </TableCell>
                          <TableCell align="right" sx={{ width: "16%" }}>
                            {t("iva") + " " + t("detraibile")}
                          </TableCell>
                          <TableCell align="right" sx={{ width: "16%" }}>
                            {t("iva") + " " + t("nonDetraibile")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* <TableRow>sdv</TableRow> */}
                        {rows.map((row, index) => {
                          return (
                            <TableRow key={`row-${index}`}>
                              <TableCell>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="error"
                                  sx={{
                                    padding: "none",
                                    minWidth: "0px",
                                    color: "#fff",
                                    padding: "8px",
                                  }}
                                  onClick={() => removeRow(index)}
                                >
                                  <DeleteIcon />
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Autocomplete
                                  size="small"
                                  value={row.iVA || null}
                                  onChange={(event, newValue) => {
                                    handleAutocompleteChange(index, newValue);
                                  }}
                                  inputValue={row.inputIVA}
                                  onInputChange={(event, newInputValue) =>
                                    handleInputChange(index, newInputValue)
                                  }
                                  getOptionLabel={(option) =>
                                    option
                                      ? ` ${option.code} - ${option.description}`
                                      : ""
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option._id === value._id
                                  }
                                  id={`controllable-states-demo-${index}`}
                                  options={listOfIVA}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      {...params}
                                      label={
                                        "*" + t("tabella") + " " + t("iva")
                                      }
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <TextField
                                  size="small"
                                  id="outlined-basic"
                                  sx={{ width: "10ch" }}
                                  label=""
                                  placeholder=""
                                  variant="standard"
                                  value={
                                    !row.deductibility ? "" : row.deductibility
                                  }
                                  onChange={(event) => {
                                    handleDeductibilityChange(
                                      index,
                                      event.target.value
                                    );
                                  }}
                                  InputProps={{
                                    inputComponent: EuroNumberFormat,
                                  }}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <TextField
                                  size="small"
                                  id="outlined-basic"
                                  sx={{ width: "10ch" }}
                                  label=""
                                  placeholder=""
                                  variant="standard"
                                  value={
                                    document?.type === 1
                                      ? editingIndexTax == index
                                        ? "-" +
                                          Math.abs(
                                            row?.taxableIncome
                                          ).toLocaleString("de-DE", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                        : "-" +
                                          Math.abs(
                                            row?.taxableIncome
                                          ).toLocaleString("de-DE", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                      : !row.taxableIncome
                                      ? ""
                                      : row?.taxableIncome
                                  }
                                  onChange={(event) => {
                                    handleTaxChange(index, event.target.value);
                                  }}
                                  InputProps={{
                                    inputComponent: EuroNumberFormat,
                                  }}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <TextField
                                  InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                    // style: {
                                    //   textAlign: 'right', // Apply text alignment directly
                                    // },
                                    inputComponent: readOnlyFormat,
                                  }}
                                  size="small"
                                  id="outlined-basic"
                                  sx={{
                                    width: "10ch",
                                    // textAlign:'right'
                                  }}
                                  label=""
                                  placeholder=""
                                  variant="standard"
                                  value={
                                    document?.type === 1
                                      ? "-" +
                                        parseFloat(
                                          Math.abs(row.deductibleVAT)
                                        ).toLocaleString("de-DE", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : !row.deductibleVAT
                                      ? ""
                                      : parseFloat(
                                          row.deductibleVAT
                                        ).toLocaleString("de-DE", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                  }
                                />
                              </TableCell>
                              <TableCell align="right">
                                <TextField
                                  InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                    // style: {
                                    //   textAlign: 'right', // Apply text alignment directly
                                    // },
                                    inputComponent: readOnlyFormat,
                                  }}
                                  size="small"
                                  id="outlined-basic"
                                  sx={{
                                    width: "10ch",
                                    // textAlign: 'right'
                                  }}
                                  label=""
                                  placeholder=""
                                  variant="standard"
                                  value={
                                    document?.type === 1
                                      ? "-" +
                                        parseFloat(
                                          Math.abs(row.nonDeductibleVAT)
                                        ).toLocaleString("de-DE", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : !row.nonDeductibleVAT
                                      ? ""
                                      : parseFloat(
                                          row.nonDeductibleVAT
                                        ).toLocaleString("de-DE", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                  }
                                  // value={
                                  //   document?.type === 1
                                  //     ? '-' + Math.abs(row.nonDeductibleVAT).toFixed(2)
                                  //     : row.nonDeductibleVAT.toFixed(2)
                                  // }
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}

                        <TableRow>
                          <TableCell rowSpan={3}></TableCell>
                          <TableCell align="right" colSpan={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "700",
                                textTransform: "uppercase",
                              }}
                            >
                              {t("totale")}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="h6" sx={{ fontWeight: "700" }}>
                              {/* {document?.type === 1
                                ? '-' + Math.abs(totalTaxableIncome).toFixed(2)
                                : totalTaxableIncome.toFixed(2)} */}

                              {document?.type === 1
                                ? "-" +
                                  parseFloat(
                                    Math.abs(totalTaxableIncome)
                                  ).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : parseFloat(totalTaxableIncome).toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="h6" sx={{ fontWeight: "700" }}>
                              {/* {document?.type === 1
                                ?'-' + Math.abs(totalDeductibleVAT).toFixed(2)
                                : totalDeductibleVAT.toFixed(2)} */}
                              {document?.type === 1
                                ? "-" +
                                  parseFloat(
                                    Math.abs(totalDeductibleVAT)
                                  ).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : parseFloat(totalDeductibleVAT).toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </Typography>
                          </TableCell>

                          <TableCell align="right">
                            <Typography variant="h6" sx={{ fontWeight: "700" }}>
                              {/* {document?.type === 1
                                ? '-' + Math.abs(totalNonDeductibleVAT).toFixed(2)
                                : totalNonDeductibleVAT.toFixed(2)} */}

                              {document?.type === 1
                                ? "-" +
                                  parseFloat(
                                    Math.abs(totalNonDeductibleVAT)
                                  ).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : parseFloat(
                                    totalNonDeductibleVAT
                                  ).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" colSpan={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "700",
                                textTransform: "uppercase",
                              }}
                            >
                              {t("totale") + " " + t("fattura")}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" colSpan={3}>
                            <Typography variant="h6" sx={{ fontWeight: "700" }}>
                              {/* {document?.type === 1
                                ? '-' + Math.abs(totalInvoice).toFixed(2)
                                : totalInvoice.toFixed(2)} */}

                              {document?.type === 1
                                ? "-" +
                                  parseFloat(
                                    Math.abs(totalInvoice)
                                  ).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : parseFloat(totalInvoice).toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: "12px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h4">
                      {t("reg") + " " + t("contabile")}
                    </Typography>
                    <Button
                      sx={{
                        marginLeft: "16px",
                        // width: "18pc"
                        textAlign: "end",
                      }}
                      // onClick={() => {
                      //   props.onAddOpen()
                      // }}
                      variant="contained"
                      size="small"
                      color="success"
                      startIcon={<AddIcon />}
                      onClick={addSubaccountRow}
                    >
                      {t("aggiungi")}
                    </Button>
                  </Box>

                  <Divider sx={{ marginY: "16px" }}></Divider>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                      <TableHead>
                        {/* <TableRow>
                        <TableCell align="center" colSpan={3}>
                          Details
                        </TableCell>
                        <TableCell align="right">Price</TableCell>
                      </TableRow> */}
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>
                            {t("conto") + "/" + t("sottoConto")}
                          </TableCell>
                          <TableCell align="right">
                            {t("importo") + " " + t("dare")}
                          </TableCell>
                          <TableCell align="right">
                            {t("importo") + " " + t("avere")}
                          </TableCell>
                          {/* <TableCell align="right">{('iVAdetraibile')}</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* <TableRow>sdv</TableRow> */}
                        {subaccountRows.map((row, index) => {
                          return (
                            <TableRow>
                              <TableCell sx={{ width: "1%" }}>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="error"
                                  sx={{
                                    padding: "none",
                                    minWidth: "0px",
                                    color: "#fff",
                                    padding: "8px",
                                  }}
                                  onClick={() => removeSubaccountRow(index)}
                                >
                                  <DeleteIcon />
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Autocomplete
                                  size="small"
                                  value={row.accountId}
                                  onChange={(event, newValue) => {
                                    handleSubaccountAutocompleteChange(
                                      index,
                                      newValue
                                    );
                                  }}
                                  inputValue={row.inputAccount}
                                  onInputChange={(event, newInputValue) =>
                                    handleSubaccountInputChange(
                                      index,
                                      newInputValue
                                    )
                                  }
                                  readOnly={
                                    row?.isSupplier === true &&
                                    row?.accountId?.accountId?.code == "51"
                                      ? //     ||
                                        // (row?.type == "1" &&
                                        //   row?.accountId?.accountId?.code == "11" &&
                                        //   row?.code == "2")
                                        true
                                      : false
                                  }
                                  getOptionLabel={(option) =>
                                    option
                                      ? ` ${option.type}.${option?.accountId?.code}.${option.code} - ${option.name}`
                                      : ""
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option._id === value._id
                                  }
                                  id={`controllable-states-demo-${index}`}
                                  options={listOfSottoConto.sort(customSort)}
                                  // sx={{ width: 240 }}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      {...params}
                                      label={"*" + t("sotto conto")}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell align="right" sx={{ width: "16%" }}>
                                <TextField
                                  size="small"
                                  id="outlined-basic"
                                  sx={{ width: "10ch" }}
                                  label=""
                                  placeholder=""
                                  variant="standard"
                                  value={
                                    !row?.amountGive
                                      ? ""
                                      : editingIndexGive == index
                                      ? row?.amountGive
                                      : row?.amountGive.toLocaleString(
                                          "de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                  }
                                  onChange={(event) => {
                                    handleAmountGiveChange(
                                      index,
                                      event.target.value
                                    );
                                  }}
                                  InputProps={{
                                    inputComponent: EuroNumberFormat,
                                  }}
                                />
                              </TableCell>
                              <TableCell align="right" sx={{ width: "16%" }}>
                                <TextField
                                  size="small"
                                  id="outlined-basic"
                                  sx={{
                                    width: "10ch",
                                  }}
                                  label=""
                                  placeholder=""
                                  variant="standard"
                                  value={
                                    !row?.amountHave
                                      ? ""
                                      : editingIndexHave == index
                                      ? row?.amountHave
                                      : row?.amountHave.toLocaleString(
                                          "de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                  }
                                  onChange={(event) => {
                                    handleAmountHaveChange(
                                      index,
                                      event.target.value
                                    );
                                  }}
                                  InputProps={{
                                    inputComponent: EuroNumberFormat,
                                  }}
                                />
                              </TableCell>
                              {/* <TableCell align="right">{ccyFormat(row.price)}</TableCell> */}
                            </TableRow>
                          );
                        })}

                        <TableRow>
                          <TableCell rowSpan={3}></TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "700",
                                textTransform: "uppercase",
                              }}
                            >
                              {t("totale")}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="h6" sx={{ fontWeight: "700" }}>
                              {/* {ccyFormat(totalAmountGive)} */}
                              {totalAmountGive.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="h6" sx={{ fontWeight: "700" }}>
                              {/* {ccyFormat(totalAmountHave)} */}
                              {totalAmountHave.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "700",
                              }}
                              // sx={{
                              //   color:
                              //     ccyFormat(squaring) == 0 ? "#2e7d32" : "red",
                              //   fontWeight: "700",
                              // }}
                            >
                              {t("squadratura")}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" colSpan={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                color: squaring == 0 ? "#4caf50" : "#EB5353",
                                fontWeight: "700",
                                paddingLeft: "88px",
                              }}
                            >
                              {/* {ccyFormat(squaring)} */}
                              {squaring == -0
                                ? Math.abs(parseFloat(squaring)).toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : parseFloat(squaring).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <Box
                sx={{
                  marginTop: "24px",
                }}
              >
                <Typography variant="caption" display="block">
                  {t("note")}
                </Typography>
                <TextField
                  size="small"
                  id="outlined-basic"
                  sx={{ marginLeft: "16px", mb: "32px", width: "98%" }}
                  label=""
                  placeholder="Aggiungi la tua nota qui"
                  variant="standard"
                  value={note}
                  multiline
                  onChange={(event) => {
                    setNote(event.target.value);
                  }}
                />
                {/* <Divider sx={{ marginY: "16px" }}></Divider> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <AddFornitori
        open={openAddUser}
        isEdit={false}
        // stateObj={[]}
        handleClose={(data) => {
          if (data?.reload) {
            getSupplierList(data.supplierId);
          }
          setOpenAddUser(!openAddUser);
        }}
      />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
