import React, { useEffect, useState, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { itIT } from '@mui/x-date-pickers/locales';
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
  InputAdornment,
  IconButton,
  Modal,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import defaultString from "constants/defaultString.json";
import { getDataFromStorage } from "storage/StorageData";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { DeleteDialog } from "components/DeleteDialog";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { NumericFormat } from "react-number-format";
import { resetToken } from "apiURL/commonFunctions";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Label } from "@mui/icons-material";

const TAX_RATE = 0.07;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit, nonDetraibile) {
  const price = priceRow(qty, unit, nonDetraibile);
  return { desc, qty, unit, price, nonDetraibile };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

function EuroNumberFormat(props) {
  const { inputRef, onChange, onBlur, ...other } = props;
  const [inputValueBlur, setInputValueBlur] = useState(true);
  const handleChange = (value) => {
    setInputValueBlur(false);
    onChange(value);
  };

  const handleBlur = () => {
    setInputValueBlur(true);
    if (onBlur) {
      onBlur();
    }
  };
  return (
    <NumericFormat
    {...other}
    onBlur={handleBlur}
    onChange={handleChange} 
    getInputRef={inputRef}
    thousandSeparator={"."}
    decimalSeparator={","}
    decimalScale={2}
    fixedDecimalScale={inputValueBlur}
    style={{
      textAlign: 'right', // Align text to the right
    }}
    />
  );
}

const PrimaNote = (props) => {
  const { t } = useTranslation();

  const [listOfClient, setListOfClient] = useState([]);

  const [listOfSupplier, setListOfSupplier] = useState([]);

  const [listOfSubAccount, setListOfSubAccount] = useState([]);

  const [totalAmountHave, setTotalAmountHave] = useState(0);
  const [totalAmountGive, setTotalAmountGive] = useState(0);
  const [squaring, setSquaring] = useState(0);
  const [reload, setReload] = useState(false);
  const [isSaveNConti, setIsSaveNConti] = useState(false);
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [openDelete, setOpenDelete] = useState(false);
  const [rows, setRows] = useState([]);
  const [year, setYear] = useState("");
  const [rememberMe, setrememberMe] = useState(null);
  const [showWarningMsg , setShowWarningMsg] = useState("");
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()

  const validationSchema = Yup.object().shape({
    regDate: Yup.date().required(t("registrationDateRequired")),
  });

  const formik = useFormik({
    initialValues: {
      regDate: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setErrors }) => {
      console.log("values: ", values);

      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {};
      let isYearOpened = false;
      requestObject.year = values.regDate ? dayjs(values.regDate).format("YYYY")  : "";
      requestObject.accountingSubjectId = accountingSubject?._id;

      // console.log(requestObject)
      const checkYearResponse = await API.post(
        apiURL.service_check_year_closed,
        requestObject
      );
      // console.log(checkYearResponse);
      if (checkYearResponse && checkYearResponse?.data?.success) {
        if (
          checkYearResponse?.data?.message?.isClosed == 1 ||
          checkYearResponse?.data?.message?.isClosed == 0
        ) {
          isYearOpened = false;

          formik?.setFieldTouched("regDate", true, false);
          setErrors({
            regDate: t("yearIsClosed"),
          });
        } else {
          isYearOpened = true;
          const currentDate = new Date();

          let currentYear = currentDate.getFullYear();
          // console.log("year:: ", currentYear, " request year:: ", requestObject.year);

          if (requestObject.year != currentYear ) {
            
            let warningMsg = "Attenzione! hai selezionato l'anno " + requestObject.year + " e l'anno corrente è " + currentYear
            setShowWarningMsg(warningMsg);
            
          }
          else {
            setShowWarningMsg("");
          }
        }
      }

      console.log("Year open:", isYearOpened);
      if (isYearOpened) {
        
        // if(rows.length > 0){
          setShowWarningMsg("");
          if(props?.isEdit){

            await fnEditPrimaNote();
          }else{
             await fnAddPrimaNote()
          }
        // }else{
        //   setOpenDelete(!openDelete);
        // }
      }
    },
  });

  const checkYearClosed = useCallback(
    debounce(async (regiDate) => {
      console.log(regiDate);
      const year =regiDate ? dayjs(regiDate).format("YYYY") :"";
      setYear(year);
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {};
      requestObject.year = regiDate ? dayjs(regiDate).format("YYYY") :"";
      requestObject.accountingSubjectId = accountingSubject?._id;

      const checkYearResponse = await API.post(
        apiURL.service_check_year_closed,
        requestObject
      );

      if (checkYearResponse && checkYearResponse?.data?.success) {
        if (
          checkYearResponse?.data?.message?.isClosed == 1 ||
          checkYearResponse?.data?.message?.isClosed == 0
        ) {
          formik?.setFieldTouched("regDate", true, false);
          formik?.setErrors({
            regDate: t("yearIsClosed"),
          });
          // return 1;
        } else {
          // return 0;
          const currentDate = new Date();

          let currentYear = currentDate.getFullYear();
          // console.log("year:: ", currentYear, " request year:: ", requestObject.year);

          if (requestObject.year != currentYear ) {
            let warningMsg = "Attenzione! hai selezionato l'anno " + requestObject.year + " e l'anno corrente è " + currentYear
            setShowWarningMsg(warningMsg);
          }
          else {
            setShowWarningMsg("");
          }
        }
      }
    }, 500),
    []
  );

  useEffect(() => {
    getSubAccounts();
    // getSubAccountsOfAllSuppliers();
    // getSubAccountsOfAllCustomers();

    if (props && props?.stateObj) {
      console.log("EDIT OBJECT::: ", props?.stateObj);

      formik.setValues({
        regDate: props?.stateObj?.registrationDate
          ? dayjs(props?.stateObj?.registrationDate).format("YYYY-MM-DD")
          : "",
      });

      setTotalAmountHave(
        props?.stateObj?.totalAmountHave ? props?.stateObj?.totalAmountHave : 0
      );
      setTotalAmountGive(
        props?.stateObj?.totalAmountGive ? props?.stateObj?.totalAmountGive : 0
      );
      setSquaring(props?.stateObj?.squaring ? props?.stateObj?.squaring : 0);
      setNote(props?.stateObj?.note ? props?.stateObj?.note : "");
    }
    if (props.open && !props.isEdit) {
      formik.resetForm();
    }
  }, [props?.stateObj, props.open]);

  useEffect(() => {
    fnCommonDefaultRows();
  }, [props.defaultRows]);

  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  const getSubAccounts = async () => {
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );

    API.get(
      `${apiURL.service_sub_accounts_except_personal_data}/${accountingSubject?._id}`
    )
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setListOfSubAccount(res?.data?.data?.subaccounts);

          if (props && props?.isEdit) {
            if (props?.stateObj?.subaccounts.length > 0) {
              const selectedSubAccountsExist = props?.stateObj?.subaccounts.filter(
                (item) => item.supplierId == null && item.clientId == null
              );

              if (selectedSubAccountsExist.length > 0) {
                const newArray = [];
                for (let i = 0; i < selectedSubAccountsExist.length; i++) {
                  let currentObject = selectedSubAccountsExist[i];

                  const selectedArray = (res?.data?.data?.subaccounts).filter(
                    (item) => item._id === currentObject.subaccountId
                  );

                  let subaccountObject = {};

                  subaccountObject.accountId = selectedArray[0];
                  subaccountObject.inputAccount = ` ${selectedArray[0]?.type}.${selectedArray[0]?.accountId?.code}.${selectedArray[0]?.code} - ${selectedArray[0]?.name}`;
                  subaccountObject.amountGive = currentObject?.amountGive;
                  subaccountObject.amountHave = currentObject?.amountHave;
                  subaccountObject.selectedCheckBox = "conti";
                  subaccountObject.rememberMe = currentObject?.rememberMe;

                  newArray.push(subaccountObject);
                }
                setRows((prevArray) => [...prevArray, ...newArray]);
                // setRows((prevArray) => [...prevArray, ...newArray]);
              }
            }
          }
          getSubAccountsOfAllSuppliers();
        } else {
          console.log("In else portion ", res.data);
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(false);
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout()
          }
        }
      });
  };

  const getSubAccountsOfAllCustomers = () => {
    API.get(apiURL.service_sub_accounts_all_customers)
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setListOfClient(res?.data?.data?.subaccounts);

          if (props && props?.isEdit) {
            if (props?.stateObj?.subaccounts.length > 0) {
              const selectedSubAccountExist = props?.stateObj?.subaccounts.filter(
                (item) => item.clientId != null
              );

              // console.log(selectedSubAccountExist)

              if (selectedSubAccountExist.length > 0) {
                const newArray = [];
                for (let i = 0; i < selectedSubAccountExist.length; i++) {
                  let currentObject = selectedSubAccountExist[i];

                  // console.log(currentObject)
                  const selectedArray = (res?.data?.data?.subaccounts).filter(
                    (item) => item._id === currentObject.subaccountId
                  );

                  let subaccountObject = {};

                  subaccountObject.accountId = selectedArray[0];
                  subaccountObject.inputAccount = ` ${selectedArray[0]?.type}.${selectedArray[0]?.accountId?.code}.${selectedArray[0]?.code} - ${selectedArray[0]?.name}`;
                  subaccountObject.amountGive = currentObject?.amountGive;
                  subaccountObject.amountHave = currentObject?.amountHave;
                  subaccountObject.selectedCheckBox = "clienti";
                  subaccountObject.rememberMe = currentObject?.rememberMe;

                  newArray.push(subaccountObject);
                }
                // setRows((prevArray) => [...prevArray, ...newArray]);
                setRows((prevArray) =>
                  Array.from(
                    new Set([...prevArray, ...newArray].map(JSON.stringify))
                  ).map(JSON.parse)
                );
              }
            }
          }
        } else {
          console.log("In else portion ", res.data);
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(false);
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout()
          }
        }
      });
  };

  const getSubAccountsOfAllSuppliers = () => {
    API.get(apiURL.service_sub_accounts_all_suppliers)
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setListOfSupplier(res?.data?.data?.subaccounts);

          if (props && props?.isEdit) {
            if (props?.stateObj?.subaccounts.length > 0) {
              const selectedSubAccountExist = props?.stateObj?.subaccounts.filter(
                (item) => item.supplierId != null
              );

              // console.log(selectedSubAccountExist)

              if (selectedSubAccountExist.length > 0) {
                const newArray = [];
                for (let i = 0; i < selectedSubAccountExist.length; i++) {
                  let currentObject = selectedSubAccountExist[i];

                  // console.log(currentObject)
                  const selectedArray = (res?.data?.data?.subaccounts).filter(
                    (item) => item._id === currentObject.subaccountId
                  );

                  let subaccountObject = {};

                  subaccountObject.accountId = selectedArray[0];
                  subaccountObject.inputAccount = ` ${selectedArray[0]?.type}.${selectedArray[0]?.accountId?.code}.${selectedArray[0]?.code} - ${selectedArray[0]?.name}`;
                  subaccountObject.amountGive = currentObject?.amountGive;
                  subaccountObject.amountHave = currentObject?.amountHave;
                  subaccountObject.selectedCheckBox = "fornitori";
                  subaccountObject.rememberMe = currentObject?.rememberMe;

                  newArray.push(subaccountObject);
                }
                // setRows((prevArray) => [...prevArray, ...newArray]);
                setRows((prevArray) =>
                  Array.from(
                    new Set([...prevArray, ...newArray].map(JSON.stringify))
                  ).map(JSON.parse)
                );
              }
            }
          }
          getSubAccountsOfAllCustomers();
        } else {
          console.log("In else portion ", res.data);
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(false);
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout()
          }
        }
      });
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const onSaveAndContinueClicked = (e, isContinue) => {
    e.preventDefault();
    setIsSaveNConti(isContinue);
    formik.handleSubmit();
  };

  const confirmDelete = async () => {
    await fnDelete();
    setOpenDelete(!openDelete);
  };

  const fnDelete = async () => {
    const idsToBeDeleted = JSON.stringify({
      noteIds: [props.stateObj?._id],
    });

    API.post(apiURL.service_delete_primanote, idsToBeDeleted)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(t("common.deleteSuccess"));
          setSeverity("success");
          formik.resetForm();
          // setIsEdit(false)
          props.handleClose({ reload: true });
        } else {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("common.internalServerError"));
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout()
          } else {
            setErrorMsg(t("common.badRequest"));
          }
        }
      });
  };

  const handleCloseDeleteDialog = () => {
    setOpenDelete(!openDelete);
  };

  const backToList = (reload) => {
    props.handleClose({ reload: reload });

    formik.resetForm();
    setRows([]);
    setTotalAmountHave(0);
    setTotalAmountGive(0);
    setSquaring(0);
    setNote("");
    setIsSaveNConti(false);
    setShowWarningMsg("");
  };

  const fnAddPrimaNote = async () => {
    // console.log("formik.values:", formik.values);
    if (!!formik.values) {

      let accountingSubject = await getDataFromStorage("accountingSubject");

      if (rows.length == 0) {
        setOpen(true);
        setErrorMsg(t("requiredPrimanotaData"));
        setSeverity("error");
        return
      }

      setIsLoading(true);

      const subAccountList = [];
      for (let account of rows) {
        console.log("rows", rows);
        let accountObj = {};
        accountObj.accountId = account?.accountId?.accountId?._id;
        accountObj.subaccountId = account?.accountId?._id;
        accountObj.name = account?.accountId?.name;
        accountObj.code = account?.accountId?.code;
        accountObj.amountGive = account.amountGive;
        accountObj.amountHave = account.amountHave;
        accountObj.supplierId = account?.accountId?.supplierId;
        accountObj.clientId = account?.accountId?.customerId;
        accountObj.rememberMe = account?.rememberMe;
        accountObj.type = account?.accountId?.type;

        if (account.selectedCheckBox == "fornitori") {
          if (account.inputAccount == "") {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("selectSupplier"));
            setIsLoading(false);
            setReload(false);
            return;
          }
        }
        if (account.selectedCheckBox == "clienti") {
          if (account.inputAccount == "") {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("selectClient"));
            setIsLoading(false);
            setReload(false);
            return;
          }
        }
        if (account.selectedCheckBox == "conti") {
          if (account.inputAccount == "") {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("selectAccount"));
            setIsLoading(false);
            setReload(false);
            return;
          }
        }
     
        if (account.inputAccount == "") {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("selectAccount"));
          setIsLoading(false);
          setReload(false);
          return;
        }

        
     
        subAccountList.push(accountObj);
      }

      const requestObject = {};
      requestObject.accountingSubjectId = accountingSubject?._id;

      requestObject.registrationDate = formik.values.regDate
        ? formik.values.regDate
        : "";

      requestObject.subaccounts = subAccountList;
      requestObject.totalAmountGive = Math.abs(
        parseFloat(totalAmountGive)
      ).toFixed(2);
      requestObject.totalAmountHave = Math.abs(
        parseFloat(totalAmountHave)
      ).toFixed(2);
      requestObject.squaring = Math.abs(parseFloat(squaring)).toFixed(2);
      requestObject.note = note ? note : "";

      // console.log("requestObject",requestObject);

      API.post(apiURL.service_add_primanote, requestObject)
        .then((response) => {
          console.log("response", response);
          setIsLoading(false);
          setReload(true);
          if (response.status === apiStatusCode.CREATED) {
            setOpen(true);
            setSeverity("success");
            setErrorMsg(t("common.addSuccess"));

            const regiDate = formik.values.regDate;
            formik.resetForm();
            formik.setFieldValue("regDate", regiDate);

            // setRows([]);
            setTotalAmountHave(0);
            setTotalAmountGive(0);
            setSquaring(0);
            setNote("");

            if (isSaveNConti) {
              const newArray = [];
              const defaultRowsPrimaNote = [
                {
                  accountId: "",
                  inputAccount: "",
                  amountGive: 0,
                  amountHave: 0,
                  selectedCheckBox: "",
                  rememberMe: false,
                },
              ];

              const updatedRowsSottoConto = defaultRowsPrimaNote.map((row) => ({
                ...row,
                accountId: "",
                inputAccount: "",
                amountGive: 0,
                amountHave: 0,
                selectedCheckBox: "",
                rememberMe: false,
              }));
              rows.map((object, index) => {
                if (object.rememberMe) {
                  object.amountGive = 0;
                  object.amountHave = 0;
                  newArray.push(object);
                }
              });
              if (newArray.length > 0) {
                setRows([...newArray, ...updatedRowsSottoConto]);
              } else {
                setRows([]);
                fnCommonDefaultRows();
              }
              setIsSaveNConti(false);
              // fnCommonDefaultRows()
            } else {
              setRows([]);
              props.handleClose({ reload: true });
            }
          } else {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("common.internalServerError"));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
              doLogout()
            } else {
              setErrorMsg(t("common.badRequest"));
            }
          }
        });
    } else {
      setOpen(true);
      setErrorMsg(t("requiredInfo"));
      setSeverity("error");
    }
  };

  const fnEditPrimaNote = async () => {
    if (!!formik.values) {
      

      let accountingSubject = await getDataFromStorage("accountingSubject");

      if (rows.length == 0) {
        setOpen(true);
        setErrorMsg(t("requiredPrimanotaData"));
        setSeverity("error");
        return
      }

      setIsLoading(true);
      const subAccountList = [];
      for (let account of rows) {
        let accountObj = {};
        accountObj.accountId = account?.accountId?.accountId?._id;
        accountObj.subaccountId = account?.accountId?._id;
        accountObj.name = account?.accountId?.name;
        accountObj.code = account?.accountId?.code;
        accountObj.amountGive = account.amountGive;
        accountObj.amountHave = account.amountHave;
        accountObj.supplierId = account?.accountId?.supplierId;
        accountObj.clientId = account?.accountId?.customerId;
        accountObj.rememberMe = account?.rememberMe;
        accountObj.type = account?.accountId?.type;

        if (account.selectedCheckBox == "fornitori") {
          if (account.inputAccount == "") {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("selectSupplier"));
            setIsLoading(false);
            setReload(false);
            return;
          }
        }
        if (account.selectedCheckBox == "clienti") {
          if (account.inputAccount == "") {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("selectClient"));
            setIsLoading(false);
            setReload(false);
            return;
          }
        }
        if (account.selectedCheckBox == "conti") {
          if (account.inputAccount == "") {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("selectAccount"));
            setIsLoading(false);
            setReload(false);
            return;
          }
        }
     
        if (account.inputAccount == "") {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("selectAccount"));
          setIsLoading(false);
          setReload(false);
          return;
        }

        subAccountList.push(accountObj);
      }

      const requestObject = {};
      requestObject.accountingSubjectId = accountingSubject?._id;

      requestObject.registrationDate = formik.values.regDate
        ? formik.values.regDate
        : "";

      requestObject.subaccounts = subAccountList;
      requestObject.totalAmountGive = Math.abs(
        parseFloat(totalAmountGive)
      ).toFixed(2);
      requestObject.totalAmountHave = Math.abs(
        parseFloat(totalAmountHave)
      ).toFixed(2);
      requestObject.squaring = Math.abs(parseFloat(squaring)).toFixed(2);
      requestObject.note = note ? note : "";

      console.log(requestObject);
      API.put(
        `${apiURL.service_update_primanote}/${props.stateObj?._id}`,
        requestObject
      )
        .then((response) => {
          console.log("response", response);
          setIsLoading(false);
          setReload(true);
          if (response.status === apiStatusCode.SUCCESS) {
            setOpen(true);
            setSeverity("success");
            setErrorMsg(t("common.updateSuccess"));

            formik.resetForm();
            setRows([]);
            setTotalAmountHave(0);
            setTotalAmountGive(0);
            setSquaring(0);
            setNote("");
            props.handleClose({ reload: true });
          } else {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("common.internalServerError"));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
              doLogout()
            } else {
              setErrorMsg(t("common.badRequest"));
            }
          }
        });
    } else {
      setOpen(true);
      setErrorMsg(t("requiredInfo"));
      setSeverity("error");
    }
  };

  const addRow = () => {
    setRows([
      ...rows,
      {
        accountId: "",
        inputAccount: "",
        amountGive: 0,
        amountHave: 0,
        selectedCheckBox: "",
        rememberMe: false,
      },
    ]);
  };

  const removeRow = (index) => {
    // console.log("index", index);
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
    let newGiveAmount = 0;
    updatedRows.map((item) => {
      newGiveAmount = newGiveAmount + Number(item.amountGive);
    });

    setTotalAmountGive(newGiveAmount);

    let newHaveAmount = 0;

    updatedRows.map((item) => {
      newHaveAmount = newHaveAmount + Number(item.amountHave);
    });

    setTotalAmountHave(newHaveAmount);

    let squaringAmount = newGiveAmount - newHaveAmount;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
  };

  const fnCommonDefaultRows = () => {
    if (props.defaultRows) {
      const defaultRowsPrimaNote = [
        {
          accountId: "",
          inputAccount: "",
          amountGive: 0,
          amountHave: 0,
          selectedCheckBox: "",
          rememberMe: false,
        },
        {
          accountId: "",
          inputAccount: "",
          amountGive: 0,
          amountHave: 0,
          selectedCheckBox: "",
          rememberMe: false,
        },
      ];

      const updatedRowsSottoConto = defaultRowsPrimaNote.map((row) => ({
        ...row,
        accountId: "",
        inputAccount: "",
        amountGive: 0,
        amountHave: 0,
        selectedCheckBox: "",
      }));
      setRows(updatedRowsSottoConto);
    }
  };

  function convertEuroToIND(euroFormat) {
    if (!euroFormat || typeof euroFormat !== "string") {
      return 0; // or handle the error in an appropriate way
    }

    const withoutThousandsSeparator = euroFormat.replace(/\./g, "");
    const standardFormat = withoutThousandsSeparator.replace(",", ".");
    return Number(standardFormat);
  }

  const handleAmountHaveChange = (index, amountHave) => {
    const updatedData = [...rows];
    const numericAmountHave = convertEuroToIND(amountHave);
    updatedData[index].amountHave = numericAmountHave;

    setRows(updatedData);

    let newHaveAmount = 0;
    updatedData.map((item) => {
      newHaveAmount = newHaveAmount + Number(item.amountHave);
    });
    setTotalAmountHave(newHaveAmount);
    let squaringAmount = totalAmountGive - newHaveAmount;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
  };
  const handleAmountGiveChange = (index, amountGive) => {
    const updatedData = [...rows];
    const numericAmountGive = convertEuroToIND(amountGive);
    updatedData[index].amountGive = numericAmountGive;

    setRows(updatedData);
    let newGiveAmount = 0;
    updatedData.map((item) => {
      newGiveAmount = newGiveAmount + Number(item.amountGive);
    });
    setTotalAmountGive(newGiveAmount);

    let squaringAmount = newGiveAmount - totalAmountHave;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
  };

  const handleAutocompleteChange = (index, newValue) => {
    const updatedRows = [...rows];

    updatedRows[index].accountId = newValue;

    setRows(updatedRows);
    let newGiveAmount = 0;
    updatedRows.map((item) => {
      newGiveAmount = newGiveAmount + Number(item.amountGive);
    });

    setTotalAmountGive(newGiveAmount);

    let newHaveAmount = 0;

    updatedRows.map((item) => {
      newHaveAmount = newHaveAmount + Number(item.amountHave);
    });

    setTotalAmountHave(newHaveAmount);

    let squaringAmount = newGiveAmount - newHaveAmount;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
  };

  const handleInputChange = (index, newInputValue) => {
    const updatedRows = [...rows];
    updatedRows[index].inputAccount = newInputValue;

    setRows(updatedRows);
  };

  const handleRemember = (index, value) => {
    console.log("event.target.checked", value);
    const updatedData = [...rows];
    updatedData[index].rememberMe = value;
    console.log("updatedData", updatedData);
    setRows(updatedData);
  };

  function customSort(a, b) {
    const keyA = `${a.type}.${a?.accountId?.code}.${a.code}`;
    const keyB = `${b.type}.${b?.accountId?.code}.${b.code}`;

    const partsA = keyA.split('.').map(part => parseInt(part, 10));
    const partsB = keyB.split('.').map(part => parseInt(part, 10));
  
    for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
      if (partsA[i] !== partsB[i]) {
        return partsA[i] - partsB[i];
      }
    }
    return partsA.length - partsB.length;
  }

  const PrimaNoteModal = (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.open}
      // onClose={props.handleClose}
    >
      <Box
        sx={{
          width: "95%",
          backgroundColor: "white",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            borderWidth: "1px",
            borderRadius: 2,
            padding: 1.5,
          }}
        >
          <Typography
            // variant='h3'
            sx={{
              fontSize: 24,
              color: "black",
            }}
          >
            {t("reg") + " " + t("primaNota")}
          </Typography>

          {/* <Button
          variant="contained"
          sx={{
            marginLeft: "auto",
          }}
        >
          {t("indietro")}
        </Button> */}
        <Box>
                {!props?.isEdit && (
                  <Button
                    size="small"
                    sx={{
                      textAlign: "end",
                    }}
                    variant="contained"
                    type="submit"
                    onClick={(e) => {
                      onSaveAndContinueClicked(e, true);
                    }}
                    disabled={squaring != 0}
                  >
                    {t("salvaEProsegui")}
                  </Button>
                )}

                <Button
                  // href="/add_fatture_vendita"
                  sx={{
                    marginLeft: "16px",
                    // width: "18pc"
                    textAlign: "end",
                  }}
                  size="small"
                  variant="contained"
                  // onClick={(e) => {
                  //   props?.isEdit
                  //     ? rows.length > 0 ? fnEditPrimaNote(): setOpenDelete(!openDelete)
                  //     : onSaveAndContinueClicked(e, false);
                  // }}
                  onClick={(e) => {
                    onSaveAndContinueClicked(e, false);
                  }}
                  disabled={squaring != 0}
                >
                  {t("salvaEdESCI")}
                </Button>

                <Button
                  sx={{
                    marginLeft: "16px",
                    // width: "18pc"
                    textAlign: "end",
                  }}
                  onClick={() => {
                    backToList(reload);
                  }}
                  variant="contained"
                  size="small"
                >
                  {t("esci") + " " + t("senza" + " " + t("salvare"))}
                </Button>

               
              </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: "center",
            height: "90vh",
            overflowY: "auto",
            padding: 2,
            "&::-webkit-scrollbar": {
              width: "16px",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#fff",
              borderRadius: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4d96ff",
              borderRadius: "10px",
              border: "4px solid #ffffff",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#2c70cf",
            },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Grid container sx={{ marginBottom: "20px" }}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    // display: "flex",
                    marginTop: "24px",
                  }}
                >
                  <TextField
                    size="small"
                    type="date"
                    name="regDate"
                    label={"*" + t("dataRegistrazione")}
                    placeholder="gg/mm/aaaa"
                    value={formik.values.regDate}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      formik.setFieldValue(name, value);
                      checkYearClosed(value);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.regDate && Boolean(formik.errors.regDate)
                    }
                    helperText={formik.touched.regDate && formik.errors.regDate}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <AccountCircle /> */}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {
                    showWarningMsg != "" && 
                    <Box>
                      <Typography style={{color:"#EB5353", fontSize:"0.75rem", marginLeft:"10px"}}>          {showWarningMsg} 
                      </Typography>
                    </Box>
                  }
                </Box>
              </Grid>
            </Grid>

            <Divider></Divider>

            <Grid
              container
              sx={{
                marginTop: "24px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  paddingRight: "12px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h4">
                    {t("reg") + " " + t("contabile")}
                  </Typography>
                  <Button
                    sx={{
                      marginLeft: "16px",
                      // width: "18pc"
                      textAlign: "end",
                    }}
                    // onClick={() => {
                    //   props.onAddOpen()
                    // }}
                    variant="contained"
                    size="small"
                    color="success"
                    startIcon={<AddIcon />}
                    onClick={addRow}
                  >
                    {t("aggiungi")}
                  </Button>
                </Box>

                <Divider sx={{ marginY: "16px" }}></Divider>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                      {/* <TableRow>
                <TableCell align="center" colSpan={3}>
                  Details
                </TableCell>
                <TableCell align="right">Price</TableCell>
              </TableRow> */}
                      <TableRow>
                        <TableCell sx={{ width: "0%" }}></TableCell>
                        <TableCell>
                          {t("conto") + "/" + t("sottoConto")}
                        </TableCell>
                        
                          <TableCell sx={{ width: "10%" }} align="right">
                          {!props.isEdit && (
                            t("fixedAccount")
                            )}
                          </TableCell>
                        

                        <TableCell sx={{ width: "8%" }} align="right">
                          {t("importo") + " " + t("dare")}
                        </TableCell>
                        <TableCell sx={{ width: "8%" }} align="right">
                          {t("importo") + " " + t("avere")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* <TableRow>sdv</TableRow> */}
                      {rows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Button
                              size="small"
                              variant="contained"
                              color="error"
                              sx={{
                                padding: "0px",
                                minWidth: "0px",
                                color: "#fff",
                                padding: "8px",
                              }}
                              onClick={() => removeRow(index)}
                            >
                              <DeleteIcon />
                              {/* <IconButton
                          
                            sx={{
                              color: "#fff",
                            }}
                          >
                            <DeleteIcon />
                          </IconButton> */}
                            </Button>
                          </TableCell>
                          <TableCell
                            sx={{
                              display: "flex",
                            }}
                          >
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                value="end"
                                control={
                                  <Checkbox
                                    checked={
                                      row?.selectedCheckBox === "clienti"
                                    }
                                    onChange={(event) => {
                                      event.stopPropagation();
                                      setRows((prevRow) => {
                                        const mRows = [...prevRow];
                                        const rowItem = { ...mRows[index] };
                                        rowItem.selectedCheckBox = "clienti";
                                        mRows[index] = rowItem;

                                        return mRows;
                                      });
                                    }}
                                  />
                                }
                                label={t("clienti")}
                                labelPlacement="end"
                              />

                              <FormControlLabel
                                value="end"
                                control={
                                  <Checkbox
                                    checked={
                                      row?.selectedCheckBox === "fornitori"
                                    }
                                    onChange={(event) => {
                                      event.stopPropagation();

                                      setRows((prevRow) => {
                                        const mRows = [...prevRow];
                                        const rowItem = { ...mRows[index] };
                                        rowItem.selectedCheckBox = "fornitori";
                                        mRows[index] = rowItem;
                                        return mRows;
                                      });
                                    }}
                                  />
                                }
                                label={t("fornitori")}
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="end"
                                control={
                                  <Checkbox
                                    checked={row?.selectedCheckBox === "conti"}
                                    onChange={(event) => {
                                      event.stopPropagation();
                                      setRows((prevRow) => {
                                        const mRows = [...prevRow];
                                        const rowItem = { ...mRows[index] };
                                        rowItem.selectedCheckBox = "conti";
                                        mRows[index] = rowItem;
                                        return mRows;
                                      });
                                    }}
                                  />
                                }
                                label={t("conti")}
                                labelPlacement="end"
                              />
                            </FormGroup>
                            <Autocomplete
                              size="small"
                              value={row?.accountId}
                              onChange={(event, newValue) => {
                                handleAutocompleteChange(index, newValue);
                              }}
                              inputValue={row?.inputAccount}
                              onInputChange={(event, newInputValue) =>
                                handleInputChange(index, newInputValue)
                              }
                              id={`controllable-states-demo-${index}`}
                              options={
                                row?.selectedCheckBox === "clienti"
                                  ? listOfClient
                                  : row?.selectedCheckBox === "fornitori"
                                  ? listOfSupplier
                                  : row?.selectedCheckBox === "conti"
                                  ? listOfSubAccount.sort(customSort)
                                  : []
                              }
                              getOptionLabel={(option) =>
                                option
                                  ? ` ${option?.type}.${option?.accountId?.code}.${option?.code} - ${option?.name}`
                                  : ""
                              }
                              sx={{ width: "50%" }}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  label={
                                    row?.selectedCheckBox === "clienti"
                                      ? `*${t("clienti")}`
                                      : row?.selectedCheckBox === "fornitori"
                                      ? `*${t("fornitori")}`
                                      : row?.selectedCheckBox === "conti"
                                      ? `*${t("conti")}`
                                      : ``
                                  }
                                />
                              )}
                            />
                          </TableCell>

                          <TableCell align="right">
                            {!props.isEdit && (
                              <FormControlLabel
                                value="end"
                                control={
                                  <Checkbox
                                    checked={row?.rememberMe}
                                    onChange={(event) => {
                                      handleRemember(
                                        index,
                                        event.target.checked
                                      );
                                    }}
                                  />
                                }
                                labelPlacement="end"
                                sx={{
                                  margin: 0,
                                }}
                              />
                            )}
                          </TableCell>

                          <TableCell align="right" sx={{ width: "12%" }}>
                            <TextField
                              size="small"
                              id="outlined-basic"
                              sx={{ width: "15ch" }}
                              label=""
                              placeholder=""
                              variant="standard"
                              value={
                                !row?.amountGive
                                  ? ""
                                  : Math.abs(row?.amountGive)
                              }
                              onChange={(event) => {
                                handleAmountGiveChange(
                                  index,
                                  event.target.value
                                );
                              }}
                              InputProps={{
                                inputComponent: EuroNumberFormat,
                              }}
                            />
                          </TableCell>
                          <TableCell align="right" sx={{ width: "12%" }}>
                            <TextField
                              size="small"
                              id="outlined-basic"
                              sx={{ width: "15ch" }}
                              label=""
                              placeholder=""
                              variant="standard"
                              value={
                                !row?.amountHave
                                  ? ""
                                  : Math.abs(row?.amountHave)
                              }
                              onChange={(event) => {
                                handleAmountHaveChange(
                                  index,
                                  event.target.value
                                );
                              }}
                              InputProps={{
                                inputComponent: EuroNumberFormat,
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}

                      <TableRow>
                        <TableCell rowSpan={4}></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell>
                          <Typography
                            align="right"
                            variant="h6"
                            sx={{ fontWeight: "700" }}
                          >
                            {t("totale")}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <Typography variant="h6" sx={{ fontWeight: "700" }}>
                            {/* {ccyFormat(totalAmountGive)} */}
                            {totalAmountGive.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="h6" sx={{ fontWeight: "700" }}>
                            {/* {ccyFormat(totalAmountHave)}
                             */}
                            {totalAmountHave.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left"></TableCell>
                        <TableCell align="right" colSpan={1}>
                          <Typography variant="h6" sx={{ fontWeight: "700" }}>
                            {t("squadratura")}
                          </Typography>
                        </TableCell>

                        <TableCell align="center" colSpan={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              color:
                                squaring === 0 ||
                                squaring == 0 ||
                                squaring == "0" ||
                                squaring === "0"
                                  ? "#4caf50"
                                  : "#EB5353",
                              fontWeight: "700",
                              paddingLeft:'168px',
                            }}
                          >
                            {/* {squaring.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })} */}
                            {squaring == -0
                                ? Math.abs(parseFloat(squaring)).toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : parseFloat(squaring).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            <Box
              sx={{
                marginTop: "24px",
              }}
            >
              <Typography variant="caption" display="block">
                {t("note")}
              </Typography>
              <TextField
                size="small"
                id="outlined-basic"
                sx={{ marginLeft: "16px", mb: "32px", width: "98%" }}
                label=""
                placeholder="Aggiungi la tua nota qui"
                variant="standard"
                value={note}
                multiline
                onChange={(event) => {
                  setNote(event.target.value);
                }}
              />
              {/* <Typography display="block">{note}</Typography>
          <Divider sx={{ marginY: "16px" }}></Divider> */}
               {props?.isEdit && (
                  <Button
                    // href="/add_fatture_vendita"
                    sx={{
                      marginLeft: "16px",
                      textAlign: "end",
                    }}
                    onClick={() => {
                      setOpenDelete(!openDelete);
                    }}
                    variant="contained"
                    color="error"
                    size="small"
                  >
                    {t("elimina")}
                  </Button>
                )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );

  return (
    <>
      {isLoading ? <LoadingSpinner /> : PrimaNoteModal}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <DeleteDialog
        open={openDelete}
        handleClose={handleCloseDeleteDialog}
        onConfirm={confirmDelete}
      />
    </>
  );
};

export default PrimaNote;
