import React, {useState, useEffect, useCallback, useContext} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { visuallyHidden } from '@mui/utils';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import { Stack } from '@mui/material';
import { FormGroup } from '@mui/material';
import { revisoftLogo, welcomeImage } from "../../../assets/image";
import { AddUser } from "../../users/Add";
import {DeleteDialog} from "../../../components/DeleteDialog";
import { useTranslation } from 'react-i18next';
import {API} from "service/AxiosService"
import apiURL from "apiURL/apiURL";
import apiStatusCode from 'constants/apiStatusCode';
import defaultString from 'constants/defaultString.json';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { AuthContext } from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { resetToken } from 'apiURL/commonFunctions';
import { Clear, Search } from '@mui/icons-material';
import { getDataFromStorage } from 'storage/StorageData';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const { i18n, t } = useTranslation();


  const headCells = [
    {
      id: 'nome',
      numeric: false,
      disablePadding: true,
      label: t('nome'),
    },
    {
      id: 'cognome',
      numeric: true,
      disablePadding: false,
      label: t('cognome'),
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: t('email'),
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, handleRemoveSearch,setRows,originalData,isSearch,setIsSearch,limit,
    setPage,
    setSearchContent,
    searchContent,
    searchUserList,
    fnUserList
   } = props;

  const { i18n, t } = useTranslation();

  return (
    <Toolbar
      sx={{
        pt: { sm: 1 },
        pb: { sm: 1 },
        pl: { sm: 2 },
        pr: { xs: 2, sm: 2 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionato
        </Typography>
      )
       : 
       numSelected > 1 ?
       <Typography
           sx={{ flex: '1 1 100%' }}
           color="inherit"
           variant="subtitle1"
           component="div"
         >
           {numSelected} Selezionati 
         </Typography>
       : 
       (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <TextField
          size='small'
              id="outlined-basic"
              label={t('cerca')}
              value={searchContent}
              onChange={(e) => 
                setSearchContent(e.target.value)
              }
              variant="outlined" sx={{ width: '25pc' }}
              placeholder={t('nome')+", "+t('cognome')+", "+t('email')} />
              <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "5px",
              marginLeft: "8px",
            }}
            onClick={async(e)=>{
              setPage(0);
             if (searchContent != "")
             {
              
             await searchUserList(1,limit);
              setIsSearch(true)
             }else{
             await fnUserList(1,limit)
             }
             
            }}
          >
            <Search />
          </Button>
          <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "5px",
              marginLeft: "8px",
            }}
            onClick={()=>handleRemoveSearch()}
          >
            <Clear />
          </Button>
        </Typography>


      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton  onClick={() => {
                props.onDelete()
              }}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip sx={{
          '&:hover': {
            backgroundColor: '#ffffff',
            boxShadow: 'none',
          }
        }}>
          <Box sx={{ display: 'flex' }}>
            {/* <Button
              variant="contained"
              color="error" size='large'
              onClick={() => {
                props.onDelete()
              }}
              startIcon={<DeleteIcon />}
            >
              {t('elimina')}
            </Button> */}

            <Button
            sx={{
                marginLeft: "16px"
              }}
              onClick={() => {
                props.onAddOpen()
                // handleRemoveSearch()
                // setRows(originalData)
              }}
              variant="contained"
              size='small'
              startIcon={<AddIcon />}>
              {t('nuovo')}
            </Button>
          </Box>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearch: PropTypes.func,
  searchContent: PropTypes.string,
};

export default function EnhancedTable() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('cognome');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [limit, setLimit] = useState(25);
  const [searchContent, setSearchContent] = useState('');
  const [updateObj, setUpdateObj] = useState({});
  const [isEdit, setEdit] = useState(false);
  const [severity,setSeverity]=useState("success")
  const [filterOriginal,setFilterOriginal]=useState([])
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()
  const [isSearch, setIsSearch] = useState(false);
  // const updateObj = {}

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    if(isSearch){
      await searchUserList(newPage + 1, limit)
     }else{
       await fnUserList(newPage+1, limit);
     }
    setSelected([]);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    let newLimit=parseInt(event.target.value, 10)
    setLimit(newLimit);
    let newPage=0;
    setPage(newPage);
    if(isSearch){
      await searchUserList( newPage + 1,newLimit)
     }else{
       await fnUserList(newPage + 1, newLimit);
     }
    setSelected([]);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (nome) => selected.indexOf(nome) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const { i18n, t } = useTranslation();

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  const fnUserList = useCallback(async (page) => {
 
    API.get(`${apiURL.service_list_users}?page=${page}&limit=${limit}`).then( async(res) => {
    
      setIsLoading(false)
      if (res.status === apiStatusCode.SUCCESS) {
        console.log(res.data.data)
        setRows(res.data.data.users)
        setOriginalData(res.data.data.users)
        setTotalRecords(res.data.data.totalRecords)
      }
      else {
        console.log('In else portion ', res.data);
        setOpen(true);
        setErrorMsg(t("common.internalServerError"))
        setSeverity("error")
      }
    }).catch((error) => {
      setIsLoading(false)
      console.log(error)
      setOpen(true);
      setSeverity("error")
      if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
      else if (error.code === defaultString.bad_request) {
        if (error.response.data.status === apiStatusCode.NOTFOUND) {
          setOpen(false);
        }
        else if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
          doLogout()
        }
      }
      setRows([])
      setOriginalData([])
      setTotalRecords(0)
    });
  }, [limit]
  )

  const searchUserList = async (pageValue ,newLimit) => {
    let accountingSubject = await getDataFromStorage("accountingSubject");
    const queryParams = {
      id: accountingSubject?._id,
      page: pageValue,
      limit: newLimit,
    };
    if (searchContent) {
      queryParams.search_text = searchContent
    }
    const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&');
    API.get(`${apiURL.service_filter_users}?${queryString}`).then( async(res) => {
    
      setIsLoading(false)
      if (res.status === apiStatusCode.SUCCESS) {
        setRows(res.data.data.users)
        setTotalRecords(res.data.data.totalRecords);
      }
      else {
        console.log('In else portion ', res.data);
        setOpen(true);
        setErrorMsg(t("common.internalServerError"))
        setSeverity("error")
      }
    }).catch((error) => {
      setIsLoading(false)
      console.log(error)
      setOpen(true);
      setSeverity("error")
      if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
      else if (error.code === defaultString.bad_request) {
        if (error.response.data.status === apiStatusCode.NOTFOUND) {
          setOpen(false);
        }
        else if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
          doLogout()
        }
      }
      setRows([])
      setOriginalData([])
      setTotalRecords(0)
    });


  }
  const confirmDelete = async () => {
    console.log('selected:: ', selected)
    await fnDelete();
    setOpenDelete(!openDelete)
  }

  const fnDelete = async () => {

    const idsToBeDeleted = JSON.stringify({
      'userIds': selected
    });

    API.post(apiURL.service_delete_users, idsToBeDeleted).then(async(res) => {
    
      console.log(res.data);
      if (res.data.status === apiStatusCode.DELETED) {
        setOpen(true);
        setErrorMsg(t("common.deleteSuccess"))
        setSeverity("success")
        if(isSearch){
          await searchUserList(page + 1,limit)
        }else{

          await fnUserList(page + 1,limit);
          handleRemoveSearch();
        }

        // const filteredArr = rows.filter(item => !selected.includes(item._id));
        // setRows(filteredArr);
        // const filteredOriginalArr = filterOriginal.filter(item => !selected.includes(item._id));
        // setOriginalData(filteredOriginalArr);
        setSelected([])
        // handleRemoveSearch()
      }
      else {
        setOpen(true);
        setSeverity("error")
        setErrorMsg(t("common.internalServerError"))
      }

    }).catch((error) => {
      console.log(error.response)
      setIsLoading(false)
      setOpen(true);
      setSeverity("error")
      if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout()
          }
          else{
            setErrorMsg(t("common.badRequest"));
          }
        }   
    });
  }

  const handleCloseDeleteDialog = () => {
    setOpenDelete(!openDelete)
    setSelected([])
  };

  useEffect(() => {
    setIsLoading(true)
    fnUserList(page + 1, limit);
  }, [])

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const navigateToUpdate = (data) => {
    console.log("Clicked Data = ", JSON.stringify(data))
    setUpdateObj(data)
    setEdit(true)
    setOpenAddUser(!openAddUser)
  }

  async function handleRemoveSearch() {
    setSearchContent("");
    setIsSearch(false);
    setTotalRecords(0);
    await fnUserList(page + 1, limit);
  }

  const userModal = (
    <Box sx={{ width: '100%', marginTop: '48px' }}>
    <Box>
      <Typography variant='h3' mb={3}>{t('utenti')}</Typography>
    </Box>
    <Paper sx={{ width: '100%', mb: 2 }}>
      
      <EnhancedTableToolbar
        numSelected={selected.length}
        searchContent={searchContent}
        // handleSearch={(event)=>{
        
        //   const search = event.target.value;
        //   setSearchContent(search)
          
        //   if (search.trim().length > 0) {
        //      const filteredData = filterOriginal.filter(item =>
        //       item.firstName.toLowerCase().includes(search.toLowerCase()) ||
        //       item.lastName.toString().includes(search) ||
        //       item.email.toLowerCase().includes(search.toLowerCase())
        //   );
        //   setRows(filteredData)
        //   }
        //   else {
        //     setRows(originalData)
        //   }
          
        // }}
        onAddOpen={()=>{
          setEdit(false);
          setOpenAddUser(!openAddUser)
        }}
        onDelete={() => {
          setOpenDelete(!openDelete)
        }}
        setSearchContent={setSearchContent}
        handleRemoveSearch={handleRemoveSearch}
        setRows={setRows}
        originalData={originalData}
        limit={limit}
        setPage={setPage}
        searchUserList={searchUserList}
        fnUserList={fnUserList}
        isSearch={isSearch}
        setIsSearch={setIsSearch}
        page={page}
        />

      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size='small'
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {/* {visibleRows.map((row, index) => { */}

            {rows.map((row, index) => {
              const isItemSelected = isSelected(row._id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row._id}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell padding="checkbox" onClick={(event) => handleClick(event, row._id)}>
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    {row.firstName}
                  </TableCell>
                  <TableCell align="left">{row.lastName}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell>
                    <Button
                    size='small'
                      variant="contained"
                      type='button'
                      onClick={() => {
                         navigateToUpdate(row) 
                        //  handleRemoveSearch()
                        // setRows(originalData)
                      
                      }}
                      // startIcon={<DeleteIcon />}
                      >
                        {t('edit')}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}

          </TableBody>
        </Table>
      </TableContainer>
      {/* {
        searchContent.trim().length === 0 && */}
      
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('rowsPerPage')}
        labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} di ${count}`
      }
      />
    {/* } */}
    </Paper>
    <AddUser open={openAddUser} handleClose={async(data)=>{
      if(data?.reload) {
        if(isSearch){
          await searchUserList(page + 1,limit)
        }else{
          await fnUserList(page + 1)

        }
      
      }
      setEdit(false)
      setUpdateObj({})
      setOpenAddUser(!openAddUser)
    }}
             isEdit={isEdit}
             stateObj={updateObj}/>
    {/* ()=>setOpenDelete(!openDelete) */}
    <DeleteDialog open={openDelete} handleClose={handleCloseDeleteDialog} onConfirm={confirmDelete}/>

  </Box>
  )

  return (
   <>
   {isLoading ? LoadingSpinner : userModal}
   <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose} sx={{ width: '100%' }} anchorOrigin={{horizontal:'right', vertical: 'top'}}>
    <Alert onClose={handleAlertClose} sx={{color:"#fff"}} severity={severity} >
      {errorMsg}
    </Alert>
  </Snackbar>
   </>

  );
}
