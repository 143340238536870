import React, { useState, useEffect, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import { t } from "i18next";
import AddFattureVenditaList from "screens/fatture/fattureVendita/Add/AddFattureVendita";
import DeleteDialog from "components/DeleteDialog/DeleteDialog";
import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import defaultString from "constants/defaultString.json";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { getDataFromStorage } from "storage/StorageData";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { MessageDialog } from "../../XMLFattureVendita/MessageDialog";
import { resetToken } from "apiURL/commonFunctions";
import { AuthContext } from "context/AuthContext";
import { Clear, Search } from "@mui/icons-material";
import { Autocomplete, InputAdornment } from "@mui/material";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {props.data.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    handleRemoveSearch,
    setRows,
    originalData,
    searchNumeroFattura,
    searchDataReg,
    searchDataFattura,
    searchPartitaIva,
    setSearchNumeroFattura,
    setSearchDataFattura,
    setSearchDataReg,
    setSearchPartitaIva,
    listOfClient,
    setListOfClient,
    client,
    setClient,
    inputClient,
    setInputClient,
    searchInvoices,
    setIsSearch,
    fnGetInvoices,
    page,
    limit,
    setPage
  } = props;

  return (
    <Toolbar
      sx={{
        pt: { sm: 1 },
        pb: { sm: 1 },
        pl: { sm: 2 },
        pr: { xs: 2, sm: 2 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected == 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionato
        </Typography>
      ) : numSelected > 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionati
        </Typography>
      ) : (
      //   <Typography
      //   sx={{ flex: "1 1 100%" }}
      //   variant="h6"
      //   id="tableTitle"
      //   component="div"
      // >
      //   <TextField
      //     id="outlined-basic"
      //     size="small"
      //     label={t("cerca")}
      //     placeholder={
      //       t("numeroDocumento") +
      //       ", " +
      //       t("clienti") +
      //       ", " +
      //       t("partita") +
      //       " " +
      //       t("iva")
      //     }
      //     variant="outlined"
      //     sx={{ width: "25pc" }}
      //     onChange={props.handleSearch}
      //     value={props.searchContent}
      //   />
      // </Typography>
        <Typography
          sx={{ flex: "1 1 100%", display: "flex", alignItems: "center" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <TextField
            size="small"
            label={t("numeroFattura")}
            placeholder={
              t("numeroFattura")
            }
            sx={{
              width: "9pc",
            }}
            variant="outlined"
            value={searchNumeroFattura}
            onChange={(e) => 
              setSearchNumeroFattura(e.target.value)
            }
          />

          <TextField
            size="small"
            id="input-with-icon-textfield"
            label={"*" + t("data") + " " + t("fattura")}
            placeholder="gg/mm/aaaa"
            type="date"
            name="date"
            value={searchDataFattura}
            onChange={(e) => {
              setSearchDataFattura(e.target.value)
            }}
            sx={{
              width: "10pc",
              marginLeft: "8px"
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AccountCircle /> */}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            size="small"
            id="input-with-icon-textfield"
            label={"*" + t("data") + " " + t("reg")}
            // placeholder="gg/mm/aaaa"

            type="date"
            name="regDate"
            sx={{ marginLeft: "8px" }}
            value={searchDataReg}
            onChange={(e) => {
              setSearchDataReg(e.target.value)
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AccountCircle /> */}
                </InputAdornment>
              ),
              // readOnly: true,
            }}
          />
          <Autocomplete
            size="small"
            value={client}
            onChange={(event, newClient) => {
              setClient(newClient);
            }}
            inputValue={inputClient}
            onInputChange={(event, newInputClient) => {
              setInputClient(newInputClient);
            }}
            id="controllable-states-demo"
            options={listOfClient}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            sx={{ width: "25pc", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField size="small" {...params} label={t("clienti")} />
            )}
          />
          <TextField
            size="small"
            id="outlined-select-currency"
            // select
            label={t("partita") + " " + t("iva")}
            sx={{
              width: "10pc",
              marginLeft: "8px",
            }}
            value={searchPartitaIva}
            onChange={(e) => setSearchPartitaIva(e.target.value)}
          />
          <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "5px",
              marginLeft: "8px",
            }}
            onClick={async(e)=>{
              setPage(0);
             if (searchDataFattura != "" ||searchDataReg!=""|| searchNumeroFattura !="" ||searchPartitaIva!= ""|| client != null  )
             {
              
             await searchInvoices(1,limit);
              setIsSearch(true)
             }else{
             fnGetInvoices(1,limit)
             }
             
            }}
          >
            <Search />
          </Button>
          <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "5px",
              marginLeft: "8px",
            }}
            onClick={()=>handleRemoveSearch()}
          >
            <Clear />
          </Button>
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              props.onDelete();
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          sx={{
            "&:hover": {
              backgroundColor: "#ffffff",
              boxShadow: "none",
            },
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Button
              // href="/add_fatture_vendita"
              sx={{
                marginLeft: "16px",
                width: "18pc",
              }}
              onClick={() => {
                props.onAddOpen();
                // handleRemoveSearch();
                // setRows(originalData);
              }}
              variant="contained"
              size="medium"
              startIcon={<AddIcon />}
            >
              {t("registra") + " " + t("fattura") + " "+t("vendita")}
            </Button>
          </Box>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearch: PropTypes.func,
  searchContent: PropTypes.string,
};

const FattureVenditaList = () => {
  const [listOfHeader, setlistOfHeader] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("cognome");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [limit, setLimit] = useState(25);
  const [searchContent, setSearchContent] = useState("");
  const [invoiceObj, setInvoiceObj] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [severity, setSeverity] = useState("success");
  const { t } = useTranslation();
  const [defaultRows, setDefaultRows] = useState(false);
  const [filterOriginal, setFilterOriginal] = useState([]);

  const location = useLocation();
  const [openMessage, setOpenMessage] = useState(false);
  const [filesData, setFilesData] = useState([]);
  const [searchNumeroFattura, setSearchNumeroFattura] = useState("");
  const [searchDataFattura, setSearchDataFattura] = useState("");
  const [searchDataReg, setSearchDataReg] = useState("");
  const [searchPartitaIva, setSearchPartitaIva] = useState("");
  const [listOfClient, setListOfClient] = useState([]);
  const [client, setClient] = useState(null);
  const [inputClient, setInputClient] = useState("");
  const [isSearch,setIsSearch]=useState(false)
  const [pageSearch,setPageSearch]=useState(0)
  const { xmlStatus, xmlData } = location.state || {};
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()

  useEffect(() => {
    const newList = [];
    newList.push({
      id: "numeroFattura",
      numeric: false,
      disablePadding: true,
      label: t("numeroFattura"),
    });
    newList.push({
      id: "dataFattura",
      numeric: true,
      disablePadding: false,
      label: t("data")+ " " +t("fattura"),
    });
    newList.push({
      id: "dataRegistrazione",
      numeric: true,
      disablePadding: false,
      label: t("dataRegistrazione"),
    });
    newList.push({
      id: "cliente",
      numeric: true,
      disablePadding: false,
      label: t("cliente"),
    });
    newList.push({
      id: "partita",
      numeric: true,
      disablePadding: false,
      label: t("partita") + " " + t("iva"),
    });
    newList.push({
      id: "modifica",
      numeric: true,
      disablePadding: false,
      label: "",
    });
    setlistOfHeader(newList);
    getCustomerList();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const getCustomerList = (supplierId = null) => {
    API.get(apiURL.service_get_all_customers)
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setListOfClient(res?.data?.data?.customers);
        } else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(false);
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout()
          }
        }
      });
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    if(isSearch){
     await searchInvoices(newPage + 1, limit)
    }else{
      await fnGetInvoices(newPage+1, limit);
    }

    setSelected([]);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    let newLimit=parseInt(event.target.value, 10)
    setLimit(newLimit);
    let newPage=0;
    setPage(newPage);
    if(isSearch){
     await searchInvoices( newPage + 1,newLimit)
    }else{
      await fnGetInvoices(newPage + 1, newLimit);
    }
    setSelected([]);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch({ type: "LOGOUT", payload: null });
      dispatch({ type: "ACCOUNT_LOGOUT", payload: null })
      localStorage.setItem("userToken", null);
          localStorage.setItem("accountingSubject", null);
          localStorage.setItem("user", null);
        navigate('/', { state: { isModalPopup: true} })
    }
}

  const fnGetInvoices = useCallback(
    async (page,limit) => {
      let accountingSubject = await getDataFromStorage("accountingSubject");
      setIsLoading(true);
      API.get(
        `${apiURL.service_get_sales_invoice}?id=${accountingSubject?._id}&page=${page}&limit=${limit}`
      )
        .then((res) => {
          setIsLoading(false);
          if (res.status === apiStatusCode.SUCCESS) {
            // console.log("Response = ", JSON.stringify(res.data.data))
            setRows(res.data.data.invoice);
            // setOriginalData(res.data.data.invoice);
            setTotalRecords(res.data.data.totalRecords);
          } else {
            console.log("In else portion ", res.data);
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.NOTFOUND) {
              setOpen(false);
            } else if (
              error.response.data.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout()
            }
          }
          setRows([]);
          setOriginalData([]);
          setTotalRecords(0);
        });
    },
    [limit]
  );

  const searchInvoices = async (pageValue ,newLimit) => {
      setIsLoading(true);
      
      // let pageValue=0;
      // setPage(pageValue)

      let accountingSubject = await getDataFromStorage("accountingSubject");
      const clientId=client && client._id 
      const queryParams = {
        id: accountingSubject?._id,
        page: pageValue,
        limit: newLimit,
      };
    
  
      if (searchNumeroFattura) {
        queryParams.invoiceNo = searchNumeroFattura;
      }
    
      if (searchDataFattura) {
        queryParams.documentDate =dayjs(searchDataFattura).format("YYYY-MM-DD");
      }
    
      if (searchDataReg) {
        queryParams.registrationDate =dayjs(searchDataReg).format("YYYY-MM-DD");
      }
    
      if (clientId) {
        queryParams.clientId = clientId;
      }
    
      if (searchPartitaIva) {
        queryParams.clientVatNo = searchPartitaIva;
      }
    
      // Convert the queryParams object to a query string
      const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join('&');
    
      API.get(
        `${apiURL.service_filter_sales_invoice}?${queryString}
        `
      )
        .then((res) => {
          console.log("res",res);
          setIsLoading(false);
          if (res.status === apiStatusCode.SUCCESS) {
            setRows(res.data.data.invoice);
            setTotalRecords(res.data.data.totalRecords);
          } else {
            console.log("In else portion ", res.data);
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.NOTFOUND) {
              setOpen(true);
              setErrorMsg(t("noRecordFound"))
            } else if (
              error.response.data.status === apiStatusCode.UNAUTHORIZED
            ) {
             doLogout()
            }
          }
          setRows([]);
          setOriginalData([]);
          setTotalRecords(0);
        });
    }

  const fnDelete = async () => {
    const idsToBeDeleted = JSON.stringify({
      invoiceIds: selected,
    });

    API.post(apiURL.service_delete_sales_invoice, idsToBeDeleted)
      .then(async (res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(t("common.deleteSuccess"));
          setSeverity("success");
          if(isSearch){
            await searchInvoices(page + 1,limit)
          }else{

            await fnGetInvoices(page + 1,limit);
            handleRemoveSearch();
          }

          // const filteredArr = rows.filter(
          //   (item) => !selected.includes(item._id)
          // );
          // setRows(filteredArr);
          // const filteredOriginalArr = filterOriginal.filter(
          //   (item) => !selected.includes(item._id)
          // );
          // setOriginalData(filteredOriginalArr);
          setSelected([]);
        } else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout()
          } else {
            setErrorMsg(t("common.badRequest"));
          }
        }
      });
  };

  const confirmDelete = async () => {
    console.log("selected:: ", selected);
    await fnDelete();
    setOpenDelete(!openDelete);
  };

  useEffect(() => {

    if (xmlStatus == "success" || xmlStatus == "successwarning") {
      setOpen(true);
      setErrorMsg(t("xmlImport"));
      setSeverity("success");
    }
    if (xmlStatus == "successwarning") {
      setFilesData(xmlData);
      setOpenMessage(true);
    }

    // Clear the state after using it
    const newLocation = { ...location, state: null };
    window.history.replaceState(newLocation, "");
      
   
  }, [ xmlStatus, location]);

  useEffect(() => {
    fnGetInvoices(page + 1,limit);
  }, [])
  
  const navigateToUpdate = (data) => {
    console.log("Clicked Data = ", JSON.stringify(data));
    setInvoiceObj(data);
    setEdit(true);
    setOpenAddUser(!openAddUser);
    setDefaultRows(false);
  };
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

async  function handleRemoveSearch() {
    setSearchNumeroFattura("");
    setSearchDataFattura("");
    setSearchDataReg("")
    setSearchPartitaIva("")
    setClient(null)
    setInputClient("")
    setIsSearch(false)
    setTotalRecords(0)
    await fnGetInvoices(page + 1,limit)
  }

  const handleCloseXMLDialog = () => {
    setOpenMessage(!openMessage);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "48px" }}>
      {isLoading && (
        <Box>
          <LoadingSpinner></LoadingSpinner>
        </Box>
      )}
      <Box>
        <Typography variant="h3" mb={3}>
          {t("fattureVendita")}
        </Typography>
      </Box>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onAddOpen={() => {
            setOpenAddUser(!openAddUser);
            setDefaultRows(true);
          }}
          // handleSearch={(event) => {
          //   const search = event.target.value;
          //   setSearchContent(search);
          //   if (search.trim().length > 0) {
          //     const filteredData = filterOriginal.filter(
          //       (item) =>
          //         item.invoiceNo.toString().includes(search.toString()) ||
          //         item.clientName
          //           .toLowerCase()
          //           .includes(search.toLowerCase()) ||
          //         item.clientVatNo.toString().includes(search.toString())
          //     );
          //     setRows(filteredData);
          //   } else {
          //     setRows(originalData);
          //   }
          // }}
          onDelete={() => {
            setOpenDelete(!openDelete);
          }}
          // searchContent={searchContent}
          // setSearchContent={setSearchContent}
          handleRemoveSearch={handleRemoveSearch}
          setRows={setRows}
          originalData={originalData}
          setSearchNumeroFattura={setSearchNumeroFattura}
          setSearchDataFattura={setSearchDataFattura}
          setSearchDataReg={setSearchDataReg}
          setSearchPartitaIva={setSearchPartitaIva}
          searchNumeroFattura={searchNumeroFattura}
          searchDataFattura={searchDataFattura}
          searchDataReg={searchDataReg}
          searchPartitaIva={searchPartitaIva}
          listOfClient={listOfClient}
          setListOfClient={setListOfClient}
          client={client}
          setClient={setClient}
          inputClient={inputClient}
          setInputClient={setInputClient}
          searchInvoices={searchInvoices}
          isSearch={isSearch}
          setIsSearch={setIsSearch}
          page={page}
          fnGetInvoices={fnGetInvoices}
          limit={limit}
          setPage={setPage}
        />
        <TableContainer
          sx={{
            maxHeight: "70vh",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4d96ff",
              borderRadius: "10px",
            },
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              data={listOfHeader}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      padding="checkbox"
                      onClick={(event) => handleClick(event, row._id)}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.invoiceNo}
                    </TableCell>
                    <TableCell align="left">
                      {dayjs(row.documentDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      {dayjs(row.registrationDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="left">{row.clientName}</TableCell>
                    <TableCell align="left">{row.clientVatNo}</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        type="button"
                        onClick={() => {
                          navigateToUpdate(row);
                          // handleRemoveSearch();
                          // setRows(originalData);
                        }}
                      >
                        {t("edit")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {!isSearch && ( */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("rowsPerPage")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${t("of")} ${count}`
            }
          />
       {/* )}  */}
      </Paper>
      <AddFattureVenditaList
        open={openAddUser}
        isEdit={isEdit}
        stateObj={invoiceObj}
        defaultRows={defaultRows}
        handleClose={async(data) => {
         
          if (data?.reload || xmlStatus) {
            if(isSearch){
            await searchInvoices(page + 1,limit)
            }else{

             await fnGetInvoices(page + 1,limit);
            }
          }
          setEdit(false);
          setInvoiceObj(null);
          setOpenAddUser(!openAddUser);
          setDefaultRows(false);
        }}
      />
      <DeleteDialog
        open={openDelete}
        handleClose={() => setOpenDelete(!openDelete)}
        onConfirm={confirmDelete}
      />

      <>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          sx={{ width: "100%" }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            sx={{ color: "#fff" }}
            severity={severity}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
        <MessageDialog
          open={openMessage}
          handleClose={handleCloseXMLDialog}
          filesData={filesData}
        />
      </>
    </Box>
  );

  // return (
  //   <>
  //     {isLoading ? LoadingSpinner : InvoiceList}
  //     <Snackbar
  //       open={open}
  //       autoHideDuration={6000}
  //       onClose={handleAlertClose}
  //       sx={{ width: "100%" }}
  //       anchorOrigin={{ horizontal: "right", vertical: "top" }}
  //     >
  //       <Alert
  //         onClose={handleAlertClose}
  //         sx={{ color: "#fff" }}
  //         severity={severity}
  //       >
  //         {errorMsg}
  //       </Alert>
  //     </Snackbar>
  //   </>
  // );
};

export default FattureVenditaList;
