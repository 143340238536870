/***
 @author Lokesh Desai
 @description
 */
import React, { useCallback, useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  Divider,
  TextField,
  Stack,
  IconButton,
  InputAdornment,
  Grid,
  MenuItem,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Radio,
} from "@mui/material";
import {
  ContactlessOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import AppLogger from "constants/AppLogger";
import apiStatusCode from "constants/apiStatusCode";
import axios from "axios";
import defaultString from "constants/defaultString.json";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { DeleteDialog } from "components/DeleteDialog";
import { getDataFromStorage } from "storage/StorageData";
import { debounce } from "lodash";
import { ConfirmSaveDialog } from "components/ConfirmSaveDialog";
import { resetToken } from "apiURL/commonFunctions";
import { AuthContext } from "context/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddTabelleIVA = (props) => {
  const { i18n, t } = useTranslation();
  const [isSaveNConti, setIsSaveNConti] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSalesOrdinary, setIsSalesOrdinary] = useState(false);
  const [isSalesReverseCharge, setIsSalesReverseCharge] = useState(false);
  const [isPurchaseOrdinary, setIsPurchaseOrdinary] = useState(false);
  const [isPurchaseReverseCharge, setIsPurchaseReverseCharge] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [accountingSubject, setAccountingSubject] = useState(null);
  const [reload, setReload] = useState(false);
  const [severity, setSeverity] = useState("success");
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()

  useEffect(() => {
    getDataFromStorage(defaultString.accountingSubject).then((response) => {
      setAccountingSubject(response);
    });

    if (props && props?.stateObj) {
      formik.setValues({
        codice: props?.stateObj?.code ? props?.stateObj?.code : "",
        descrizione: props?.stateObj?.description
          ? props?.stateObj?.description
          : "",
        aliquota: props?.stateObj?.rate ? props?.stateObj?.rate : "",
        // isSalesOrdinary: props?.stateObj?.ordinarySalesRegister,
        // isSalesReverseCharge: props?.stateObj?.reverseChargeSales,
        // isPurchaseOrdinary: props?.stateObj?.ordinaryPurchaseRegister,
        // isPurchaseReverseCharge: props?.stateObj?.reverseChargePurchase,
      });
      setIsSalesOrdinary(props?.stateObj?.ordinarySalesRegister);
      setIsSalesReverseCharge(props?.stateObj?.reverseChargeSales);
      setIsPurchaseOrdinary(props?.stateObj?.ordinaryPurchaseRegister);
      setIsPurchaseReverseCharge(props?.stateObj?.reverseChargePurchase);
    }
  }, [props, props?.stateObj]);

  const validationSchema = Yup.object().shape({
    codice: Yup.string().required("*" + t("codice") + " " + t("richiede")),
    // .integer(t('common.mustIntegerMessage'))
    // .typeError(t('common.mustNumberMessage')),
    descrizione: Yup.string().required(
      "*" + t("descrizione") + " " + t("richiede")
    ),
    aliquota: Yup.number()
      .required("*" + t("aliquota") + " " + t("richiede"))
      .integer(t("common.mustIntegerMessage"))
      .typeError(t("common.mustNumberMessage")),
    // isSalesOrdinary: Yup.boolean(),
    // isSalesReverseCharge: Yup.boolean(),
    // anotherField: Yup.string().when(["isSalesOrdinary", "isSalesReverseCharge"], {
    //   is: (isSalesOrdinary, isSalesReverseCharge) => isSalesOrdinary === false && isSalesReverseCharge === false,
    //   then: Yup.string().required('I am required now that both checkboxes are checked')
    // })
  });
  // .test(
  //   'checkbox-one-or-two',
  //   'At least one checkbox must be checked',
  //   (value) => value.isSalesOrdinary || value.isSalesReverseCharge
  // );

  const formik = useFormik({
    initialValues: {
      codice: "",
      descrizione: "",
      aliquota: "",
      // isSalesOrdinary: false,
      // isSalesReverseCharge: false,
      // isPurchaseOrdinary: false,
      // isPurchaseReverseCharge: false,
    },
    validationSchema,
    onSubmit: async (values, { setErrors }) => {
      console.log("Form values:", values);

      const requestObject = {};
      let isCodeUnique = false;

      requestObject.code = values.codice;
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.vatId = props?.isEdit ? props.stateObj?._id : 0;

      const checkCodeResponse = await API.post(
        apiURL.service_check_vat_code_exist,
        requestObject
      );
      console.log(JSON.stringify(checkCodeResponse));
      if (checkCodeResponse && checkCodeResponse?.data?.success) {
        if (checkCodeResponse?.data?.data?.isExist) {
          isCodeUnique = false;
          formik?.setFieldTouched("codice", true, false);
          setErrors({
            codice: t("codeIsAlreadyInUser"),
          });
        } else {
          isCodeUnique = true;
        }
      }

      if (isCodeUnique) {
        if (props?.isEdit) {
          await fnEditVat();
        } else {
          await fnAddVat();
        }
      } else {
      }

      // setTimeout(() => {
      //   // alert(JSON.stringify(values, null, 2));
      //   setSubmitting(false);
      // }, 400);
    },
  });

  const backToList = (reload) => {
    props.handleClose({ reload: reload });
    formik.resetForm();
    setIsSalesOrdinary(false);
    setIsSalesReverseCharge(false);
    setIsPurchaseOrdinary(false);
    setIsPurchaseReverseCharge(false);
    setIsSaveNConti(false);
  };

  const onSaveAndContinueClicked = (e, isContinue) => {
    e.preventDefault();
    setIsSaveNConti(isContinue);
    formik.handleSubmit();
  };

  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  const checkCode = useCallback(
    debounce((value) => {
      const requestObject = {};
      requestObject.code = value;
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.vatId = props?.isEdit ? props.stateObj?._id : 0;

      API.post(apiURL.service_check_vat_code_exist, requestObject).then(
        (checkCodeResponse) => {
          if (checkCodeResponse?.data?.data?.isExist) {
            formik?.setErrors({
              codice: t("codeIsAlreadyInUser"),
            });
          } else {
            formik?.setFieldTouched("codice", true, false);
            formik?.setErrors({
              codice: null,
            });
          }
        }
      );
    }, 500),
    []
  );

  const handleCodeChange = (event) => {
    formik.handleChange(event);
    if (event.target.value.length > 0) {
      checkCode(event.target.value);
    }
  };

  const fnAddVat = async () => {
    if (!!formik.values) {
      if (
        isSalesOrdinary === false &&
        isSalesReverseCharge === false &&
        isPurchaseOrdinary === false &&
        isPurchaseReverseCharge === false
      ) {
        setOpen(true);
        setErrorMsg(t("atleatOneCheckbox"));
        setSeverity("error");
        return;
      }

      // if (isPurchaseOrdinary === false && isPurchaseReverseCharge === false) {
      //   setOpen(true);
      //   setErrorMsg(t('oneOfTwoIsRequired'));
      //   setSeverity("error")
      //   return
      // }

      setIsLoading(true);

      const requestObject = {};
      requestObject.code = formik.values.codice ? formik.values.codice : "";
      requestObject.accountingSubjectId = accountingSubject._id;
      requestObject.description = formik.values.descrizione
        ? formik.values.descrizione
        : "";
      requestObject.rate = formik.values.aliquota ? formik.values.aliquota : "";
      requestObject.ordinarySalesRegister = isSalesOrdinary;
      requestObject.reverseChargeSales = isSalesReverseCharge;
      requestObject.ordinaryPurchaseRegister = isPurchaseOrdinary;
      requestObject.reverseChargePurchase = isPurchaseReverseCharge;

      console.log(requestObject);
      API.post(apiURL.service_add_vat, requestObject)
        .then((response) => {
          setIsLoading(false);
          setReload(true);
          if (response.status === apiStatusCode.CREATED) {
            setOpen(true);
            setErrorMsg(t("common.addSuccess"));
            setSeverity("success");

            formik.resetForm();
            setIsSalesOrdinary(false);
            setIsSalesReverseCharge(false);
            setIsPurchaseOrdinary(false);
            setIsPurchaseReverseCharge(false);
            console.log("isSaveNConti: ", isSaveNConti);
            if (isSaveNConti) {
              setIsSaveNConti(false);
            } else {
              props.handleClose({ reload: true });
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
              doLogout()
            } else {
              setErrorMsg(t("common.badRequest"));
            }
          }
        });
    }
  };

  const fnEditVat = async () => {
    if (!!formik.values) {
      // // if (isSalesOrdinary === false && isSalesReverseCharge === false) {
      // //   setOpen(true);
      // //   setErrorMsg(t('oneOfTwoIsRequired'));
      // //   setSeverity("error")

      // //   return
      // // }

      // if (isPurchaseOrdinary === false && isPurchaseReverseCharge === false) {
      //   setOpen(true);
      //   setErrorMsg(t('oneOfTwoIsRequired'));
      //   setSeverity("error")

      //   return
      // }

      if (
        isSalesOrdinary === false &&
        isSalesReverseCharge === false &&
        isPurchaseOrdinary === false &&
        isPurchaseReverseCharge === false
      ) {
        setOpen(true);
        setErrorMsg(t("atleatOneCheckbox"));
        setSeverity("error");
        return;
      }

      setIsLoading(true);

      const requestObject = {};
      requestObject.code = formik.values.codice;
      requestObject.accountingSubjectId = accountingSubject._id;
      requestObject.description = formik.values.descrizione
        ? formik.values.descrizione
        : "";
      requestObject.rate = formik.values.aliquota ? formik.values.aliquota : "";
      requestObject.ordinarySalesRegister = isSalesOrdinary;
      requestObject.reverseChargeSales = isSalesReverseCharge;
      requestObject.ordinaryPurchaseRegister = isPurchaseOrdinary;
      requestObject.reverseChargePurchase = isPurchaseReverseCharge;

      console.log(requestObject);
      API.put(
        `${apiURL.service_update_vat}/${props.stateObj?._id}`,
        requestObject
      )
        .then((response) => {
          console.log(response);
          setIsLoading(false);
          setReload(true);
          if (response.status === apiStatusCode.SUCCESS) {
            setOpen(true);
            setErrorMsg(t("common.updateSuccess"));
            setSeverity("success");

            formik.resetForm();
            setIsSalesOrdinary(false);
            setIsSalesReverseCharge(false);
            setIsPurchaseOrdinary(false);
            setIsPurchaseReverseCharge(false);
            props.handleClose({ reload: true });
          } else {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("common.internalServerError"));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
              doLogout()
            } else {
              setErrorMsg(t("common.badRequest"));
            }
          }
        });
    }
  };

  const fnDeleteVat = async () => {
    const idsToBeDeleted = JSON.stringify({
      vatIds: [props.stateObj?._id],
      accountingSubjectId: accountingSubject?._id,
    });

    await API.post(apiURL.service_delete_vat, idsToBeDeleted)
      .then(async (res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(t("common.deleteSuccess"));
          setSeverity("success");
          formik.resetForm();
          setIsSalesOrdinary(false);
          setIsSalesReverseCharge(false);
          setIsPurchaseOrdinary(false);
          setIsPurchaseReverseCharge(false);
          props.handleClose({ reload: true });
        } else {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("common.internalServerError"));
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout()
          } else {
            setErrorMsg(t("common.badRequest"));
          }
        }
      });
  };

  const confirmDelete = async () => {
    await fnDeleteVat();
    setOpenDelete(!openDelete);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDelete(!openDelete);
  };
  const handleAlertClose = (event, reason) => {
    setIsLoading(false);
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleOridinarySalesChange = (event) => {
    formik.setValues({
      isSalesOrdinary: event.target.checked,
      isSalesReverseCharge: false,
    });
  };

  const handleReverseSalesChange = (event) => {
    // formik.handleChange(event);
    formik.setValues({
      isSalesReverseCharge: event.target.checked,
      isSalesOrdinary: false,
    });
  };

  const confirmCloseSave = (e) => {
    onSaveAndContinueClicked(e, false);
    setOpenConfirm(!openConfirm);
  };

  const handleCloseDeleteDialogSave = () => {
    setOpenConfirm(!openConfirm);
  };

  const AddVatModal = (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.open}
      // onClose={props.handleClose}
    >
      <Box
        sx={{
          width: "70%",
          backgroundColor: "white",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 1.5, // position: "fixed"
          }}
        >
          <Typography
            sx={{
              fontSize: 24,
              color: "black",
            }}
          >
            {props?.isEdit ? t("changeVat") : t("addVat")}
          </Typography>

          <Button
            size="small"
            variant="contained"
            onClick={() => {
              backToList(reload);
            }}
          >
            {t("indietro")}
          </Button>
        </Box>
        <Divider />

        <Box
          sx={{
            alignItems: "center",
            height: "60vh",
            overflowY: "auto",
            padding: 2,
            "&::-webkit-scrollbar": {
              width: "16px",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#fff",
              borderRadius: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4d96ff",
              borderRadius: "10px",
              border: "4px solid #ffffff",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#2c70cf",
            },
          }}
        >
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              maxWidth: 550,
              width: "100%",
              marginX: "auto",
            }}
          >
            <Stack spacing={2}>
              <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                name="codice"
                label={t("codice")}
                value={formik.values.codice}
                onChange={handleCodeChange}
                onBlur={formik.handleBlur}
                error={formik.touched.codice && Boolean(formik.errors.codice)}
                helperText={formik.touched.codice && formik.errors.codice}
                variant="outlined"
                required
              />

              <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                name="descrizione"
                label={t("descrizione")}
                value={formik.values.descrizione}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.descrizione &&
                  Boolean(formik.errors.descrizione)
                }
                helperText={
                  formik.touched.descrizione && formik.errors.descrizione
                }
                variant="outlined"
                required
              />

              <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                name="aliquota"
                label={t("aliquota")}
                value={formik.values.aliquota}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.aliquota && Boolean(formik.errors.aliquota)
                }
                helperText={formik.touched.aliquota && formik.errors.aliquota}
                variant="outlined"
                required
              />

              <FormControl component="fieldset">
                <FormLabel>{t("registra") + " " + t("vendita")}</FormLabel>

                <FormGroup
                  id="isSalesOrdinary"
                  aria-label="position"
                  sx={{ paddingLeft: "15px" }}
                  column
                >
                  {/* <FormControlLabel
                    control={<Checkbox />}
                    name="isSalesOrdinary"
                    checked={formik.values.isSalesOrdinary}
                    onChange={handleOridinarySalesChange}
                    label={t("ordinario")}
                  />
                  <ErrorMessage name="isSalesOrdinary" component="div" />

                  <FormControlLabel
                    control={<Checkbox />}
                    name="isSalesReverseCharge"
                    checked={formik.values.isSalesReverseCharge}
                    label={t("revCharge")}
                    onChange={handleReverseSalesChange}
                  />
                  <ErrorMessage name="isSalesReverseCharge" component="div" />

                  {formik.errors['checkbox-one-or-two'] && formik.touched.isSalesOrdinary && 
                  formik.touched.isSalesReverseCharge && (
                    <div>{formik.errors['checkbox-one-or-two']}</div>
                  )} */}

                  {/* <FormControlLabel
                    control={<Checkbox />}
                    label={t("ordinario")}
                    labelPlacement="left"
                    name="isSalesOrdinary"
                    checked={formik.values.isSalesOrdinary}
                    onChange={handleOridinarySalesChange}
                    onBlur={formik.handleBlur}
                    // error={
                    //   formik.touched.isSalesOrdinary && Boolean(formik.errors.isSalesOrdinary)
                    // }
                    error={
                      formik.errors['checkbox-one-or-two'] && Boolean(formik.touched.isSalesOrdinary) && Boolean(formik.errors.isSalesReverseCharge)
                    }
                    helperText={formik.touched.isSalesOrdinary && formik.errors.isSalesOrdinary}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label={t("revCharge")}
                    labelPlacement="left"
                    name="isSalesReverseCharge"
                    checked={formik.values.isSalesReverseCharge}
                    onChange={() => handleReverseSalesChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors['checkbox-one-or-two'] && Boolean(formik.touched.isSalesOrdinary) && Boolean(formik.errors.isSalesReverseCharge)
                    }
                    helperText={formik.touched.isSalesReverseCharge && formik.errors.isSalesReverseCharge}
                  /> */}

                  {/* {formik.errors['checkbox-one-or-two'] && formik.touched.isSalesOrdinary && formik.touched.isSalesReverseCharge && (
            <div>{formik.errors['checkbox-one-or-two']}</div>
          )} */}

                  <FormControlLabel
                    value="top"
                    control={<Checkbox />}
                    label={t("ordinario")}
                    labelPlacement="start"
                    checked={isSalesOrdinary}
                    onChange={(e) => {
                      setIsSalesOrdinary(e.target.checked);
                    }}
                  />
                  <FormControlLabel
                    value="top"
                    control={<Checkbox />}
                    label={t("revCharge")}
                    labelPlacement="start"
                    checked={isSalesReverseCharge}
                    onChange={(e) => {
                      setIsSalesReverseCharge(e.target.checked);
                    }}
                  />
                </FormGroup>

                <FormLabel>{t("registra") + " " + t("acquisti")}</FormLabel>
                <FormGroup
                  aria-label="position"
                  sx={{ paddingLeft: "15px" }}
                  column
                >
                  <FormControlLabel
                    value="top"
                    control={<Checkbox />}
                    label={t("ordinario")}
                    labelPlacement="start"
                    checked={isPurchaseOrdinary}
                    onChange={(e) => {
                      setIsPurchaseOrdinary(e.target.checked);
                    }}
                  />
                  <FormControlLabel
                    value="top"
                    control={<Checkbox />}
                    label={t("revCharge")}
                    labelPlacement="start"
                    checked={isPurchaseReverseCharge}
                    onChange={(e) => {
                      setIsPurchaseReverseCharge(e.target.checked);
                    }}
                  />
                </FormGroup>
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {!props?.isEdit && (
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    onClick={(e) => {
                      onSaveAndContinueClicked(e, true);
                    }}
                  >
                    {t("salvaEProsegui")}
                  </Button>
                )}

                <Button
                  size="small"
                  variant="contained"
                  onClick={(e) => {
                    props.isEdit
                      ? setOpenConfirm(!openConfirm)
                      : onSaveAndContinueClicked(e, false);
                  }}

                  // onClick={(e) => {
                  //    props?.isEdit? fnEditVat() : onSaveAndContinueClicked(e, false)
                  // }}
                >
                  {t("salvaEdESCI")}
                </Button>

                {props?.isEdit && (
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setOpenDelete(!openDelete);
                    }}
                  >
                    {t("elimina")}
                  </Button>
                )}
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
  return (
    <>
      {isLoading ? <LoadingSpinner /> : AddVatModal}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <DeleteDialog
        open={openDelete}
        handleClose={handleCloseDeleteDialog}
        onConfirm={confirmDelete}
      />
      <ConfirmSaveDialog
        open={openConfirm}
        handleClose={handleCloseDeleteDialogSave}
        onConfirm={confirmCloseSave}
      />
    </>
  );
};

export default AddTabelleIVA;
