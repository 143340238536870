import axios from "axios";
import apiURL from "./apiURL";
import apiStatusCode from '../constants/apiStatusCode';
import defaultString from '../constants/defaultString.json';

export const resetToken = async () => {
    const user = JSON.parse(localStorage.getItem("user"))
    return await axios.get(`${apiURL.service_logout}/${user?._id}`,
        {
            'headers': {
                'Content-Type': 'application/json',
                // 'Authorization': token
            }
        }
    )
        .then(res => {
            console.log("=======", res);
            if (res.data.status === apiStatusCode.SUCCESS) {
                localStorage.setItem(defaultString.jsonWebToken, null);
                return true;
            }
        })
        .catch(error => {
            console.log("error", error);
            return false;
        });
}
