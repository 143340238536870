const styles = {
  // viewMainContainer: {
  //     width: '100%',
  //     flexDirection: 'row',
  //     height: '35px',
  //     // backgroundColor: 'black',
  //     // paddingHorizontal: '1px'/2,
  // },
  // flexCommon: {
  //     flex: 0.78,
  //     borderColor: 'black',
  //     borderLeftWidth:  '1px',
  //     borderTopWidth:  '1px',
  //     borderBottomWidth:  '1px',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  // },
  // flex1: {
  //     flex: 1,
  //     alignItems: 'center',
  //     justifyContent: 'center',
  // },
  // flexDouble: {
  //     flex: 2.5,
  //     borderColor: 'black',
  //     borderLeftWidth:  '1px',
  //     borderTopWidth:  '1px',
  //     borderBottomWidth:  '1px',
  // },
  // flexLarger: {
  //     flex: 2.5,
  //     borderColor: 'black',
  //     borderLeftWidth:  '1px',
  //     borderTopWidth:  '1px',
  //     borderBottomWidth:  '1px',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  // },
  // textHeader: {
  //     fontFamily: "Helvetica Neue",
  //     fontWeight: "600",
  //     fontSize: 10,
  //     textAlign: "center",
  // },
  // viewHorizonatlDoubled: {
  //     flex: 1,
  //     flexDirection: 'row',
  //     borderTopWidth: '1px',
  //     borderColor: 'black',
  // },
  // borderLeft: {
  //     borderLeftWidth: '1px',
  //     borderColor: 'black',
  // },
  // viewBottomBorder: {} // NEED TO CHECK END

  viewMainContainer: {
    width: "100%",
    flexDirection: "row",
    height: "35px",
  },
  viewRowList: {
    flex: 2.8,
    width: "100%",
  },
  viewRowListItem: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
  },
  flex1: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  flexCommon: {
    flex: 0.7,
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    borderLeftWidth: "1px",
    borderTopWidth: "1px",
    borderBottomWidth: "1px",
  },
  flexCommonInside: {
    flex: 0.7,
    alignItems: "center",
    justifyContent: "center",
  },
  flexDouble: {
    flex: 2.5,
    borderColor: "black",
    borderLeftWidth: "1px",
    borderTopWidth: "1px",
    borderBottomWidth: "1px",
    alignItems: "center",
    justifyContent: "center",
  },
  flexCommonLeftLarge: {
    flex: 2.5,
    borderColor: "black",
    borderLeftWidth: "1px",
    borderTopWidth: "1px",
    borderBottomWidth: "1px",
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  flexLarger: {
    flex: 1.8,
    borderColor: "black",
    borderLeftWidth: "1px",
    alignItems: "center",
    justifyContent: "center",
  },
  textHeader: {
    fontFamily: "Helvetica Neue",
    fontWeight: "600",
    fontSize: 10,
    textAlign: "center",
  },
  viewHorizonatlDoubled: {
    flex: 1,
    flexDirection: "row",
    borderTopWidth: "1px",
    borderColor: "black",
  },
  borderLeft: {
    borderLeftWidth: "1px",
    borderColor: "black",
  },
  totalView: {
    flexDirection: "row",
  },
  totalWidth: {
    flex: 1,
  },
  totalText: {
    fontFamily: "Helvetica Neue",
    fontWeight: "600",
    fontSize: 10,
    textAlign: "right",
    padding: 2,
    textTransform: "uppercase",
  },
  viewBottomBorder: {
    borderBottomWidth: "1px",
    borderColor: "black",
  }, // NEED TO CHECK END
};

export default styles;
