/***
 @author Lokesh Desai
 @description
 */
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Modal,
  Typography,
  Divider,
  TextField,
  Stack,
  IconButton,
  InputAdornment,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import StepContent from "@mui/material/StepContent";
import { t } from "i18next";
import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import defaultString from "constants/defaultString.json";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { getDataFromStorage } from "storage/StorageData";
import AppLogger from "constants/AppLogger";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { resetToken } from "apiURL/commonFunctions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddEsercizi = (props) => {
  const { i18n, t } = useTranslation();

  const steps = [
    {
      label: t("selezionaEsercizio"),
    },
    {
      label: t("seiSicuro"),
    },
    {
      label: t("confermaOperazione"),
    },
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [listOfYears, setListOfYears] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [severity,setSeverity]=useState('success')
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()

  const handleAlertClose = (event, reason) => {
    setIsLoading(false);
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    selectedYear: Yup.string().required("selectedYear"),
  });

  const formik = useFormik({
    initialValues: {
      selectedYear: props?.stateObj?.year,
    },
    validationSchema,
  });

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    if (activeStep == 2) {
      fnUpdateExerise();
    }
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const backToList = () => {
    setActiveStep(0);
    setCompleted({});
    props.handleClose({ reload: false });
  };

  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  const getListOfYears = async () => {
    let accountingSubject = await getDataFromStorage("accountingSubject");

    API.get(`${apiURL.service_get_years_of_exercise}/${accountingSubject?._id}`)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.success &&
          response?.data?.data?.list &&
          response?.data?.data?.list?.length > 0
        ) {
          setListOfYears(
            response?.data?.data?.list ? response?.data?.data?.list : []
          );

          // const latestIndex = response?.data?.data?.list.map((item, index)=>{
          //   if ( item.year == props?.stateObj?.year)
          //    {
          //     console.log(index)
          //     return index
          //    }
          // })
          // console.log(latestIndex)

          if (props?.stateObj?.year) {
            setSelectedYear(props?.stateObj?.year);
          } else {
            setSelectedYear(response?.data?.data?.list[0].year);
          }
        }
      })
      .catch((error) => {
        AppLogger.logError(JSON.stringify(error));
        setIsLoading(false);
        setOpen(true);
        setSeverity("error")
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(false);
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout()
          }
        }
      });
  };

  useEffect(() => {
    getListOfYears();
    if (props && props?.stateObj) {
      setSelectedYear(props?.stateObj?.year);
    }
  }, [props, props?.stateObj]);

  const fnUpdateExerise = async () => {
    if (!!formik.values) {
      setIsLoading(true);

      let updateObj = {};
      updateObj.state = "close";
      updateObj.year = selectedYear;
      updateObj.accountingSubjectId = props?.stateObj?.accountingSubjectId;

      API.put(
        `${apiURL.service_update_exercise}/${props?.stateObj?._id}`,
        updateObj
      )
        .then(async (response) => {
          setIsLoading(false);
          console.log(response.data);

          if (response.status === apiStatusCode.SUCCESS) {
            setOpen(true);
            setErrorMsg(t("common.updateSuccess"));
            setSeverity("success")
            props.handleClose({ reload: true });
          } else {
            setOpen(true);
            setSeverity("error")
            setErrorMsg(t("common.internalServerError"));
          }
        })
        .catch((error) => {
           if (error?.response?.data?.data?.subject == 1) {
            setErrorMsg(t("excercise.previousYearOpen"));
            setIsLoading(false);
            setOpen(true);
            setSeverity("error")
           }
           else {
            AppLogger.logError(JSON.stringify(error));
            setIsLoading(false);
            setOpen(true);
            setSeverity("error")
            if (error.code === defaultString.network_error)
              setErrorMsg(t("common.networkError"));
            else if (error.code === defaultString.bad_request) {
              if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
                doLogout()
              } else {
                setErrorMsg(t("common.badRequest"));
              }
            }
           }
          
        });
    }
  };

  const closeModal = (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.open}
      onClose={props.handleClose}
    >
      <Box
        sx={{
          width: "70%",
          backgroundColor: "white",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
           
            borderRadius: 2,
            padding: "16px",
          }}
        >
          <Typography
            sx={{
              fontSize: 24,
              color: "black",
            }}
          >
            {t("chiudiEsercizio")}
          </Typography>

          <Button size='small' variant="contained" onClick={backToList}>
            {" "}
            {t("indietro")}{" "}
          </Button>
        </Box>
        <Divider />

        <Box
          sx={{
           
            height: "50vh",
            overflowY: "auto",
            py: 3,
            "&::-webkit-scrollbar": {
              width: "16px",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#fff",
              borderRadius: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4d96ff",
              borderRadius: "10px",
              border: "4px solid #ffffff",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#2c70cf",
            },
          }}
        >
          <Box
            component="form"
            sx={{
              maxWidth: 550,
              width: "100%",
              margin: "auto",
            }}
          >
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((step, index) => (
                <Step key={step.label} completed={completed[index]}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {step.label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>

            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button size='small' onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1, py: 1 }}></Typography>

                  {activeStep === 0 ? (
                    <Select
                      sx={{ width: "100%" }}
                      id="outlined-basic"
                      onChange={(event) => {
                        setSelectedYear(event.target.value);
                      }}
                      name="selectedYear"
                      variant="standard"
                      value={selectedYear}
                      label={t("annoEsercizio")}
                    >
                      {listOfYears?.map((item) => {
                        return (
                          <MenuItem key={item?.year} value={item?.year}>
                            {item?.year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : //     <Stack spacing={2} sx={{ mt: 2, mb: 1, py: 1 }}>
                  //     <TextField
                  //       id="standard-select-currency"
                  //       select
                  //       label={t("annoEsercizio")}
                  //       defaultValue={year}
                  //       sx={{
                  //         width: "100%"
                  //       }}
                  //       variant='standard'
                  //     >
                  //     {
                  //       listOfYears?.map((item) => {
                  //         return (
                  //           <MenuItem key={item?._id} value={item?._id}>
                  //             {item.year}
                  //           </MenuItem>
                  //         )

                  //       })
                  //     }
                  //   </TextField>

                  // </Stack>
                  activeStep === 1 ? (
                    <>
                      <Typography>
                        {" "}
                        Sei sicuro di voler chiudere l'esercizio{" "}
                        <strong>{selectedYear}</strong>? Una volta chiuso
                        l’esercizio non sarà più possibile ri aprirlo.
                      </Typography>
                      <Divider />
                    </>
                  ) : activeStep === 2 ? (
                    <Typography>
                      Sei veramente sicuro di confermare?
                      <Divider />
                    </Typography>
                  ) : (
                    <Typography>No</Typography>
                  )}
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                    size='small'
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      {t("indietro")}
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />

                    <Button
                    size='small'
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mr: 1 }}
                    >
                      {t("continua")}
                    </Button>
                    {/* {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                      <Typography variant="caption" sx={{ display: 'inline-block' }}>
                        Step {activeStep + 1} already completed
                      </Typography>
                    ) : (
                      <Button onClick={handleComplete} >
                        {completedSteps() === totalSteps() - 1
                          ? 'Finish'
                          : 'Complete Step'}
                      </Button>
                    ))} */}
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Box>
        </Box>
      </Box>
    </Modal>
  );

  return (
    <>
      {isLoading ? <LoadingSpinner /> : closeModal}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddEsercizi;
