/***
 @author Lokesh Desai
 @description
 */
import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  Divider,
  TextField,
  Stack,
  IconButton,
  InputAdornment,
  Grid,
  Tabs,
  Tab,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";

import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { visuallyHidden } from "@mui/utils";
import { DeleteDialog } from "../../../../components/DeleteDialog";

import { FormGroup } from "@mui/material";
import { revisoftLogo, welcomeImage } from "../../../../assets/image";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import apiURL from "apiURL/apiURL";
import { useEffect } from "react";
import defaultString from "../../../../constants/defaultString.json";
import { getDataFromStorage } from "storage/StorageData";
import { API } from "service/AxiosService";
import apiStatusCode from "../../../../constants/apiStatusCode";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AuthContext } from "context/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { resetToken } from "apiURL/commonFunctions";

function createData(id, nome, codice) {
  return {
    id,
    nome,
    codice,
  };
}

const rows = [
  createData(1, "1:1", "Cassa1"),
  createData(2, "1:2", "Cassa2"),
  createData(3, "1:3", "Cassa3"),
  createData(4, "1:4", "Cassa4"),
  createData(5, "1:5", "Cassa5"),
  createData(6, "1:6", "Cassa6"),
  createData(7, "1:7", "Cassa7"),
  createData(8, "1:8", "Cassa8"),
  createData(9, "1:9", "Cassa9"),
];

function createData2(id2, nome2, codice2) {
  return {
    id2,
    nome2,
    codice2,
  };
}

const rows2 = [
  createData2(21, "2:1", "La Cassa1"),
  createData2(22, "2:2", "La Cassa2"),
  createData2(23, "2:3", "La Cassa3"),
  createData2(24, "2:4", "La Cassa4"),
  createData2(25, "2:5", "La Cassa5"),
  createData2(26, "2:6", "La Cassa6"),
  createData2(27, "2:7", "La Cassa7"),
  createData2(28, "2:8", "La Cassa8"),
  createData2(29, "2:9", "La Cassa9"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator2(a, b, orderBy2) {
  if (b[orderBy2] < a[orderBy2]) {
    return -1;
  }
  if (b[orderBy2] > a[orderBy2]) {
    return 1;
  }
  return 0;
}

function getComparator2(order2, orderBy2) {
  return order2 === "desc"
    ? (a, b) => descendingComparator2(a, b, orderBy2)
    : (a, b) => -descendingComparator2(a, b, orderBy2);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function stableSort2(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { t } = useTranslation();

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = [
    {
      id: "codice",
      numeric: false,
      disablePadding: true,
      label: t("codice"),
    },
    {
      id: "nome",
      numeric: true,
      disablePadding: false,
      label: t("nome"),
    },
  ];
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
             color="primary"
             indeterminate={numSelected > 0 && numSelected < rowCount}
             checked={rowCount > 0 && numSelected === rowCount}
             onChange={onSelectAllClick}
             inputProps={{
               'aria-label': 'select all desserts',
             }}
           /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {/* <TableSortLabel
               active={orderBy === headCell.id}
               direction={orderBy === headCell.id ? order : 'asc'}
               onClick={createSortHandler(headCell.id)}
             >
               {headCell.label}
               {orderBy === headCell.id ? (
                 <Box component="span" sx={visuallyHidden}>
                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                 </Box>
               ) : null}
             </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableHead2(props) {
  const { t } = useTranslation();

  const {
    onSelectAllClick2,
    order2,
    orderBy2,
    numSelected2,
    rowCount2,
    onRequestSort2,
  } = props;
  const createSortHandler2 = (property) => (event) => {
    onRequestSort2(event, property);
  };
  const headCells2 = [
    {
      id: "codice2",
      numeric: false,
      disablePadding: true,
      label: t("codice"),
    },
    {
      id: "nome2",
      numeric: true,
      disablePadding: false,
      label: t("nome"),
    },
  ];
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
             color="primary"
             indeterminate={numSelected2 > 0 && numSelected2 < rowCount2}
             checked={rowCount2 > 0 && numSelected2 === rowCount2}
             onChange={onSelectAllClick2}
             inputProps={{
               'aria-label': 'select all desserts',
             }}
           /> */}
        </TableCell>
        {headCells2.map((headCell2) => (
          <TableCell
            key={headCell2.id}
            align={headCell2.numeric ? "left" : "left"}
            padding={headCell2.disablePadding ? "none" : "normal"}
            sortDirection={orderBy2 === headCell2.id ? order2 : false}
          >
            {headCell2.label}
            {/* <TableSortLabel
               active={orderBy2 === headCell2.id}
               direction={orderBy2 === headCell2.id ? order2 : 'asc'}
               onClick={createSortHandler2(headCell2.id)}
             >
               {headCell2.label}
               {orderBy2 === headCell2.id ? (
                 <Box component="span" sx={visuallyHidden}>
                   {order2 === 'desc' ? 'sorted descending' : 'sorted ascending'}
                 </Box>
               ) : null}
             </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  //  onRequestSort: PropTypes.func.isRequired,
  //  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

EnhancedTableHead2.propTypes = {
  numSelected2: PropTypes.number.isRequired,
  //  onRequestSort2: PropTypes.func.isRequired,
  //  onSelectAllClick2: PropTypes.func.isRequired,
  order2: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy2: PropTypes.string.isRequired,
  rowCount2: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const { i18n, t } = useTranslation();

  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          pt: { sm: 0 },
          pb: { sm: 0 },
          pl: { sm: 2 },
          pr: { xs: 2, sm: 2 },
        }}
      >
        {t("statoPatrimoniale")}
      </Typography>
      <Toolbar
        sx={{
          pt: { sm: 0 },
          pb: { sm: 0 },
          pl: { sm: 2 },
          pr: { xs: 2, sm: 2 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
          display: "flex",
        }}
      >
        {numSelected == 1 ? (
          <Typography
            sx={{ flex: "1 1" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} Selezionato
          </Typography>
        ) : numSelected > 1 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} Selezionati
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <TextField
              size="small"
              id="outlined-basic"
              label={t("cerca")}
              variant="outlined"
              sx={{ width: "15pc", marginRight: "16px" }}
              onChange={props.handleAccountSearch}
            />
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip
            sx={{
              "&:hover": {
                backgroundColor: "#ffffff",
                boxShadow: "none",
              },
            }}
          >
            <Box sx={{ display: "flex" }}></Box>
          </Tooltip>
        )}
      </Toolbar>
    </Box>
  );
}

function EnhancedTableToolbar2(props) {
  const { numSelected2 } = props;
  const { t } = useTranslation();

  return (
    <Box sx={{}}>
      <Typography
        variant="h4"
        sx={{
          pt: { sm: 0 },
          pb: { sm: 0 },
          pl: { sm: 2 },
          pr: { xs: 2, sm: 2 },
        }}
      >
        {t("contoEconomico")}
      </Typography>

      <Toolbar
        sx={{
          pt: { sm: 0 },
          pb: { sm: 0 },
          pl: { sm: 2 },
          pr: { xs: 2, sm: 2 },
          ...(numSelected2 > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected2 == 1 ? (
          <Typography
            sx={{ flex: "1 1" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected2} Selezionato
          </Typography>
        ) : numSelected2 > 1 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected2} Selezionati
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <TextField
              size="small"
              id="outlined-basic"
              label={t("cerca")}
              variant="outlined"
              sx={{ width: "15pc", marginRight: "16px" }}
              onChange={props.handleAccountSearch}
            />
          </Typography>
        )}
        {numSelected2 > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip
            sx={{
              "&:hover": {
                backgroundColor: "#ffffff",
                boxShadow: "none",
              },
            }}
          >
            <Box sx={{ display: "flex" }}></Box>
          </Tooltip>
        )}
      </Toolbar>
    </Box>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleAccountSearch: PropTypes.func,
  accountSearchContent: PropTypes.string,
};

EnhancedTableToolbar2.propTypes = {
  numSelected2: PropTypes.number.isRequired,
  handleAccountSearch: PropTypes.func,
  accountSearchContent: PropTypes.string,
};

const AddAccountSubjects = (props) => {
  const { i18n, t } = useTranslation();

  const validationSchema = Yup.object().shape({
    nomeECognome: Yup.string().required(t("nomeCognomeRequired")),
  });

  const formik = useFormik({
    initialValues: {
      personaFisica: "",
      nomeECognome: "",
      indirizzo: "",
      cap: "",
      città: "",
      provincia: "",
      partita: "",
      codiceFiscale: "",
      sdi: "",
      note: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("Form values:", values);
      props.handleClose();
    },
  });

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("codice");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [openPetrimonialeConto, setopenPetrimonialeConto] = useState(false);
  const [openPetrimonialeSottoConto, setopenPetrimonialeSottoConto] =
    useState(false);
  const [openEconomicoConto, setopenEconomicoConto] = useState(false);
  const [openEconomicoSottoConto, setopenEconomicoSottoConto] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [rowsEconomico, setRowsEconomico] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageEconomico, setPageEconomico] = React.useState(0);
  const [rowsPerPageEconomico, setRowsPerPageEconomico] = React.useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalRecordsEconomico, setTotalRecordsEconomico] = useState(0);
  const [searchAccountContent, setSearchAccountContent] = useState("");
  const [searchEconomicoAccountContent, setSearchEconomicoAccountContent] =
    useState("");
  const [originalEconomicoData, setOriginalEconomicoData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedEconomicoName, setSelectedEconomicoName] = useState([]);
  const [reload, setReload] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()
  const [selectedItems, setSelectedItems1] = useState([]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.nome);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, nome) => {
    const selectedIndex = selected.indexOf(nome);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, nome);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (nome) => selected.indexOf(nome) !== -1;

  // const2
  const [order2, setOrder2] = React.useState("asc");
  const [orderBy2, setOrderBy2] = React.useState("codice");
  const [selected2, setSelected2] = React.useState([]);

  const handleRequestSort2 = (event, property) => {
    const isAsc = orderBy2 === property && order2 === "asc";
    setOrder2(isAsc ? "desc" : "asc");
    setOrderBy2(property);
  };

  const handleSelectAllClick2 = (event) => {
    if (event.target.checked) {
      const newSelected2 = rows2.map((n) => n.id2);
      setSelected2(newSelected2);
      return;
    }
    setSelected2([]);
  };

  const handleClick2 = (event, id2) => {
    const selectedIndex = selected2.indexOf(id2);
    let newSelected2 = [];

    if (selectedIndex === -1) {
      newSelected2 = newSelected2.concat(selected2, id2);
    } else if (selectedIndex === 0) {
      newSelected2 = newSelected2.concat(selected2.slice(1));
    } else if (selectedIndex === selected2.length - 1) {
      newSelected2 = newSelected2.concat(selected2.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected2 = newSelected2.concat(
        selected2.slice(0, selectedIndex),
        selected2.slice(selectedIndex + 1)
      );
    }

    setSelected2(newSelected2);
  };

  const handleChangePage2 = (event, newPage) => {
    setPageEconomico(newPage);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPageEconomico(parseInt(event.target.value, 10));
    setPageEconomico(0);
  };

  const handleChangeDense2 = (event) => {
    setDense(event.target.checked);
  };

  const isSelected2 = (id2) => selected2.indexOf(id2) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  //  const visibleRows = React.useMemo(
  //    () =>
  //      stableSort(rows, getComparator(order, orderBy)).slice(
  //        page * rowsPerPage,
  //        page * rowsPerPage + rowsPerPage,
  //      ),
  //    [order, orderBy, page, rowsPerPage],
  //  );

  //  const visibleRows2 = React.useMemo(
  //    () =>
  //      stableSort2(rows2, getComparator2(order2, orderBy2)).slice(
  //        page * rowsPerPage,
  //        page * rowsPerPage + rowsPerPage,
  //      ),
  //    [order2, orderBy2, page, rowsPerPage],
  //  );

  // const { t } = useTranslation();
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (value == 1) {
      setSearchEconomicoAccountContent("");
      setRowsEconomico(originalEconomicoData);
      setPageEconomico(0);
    } else if (value == 2) {
      setSearchAccountContent("");
      setRows(originalData);
      setPage(0);
    }
  };

  // useEffect(() => {
  //   getAllContoAccounts(pageEconomico + 1);
  //   getAllStatoAccounts(page + 1);
  // }, [page, pageEconomico, props.handleClose]);

  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  useEffect(() => {
    if (value === '1' && props.open) {
      getAllStatoAccounts(pageEconomico + 1);
      setSelectedEconomicoName([])
    
    } else if (value === '2' && props.open) {
      getAllContoAccounts(page + 1);
      setSelectedName([])
    }
  }, [value,pageEconomico,page,props.open]);

  const getAllContoAccounts = async (page) => {
    setReload(true);
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
   await API.get(
      apiURL.service_all_subaccounts +
        `?page=${page}&type=${2}&id=${accountingSubject._id}`
    )
      .then((res) => {
        // console.log("res",res);
        if (res.status === apiStatusCode.SUCCESS) {
          setRowsEconomico(res.data.data.accounts);
          setTotalRecordsEconomico(res.data.data.accounts.length);
          setOriginalEconomicoData(res.data.data.accounts);
          // props.handleClose({ reload: true, selectedEconomicoName });
        } else {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("common.internalServerError"));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(false);
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout()
          }
        }
        setRowsEconomico([]);
        setTotalRecordsEconomico(0);
      });
  };
  const getAllStatoAccounts = async (page) => {
    setReload(true);
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
   await API.get(
      apiURL.service_all_subaccounts +
        `?page=${page}&type=${1}&id=${accountingSubject._id}`
    )
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          // Filter data for type == 1 and code not equal to 3 or 51
          const filterWithoutClientAndSupplier = res.data.data.accounts.filter(item => {
            return (item.type == 1 && (item.accountId?.code != 3 && item.accountId?.code != 51));
          });
         const sortWithoutClientAndSupplier= filterWithoutClientAndSupplier.sort(customSort)
            // Filter data for type == 1 and code == 3 or code == 51
            const filterWithClientAndSupplier = res.data.data.accounts.filter(item => {
              return (item.type == 1 && (item.accountId?.code == 3 || item.accountId?.code == 51));
            });
            // Concatenate filteredData1 to the end of filteredData2
            const concatenatedData = [...sortWithoutClientAndSupplier,...filterWithClientAndSupplier]
            setRows(concatenatedData);
          setOriginalData(concatenatedData);
          setTotalRecords(concatenatedData.length);
          // props.handleClose({ reload: true, selectedName });
        } else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(false);
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout()
          }
        }
        setRows([]);
        setTotalRecords(0);
        setOriginalData([]);
      });
  };
  
  function customSort(a, b) {
    const keyA = `${a.type}.${a?.accountId?.code}.${a.code}`;
    const keyB = `${b.type}.${b?.accountId?.code}.${b.code}`;

    const partsA = keyA.split('.').map(part => parseInt(part, 10));
    const partsB = keyB.split('.').map(part => parseInt(part, 10));
  
    for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
      if (partsA[i] !== partsB[i]) {
        return partsA[i] - partsB[i];
      }
    }
    return partsA.length - partsB.length;
  }
  // rows.sort(customSort);
  rowsEconomico.sort(customSort);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setopenPetrimonialeConto(false);
  };

  const visibleRows = React.useMemo(
    () => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, rows]
  );
  
  const visibleEcoRows = React.useMemo(
    () =>
      rowsEconomico.slice(
        pageEconomico * rowsPerPageEconomico,
        pageEconomico * rowsPerPageEconomico + rowsPerPageEconomico
      ),
    [pageEconomico, rowsPerPageEconomico, rowsEconomico]
  );

  // const handleCheckboxClick = (event, row) => {
  //   setSelectedName(row.name);
  //   props.handleClose({ reload: true, data: row });
  //   setSelectedName("");
  //   setSearchAccountContent("");
  //   setPage(0);
  // };
  const handleCheckboxClick = (event, row) => {
    
    const updatedSelectedItems = [...selectedName];

    const isItemSelected = updatedSelectedItems.find((item) => item._id === row._id);

    if (!isItemSelected) {
      if (updatedSelectedItems.length < 5) {
        updatedSelectedItems.push(row);
      } else {
        // You can choose to show a message or take another action when the limit is reached.
        setSeverity("error")
        setOpen(true)
        setErrorMsg(t("moreThan5Subaccounts"))
        return; // Don't add the item if the limit is reached.
      }
    } else {
      // Remove the item from the array if it's already selected
      const index = updatedSelectedItems.findIndex((item) => item._id === row._id);
      updatedSelectedItems.splice(index, 1);
    }

    setSelectedName(updatedSelectedItems);

    // new changes
    props.handleClose({ reload: true, data: updatedSelectedItems, data1:selectedEconomicoName});
    setPage(0);
    setPageEconomico(0);
    setValue("1");
    setSearchAccountContent("")
    setSearchEconomicoAccountContent("")
    setSelectedName([])
    setSelectedEconomicoName([])
  };

  // const handleCheckboxClick2 = (event, row) => {
  //   setSelectedEconomicoName(row.name);
  //   props.handleClose({ reload: true, data: row });
  //   setSelectedEconomicoName("");
  //   setSearchEconomicoAccountContent("");
  //   setPageEconomico(0);
  //   setValue("1");
  // };

  const handleCheckboxClick2 = (event, row) => {
    const updatedSelectedItems = [...selectedEconomicoName];

    const isItemSelected = updatedSelectedItems.find((item) => item._id === row._id);

    if (!isItemSelected) {
      if (updatedSelectedItems.length < 5) {
        updatedSelectedItems.push(row);
      } else {
        // You can choose to show a message or take another action when the limit is reached.
        setOpen(true)
        setErrorMsg(t("moreThan5Subaccounts"))
        return; // Don't add the item if the limit is reached.
      }
    } else {
      // Remove the item from the array if it's already selected
      const index = updatedSelectedItems.findIndex((item) => item._id === row._id);
      updatedSelectedItems.splice(index, 1);
    }

    setSelectedEconomicoName(updatedSelectedItems);
     // new changes
     props.handleClose({ reload: true, data: selectedName, data1:updatedSelectedItems});
     setPage(0);
     setPageEconomico(0);
     setValue("1");
     setSearchAccountContent("")
     setSearchEconomicoAccountContent("")
     setSelectedName([])
     setSelectedEconomicoName([])
  };
  return (
    <>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={props.open}
        // onClose={props.handleClose}
      >
        <Box
          sx={{
            width: "80%",
            backgroundColor: "white",
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              padding: 1.5,
              // position: "fixed"
            }}
          >
            <Typography
              // variant='h3'
              sx={{
                fontSize: 24,
                color: "black",
              }}
            >
              {t("selezionaUno")}
            </Typography>

            <Box>
            {/* <Button
            sx={{
              marginRight:"16px",
            }}
              size="small"
              variant="contained"
             
              onClick={() => {
                props.handleClose({ reload: true, data: selectedName,data1:selectedEconomicoName});
                setPage(0);
                setPageEconomico(0);
                setValue("1");
                setSearchAccountContent("")
                setSearchEconomicoAccountContent("")
                setSelectedName([])
                setSelectedEconomicoName([])
              }}
              disabled={selectedName.length === 0 && selectedEconomicoName.length === 0}
            >
              {t("seleziona")}
            </Button> */}
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                props.handleClose();
                setPage(0);
                setPageEconomico(0);
                setValue("1");
                setSearchAccountContent("")
                setSearchEconomicoAccountContent("")
                setSelectedName([])
                setSelectedEconomicoName([])
              }}
            >
              {t("indietro")}
            </Button>
            </Box>
          </Box>
          <Divider />

          <Box
            sx={{
              alignItems: "center",
              height: "80vh",
              overflowY: "scroll",
              padding: 2,
              "&::-webkit-scrollbar": {
                width: "16px",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#fff",
                borderRadius: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#4d96ff",
                borderRadius: "10px",
                border: "4px solid #ffffff",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#2c70cf",
              },
            }}
          >
            <Box
              sx={{
                maxWidth: "100%",
                width: "100%",
                marginX: "auto",
              }}
            >
              <Paper sx={{ width: "100%", mb: 2 }}>
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label={t("statoPatrimoniale")} value="1" />
                        <Tab label={t("contoEconomico")} value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <EnhancedTableToolbar
                        numSelected={selected.length}
                        onPatrimonialeContoOpen={() => {
                          setopenPetrimonialeConto(!openPetrimonialeConto);
                        }}
                        onPatrimonialeSottoContoOpen={() => {
                          setopenPetrimonialeSottoConto(
                            !openPetrimonialeSottoConto
                          );
                        }}
                        onDelete={() => {
                          setOpenDelete(!openDelete);
                        }}
                        handleAccountSearch={(event) => {
                          const search = event.target.value;
                          setSearchAccountContent(search);
                          if (search.trim().length > 0) {
                            const filteredData = originalData.filter((item) => {
                              let codice = `${item.type}.${item.accountId.code}.${item.code}`;
                              return (
                                item.name
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                codice.includes(search.toLowerCase())
                              );
                            });
                            setRows(filteredData);
                            setPage(0);
                          } else {
                            setRows(originalData);
                            setPage(0);
                          }
                        }}
                      />
                      <TableContainer
                        sx={{
                          maxHeight: "55vh",
                          "&::-webkit-scrollbar": {
                            width: "0.4em",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#4d96ff",
                            borderRadius: "10px",
                          },
                        }}
                      >
                        <Table
                          stickyHeader
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                          size="small"
                        >
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            //  onSelectAllClick={handleSelectAllClick}
                            //  onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                          />
                          <TableBody>
                            {visibleRows.map((row, index) => {
                              //  const isItemSelected = isSelected(row.nome);
                              const labelId = `enhanced-table-checkbox-${row._id}`;
                              const isItemSelected = selectedName.some((item) => item._id === row._id);
                              

                              return (
                                <TableRow
                                  hover
                                  onClick={(event) =>
                                    handleCheckboxClick(event, row)
                                  }
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row._id}
                                  selected={isItemSelected}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      //  checked={isItemSelected}
                                      checked={isItemSelected}
                                      // onClick={(event) => handleCheckboxClick(event, row.name)}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                  width="15%"
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                  >
                                    {row?.accountId?.type}.
                                    {row?.accountId?.code}.{row?.code}
                                  </TableCell>
                                  <TableCell
                                  width="85%"
                                    align="left"
                                    //  sx={{width:"200px"}}
                                  >
                                    {row?.name}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {searchAccountContent.trim().length === 0 ? (
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={totalRecords}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      ) : null}
                    </TabPanel>
                    <TabPanel value="2">
                      <EnhancedTableToolbar2
                        numSelected2={selected2.length}
                        onEconomicoContoOpen={() => {
                          setopenEconomicoConto(!openEconomicoConto);
                        }}
                        onEconomicoSottoContoOpen={() => {
                          setopenEconomicoSottoConto(!openEconomicoSottoConto);
                        }}
                        onDelete={() => {
                          setOpenDelete(!openDelete);
                        }}
                        handleAccountSearch={(event) => {
                          const search = event.target.value;
                          setSearchEconomicoAccountContent(search);
                          if (search.trim().length > 0) {
                            const filteredData = originalEconomicoData.filter(
                              (item) => {
                                let codice = `${item.type}.${item.accountId.code}.${item.code}`;
                                return (
                                  item.name
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                  codice.includes(search.toLowerCase())
                                );
                              }
                            );
                            setRowsEconomico(filteredData);
                            setPageEconomico(0);
                          } else {
                            setRowsEconomico(originalEconomicoData);
                            setPageEconomico(0);
                          }
                        }}
                      />
                      <TableContainer
                        sx={{
                          maxHeight: "55vh",
                          "&::-webkit-scrollbar": {
                            width: "0.4em",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#4d96ff",
                            borderRadius: "10px",
                          },
                        }}
                      >
                        <Table
                          stickyHeader
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                          size="small"
                        >
                          <EnhancedTableHead2
                            numSelected2={selected2.length}
                            order2={order2}
                            orderBy2={orderBy2}
                            //  onSelectAllClick2={handleSelectAllClick2}
                            //  onRequestSort2={handleRequestSort2}
                            rowCount2={rows.length}
                          />
                          <TableBody>
                            {visibleEcoRows.map((row, index) => {
                              //  const isItemSelected2 = isSelected2(row.id2);
                              const labelId = `enhanced-table-checkbox-${row._id}`;
                              const isItemSelected2 =selectedEconomicoName.some((item) => item._id === row._id);
                                // selectedEconomicoName === row.name;

                              return (
                                <TableRow
                                  hover
                                  onClick={(event) =>
                                    handleCheckboxClick2(event, row)
                                  }
                                  role="checkbox"
                                  aria-checked={isItemSelected2}
                                  tabIndex={-1}
                                  key={row._id}
                                  selected2={isItemSelected2}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected2}
                                      //  onClick={(event)=>handleCheckboxClick2(event,row.name)}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell width="15%"
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                  >
                                    {row?.type}.
                                    {row?.accountId?.code}.{row?.code}
                                  </TableCell>
                                  <TableCell width="85%" align="left">{row.name}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {searchEconomicoAccountContent.trim().length === 0 ? (
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={totalRecordsEconomico}
                          rowsPerPage={rowsPerPageEconomico}
                          page={pageEconomico}
                          onPageChange={handleChangePage2}
                          onRowsPerPageChange={handleChangeRowsPerPage2}
                        />
                      ) : null}
                    </TabPanel>
                  </TabContext>
                </Box>
              </Paper>

              {/* <AddPatrimonialeConto open={openPetrimonialeConto} handleClose={() => setopenPetrimonialeConto(!openPetrimonialeConto)} />
 <AddPatrimonialeSottoConto open={openPetrimonialeSottoConto} handleClose={() => setopenPetrimonialeSottoConto(!openPetrimonialeSottoConto)} />
 <AddEconomicoConto open={openEconomicoConto} handleClose={() => setopenEconomicoConto(!openEconomicoConto)} />
 <AddEconomicoSottoConto open={openEconomicoSottoConto} handleClose={() => setopenEconomicoSottoConto(!openEconomicoSottoConto)} /> */}

              {/* <DeleteDialog open={openDelete} handleClose={() => setOpenDelete(!openDelete)} /> */}
            </Box>
          </Box>

          <Box></Box>
        </Box>
      </Modal>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddAccountSubjects;
