import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Stack, FormGroup, Divider } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { revisoftLogo, welcomeImage } from "../../../assets/image";
import { useFormik } from "formik";
import * as Yup from "yup";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "../../../constants/apiStatusCode";
import defaultString from "../../../constants/defaultString.json";
import { getDataFromStorage } from "storage/StorageData";
import dayjs from "dayjs";
import { API } from "service/AxiosService";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import {
  Document,
  Page,
  Text,
  View,
  PDFDownloadLink,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import logo from "assets/image/revisoft_logo.png";
import { resetToken } from "apiURL/commonFunctions";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { saveAs } from "file-saver";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import axios from "axios";

function createData(dataRegistrazione, conto, importoDare, importoAvere, nota) {
  return {
    dataRegistrazione,
    conto,
    importoDare,
    importoAvere,
    nota,
  };
}

// const rows = [
//   createData(
//     "11/01/1991",
//     "04 - Conto anticipi",
//     "999.999.999,00",
//     "999.999.999,00 ",
//     "Nota bla baladkf "
//   ),
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {props.data.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pt: { sm: 3 },
        pb: { sm: 3 },
        pl: { sm: 2 },
        pr: { xs: 2, sm: 2 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected == 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionato
        </Typography>
      ) : numSelected > 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionati
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <TextField
            id="outlined-basic"
            label="Cerca"
            variant="outlined"
            sx={{ width: "25pc" }}
          />
        </Typography>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [dense, setDense] = React.useState(false);
  const { i18n, t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    startDate: Yup.date()
      .required(t("startDateRequired"))
      .test(
        t("startDateRequired"),
        t("startDateSmallerThanEndDate"),
        function (startDate) {
          const { endDate } = this.parent;
          if (!endDate || !startDate) {
            return true; // Skip validation if either field is not selected
          }
          return new Date(startDate) <= new Date(endDate);
        }
      ),
    endDate: Yup.date()
      .required(t("endDateRequired"))
      .test(
        t("endDateRequired"),
        t("endDateGreaterThanStartDate"),
        function (endDate) {
          const { startDate } = this.parent;
          if (!startDate || !endDate) {
            return true; // Skip validation if either field is not selected
          }
          return new Date(endDate) >= new Date(startDate);
        }
      ),
  });
  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // useEffect(() => {
  //   if (formik?.values?.startDate && formik?.values?.endDate) {
  //     getLibroGiornaleReport();
  //   }
  // }, [formik?.values?.startDate, formik?.values?.endDate]);

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
      navigate("/", { state: { isModalPopup: true } });
    }
  };

  const getLibroGiornaleReport = async () => {
    setIsLoading(true);
    const accountingSubject = await getDataFromStorage("accountingSubject");
    const token = JSON.parse(localStorage.getItem("userToken"));
    // setClientName(accountingSubject.name);
    // setVatNumber(accountingSubject.vatNumber);
    const clientName = accountingSubject.name;
    const vatNumber = accountingSubject.vatNumber;
    if (!!formik?.values) {
      const requestObject = {};
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.startDate = dayjs(formik?.values?.startDate).format(
        "YYYY-MM-DD"
      );
      requestObject.endDate = dayjs(formik?.values?.endDate).format(
        "YYYY-MM-DD"
      );

      await axios.post(apiURL.service_librogiornale_report, requestObject, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(async (res) => {
          setIsLoading(false);
          if (res?.status === apiStatusCode.SUCCESS) {
            await generatePDFDocument(
              res?.data?.data?.report,
              clientName,
              vatNumber
            );
            setRows(res?.data?.data?.report);
          } else {
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setOpen(true);
          setSeverity("error");
          setIsLoading(false);
          if (error?.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error?.code === defaultString.bad_request) {
            if (error?.response?.data?.status === apiStatusCode.NOTFOUND) {
              setErrorMsg(t("noRecordFound"));
            } else if (
              error?.response?.data?.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout();
            }
          }
        });
    }
  };

  const isDataAvailable = !!rows.length > 0; // Assuming rowsForPDF is the data for PDF generation
  const isStartDateSelected = !!formik.values.startDate;
  const isEndDateSelected = !!formik.values.endDate;

  const callApiAndPdf = async () => {
    const data = await getLibroGiornaleReport();
  };
  const generatePDFDocument = async (rows, clientName, vatNumber) => {
    setIsLoading(true);
    try {
      const blob = await pdf(
        <Document>
          <Page size="A3" orientation="landscape" style={{ padding: "24px" }}>
            <View fixed>
              <View
                style={{
                  fontSize: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ marginBottom: "16px" }}>
                  <Text
                    style={{
                      fontSize: 10,
                    }}
                  >
                    {t("soggetto") + " " + t("contabile")} :{" "}
                    <Text
                      style={{
                        fontSize: 10,
                        fontFamily: "Helvetica Neue",
                        fontWeight: "600",
                      }}
                    >
                      {clientName} -{" "}
                    </Text>
                    {vatNumber}
                  </Text>
                </View>
                {/* <View
                  style={{
                    width: "70%",
                  }}
                >
                  <Image
                                    src={logo}
                                    style={{ width: "240px" }}
                                  />
                </View> */}

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "10px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                      marginRight: "16px",
                      alignSelf: "baseline",
                    }}
                  >
                    {t("printDate")}
                  </Text>

                  <Text
                    style={{
                      fontSize: "10px",
                      alignSelf: "baseline",
                    }}
                  >
                    {dayjs().format("DD/MM/YYYY")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <View style={{ width: "50%" }}>
                  <Text
                    style={{
                      fontSize: 10,
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {t("libroGiornale")}
                  </Text>
                </View>

                <View
                  style={{
                    width: "50%",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 9,
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                      border: "1px solid black",
                      paddingHorizontal: 10,
                      paddingVertical: 2,
                      alignSelf: "center",
                    }}
                  >
                    {t("periodo")}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      border: "1px solid black",
                      paddingHorizontal: 10,
                      paddingVertical: 2,
                      alignSelf: "center",
                      borderLeft: "none",
                    }}
                  >
                    {formik?.values?.startDate &&
                      dayjs(formik?.values?.startDate).format("DD/MM/YYYY")}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      border: "1px solid black",
                      paddingHorizontal: 10,
                      paddingVertical: 2,
                      alignSelf: "center",
                      borderLeft: "none",
                    }}
                  >
                    {formik?.values?.endDate &&
                      dayjs(formik?.values?.endDate).format("DD/MM/YYYY")}
                  </Text>
                </View>
              </View>
            </View>
            {rows &&
              rows.map((item, index) => {
                return (
                  <View
                    style={{
                      marginBottom: "16px",
                      flexDirection: "column",
                    }}
                    key={index}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          width: "15%",
                          padding: "8px",
                          paddingLeft: "0px",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: "Helvetica Neue",
                            fontWeight: "600",
                          }}
                        >
                          {t("dataRegistrazione")}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "55%",
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "8px",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                          }}
                        >
                          {dayjs(item.registrationDate).format("DD/MM/YYYY")}
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: "Helvetica Neue",
                            fontWeight: "600",
                          }}
                        >
                          {t("num")} {item?.invoiceNo ? item?.invoiceNo : ""}
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica Neue",
                              fontWeight: "600",

                              marginRight: "12px",
                            }}
                          >
                            {t("data")}
                          </Text>
                          <Text
                            style={{
                              fontSize: 10,
                            }}
                          >
                            {dayjs(item.documentDate).format("DD/MM/YYYY")}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: "30%",
                          padding: "8px",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: "Helvetica Neue",
                            fontWeight: "600",
                          }}
                        >
                          {t("protocollo")}{" "}
                          {item?.protocol ? item?.protocol : ""}
                        </Text>
                      </View>
                    </View>

                    <View>
                      <View
                        style={{
                          flexDirection: "row",
                          border: "1px solid black",
                        }}
                      >
                        <View
                          style={{
                            borderRight: "1px solid black",
                            width: "15%",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica Neue",
                              fontWeight: "600",

                              padding: "2px",
                            }}
                          >
                            {t("codConto")}
                          </Text>
                        </View>
                        <View
                          style={{
                            borderRight: "1px solid black",
                            width: "55%",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica Neue",
                              fontWeight: "600",
                              padding: "2px",
                            }}
                          >
                            {t("descrizioneConto")}
                          </Text>
                        </View>
                        <View
                          style={{
                            borderRight: "1px solid black",
                            width: "15%",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica Neue",
                              fontWeight: "600",
                              textAlign: "right",
                              padding: "2px",
                            }}
                          >
                            {t("importo") + " " + t("dare")}
                          </Text>
                        </View>
                        <View style={{ width: "15%" }}>
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica Neue",
                              fontWeight: "600",
                              textAlign: "right",
                              padding: "2px",
                            }}
                          >
                            {t("importo") + " " + t("avere")}
                          </Text>
                        </View>
                      </View>
                      {item.subaccounts.map((subaccount) => {
                        return (
                          <View
                            style={{
                              flexDirection: "row",
                              border: "1px solid black",
                              borderTop: "none",
                            }}
                          >
                            <View
                              style={{
                                borderRight: "1px solid black",
                                width: "15%",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 10,
                                  padding: "2px",
                                }}
                              >
                                {`${subaccount?.type}.${subaccount?.accountId.code}.${subaccount?.code}`}
                              </Text>
                            </View>
                            <View
                              style={{
                                borderRight: "1px solid black",
                                width: "55%",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 10,

                                  padding: "2px",
                                }}
                              >
                                {subaccount?.name}
                              </Text>
                            </View>
                            <View
                              style={{
                                borderRight: "1px solid black",
                                width: "15%",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 10,
                                  textAlign: "right",
                                  padding: "2px",
                                }}
                              >
                                {subaccount?.amountGive == 0
                                  ? null
                                  : subaccount?.amountGive?.toLocaleString(
                                      "de-DE",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                              </Text>
                            </View>
                            <View style={{ width: "15%" }}>
                              <Text
                                style={{
                                  fontSize: 10,
                                  textAlign: "right",
                                  padding: "2px",
                                }}
                              >
                                {subaccount?.amountHave == 0
                                  ? null
                                  : subaccount?.amountHave?.toLocaleString(
                                      "de-DE",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                    {item?.note ? (
                      <View
                        style={{
                          flexDirection: "row",
                          border: "1px solid black",
                          marginTop: "14px",
                          marginBottom: "14px",
                        }}
                      >
                        <View style={{ width: "100%" }}>
                          <Text
                            style={{
                              fontSize: "10px",
                              padding: "2px",
                            }}
                          >
                            {item?.note}
                          </Text>
                        </View>
                      </View>
                    ) : (
                      ""
                    )}
                  </View>
                );
              })}

            <View
              style={{
                position: "absolute",
                bottom: "10px",
                left: "50%",
              }}
              render={({ pageNumber, totalPages }) => (
                <Text style={{ fontSize: 12 }}>
                  Pagina {pageNumber} di {totalPages}
                </Text>
              )}
              fixed
            />
          </Page>
        </Document>
      ).toBlob();
      saveAs(blob, "Libro_Giornale");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Box sx={{ width: "100%", marginTop: "48px" }}>
        {isLoading && (
          <Box>
            <LoadingSpinner></LoadingSpinner>
          </Box>
        )}
        <Box>
          <Typography variant="h3">{t("libroGiornale")}</Typography>
          <Divider />
        </Box>
        <Paper sx={{ width: "100%", mt: 8, padding: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="small"
            >
              <TableBody>
                <TableRow display="flex">
                  <TableCell
                    sx={{ borderBottom: "none", padding: "0", width: "19%" }}
                  >
                    <Typography mr={2} sx={{ textTransform: "capitalize" }}>
                      {t("selezionedataRegistrazione")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "none", padding: "0", width: "22%" }}
                  >
                    <TableCell sx={{ borderBottom: "0px" }}>
                      <TextField
                        name="startDate"
                        type="date"
                        variant="standard"
                        size="small"
                        value={formik.values.startDate}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.startDate &&
                          Boolean(formik.errors.startDate)
                        }
                        helperText={
                          formik.touched.startDate && formik.errors.startDate
                        }
                      ></TextField>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0px" }}>
                      <TextField
                        name="endDate"
                        size="small"
                        type="date"
                        variant="standard"
                        value={formik.values.endDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.endDate &&
                          Boolean(formik.errors.endDate)
                        }
                        helperText={
                          formik.touched.endDate && formik.errors.endDate
                        }
                      ></TextField>
                    </TableCell>
                  </TableCell>

                  <TableCell sx={{ borderBottom: "none", padding: "0" }}>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<LocalPrintshopIcon />}
                      disabled={!isStartDateSelected || !isEndDateSelected}
                      onClick={() => callApiAndPdf("doc name")}
                    >
                      {t("stampa")}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
