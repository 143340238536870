const styles = {
  viewMainContainer: {
    // flex: 1,
    width: "100%",
    flexDirection: "row",
  },
  viewRowList: {
    flex: 2.8,
    width: "100%",
  },
  viewRowListItem: {
    // flex: 1,
    minHeight: 25,
    width: "100%",
    flexDirection: "row",
  },
  flex1: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  flexCommon: {
    flex: 0.7,
    borderColor: "black",
    // justifyContent: "center",
    borderLeftWidth: "1px",
  },
  flexDouble: {
    flex: 2.5,
    borderColor: "black",
    borderLeftWidth: "1px",
    // justifyContent: "center",
  },
  flexLarger: {
    flex: 1.8,
    borderColor: "black",
    borderLeftWidth: "1px",
    alignItems: "center",
    justifyContent: "center",
  },
  textCenter: {
    fontFamily: "Calibri",
    fontSize: 10,
    textAlign: "center",
  },
  textLeft: {
    fontFamily: "Calibri",
    fontSize: 10,
    textAlign: "left",
    padding: "0 3px",
  },
  textRight: {
    fontFamily: "Calibri",
    fontSize: 10,
    textAlign: "right",
    padding: "0 3px",
  },

  viewHorizonatlDoubled: {
    flex: 1,
    flexDirection: "row",
    borderTopWidth: "1px",
    borderColor: "black",
  },
  borderLeft: {
    borderLeftWidth: "1px",
    borderColor: "black",
  },
  viewBottomBorder: {}, // NEED TO CHECK END
  viewInsiderSmall: {
    flex: 0.75,
    borderColor: "black",
    borderLeftWidth: "1px",
    justifyContent: "center",
  },
  viewInsiderLarge: {
    flex: 1.5,
    borderColor: "black",
    borderLeftWidth: "1px",
    justifyContent: "center",
  },
};

export default styles;
