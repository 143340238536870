const siteBaseUrl = window.location.href.includes("localhost") ? "http://localhost:3001" : "https://revimiki.it";
//"http://15.237.95.82"
const baseUrl = siteBaseUrl + "/api/";

const apiURL = {
    "siteBaseUrl": siteBaseUrl + "/",
    "service_login": baseUrl + "auth/login",
    "service_create_user": baseUrl + "auth/createUser",
    "service_delete_users": baseUrl + "auth/deleteMany",
    "service_update_user": baseUrl + "auth/update",
    "service_list_users": baseUrl + "auth/list",
    "service_forgot_password": baseUrl + "auth/forgotPassword",
    "service_reset_password": baseUrl + "auth/resetPassword",
    "service_list_all_users": baseUrl + "auth/listAll",
    "service_logout": baseUrl + "auth/logout",
    "service_get_subject_types": baseUrl + "account_sub/subjectTypes",
    "service_add_account_sub": baseUrl + "account_sub/add",
    "service_get_account_sub": baseUrl + "account_sub/list",
    "service_update_account_sub": baseUrl + "account_sub/update",
    "service_delete_account_sub": baseUrl + "account_sub/deleteMany",
    "service_add_supplier": baseUrl + "supplier/add",
    "service_get_supplier": baseUrl + "supplier/list",
    "service_delete_supplier": baseUrl + "supplier/deleteMany",
    "service_update_supplier": baseUrl + "supplier/update",
    "service_add_customer": baseUrl + "customer/add",
    "service_update_customer": baseUrl + "customer/update",
    "service_get_customer": baseUrl + "customer/list",
    "service_delete_customer": baseUrl + "customer/deleteMany",
    "service_check_code_exist": baseUrl + "account/checkCodeExist",
    "service_add_account": baseUrl + "account/add",
    "service_update_account": baseUrl + "account/update",
    "service_check_account_name_exist": baseUrl + "account/checkNameExist",
    "service_add_exercise": baseUrl + "exercise/add",
    "service_update_exercise": baseUrl + "exercise/update",
    "service_get_exercise": baseUrl + "exercise/list",
    "service_get_years_of_exercise": baseUrl + "exercise/yearlist",
    "service_get_stato_accounts": baseUrl + "account/list",
    "service_delete_account": baseUrl + "account/deleteMany",
    "service_check_count_year_exercise": baseUrl + "exercise/checkYearCount",
    "service_add_sub_account": baseUrl + "subaccount/add",
    "service_check_subaccount_code_exist": baseUrl + "subaccount/checkCodeExist",
    "service_get_all_accounts": baseUrl + "account/getAllAccounts",
    "service_check_subaccount_name_exist": baseUrl + "subaccount/checkNameExist",
    "service_update_subaccount": baseUrl + "subaccount/update",
    "service_delete_subaccount": baseUrl + "subaccount/deleteMany",
    "service_check_vat_code_exist": baseUrl + "vat/checkCodeExist",
    "service_add_vat": baseUrl + "vat/add",
    "service_update_vat": baseUrl + "vat/update",
    "service_delete_vat": baseUrl + "vat/deleteMany",
    "service_vat_list": baseUrl + "vat/list",
    "service_get_all_customers": baseUrl + "customer/listAll",
    "service_get_all_suppliers": baseUrl + "supplier/listAll",
    "service_get_vats_by_invoice_type": baseUrl + "vat/listByType",
    "service_get_invoice_number": baseUrl + "invoice/getInvoiceNumber",
    "service_add_sales_invoice": baseUrl + "invoice/add",
    "service_update_sales_invoice": baseUrl + "invoice/update",
    "service_get_sales_invoice": baseUrl + "invoice/list",
    "service_delete_sales_invoice": baseUrl + "invoice/deleteMany",
    "service_check_year_closed": baseUrl + "invoice/checkYearClosed",
    "service_customer_sub_accounts_for_invoice": baseUrl + "subaccount/listCustomerSubAccounts",
    "service_supplier_sub_accounts_for_invoice": baseUrl + "subaccount/listSupplierSubAccounts",

    "service_add_reversecharge_invoice": baseUrl + "reversechargeinvoice/add",
    "service_update_reversecharge_invoice": baseUrl + "reversechargeinvoice/update",
    "service_get_reversecharge_invoice": baseUrl + "reversechargeinvoice/list",
    "service_delete_reversecharge_invoice": baseUrl + "reversechargeinvoice/deleteMany",
    "service_check_reversecharge_invoice_number": baseUrl + "reversechargeinvoice/checkInvoiceNumberExist",
    "service_add_purchase_invoice": baseUrl + "purchaseinvoice/add",
    "service_update_purchase_invoice": baseUrl + "purchaseinvoice/update",
    "service_get_purchase_invoice": baseUrl + "purchaseinvoice/list",
    "service_delete_purchase_invoice": baseUrl + "purchaseinvoice/deleteMany",
    "service_check_purchase_invoice_number": baseUrl + "purchaseinvoice/checkInvoiceNumberExist",
    "service_add_primanote": baseUrl + "primanote/add",
    "service_update_primanote": baseUrl + "primanote/update",
    "service_get_primanote": baseUrl + "primanote/list",
    "service_delete_primanote": baseUrl + "primanote/deleteMany",

    "service_sub_accounts_all_customers": baseUrl + "subaccount/listAllCustomerSubAccounts",
    "service_sub_accounts_all_suppliers": baseUrl + "subaccount/listAllSupplierSubAccounts",
    "service_sub_accounts_except_personal_data": baseUrl + "subaccount/listExceptPersonalData",
    "service_month_wise_vat_report": baseUrl + "report/monthwiseVats",
    "service_year_wise_vat_report": baseUrl + "report/yearwiseVats",

    "service_all_subaccounts": baseUrl + "subaccount/list",
    "service_subaccount_wise_invoice_report": baseUrl + "report/subaccountsWiseInvoiceReport",
    "service_get_primanote_number": baseUrl + "primanote/getNumber",

    "service_check_document_date": baseUrl + "invoice/checkDocumentDate",
    "service_check_document_date_purchase": baseUrl + "purchaseinvoice/checkDocumentDate",
    "service_check_document_date_reverse": baseUrl + "reversechargeinvoice/checkDocumentDate",

    "service_get_invoice_number_sales": baseUrl + "invoice/getInvoiceNumber",
    "service_get_protocol_purchase": baseUrl + "purchaseinvoice/getInvoiceNumber",
    "service_get_protocol_reverse": baseUrl + "reversechargeinvoice/getInvoiceNumber",

    "service_audit_report": baseUrl + "report/auditReport",
    "service_delete_exercise": baseUrl + "exercise/deleteMany",

    "service_check_register_date_purchase": baseUrl + "purchaseinvoice/checkRegistrationDate",
    "service_check_register_date_reverse": baseUrl + "reversechargeinvoice/checkRegistrationDate",
    "service_importxml_sales_invoice": baseUrl + "invoice/importXML",
    "service_librogiornale_report": baseUrl + "report/libroGiornale",
    "service_registriIVA_report": baseUrl + "report/registriIVA",
    "service_token_expire": baseUrl + "auth/isTokenExpire",
    "service_all_subaccount_wise_invoice_report": baseUrl + "report/allSubaccountsWiseInvoiceReport",
    "service_get_subaccount_of_customer": baseUrl + "customer/subaccountOfCustomer",
    "service_get_subaccount_of_supplier": baseUrl + "supplier/subaccountOfSupplier",
    "service_filter_sales_invoice": baseUrl + "invoice/filter",
    "service_filter_purchase_invoice": baseUrl + "purchaseinvoice/filter",
    "service_filter_reverse_invoice": baseUrl + "reversechargeinvoice/filter",
    "service_filter_customer": baseUrl + "customer/filter",
    "service_filter_supplier": baseUrl + "supplier/filter",
    "service_list_all_subaccounts": baseUrl + "subaccount/listAll",
    "service_default_dates": baseUrl + "exercise/defaultDates",
    "service_filter_vat": baseUrl + "vat/search",
    "service_filter_users": baseUrl + "auth/search",
    "service_upto5subaccounts_report": baseUrl + "report/upto5SubaccountsReport",
    "service_subaccount_report": baseUrl + "report/subaccountReport",
    "service_audit_customer_report": baseUrl + "report/auditReportForCustomer",
    "service_audit_supplier_report": baseUrl + "report/auditReportForSupplier",
    "service_excel_mastro_contabile_report": baseUrl + "report/excelForMastroContabileeReport",

}

export default apiURL;

