import React, { useEffect, useState, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as Yup from "yup";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  Autocomplete,
  InputAdornment,
  IconButton,
  Modal,
  InputLabel,
  FormControl,
  Input,
} from "@mui/material";
// import { t } from 'i18next';

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { useFormik } from "formik";

import AccountCircle from "@mui/icons-material/AccountCircle";
import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import defaultString from "constants/defaultString.json";
import { getDataFromStorage } from "storage/StorageData";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { object } from "yup";
import { DeleteDialog } from "components/DeleteDialog";
import { AddClienti } from "screens/Anagrafiche/clienti/Add";

import dayjs from "dayjs";
import { itIT } from "@mui/x-date-pickers/locales";
import "dayjs/locale/it";
import { isValid, parseISO } from "date-fns"; // Import date-fns functions for date validation
import { debounce } from "lodash";
import { NumericFormat } from "react-number-format";
import { resetToken } from "apiURL/commonFunctions";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/AuthContext";

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const documentList = [
  {
    type: 1,
    name: "Nota di Credito",
  },
  {
    type: 2,
    name: "Fattura",
  },
];

function EuroNumberFormat(props) {
  const { inputRef, onChange, onBlur, ...other } = props;

  const [inputValueBlur, setInputValueBlur] = useState(true);
  const handleChange = (value) => {
    setInputValueBlur(false);
    onChange(value);
  };

  const handleBlur = () => {
    setInputValueBlur(true);
    if (onBlur) {
      onBlur();
    }
  };
  return (
    <NumericFormat
      {...other}
      onBlur={handleBlur}
      onChange={handleChange}
      getInputRef={inputRef}
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={2}
      fixedDecimalScale={inputValueBlur}
      style={{
        textAlign: "right", // Align text to the right
      }}
    />
  );
}

export default function AddFattureVendita(props) {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    client: Yup.object().nullable().required(t("clientIsRequired")),
    regDate: Yup.date()
      .required(t("registrationDateRequired"))
      .test(
        t("registrationDateRequired"),
        t("registerDateIsGreaterThanDocuDate"),
        function (regDate) {
          const { date } = this.parent;
          if (!date || !regDate) {
            return true; // Skip validation if either field is not selected
          }
          return new Date(regDate) >= new Date(date);
        }
      ),
    date: Yup.date().required(t("dateRequired")),
    // Add validation rules for other form fields if needed
  });

  const formik = useFormik({
    initialValues: {
      client: null,
      inputClient: "",
      regDate: "",
      date: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setErrors }) => {
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      let isYearOpened = false;
      if (values.regDate != "Invalid Date") {
        const requestObject = {};

        requestObject.year = values.regDate
          ? dayjs(values.regDate).format("YYYY")
          : "";
        requestObject.accountingSubjectId = accountingSubject?._id;

        const checkYearResponse = await API.post(
          apiURL.service_check_year_closed,
          requestObject
        );

        // console.log(checkYearResponse);
        if (checkYearResponse && checkYearResponse?.data?.success) {
          if (
            checkYearResponse?.data?.message?.isClosed == 1 ||
            checkYearResponse?.data?.message?.isClosed == 0
          ) {
            isYearOpened = false;

            if (isChangeDate == true) {
              formik?.setFieldTouched("date", true, false);
              formik?.setErrors({
                date: t("yearIsClosed"),
              });
            }

            if (isChangeRegDate == true) {
              formik?.setFieldTouched("regDate", true, false);
              formik?.setErrors({
                regDate: t("yearIsClosed"),
              });
            }
          } else {
            isYearOpened = true;
            // if (!props.isEdit) {
            //   getInvoiceNumber();
            // }
          }
        }
      }

      const requestObjectDocumentDate = {};
      let isDocumentDate = false;
      requestObjectDocumentDate.documentDate = dayjs(values.date).format(
        "YYYY-MM-DD"
      );
      requestObjectDocumentDate.accountingSubjectId = accountingSubject?._id;
      requestObjectDocumentDate.invoiceId = props?.isEdit
        ? props?.stateObj?._id
        : 0;
      requestObjectDocumentDate.invoiceNumber = invoiceNumber;

      const checkPreviousDateResponse = await API.post(
        apiURL.service_check_document_date,
        requestObjectDocumentDate
      );
      if (
        checkPreviousDateResponse &&
        checkPreviousDateResponse?.data?.success
      ) {
        // console.log("checkCodeResponse", checkPreviousDateResponse);
        if (checkPreviousDateResponse?.data?.data?.isAllow == 0) {
          isDocumentDate = false;
          formik?.setFieldTouched("date", true, false);
          if (props?.isEdit) {
            setErrors({
              date: t("wrongDateForSales"),
            });
          } else {
            setErrors({
              date: t("checkPreviousDoucmentDate"),
            });
          }
        } else {
          isDocumentDate = true;
        }
      }

      if (isYearOpened && isDocumentDate) {
        if (props?.isEdit) {
          await fnEditSalesInvoice();
        } else {
          await fnAddSalesInvoice();
        }
      }
    },
  });

  const [listOfClient, setListOfClient] = useState([]);
  const [client, setClient] = useState(listOfClient[0]);
  const [inputClient, setInputClient] = useState("");

  const [listOfDocument, setListOfDocument] = useState([]);
  const [document, setDocument] = useState(
    documentList.find((item) => item.name === "Fattura")
  );
  const [inputDocument, setInputDocument] = useState("");

  const [listOfIVA, setListOfIVA] = useState([]);

  const [iVA, setIVA] = useState(listOfIVA[0]);
  const [inputIVA, setInputIVA] = useState("");

  const [listOfSottoConto, setListOfSottoConto] = useState([]);
  const [sottoConto, setSottoConto] = useState(listOfSottoConto[0]);
  const [inputSottoConto, setInputSottoConto] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [vatNumber, setVatNumber] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [accountingSubject, setAccountingSubject] = useState(null);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);
  const [subaccountRows, setSubaccountRows] = useState([]);
  const [totalAmountHave, setTotalAmountHave] = useState(0);
  const [totalAmountGive, setTotalAmountGive] = useState(0);
  const [squaring, setSquaring] = useState(0);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [reload, setReload] = useState(false);
  const [isSaveNConti, setIsSaveNConti] = useState(false);
  const [note, setNote] = useState("");
  const [openAddUser, setOpenAddUser] = useState(false);
  const [timePeriod, setTimePeriod] = useState(null);
  const [isChangeRegDate, setIsChangeRegDate] = useState(false);
  const [isChangeDate, setIsChangeDate] = useState(false);
  const [editingIndexGive, setEditingIndexGive] = useState(null);
  const [editingIndexHave, setEditingIndexHave] = useState(null);
  const [editingIndexTax, setEditingIndexTax] = useState(null);
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  // const [isFirstTimeOnly, setIsFirstTimeOnly] = useState(true);

  const getCustomerList = async (clientId = null) => {
    // const accountingSubject = await getDataFromStorage(
    //   defaultString.accountingSubject
    // );

    // `${apiURL.service_get_all_customers}/${accountingSubject?._id}`
    API.get(apiURL.service_get_all_customers)
      .then(async (res) => {
        // console.log("res", res?.data?.data);
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          // console.log("Response = ", JSON.stringify(res.data.data));
          setListOfClient(res?.data?.data?.customers);

          if (clientId != null) {
            const newClient = res?.data?.data?.customers.filter(
              (obj) => obj._id === clientId
            );
            handleCustomerChange(newClient[0]);
          }

          if (props?.isEdit) {
            const selectedClient = res?.data?.data?.customers.filter(
              (item) =>
                item._id ===
                (clientId === null ? props?.stateObj?.clientId : clientId)
            );

            if (selectedClient.length > 0) {
              const requestObject = {};
              requestObject.customerId = selectedClient[0]._id;

              // console.log(requestObject)
              const customerSubaccount = await API.post(
                apiURL.service_get_subaccount_of_customer,
                requestObject
              );

              // console.log(customerSubaccount);
              if (customerSubaccount && customerSubaccount?.data?.success) {
                const subaccount = customerSubaccount?.data?.data?.subaccount;
                selectedClient[0].subaccounts = subaccount;
                // console.log(newClient);
              }

              // console.log("selected client: ",selectedClient[0])

              formik.setFieldValue("client", selectedClient[0]);

              if (clientId == null) {
                if (props?.stateObj?.subaccounts.length > 0) {
                  // const selectedClientExist = props?.stateObj?.subaccounts.filter((item)=> item._id === selectedClient[0].subaccounts[0]?._id)
                  const selectedClientExist = props?.stateObj?.subaccounts.filter(
                    (item) => item.isClient === true
                  );

                  if (selectedClientExist.length > 0) {
                    let currentObject = selectedClientExist[0];

                    if (currentObject) {
                      const selectedArray = selectedClient[0].subaccounts[0];

                      // console.log('selectedArray=====',selectedArray)

                      let subaccountObject = {};

                      subaccountObject.accountId = selectedArray;
                      subaccountObject.inputAccount = ` ${selectedArray.type}.${selectedArray?.accountId?.code}.${selectedArray.code} - ${selectedArray.name}`;
                      subaccountObject.amountGive = currentObject.amountGive;
                      subaccountObject.amountHave = currentObject.amountHave;
                      subaccountObject.isClient = true;

                      setSubaccountRows([subaccountObject, ...subaccountRows]);
                    }
                  }
                }
              }
            }
          }

          if (clientId === null) {
            getSubAccounts();
          }
        }
      })
      .catch((error) => {
        // console.log("Error: ",error);
        // console.log('client len:',listOfClient.length);
        if (listOfClient.length == 0) {
          getSubAccounts();
        }
      });
  };

  const getInvoiceNumber = async (yearTyped) => {
    console.log("year typed::", yearTyped);

    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    // API.get(`${apiURL.service_get_invoice_number}/${accountingSubject?._id}`)
    const currentDate = new Date();
    let docuDate = "";
    if (yearTyped == "Invalid") {
      docuDate = null;
    } else {
      docuDate = yearTyped
        ? dayjs(yearTyped).format("YYYY-MM-DD")
        : formik.values.date
        ? dayjs(formik.values.date).format("YYYY-MM-DD")
        : dayjs(currentDate).format("YYYY-MM-DD");
    }

    const requestObject = {
      accountingSubjectId: accountingSubject?._id,
      documentDate: docuDate,
    };

    console.log(requestObject);
    API.post(apiURL.service_get_invoice_number_sales, requestObject)
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setInvoiceNumber(res?.data?.data?.invoice?.invoiceNumber);
        } else {
          // setOpen(true);
          // setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        // setOpen(true);
        // setSeverity("error");
        // if (error.code === defaultString.network_error)
        //   setErrorMsg(t("common.networkError"));
        // else if (error.code === defaultString.bad_request) {
        //   if (error.response.data.status === apiStatusCode.NOTFOUND) {
        //     setOpen(false);
        //   } else if (
        //     error.response.data.status === apiStatusCode.UNAUTHORIZED
        //   ) {
        //     setOpen(true);
        //     setErrorMsg(t("common.unAuthorized"));
        //   }
        // }
      });
  };

  const getSubAccounts = async () => {
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );

    API.get(
      `${apiURL.service_customer_sub_accounts_for_invoice}/${accountingSubject?._id}`
    )
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setListOfSottoConto(res?.data?.data?.subaccounts);

          if (props?.isEdit) {
            if (props?.stateObj?.subaccounts.length > 0) {
              const selectedClientExist = props?.stateObj?.subaccounts.filter(
                (item) => item.isClient === false
              );

              if (selectedClientExist.length > 0) {
                const newArray = [];
                for (let i = 0; i < selectedClientExist.length; i++) {
                  let currentObject = selectedClientExist[i];

                  const selectedArray = (res?.data?.data?.subaccounts).filter(
                    (item) => item._id === currentObject.subaccountId
                  );

                  let subaccountObject = {};

                  subaccountObject.accountId = selectedArray[0];
                  subaccountObject.inputAccount = ` ${selectedArray[0]?.type}.${selectedArray[0]?.accountId?.code}.${selectedArray[0]?.code} - ${selectedArray[0]?.name}`;
                  subaccountObject.amountGive = currentObject?.amountGive;
                  subaccountObject.amountHave = currentObject?.amountHave;
                  subaccountObject.code = selectedArray[0]?.code;
                  subaccountObject.type = selectedArray[0]?.type;

                  newArray.push(subaccountObject);

                  // setSubaccountRows(prevArray => [...prevArray, subaccountObject]);
                }
                setSubaccountRows((prevArray) =>
                  Array.from(
                    new Set([...prevArray, ...newArray].map(JSON.stringify))
                  ).map(JSON.parse)
                );
              }
            }
          }
        } else {
          // setOpen(true);
          // setErrorMsg(t("common.internalServerError"));
          // setSeverity("error");
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        // setOpen(true);
        // setSeverity("error");
        // if (error.code === defaultString.network_error)
        //   setErrorMsg(t("common.networkError"));
        // else if (error.code === defaultString.bad_request) {
        //   if (error.response.data.status === apiStatusCode.NOTFOUND) {
        //     setOpen(false);
        //   } else if (
        //     error.response.data.status === apiStatusCode.UNAUTHORIZED
        //   ) {
        //     setOpen(true);
        //     setErrorMsg(t("common.unAuthorized"));
        //   }
        // }
      });
  };

  // const handleSubmit=async()=>{
  //   formik.handleSubmit();
  //   await formik.validateForm();
  //   if (formik.isValid) {
  //     // Perform your custom logic here
  //   }
  // }

  const checkYearClosed = useCallback(
    debounce(async (regiDate) => {
      if (regiDate == "Invalid Date") {
        return;
      }
      const year = dayjs(regiDate).format("YYYY");
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {};
      requestObject.year = regiDate ? dayjs(regiDate).format("YYYY") : "";
      requestObject.accountingSubjectId = accountingSubject?._id;

      const checkYearResponse = await API.post(
        apiURL.service_check_year_closed,
        requestObject
      );

      if (checkYearResponse && checkYearResponse?.data?.success) {
        if (
          checkYearResponse?.data?.message?.isClosed == 1 ||
          checkYearResponse?.data?.message?.isClosed == 0
        ) {
          if (isChangeDate == true && isChangeRegDate == true) {
            formik?.setFieldTouched("date", true, false);
            formik?.setFieldTouched("regDate", true, false);
            formik?.setErrors({
              date: t("yearIsClosed"),
              regDate: t("yearIsClosed"),
            });
          }

          if (isChangeRegDate == true && isChangeDate == false) {
            formik?.setFieldTouched("regDate", true, false);
            formik?.setErrors({
              regDate: t("yearIsClosed"),
            });
          }
          // return 1;
        } else {
          // return 0;
        }
      }
    }, 500),
    [isChangeDate, isChangeRegDate]
  );

  const checkPreviousDocumentDate = useCallback(
    debounce(async (date) => {
      const documentDate = dayjs(date).format("YYYY-MM-DD");
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {};
      requestObject.documentDate = documentDate;
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.invoiceId = props?.isEdit ? props?.stateObj?._id : 0;
      requestObject.invoiceNumber = invoiceNumber;

      const checkPreviousDateResponse = await API.post(
        apiURL.service_check_document_date,
        requestObject
      );
      if (
        checkPreviousDateResponse &&
        checkPreviousDateResponse?.data?.success
      ) {
        if (checkPreviousDateResponse?.data?.data?.isAllow == 0) {
          formik?.setFieldTouched("date", true, false);

          if (props?.isEdit) {
            formik?.setErrors({
              date: t("wrongDateForSales"),
            });
          } else {
            formik?.setErrors({
              date: t("checkPreviousDoucmentDate"),
            });
          }
          // return 1;
        } else {
          // return 0;
          // if (!props.isEdit) {
          //   getInvoiceNumber();
          // }
        }
      }
    }, 500),
    [props?.stateObj]
  );

  const getVatsByInvoiceType = async () => {
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );

    API.get(
      `${apiURL.service_get_vats_by_invoice_type}?id=${accountingSubject?._id}&type=1`
    )
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setListOfIVA(res?.data?.data?.vat);

          if (props?.isEdit) {
            if (props?.stateObj?.vats.length > 0) {
              const arrayVatTables = [];
              for (let i = 0; i < props?.stateObj?.vats.length; i++) {
                let currentObject = props?.stateObj?.vats[i];

                const selectedArray = res?.data?.data?.vat.filter(
                  (item) => item._id == currentObject.vatId
                );

                let vatObject = {};
                vatObject.iVA = selectedArray[0];
                vatObject.inputIVA = `${selectedArray[0].code} - ${selectedArray[0].description}`;
                vatObject.rate = currentObject.rate;
                vatObject.tax = currentObject.taxableIncome;
                arrayVatTables.push(vatObject);
              }
              setRows(arrayVatTables);
            }
          }
        } else {
          // setOpen(true);
          // setErrorMsg(t("common.internalServerError"));
          // setSeverity("error");
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        // setOpen(true);
        // setSeverity("error");
        // if (error.code === defaultString.network_error)
        //   setErrorMsg(t("common.networkError"));
        // else if (error.code === defaultString.bad_request) {
        //   if (error.response.data.status === apiStatusCode.NOTFOUND) {
        //     setOpen(false);
        //   } else if (
        //     error.response.data.status === apiStatusCode.UNAUTHORIZED
        //   ) {
        //     setOpen(true);
        //     setErrorMsg(t("common.unAuthorized"));
        //   }
        // }
      });
  };

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
      navigate("/", { state: { isModalPopup: true } });
    }
  };

  useEffect(() => {
    if (props.open) {
      if (!props.isEdit) {
        console.log("in open");
        getInvoiceNumber();
      }
    }
    getCustomerList();
    getVatsByInvoiceType();

    setListOfDocument(documentList);

    if (props && props?.stateObj) {
      console.log("EDIT OBJECT::: ", props?.stateObj);

      formik.setValues({
        client: props?.stateObj?.clientId ? props?.stateObj?.clientId : "",
        inputClient: props?.stateObj?.clientName
          ? props?.stateObj?.clientName
          : "",
        regDate: props?.stateObj?.registrationDate
          ? dayjs(props?.stateObj?.registrationDate).format("YYYY-MM-DD")
          : "",

        date: props?.stateObj?.documentDate
          ? dayjs(props?.stateObj?.documentDate).format("YYYY-MM-DD")
          : "",
      });

      const parsedTimePeriod = isValid(parseISO(props?.stateObj?.timePeriod))
        ? parseISO(props?.stateObj?.timePeriod)
        : null;
      setTimePeriod(parsedTimePeriod);

      setSelectedClientId(
        props?.stateObj?.clientId ? props?.stateObj?.clientId : null
      );
      setVatNumber(
        props?.stateObj?.clientVatNo ? props?.stateObj?.clientVatNo : ""
      );
      setInvoiceNumber(
        props?.stateObj?.invoiceNo ? props?.stateObj?.invoiceNo : ""
      );

      if (props?.stateObj?.documentType == 1) {
        setDocument(
          documentList.find((item) => item.name === "Nota di Credito")
        );
        setInputDocument();
      } else {
        setDocument(documentList.find((item) => item.name === "Fattura"));
        setInputDocument();
      }

      setFinalTotal(
        props?.stateObj?.totalInvoice ? props?.stateObj?.totalInvoice : 0
      );
      setTotalTax(
        props?.stateObj?.totalTaxableAmount
          ? props?.stateObj?.totalTaxableAmount
          : 0
      );
      setTotal(
        props?.stateObj?.totalVatAmount ? props?.stateObj?.totalVatAmount : 0
      );
      // setSubaccountRows(props?.stateObj?.subaccounts ? props?.stateObj?.subaccounts : [])
      setTotalAmountHave(
        props?.stateObj?.totalAmountHave ? props?.stateObj?.totalAmountHave : 0
      );
      setTotalAmountGive(
        props?.stateObj?.totalAmountGive ? props?.stateObj?.totalAmountGive : 0
      );
      setSquaring(props?.stateObj?.squaring ? props?.stateObj?.squaring : 0);
      setNote(props?.stateObj?.note ? props?.stateObj?.note : "");
    }
    if (props.open && !props.isEdit) {
      formik.resetForm();
      setTimePeriod(null);
    }
  }, [props?.stateObj, props.open]);

  const fnCommonDefaultRows = () => {
    if (props.defaultRows) {
      if (listOfIVA.length > 0) {
        const filteredRowsIVA = listOfIVA.filter((item) => item.code == "22");

        const updatedRowsIVA = filteredRowsIVA.map((row) => ({
          ...row,
          iVA: row,
          inputIVA: "",
          tax: "",
          rate: row.rate,
        }));

        setRows(updatedRowsIVA);
      }
    }
    if (props.defaultRows) {
      if (listOfSottoConto.length > 0) {
        const filteredRowsSottoConto = listOfSottoConto.filter(
          (item) =>
            (item?.accountId?.code == "11" &&
              item?.code == "1" &&
              item?.type == "1") ||
            (item?.accountId?.code == "1" &&
              item?.code == "1" &&
              item?.type == "2")
        );
        const updatedRowsSottoConto = filteredRowsSottoConto.map((row) => ({
          ...row,
          accountId: row, // Assign a specific item from filteredRows
          inputAccount: "",
          amountGive: 0,
          amountHave: 0,
          isBlock: false,
        }));
        setSubaccountRows(updatedRowsSottoConto);
      }
    }
  };

  useEffect(() => {
    fnCommonDefaultRows();
  }, [props.defaultRows, listOfIVA, listOfSottoConto]);

  //this useEffect for swapping value on document change

  // useEffect(() => {
  //   const calculateValues = () => {
  //     const updatedData = [...rows];
  //     let newVal = 0;
  //     let newTaxMain = 0;

  //     let totalPercent;
  //     let vat17IVA;
  //     let v17IVAPercent=0;
  //     updatedData.map((item) => {
  //       console.log(item);
  //     //      if(item.iVA.code == "V 17 ter"){
  //     //   vat17IVA = item.iVA.code
  //     // }
  //       const newTotal = Math.abs(item.tax) * item.rate;
  //       totalPercent = newTotal / 100

  //       if(item.iVA.code == "V 17 ter"){
  //         vat17IVA = item.iVA.code
  //         v17IVAPercent=totalPercent
  //       }

  //       if (isNaN(totalPercent)) {
  //         totalPercent = 0;
  //         newVal = newVal + totalPercent;
  //       } else {
  //         newVal = newVal + totalPercent;
  //       }
  //       newTaxMain = newTaxMain + Number(item.tax);
  //     });

  //     const mainTotal = total + totalTax;

  //     const updatedSubaccountRows = subaccountRows.map((item) => {
  //       const isV17Exist=updatedData.filter((item)=>item.iVA.code==vat17IVA)
  //       if (item?.isClient === true && document.type === 1) {
  //         if(isV17Exist.length>0){
  //           const tempAmount = item.amountHave; // Preserve original amountHave
  //         item.amountHave = mainTotal - v17IVAPercent;
  //         item.amountGive = tempAmount  ? tempAmount : 0;
  //         }else{
  //           const tempAmount = item.amountHave; // Preserve original amountHave
  //           item.amountHave = mainTotal;
  //           item.amountGive =  tempAmount ? tempAmount : 0 ;
  //         }

  //       } else if (item?.isClient === true && document.type === 2) {
  //         if(isV17Exist.length>0){
  //           const tempAmount = item.amountGive; // Preserve original amountGive
  //         item.amountGive = mainTotal - v17IVAPercent ;
  //         item.amountHave =  tempAmount  ? tempAmount : 0;
  //         }else{
  //           const tempAmount = item.amountGive; // Preserve original amountGive
  //           item.amountGive = mainTotal;
  //           item.amountHave =  tempAmount  ? tempAmount : 0;
  //         }

  //       } else if (document.type === 1) {
  //         if (item?.accountId?.accountId?.code === "11" && item?.code === "1") {
  //           if(isV17Exist.length>0){
  //             const originalAmountGive = item.amountGive; // Preserve original amountGive
  //           item.amountGive = total - v17IVAPercent;
  //           item.amountHave = originalAmountGive  ? originalAmountGive : 0 ;
  //           }else{

  //             const originalAmountGive = item.amountGive; // Preserve original amountGive
  //             item.amountGive = total;
  //             item.amountHave =originalAmountGive  ? originalAmountGive : 0 ;
  //           }
  //         } else if (
  //           item?.accountId?.accountId?.code === "1" &&
  //           item?.code === "1"
  //         ) {
  //           const originalAmountGive = item.amountGive; // Preserve original amountGive
  //           item.amountGive = totalTax;
  //           item.amountHave =originalAmountGive  ? originalAmountGive : 0  ;
  //         }
  //       } else if (document.type === 2) {
  //         if (item?.accountId?.accountId?.code === "11" && item?.code === "1") {
  //           if(isV17Exist.length>0){
  //             const originalAmountHave = item.amountHave; // Preserve original amountHave
  //             item.amountHave = total - v17IVAPercent ;
  //             item.amountGive =originalAmountHave  ? originalAmountHave : 0 ;
  //           }else{

  //             const originalAmountHave = item.amountHave; // Preserve original amountHave
  //             item.amountHave = total;
  //             item.amountGive =originalAmountHave  ? originalAmountHave : 0 ;
  //           }
  //         } else if (
  //           item?.accountId?.accountId?.code === "1" &&
  //           item?.code === "1"
  //         ) {
  //           const originalAmountHave = item.amountHave; // Preserve original amountHave
  //           item.amountHave = totalTax;
  //           item.amountGive =originalAmountHave  ? originalAmountHave : 0 ;
  //         }
  //       }
  //       return item;
  //     });

  //     setSubaccountRows(updatedSubaccountRows);

  //     let newHaveAmount = 0;
  //     let newGiveAmount = 0;

  //     const data = updatedSubaccountRows.forEach((item) => {
  //       newHaveAmount += Number(item.amountHave);
  //       newGiveAmount += Number(item.amountGive);
  //     });

  //     if (data) {
  //       setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount.toFixed(2));
  //       setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount.toFixed(2));

  //       const squaringAmount = newGiveAmount - newHaveAmount;
  //       setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount);
  //     }
  //   };

  //   calculateValues();
  // }, [document]);

  const fnAddSalesInvoice = async () => {
    if (!!formik.values) {
      

      let accountingSubject = await getDataFromStorage("accountingSubject");

      if (rows.length == 0 || subaccountRows.length == 0) {
        setOpen(true);
        setErrorMsg(t("requiredInvoiceData"));
        setSeverity("error");
        return
      }
      
      setIsLoading(true);
      const vatList = [];
      for (let vat of rows) {
        // console.log("vat",vat);
        let vatObj = {};
        vatObj.vatId = vat.iVA._id;
        vatObj.name = vat.iVA.description;
        vatObj.code = vat.iVA.code;
        vatObj.rate = vat.iVA.rate;
        vatObj.taxableIncome = isNaN(Math.abs(parseFloat(vat.tax)))
          ? 0
          : Math.abs(parseFloat(vat.tax)).toFixed(2);
        vatObj.vatAmount = calculatePercentage(
          vat.iVA.rate,
          isNaN(vat.tax) ? 0 : Math.abs(parseFloat(vat.tax)).toFixed(2)
        );

        if (vat.inputIVA == "") {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("selectIVA"));
          return;
        }
        vatList.push(vatObj);
      }

      const subAccountList = [];
      for (let account of subaccountRows) {
        let accountObj = {};
        accountObj.accountId = account?.accountId?.accountId?._id;
        accountObj.subaccountId = account?.accountId?._id;
        accountObj.name = account?.accountId?.name;
        accountObj.code = account?.accountId?.code;
        accountObj.type = account?.accountId?.type;
        accountObj.amountGive = isNaN(account.amountGive)
          ? 0
          : account.amountGive == ""
          ? 0
          : Math.abs(parseFloat(account.amountGive)).toFixed(2);
        accountObj.amountHave = isNaN(account.amountHave)
          ? 0
          : account.amountHave == ""
          ? 0
          : Math.abs(parseFloat(account.amountHave)).toFixed(2);
        accountObj.isClient = account.isClient;

        if (account.inputAccount == "") {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("selectSubaccount"));
          return;
        }
        subAccountList.push(accountObj);
      }

      const requestObject = {};
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.invoiceNo = invoiceNumber;
      requestObject.clientId = formik.values.client
        ? formik.values.client?._id
        : "";
      requestObject.clientName = formik.values.client
        ? formik.values.client?.name
        : "";
      requestObject.clientVatNo = formik.values.client
        ? formik.values.client?.vatNumber
        : "";
      requestObject.registrationDate = formik.values.regDate
        ? formik.values.regDate
        : "";
      requestObject.documentDate = formik.values.date ? formik.values.date : "";

      requestObject.timePeriod = timePeriod ? timePeriod : "";
      requestObject.documentType = document?.type ? document?.type : "1";
      requestObject.vats = vatList;
      requestObject.totalVatAmount = isNaN(total)
        ? 0
        : Math.abs(parseFloat(total)).toFixed(2);
      requestObject.totalTaxableAmount = isNaN(totalTax)
        ? 0
        : Math.abs(parseFloat(totalTax)).toFixed(2);
      requestObject.totalInvoice = isNaN(finalTotal)
        ? 0
        : Math.abs(parseFloat(finalTotal)).toFixed(2);
      requestObject.subaccounts = subAccountList;
      requestObject.totalAmountGive = isNaN(totalAmountGive)
        ? 0
        : Math.abs(parseFloat(totalAmountGive)).toFixed(2);
      requestObject.totalAmountHave = isNaN(totalAmountHave)
        ? 0
        : Math.abs(parseFloat(totalAmountHave)).toFixed(2);
      requestObject.squaring = isNaN(squaring)
        ? 0
        : Math.abs(parseFloat(squaring)).toFixed(2);
      requestObject.note = note ? note : "";

      API.post(apiURL.service_add_sales_invoice, requestObject)
        .then((response) => {
          setIsLoading(false);
          setReload(true);
          if (response.status === apiStatusCode.CREATED) {
            setOpen(true);
            setSeverity("success");
            setErrorMsg(t("common.addSuccess"));
            const regiDate = formik.values.regDate;

            formik.resetForm();
            formik.setFieldValue("regDate", regiDate);
            setRows([]);
            setSubaccountRows([]);
            setTotal(0);
            setTotalTax(0);
            setFinalTotal(0);
            setTotalAmountHave(0);
            setTotalAmountGive(0);
            setSquaring(0);
            setSelectedClientId(null);
            setVatNumber("");
            setInvoiceNumber("");
            setDocument(documentList.find((item) => item.name === "Fattura"));
            setNote("");
            // setTimePeriod(null);
            setIsChangeDate(false);
            setIsChangeRegDate(false);
            // setIsFirstTimeOnly(true);

            if (isSaveNConti) {
              setIsSaveNConti(false);
              fnCommonDefaultRows();
              getInvoiceNumber();
            } else {
              props.handleClose({ reload: true });
            }
          } else {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("common.internalServerError"));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
              doLogout();
            } else {
              setErrorMsg(t("common.badRequest"));
            }
          }
        });
    } else {
      setOpen(true);
      setErrorMsg(t("requiredInfo"));
      setSeverity("error");
    }
  };

  const fnEditSalesInvoice = async () => {
    if (!!formik.values) {

      let accountingSubject = await getDataFromStorage("accountingSubject");

      if (rows.length == 0 || subaccountRows.length == 0) {
        setOpen(true);
        setErrorMsg(t("requiredInvoiceData"));
        setSeverity("error");
        return
      }

      setIsLoading(true);
      const vatList = [];
      for (let vat of rows) {
        let vatObj = {};

        vatObj.vatId = vat.iVA._id;
        vatObj.name = vat.iVA.description;
        vatObj.code = vat.iVA.code;
        vatObj.rate = vat.iVA.rate;
        vatObj.taxableIncome = isNaN(Math.abs(parseFloat(vat.tax)))
          ? 0
          : Math.abs(parseFloat(vat.tax)).toFixed(2);
        vatObj.vatAmount = calculatePercentage(
          vat.iVA.rate,
          isNaN(vat.tax) ? 0 : Math.abs(parseFloat(vat.tax)).toFixed(2)
        );

        if (vat.inputIVA == "") {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("selectIVA"));
          return;
        }
        vatList.push(vatObj);
      }

      const subAccountList = [];
      for (let account of subaccountRows) {
        let accountObj = {};
        accountObj.accountId = account?.accountId?.accountId?._id;
        accountObj.subaccountId = account?.accountId?._id;
        accountObj.name = account?.accountId?.name;
        accountObj.code = account?.accountId?.code;
        accountObj.type = account?.accountId?.type;
        accountObj.amountGive = isNaN(account.amountGive)
          ? 0
          : account.amountGive == ""
          ? 0
          : Math.abs(parseFloat(account.amountGive)).toFixed(2);
        accountObj.amountHave = isNaN(account.amountHave)
          ? 0
          : account.amountHave == ""
          ? 0
          : Math.abs(parseFloat(account.amountHave)).toFixed(2);
        accountObj.isClient = account.isClient;
        if (account.inputAccount == "") {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("selectSubaccount"));
          return;
        }
        subAccountList.push(accountObj);
      }

      const requestObject = {};
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.invoiceNo = invoiceNumber;
      requestObject.clientId = formik.values.client?._id
        ? formik.values.client?._id
        : "";
      requestObject.clientName = formik.values.client?.name
        ? formik.values.client?.name
        : "";
      requestObject.clientVatNo = formik.values.client?.vatNumber
        ? formik.values.client?.vatNumber
        : "";
      requestObject.registrationDate = formik.values.regDate
        ? formik.values.regDate
        : "";
      requestObject.documentDate = formik.values.date ? formik.values.date : "";

      requestObject.timePeriod = timePeriod ? timePeriod : "";
      requestObject.documentType = document?.type ? document?.type : "1";
      requestObject.vats = vatList;
      requestObject.totalVatAmount = isNaN(total)
        ? 0
        : Math.abs(parseFloat(total)).toFixed(2);
      requestObject.totalTaxableAmount = isNaN(totalTax)
        ? 0
        : Math.abs(parseFloat(totalTax)).toFixed(2);
      requestObject.totalInvoice = isNaN(finalTotal)
        ? 0
        : Math.abs(parseFloat(finalTotal)).toFixed(2);
      requestObject.subaccounts = subAccountList;
      requestObject.totalAmountGive = isNaN(totalAmountGive)
        ? 0
        : Math.abs(parseFloat(totalAmountGive)).toFixed(2);
      requestObject.totalAmountHave = isNaN(totalAmountHave)
        ? 0
        : Math.abs(parseFloat(totalAmountHave)).toFixed(2);
      requestObject.squaring = isNaN(squaring)
        ? 0
        : Math.abs(parseFloat(squaring)).toFixed(2);
      requestObject.note = note ? note : "";

      console.log(requestObject);

      API.put(
        `${apiURL.service_update_sales_invoice}/${props.stateObj?._id}`,
        requestObject
      )
        .then((response) => {
          console.log(response);
          setIsLoading(false);
          setReload(true);
          if (response.status === apiStatusCode.SUCCESS) {
            setOpen(true);
            setSeverity("success");
            setErrorMsg(t("common.addSuccess"));
            formik.resetForm();
            setRows([]);
            setSubaccountRows([]);
            setTotal(0);
            setTotalTax(0);
            setFinalTotal(0);
            setTotalAmountHave(0);
            setTotalAmountGive(0);
            setSquaring(0);
            setSelectedClientId(null);
            setVatNumber("");
            setInvoiceNumber("");
            setDocument(documentList.find((item) => item.name === "Fattura"));
            setNote("");
            setTimePeriod(null);
            setIsChangeDate(false);
            setIsChangeRegDate(false);
            // setIsFirstTimeOnly(true);

            // getInvoiceNumber();
            props.handleClose({ reload: true });
          } else {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("common.internalServerError"));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
              doLogout();
            } else {
              setErrorMsg(t("common.badRequest"));
            }
          }
        });
    } else {
      setOpen(true);
      setErrorMsg(t("requiredInfo"));
      setSeverity("error");
    }
  };

  const backToList = (reload) => {
    props.handleClose({ reload: reload });
    formik.resetForm();
    setRows([]);
    setSubaccountRows([]);
    setTotal(0);
    setTotalTax(0);
    setFinalTotal(0);
    setTotalAmountHave(0);
    setTotalAmountGive(0);
    setSquaring(0);
    setSelectedClientId(null);
    setVatNumber("");
    setInvoiceNumber("");
    setDocument(documentList.find((item) => item.name === "Fattura"));
    setNote("");
    setIsSaveNConti(false);
    setTimePeriod(null);
    setIsChangeDate(false);
    setIsChangeRegDate(false);
    // setIsFirstTimeOnly(true);
  };

  const onSaveAndContinueClicked = (e, isContinue) => {
    e.preventDefault();
    // setIsFirstTimeOnly(true);
    if (timePeriod === null) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(t("timePeriodIsRequired"));
    }
    setIsSaveNConti(isContinue);
    formik.handleSubmit();
  };

  const confirmDelete = async () => {
    await fnDelete();
    setOpenDelete(!openDelete);
  };

  const fnDelete = async () => {
    const idsToBeDeleted = JSON.stringify({
      invoiceIds: [props.stateObj?._id],
    });

    API.post(apiURL.service_delete_sales_invoice, idsToBeDeleted)
      .then((response) => {
        if (response.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(t("common.deleteSuccess"));
          setSeverity("success");
          formik.resetForm();
          // setIsEdit(false)
          props.handleClose({ reload: true });
        } else {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("common.internalServerError"));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout();
          } else {
            setErrorMsg(t("common.badRequest"));
          }
        }
      });
  };

  const handleCloseDeleteDialog = () => {
    setOpenDelete(!openDelete);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const removeRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);

    setRows(updatedRows);
    let newVal = 0;
    let newTaxMain = 0;

    let totalPercent;
    let vat17IVA;
    let v17IVAPercent = 0;
    updatedRows.map((item) => {
      // if(item.iVA.code == "V 17 ter"){
      //   vat17IVA = item.iVA.code
      // }
      const newTotal = Math.abs(item.tax) * item.rate;
      totalPercent = newTotal / 100;

      if (item.iVA.code == "V 17 ter") {
        vat17IVA = item.iVA.code;
        v17IVAPercent = totalPercent;
      }

      if (isNaN(totalPercent)) {
        totalPercent = 0;
        newVal = newVal + totalPercent;
      } else {
        newVal = newVal + totalPercent;
      }
      newTaxMain = newTaxMain + Number(item.tax);
    });

    setTotal(parseFloat(newVal).toFixed(2));
    setTotalTax(parseFloat(newTaxMain).toFixed(2));

    const mainTotal = newVal + newTaxMain;
    setFinalTotal(parseFloat(mainTotal).toFixed(2));
    // for default automation value
    const data = subaccountRows.map((item) => {
      const isV17Exist = updatedRows.filter(
        (item) => item.iVA.code == vat17IVA
      );

      if (item?.isClient == true && document.type === 1) {
        if (isV17Exist.length > 0) {
          item.amountHave =
            mainTotal != 0
              ? parseFloat(mainTotal - v17IVAPercent).toFixed(2)
              : "";
        } else {
          item.amountHave =
            mainTotal != 0 ? parseFloat(mainTotal).toFixed(2) : "";
        }
        // item.amountGive = 0;
      } else if (item?.isClient == true && document.type === 2) {
        if (isV17Exist.length > 0) {
          item.amountGive =
            mainTotal != 0
              ? parseFloat(mainTotal - v17IVAPercent).toFixed(2)
              : "";
        } else {
          item.amountGive =
            mainTotal != 0 ? parseFloat(mainTotal).toFixed(2) : "";
        }
        // item.amountHave = 0;
      } else if (document.type === 1) {
        if (item?.accountId?.accountId?.code == "11" && item?.code == "1") {
          if (isV17Exist.length > 0) {
            item.amountGive =
              newVal != 0 ? parseFloat(newVal - v17IVAPercent).toFixed(2) : "";
          } else {
            item.amountGive = newVal != 0 ? parseFloat(newVal).toFixed(2) : "";
          }
          // item.amountHave = 0;
        } else if (
          item?.accountId?.accountId?.code == "1" &&
          item?.code == "1"
        ) {
          item.amountGive =
            newTaxMain != 0 ? parseFloat(newTaxMain).toFixed(2) : "";
          // item.amountHave = 0;
        }
      } else if (document.type === 2) {
        if (item?.accountId?.accountId?.code == "11" && item?.code == "1") {
          if (isV17Exist.length > 0) {
            item.amountHave =
              newVal != 0 ? parseFloat(newVal - v17IVAPercent).toFixed(2) : "";
          } else {
            item.amountHave = newVal != 0 ? parseFloat(newVal).toFixed(2) : "";
          }
          // item.amountGive = 0;
        } else if (
          item?.accountId?.accountId?.code == "1" &&
          item?.code == "1"
        ) {
          item.amountHave =
            newTaxMain != 0 ? parseFloat(newTaxMain).toFixed(2) : "";
          // item.amountGive = 0;
        }
      }

      return item;
    });

    setSubaccountRows(data);

    let newHaveAmount = 0;
    let newGiveAmount = 0;

    subaccountRows.map((item) => {
      newHaveAmount = newHaveAmount + Number(item.amountHave);
      newGiveAmount = newGiveAmount + Number(item.amountGive);
    });

    setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);
    setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

    let squaringAmount = newGiveAmount - newHaveAmount;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
  };

  const handleAutocompleteChange = (index, newValue) => {
    const updatedRows = [...rows];

    updatedRows[index].iVA = newValue;
    updatedRows[index].rate = newValue ? newValue.rate : "";
    setRows(updatedRows);

    fnCommonCalculation(index, updatedRows[index].tax);
    setEditingIndexTax(null);
  };

  const handleInputChange = (index, newInputValue) => {
    setEditingIndexGive(null);
    setEditingIndexHave(null);
    setEditingIndexTax(null);
    const updatedRows = [...rows];
    updatedRows[index].inputIVA = newInputValue;

    setRows(updatedRows);
  };

  const addRow = () => {
    setRows([
      ...rows,
      { iVA: "", inputIVA: "", desc: "", rate: 0, tax: 0, totalVatAmount: 0 },
    ]);
  };

  const calculatePercentage = (rate, tax) => {
    const newTotal = tax * rate;
    const totalPercent = newTotal / 100;
    const formattedPercent = isNaN(totalPercent) ? 0 : totalPercent.toFixed(2);
    return formattedPercent;
  };
  const handleTaxChange = (index, newTax) => {
    setEditingIndexGive(null);
    setEditingIndexHave(null);
    setEditingIndexTax(index);
    fnCommonCalculation(index, newTax);
  };

  // For sub account

  const removeSubaccountRow = (index) => {
    const updatedRows = [...subaccountRows];
    updatedRows.splice(index, 1);
    setSubaccountRows(updatedRows);

    let newGiveAmount = 0;
    updatedRows.map((item) => {
      newGiveAmount = newGiveAmount + Number(item.amountGive);
    });

    setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

    let newHaveAmount = 0;

    updatedRows.map((item) => {
      newHaveAmount = newHaveAmount + Number(item.amountHave);
    });

    setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);

    let squaringAmount = newGiveAmount - newHaveAmount;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
  };

  const handleSubaccountAutocompleteChange = (index, newValue) => {
    console.log("index====", index);
    console.log("newValue", newValue);
    if (newValue === null || newValue === undefined) {
      // Clearing the Autocomplete value, no need for duplicate check
      const updatedRows = [...subaccountRows];
      updatedRows[index].accountId = null;
      setSubaccountRows(updatedRows);
    } else {
      const isAccountSelected = subaccountRows.some(
        (row, rowIndex) =>
          rowIndex !== index &&
          row.accountId &&
          row.accountId.name === newValue.name
      );

      if (isAccountSelected) {
        const updatedRows = subaccountRows.filter(
          (_, rowIndex) => rowIndex !== index
        );
        setSeverity("error");
        setOpen(true);
        setErrorMsg(t("noDuplicateRow"));
        setSubaccountRows(updatedRows);
      } else {
        const updatedRows = [...subaccountRows];
        updatedRows[index].accountId = newValue;
        setSubaccountRows(updatedRows);
      }
    }
  };

  const handleSubaccountInputChange = (index, newInputValue) => {
    // console.log("newInputValue", newInputValue)
    const updatedRows = [...subaccountRows];
    updatedRows[index].inputAccount = newInputValue;

    setSubaccountRows(updatedRows);
  };

  const addSubaccountRow = () => {
    setSubaccountRows([
      ...subaccountRows,
      {
        accountId: "",
        inputAccount: "",
        amountGive: 0,
        amountHave: 0,
        isBlock: false,
      },
    ]);
  };

  const handleAmountGiveChange = (index, newAmount) => {
    const updatedData = [...subaccountRows];
    const numericNewAmount = convertEuroToIND(newAmount);
    updatedData[index].amountGive = numericNewAmount;

    setSubaccountRows(updatedData);
    // calculatePercentage(updatedData);

    let newGiveAmount = 0;
    updatedData.map((item) => {
      newGiveAmount = newGiveAmount + Number(item.amountGive);
    });

    setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

    let squaringAmount = newGiveAmount - totalAmountHave;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
    setEditingIndexGive(index);
  };

  const handleAmountHaveChange = (index, newAmount) => {
    const updatedData = [...subaccountRows];
    const numericNewAmount = convertEuroToIND(newAmount);
    updatedData[index].amountHave = numericNewAmount;

    setSubaccountRows(updatedData);

    let newHaveAmount = 0;
    updatedData.map((item) => {
      newHaveAmount = newHaveAmount + Number(item.amountHave);
    });

    setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount.toFixed(2));
    let squaringAmount = totalAmountGive - newHaveAmount;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));

    setEditingIndexHave(index);
  };

  function convertEuroToIND(euroFormat) {
    if (!euroFormat || typeof euroFormat !== "string") {
      return 0; // or handle the error in an appropriate way
    }

    const withoutThousandsSeparator = euroFormat.replace(/\./g, "");
    const standardFormat = withoutThousandsSeparator.replace(",", ".");
    return Number(standardFormat);
  }

  const fnCommonCalculation = (index, newTax) => {
    // if (newTax < 0) {
    //   newTax = 0 - newTax;
    // }
    // if (newTax == "-") {
    //   newTax = 0;
    // }
    const updatedData = [...rows];

    const numericNewTax = convertEuroToIND(newTax);
    updatedData[index].tax = Math.abs(numericNewTax);

    setRows(updatedData);
    // calculatePercentage(updatedData);

    let newVal = 0;
    let newTaxMain = 0;

    let totalPercent;
    let vat17IVA;
    let v17IVAPercent = 0;
    updatedData.map((item) => {
      // console.log(item);
      // if(item.iVA.code == "V 17 ter"){
      //   vat17IVA = item.iVA.code

      // }
      const newTotal = Math.abs(item.tax) * item.rate;
      totalPercent = newTotal / 100;

      if (item.iVA && item.iVA.code == "V 17 ter") {
        vat17IVA = item.iVA.code;
        v17IVAPercent = totalPercent;
      }

      if (isNaN(totalPercent)) {
        totalPercent = 0;
        newVal = newVal + totalPercent;
      } else {
        newVal = newVal + totalPercent;
      }
      newTaxMain = newTaxMain + Number(item.tax);
    });

    setTotal(parseFloat(newVal).toFixed(2));
    setTotalTax(parseFloat(newTaxMain).toFixed(2));

    const mainTotal = newVal + newTaxMain;
    setFinalTotal(parseFloat(mainTotal).toFixed(2));
    // for default automation value
    const data = subaccountRows.map((item) => {
      const isV17Exist = updatedData.filter(
        (item) => item.iVA && item.iVA.code == vat17IVA
      );

      if (item?.isClient == true && document.type === 1) {
        if (isV17Exist.length > 0) {
          item.amountHave =
            mainTotal != 0
              ? parseFloat(mainTotal - v17IVAPercent).toFixed(2)
              : "";
        } else {
          item.amountHave =
            mainTotal != 0 ? parseFloat(mainTotal).toFixed(2) : "";
        }
        // item.amountGive = 0;
      } else if (item?.isClient == true && document.type === 2) {
        if (isV17Exist.length > 0) {
          item.amountGive =
            mainTotal != 0
              ? parseFloat(mainTotal - v17IVAPercent).toFixed(2)
              : "";
        } else {
          item.amountGive =
            mainTotal != 0 ? parseFloat(mainTotal).toFixed(2) : "";
        }
        // item.amountHave = 0;
      } else if (document.type === 1) {
        if (item?.accountId?.accountId?.code == "11" && item?.code == "1") {
          if (isV17Exist.length > 0) {
            item.amountGive =
              newVal != 0 ? parseFloat(newVal - v17IVAPercent).toFixed(2) : "";
          } else {
            item.amountGive = newVal != 0 ? parseFloat(newVal).toFixed(2) : "";
          }
          // item.amountHave = 0;
        } else if (
          item?.accountId?.accountId?.code == "1" &&
          item?.code == "1"
        ) {
          item.amountGive =
            newTaxMain != 0 ? parseFloat(newTaxMain).toFixed(2) : "";
          // item.amountHave = 0;
        }
      } else if (document.type === 2) {
        if (item?.accountId?.accountId?.code == "11" && item?.code == "1") {
          if (isV17Exist.length > 0) {
            item.amountHave =
              newVal != 0 ? parseFloat(newVal - v17IVAPercent).toFixed(2) : "";
          } else {
            item.amountHave = newVal != 0 ? parseFloat(newVal).toFixed(2) : "";
          }
          // item.amountGive = 0;
        } else if (
          item?.accountId?.accountId?.code == "1" &&
          item?.code == "1"
        ) {
          item.amountHave =
            newTaxMain != 0 ? parseFloat(newTaxMain).toFixed(2) : "";
          // item.amountGive = 0;
        }
      }

      return item;
    });

    setSubaccountRows(data);

    let newHaveAmount = 0;
    let newGiveAmount = 0;

    subaccountRows.map((item) => {
      newHaveAmount = newHaveAmount + Number(item.amountHave);
      newGiveAmount = newGiveAmount + Number(item.amountGive);
    });

    setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);
    setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

    let squaringAmount = newGiveAmount - newHaveAmount;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
  };

  const handleDocumentChange = (event, newDocument) => {
    setDocument(newDocument);
    const updatedData = [...rows];
    let newVal = 0;
    let newTaxMain = 0;

    let totalPercent;
    let vat17IVA;
    let v17IVAPercent = 0;
    updatedData.map((item) => {
      console.log(item);
      //      if(item.iVA.code == "V 17 ter"){
      //   vat17IVA = item.iVA.code
      // }
      const newTotal = Math.abs(item.tax) * item.rate;
      totalPercent = newTotal / 100;

      if (item.iVA.code == "V 17 ter") {
        vat17IVA = item.iVA.code;
        v17IVAPercent = totalPercent;
      }

      if (isNaN(totalPercent)) {
        totalPercent = 0;
        newVal = newVal + totalPercent;
      } else {
        newVal = newVal + totalPercent;
      }
      newTaxMain = newTaxMain + Number(item.tax);
    });

    const mainTotal = newVal + newTaxMain;

    const updatedSubaccountRows = subaccountRows.map((item) => {
      const isV17Exist = updatedData.filter(
        (item) => item.iVA.code == vat17IVA
      );
      if (item?.isClient === true && newDocument && newDocument.type === 1) {
        if (isV17Exist.length > 0) {
          const tempAmount = item.amountHave; // Preserve original amountHave
          item.amountHave = mainTotal - v17IVAPercent;
          item.amountGive = tempAmount ? tempAmount : 0;
        } else {
          const tempAmount = item.amountHave; // Preserve original amountHave
          item.amountHave = mainTotal;
          item.amountGive = tempAmount ? tempAmount : 0;
        }
      } else if (
        item?.isClient === true &&
        newDocument &&
        newDocument.type === 2
      ) {
        if (isV17Exist.length > 0) {
          const tempAmount = item.amountGive; // Preserve original amountGive
          item.amountGive = mainTotal - v17IVAPercent;
          item.amountHave = tempAmount ? tempAmount : 0;
        } else {
          const tempAmount = item.amountGive; // Preserve original amountGive
          item.amountGive = mainTotal;
          item.amountHave = tempAmount ? tempAmount : 0;
        }
      } else if (newDocument && newDocument.type === 1) {
        if (item?.accountId?.accountId?.code === "11" && item?.code === "1") {
          if (isV17Exist.length > 0) {
            const originalAmountGive = item.amountGive; // Preserve original amountGive
            item.amountGive = newVal - v17IVAPercent;
            item.amountHave = originalAmountGive ? originalAmountGive : 0;
          } else {
            const originalAmountGive = item.amountGive; // Preserve original amountGive
            item.amountGive = newVal;
            item.amountHave = originalAmountGive ? originalAmountGive : 0;
          }
        } else if (
          item?.accountId?.accountId?.code === "1" &&
          item?.code === "1"
        ) {
          const originalAmountGive = item.amountGive; // Preserve original amountGive
          item.amountGive = newTaxMain;
          item.amountHave = originalAmountGive ? originalAmountGive : 0;
        }
      } else if (newDocument && newDocument.type === 2) {
        if (item?.accountId?.accountId?.code === "11" && item?.code === "1") {
          if (isV17Exist.length > 0) {
            const originalAmountHave = item.amountHave; // Preserve original amountHave
            item.amountHave = newVal - v17IVAPercent;
            item.amountGive = originalAmountHave ? originalAmountHave : 0;
          } else {
            const originalAmountHave = item.amountHave; // Preserve original amountHave
            item.amountHave = newVal;
            item.amountGive = originalAmountHave ? originalAmountHave : 0;
          }
        } else if (
          item?.accountId?.accountId?.code === "1" &&
          item?.code === "1"
        ) {
          const originalAmountHave = item.amountHave; // Preserve original amountHave
          item.amountHave = newTaxMain;
          item.amountGive = originalAmountHave ? originalAmountHave : 0;
        }
      }
      return item;
    });

    setSubaccountRows(updatedSubaccountRows);

    let newHaveAmount = 0;
    let newGiveAmount = 0;

    const data = updatedSubaccountRows.forEach((item) => {
      newHaveAmount += Number(item.amountHave);
      newGiveAmount += Number(item.amountGive);
    });

    if (data) {
      setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);
      setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

      const squaringAmount = newGiveAmount - newHaveAmount;
      setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
    }
  };

  const handleCustomerChange = async (newClient) => {
    // console.log(newClient);
    if (newClient != null) {
      const requestObject = {};
      requestObject.customerId = newClient._id;

      // console.log(requestObject)
      const customerSubaccount = await API.post(
        apiURL.service_get_subaccount_of_customer,
        requestObject
      );

      // console.log(customerSubaccount);
      if (customerSubaccount && customerSubaccount?.data?.success) {
        const subaccount = customerSubaccount?.data?.data?.subaccount;
        newClient.subaccounts = subaccount;
        // console.log(newClient);

        formik.setFieldValue("client", newClient);
        setVatNumber(newClient ? newClient.vatNumber : "");
        // console.log('newClient',newClient)
        // START - Code for selected client 's subaccount

        // console.log("newClient", newClient);

        const updatedData = [...rows];

        let totalPercent;
        let vat17IVA;
        let v17IVAPercent = 0;
        updatedData.map((item) => {
          //      if(item.iVA.code == "V 17 ter"){
          //   vat17IVA = item.iVA.code
          // }
          const newTotal = Math.abs(item.tax) * item.rate;
          totalPercent = newTotal / 100;

          if (item.iVA.code == "V 17 ter") {
            vat17IVA = item.iVA.code;
            v17IVAPercent = totalPercent;
          }
        });

        if (newClient != null) {
          if (selectedClientId !== null) {
            const newArray = [];

            const newSubAccountArr = subaccountRows.filter(
              (object) => object?.accountId?.customerId !== selectedClientId
            );

            let amountHave = 0;
            let amountGive = 0;

            if (document.type === 1) {
              if (vat17IVA) {
                amountHave = finalTotal - v17IVAPercent;
              } else {
                amountHave = finalTotal;
              }
            }
            if (document.type === 2) {
              if (vat17IVA) {
                amountGive = finalTotal - v17IVAPercent;
              } else {
                amountGive = finalTotal;
              }
            }

            newArray.push({
              accountId: newClient?.subaccounts[0],
              inputAccount: `${newClient?.subaccounts[0]?.type}.${newClient?.subaccounts[0]?.accountId?.code}.${newClient?.subaccounts[0]?.code} - ${newClient?.subaccounts[0]?.name}`,
              amountGive: amountGive,
              amountHave: amountHave,
              isBlock: true,
              isClient: true,
            });

            newSubAccountArr.map((obj) => {
              newArray.push(obj);
            });

            setSubaccountRows(newArray);

            let newGiveAmount = 0;
            let newHaveAmount = 0;
            newArray.map((item) => {
              newGiveAmount = newGiveAmount + Number(item.amountGive);
              newHaveAmount = newHaveAmount + Number(item.amountHave);
            });

            if (document.type === 1) {
              if (vat17IVA) {
                newHaveAmount = finalTotal - v17IVAPercent;
              } else {
                newHaveAmount = finalTotal;
              }
            } else if (document.type === 2) {
              if (vat17IVA) {
                newGiveAmount = finalTotal - v17IVAPercent;
              } else {
                newGiveAmount = finalTotal;
              }
            }

            setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

            setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);

            let squaringAmount = newGiveAmount - newHaveAmount;
            setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
          } else {
            let amountHave = 0;
            let amountGive = 0;

            if (document.type === 1) {
              if (vat17IVA) {
                amountHave = finalTotal - v17IVAPercent;
              } else {
                amountHave = finalTotal;
              }
            }
            if (document.type === 2) {
              if (vat17IVA) {
                amountGive = finalTotal - v17IVAPercent;
              } else {
                amountGive = finalTotal;
              }
            }

            setSubaccountRows([
              {
                accountId: newClient?.subaccounts[0],
                inputAccount: `${newClient?.subaccounts[0]?.type}.${newClient?.subaccounts[0]?.accountId?.code}.${newClient?.subaccounts[0]?.code} - ${newClient?.subaccounts[0]?.name}`,
                amountGive: amountGive,
                amountHave: amountHave,
                isBlock: true,
                isClient: true,
              },
              ...subaccountRows,
            ]);

            let newGiveAmount = 0;
            let newHaveAmount = 0;
            subaccountRows.map((item) => {
              newGiveAmount = newGiveAmount + Number(item.amountGive);
              newHaveAmount = newHaveAmount + Number(item.amountHave);
            });

            if (document.type === 1) {
              if (vat17IVA) {
                newHaveAmount = finalTotal - v17IVAPercent;
              } else {
                newHaveAmount = finalTotal;
              }
            } else if (document.type === 2) {
              if (vat17IVA) {
                newGiveAmount = finalTotal - v17IVAPercent;
              } else {
                newGiveAmount = finalTotal;
              }
            }

            setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);
            setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

            let squaringAmount = newGiveAmount - newHaveAmount;
            setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
          }

          setSelectedClientId(newClient?._id);
        }
      }
    }

    // END - Code for selected client 's subaccount
  };

  useEffect(() => {
    let newHaveAmount = 0;
    let newGiveAmount = 0;

    subaccountRows.map((item) => {
      newHaveAmount += Number(item.amountHave);
      newGiveAmount += Number(item.amountGive);
    });

    setTotalAmountHave(isNaN(newHaveAmount) ? 0 : newHaveAmount);
    setTotalAmountGive(isNaN(newGiveAmount) ? 0 : newGiveAmount);

    const squaringAmount = newGiveAmount - newHaveAmount;
    setSquaring(isNaN(squaringAmount) ? 0 : squaringAmount.toFixed(2));
  }, [subaccountRows]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  function customSort(a, b) {
    const keyA = `${a.type}.${a?.accountId?.code}.${a.code}`;
    const keyB = `${b.type}.${b?.accountId?.code}.${b.code}`;

    const partsA = keyA.split(".").map((part) => parseInt(part, 10));
    const partsB = keyB.split(".").map((part) => parseInt(part, 10));

    for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
      if (partsA[i] !== partsB[i]) {
        return partsA[i] - partsB[i];
      }
    }
    return partsA.length - partsB.length;
  }

  return (
    <>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={props.open}
      >
        <Box
          sx={{
            width: "95%",
            backgroundColor: "white",
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              borderRadius: 2,
              padding: 1.5,
            }}
          >
            <Typography
              // variant='h3'
              sx={{
                fontSize: 24,
                color: "black",
              }}
            >
              {props?.isEdit
                ? t("edit") + " " + t("fattureVendita")
                : t("reg") + " " + t("fattureVendita")}
            </Typography>

            {/* <Button
            variant="contained"
            sx={{
              marginLeft: "auto",
            }}
          >
            {t("indietro")}
          </Button> */}

            <Box>
              {!props?.isEdit && (
                <Button
                  sx={{
                    textAlign: "end",
                  }}
                  variant="contained"
                  type="submit"
                  size="small"
                  onClick={(e) => {
                    onSaveAndContinueClicked(e, true);
                  }}
                  disabled={squaring != 0}
                >
                  {t("salvaEProsegui")}
                </Button>
              )}

              <Button
                // href="/add_fatture_vendita"
                sx={{
                  marginLeft: "16px",
                  // width: "18pc"
                  textAlign: "end",
                }}
                size="small"
                variant="contained"
                onClick={(e) => {
                  onSaveAndContinueClicked(e, false);
                }}
                // onClick={(e) => {
                //   props?.isEdit
                //     ? fnEditSalesInvoice()
                //     : onSaveAndContinueClicked(e, false);
                // }}
                disabled={squaring != 0}
              >
                {t("salvaEdESCI")}
              </Button>

              <Button
                sx={{
                  marginLeft: "16px",
                  // width: "18pc"
                  textAlign: "end",
                }}
                onClick={() => {
                  backToList(reload);
                }}
                variant="contained"
                size="small"
              >
                {t("esci") + " " + t("senza" + " " + t("salvare"))}
              </Button>
              {/* {
                      props?.isEdit &&
                    <Button variant="contained" color="error"
                      onClick={() => {
                        setOpenDelete(!openDelete)
                      }}>{t('elimina')}</Button>
                  } */}
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              height: "90vh",
              overflowY: "auto",
              p: 2,
              "&::-webkit-scrollbar": {
                width: "16px",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#fff",
                borderRadius: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#4d96ff",
                borderRadius: "10px",
                border: "4px solid #ffffff",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#2c70cf",
              },
            }}
          >
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              onKeyDown={handleKeyPress}
            >
              <Grid container>
                <Grid item xs={9}>
                  <Box
                    sx={{
                      display: "flex",
                      margin: "auto",
                    }}
                  >
                    <Autocomplete
                      size="small"
                      value={formik.values.client}
                      onChange={(event, newClient) => {
                        handleCustomerChange(newClient);
                      }}
                      inputValue={formik.values.inputClient}
                      onInputChange={(event, newInputClient) => {
                        formik.setFieldValue("inputClient", newInputClient);
                      }}
                      getOptionLabel={(option) => option.name || ""}
                      // getOptionSelected = {(option, value) => option === value}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      id="controllable-states-demo"
                      options={listOfClient}
                      sx={{ width: 400 }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          label={"*" + t("cliente")}
                          error={
                            formik.touched.client &&
                            Boolean(formik.errors.client)
                          }
                          helperText={
                            formik.touched.client && formik.errors.client
                          }
                        />
                      )}
                    />
                    <TextField
                      size="small"
                      id="outlined-select-currency"
                      // select
                      disabled
                      label={t("partita") + " " + t("iva")}
                      value={vatNumber}
                      sx={{
                        width: "15pc",
                        marginLeft: "16px",
                      }}
                    >
                      {/* <MenuItem value="01822770341">01822770341</MenuItem> */}
                    </TextField>
                  </Box>
                </Grid>

                <Grid item xs={3} sx={{ textAlign: "end" }}>
                  <Button
                    // href="/add_fatture_vendita"
                    sx={{
                      marginLeft: "16px",
                      width: "15pc",
                    }}
                    onClick={() => {
                      // props.onAddOpen()
                      setOpenAddUser(!openAddUser);
                    }}
                    variant="contained"
                    size="small"
                    color="success"
                    startIcon={<AddIcon />}
                  >
                    {t("nuova") + " " + t("anagrafica")}
                  </Button>
                </Grid>

                <Grid>
                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "24px",
                    }}
                  >
                    <TextField
                      size="small"
                      id="input-with-icon-textfield"
                      label={"*" + t("data") + " " + t("fattura")}
                      placeholder="gg/mm/aaaa"
                      type="date"
                      name="date"
                      value={formik.values.date}
                      sx={{
                        width: "12pc",
                      }}
                      onChange={async (event) => {
                        const { name, value } = event.target;
                        // Check if the year part has more than 4 characters
                        if (name === "date" && value.length > 10) {
                          return;
                        }

                        setIsChangeRegDate(true);
                        setIsChangeDate(true);

                        formik.setFieldValue(name, value);
                        // if (isFirstTimeOnly) {
                        formik.setFieldValue("regDate", value);
                        await checkYearClosed(value);
                        await checkPreviousDocumentDate(value);
                        const parsedDate = isValid(parseISO(value))
                          ? parseISO(value)
                          : null;
                        setTimePeriod(parsedDate);

                        if (!props.isEdit) {
                          await getInvoiceNumber();
                        }
                        //   setIsFirstTimeOnly(false);
                        // }
                      }}
                      onKeyUp={async (event) => {
                        const { value } = event.target;

                        // Check if the year is typed (assuming year is the last 4 characters)
                        const yearTyped = value.slice(0, 4);
                        if (!props.isEdit) {
                          if (!value) {
                            await getInvoiceNumber("Invalid");
                          }
                        }
                        if (yearTyped.length === 4) {
                          // Year is typed, you can perform any specific logic here
                          await checkYearClosed(yearTyped);
                          if (!props.isEdit) {
                            await getInvoiceNumber(value);
                          }
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.date && Boolean(formik.errors.date)}
                      helperText={formik.touched.date && formik.errors.date}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <AccountCircle /> */}
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      size="small"
                      id="outlined-basic"
                      sx={{ marginLeft: "16px" }}
                      label={"*" + t("numeroFattura")}
                      placeholder="100"
                      variant="outlined"
                      value={invoiceNumber}
                    />
                    <TextField
                      size="small"
                      id="input-with-icon-textfield"
                      label={"*" + t("data") + " " + t("reg")}
                      // placeholder="gg/mm/aaaa"

                      type="date"
                      name="regDate"
                      value={formik.values.regDate}
                      sx={{ marginLeft: "16px" }}
                      onChange={async (event) => {
                        const { name, value } = event.target;
                        // Check if the year part has more than 4 characters
                        if (name === "regDate" && value.length > 10) {
                          return;
                        }
                        setIsChangeRegDate(true);
                        setIsChangeDate(false);
                        formik.setFieldValue(name, value);
                        await checkYearClosed(value);

                        const parsedDate = isValid(parseISO(value))
                          ? parseISO(value)
                          : null;
                        // const pdate = parsedDate ? parsedDate.toISOString().slice(0, 10) : ''
                        // console.log(pdate)
                        setTimePeriod(parsedDate);
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.regDate && Boolean(formik.errors.regDate)
                      }
                      helperText={
                        formik.touched.regDate && formik.errors.regDate
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <AccountCircle /> */}
                          </InputAdornment>
                        ),
                        // readOnly: true,
                      }}
                    />

                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      localeText={
                        itIT.components.MuiLocalizationProvider.defaultProps
                          .localeText
                      }
                      adapterLocale={"it"}
                    >
                      <DatePicker
                        readOnly
                        slotProps={{
                          textField: {
                            error: false,
                            size: "small",
                          },
                        }}
                        sx={{ ml: 2, width: "9pc" }}
                        label={"*" + t("periodo")}
                        views={["month", "year"]}
                        format="MM/YYYY"
                        value={dayjs(timePeriod)}
                        onChange={setTimePeriod}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Your Custom Placeholder"
                            InputProps={{
                              ...params.InputProps,
                              // placeholder: 'jkhjkhjk'
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>

                    <Autocomplete
                      size="small"
                      value={document}
                      onChange={(event, newDocument) => {
                        handleDocumentChange(event, newDocument);
                      }}
                      inputValue={inputDocument}
                      onInputChange={(event, newInputDocument) => {
                        setInputDocument(newInputDocument);
                      }}
                      id="controllable-states-demo"
                      options={listOfDocument}
                      getOptionLabel={(option) => option.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.type === value.type
                      }
                      sx={{ width: 240, marginLeft: "16px" }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          label={"*" + t("documento")}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Divider sx={{ paddingTop: "20px" }}></Divider>

              <Grid
                container
                sx={{
                  marginTop: "24px",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    paddingRight: "12px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h4">
                      {t("reg") + " " + t("iva")}
                    </Typography>
                    <Button
                      sx={{
                        marginLeft: "16px",
                        textAlign: "end",
                      }}
                      variant="contained"
                      size="small"
                      color="success"
                      startIcon={<AddIcon />}
                      onClick={addRow}
                    >
                      {t("aggiungi")}
                    </Button>
                  </Box>

                  <Divider sx={{ marginY: "16px" }}></Divider>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: "0%" }}></TableCell>
                          <TableCell>{t("tabella") + " " + t("iva")}</TableCell>
                          <TableCell sx={{ width: "16%" }} align="right">
                            {t("imponibile")}
                          </TableCell>
                          <TableCell sx={{ width: "16%" }} align="right">
                            {t("iva")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => {
                          const percentAmount = calculatePercentage(
                            row.rate,
                            row.tax
                          );
                          return (
                            <TableRow key={`row-${index}`}>
                              <TableCell>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="error"
                                  sx={{
                                    padding: "0px",
                                    minWidth: "0px",
                                    color: "#fff",
                                    padding: "8px",
                                  }}
                                  onClick={() => removeRow(index)}
                                >
                                  <DeleteIcon />
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Autocomplete
                                  size="small"
                                  value={row.iVA || null}
                                  onChange={(event, newValue) => {
                                    handleAutocompleteChange(index, newValue);
                                  }}
                                  inputValue={row.inputIVA}
                                  onInputChange={(event, newInputValue) =>
                                    handleInputChange(index, newInputValue)
                                  }
                                  getOptionLabel={(option) =>
                                    option
                                      ? ` ${option.code} - ${option.description}`
                                      : ""
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option._id === value._id
                                  }
                                  id={`controllable-states-demo-${index}`}
                                  options={listOfIVA}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      {...params}
                                      label={
                                        "*" + t("tabella") + " " + t("iva")
                                      }
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell align="right">
                                {/* <label className="numberSign">-</label> */}
                                <TextField
                                  size="small"
                                  id="outlined-basic"
                                  sx={{ width: "10ch" }}
                                  label=""
                                  placeholder=""
                                  variant="standard"
                                  value={
                                    document?.type === 1
                                      ? editingIndexTax == index
                                        ? "-" +
                                          row?.tax.toLocaleString("de-DE", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                        : "-" +
                                          Math.abs(row?.tax).toLocaleString(
                                            "de-DE",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )
                                      : !row.tax
                                      ? ""
                                      : row?.tax
                                  }
                                  onChange={(event) => {
                                    handleTaxChange(index, event.target.value);
                                  }}
                                  InputProps={{
                                    inputComponent: EuroNumberFormat,
                                  }}
                                />
                              </TableCell>

                              <TableCell align="right">
                                <TextField
                                  size="small"
                                  sx={{
                                    width: "10ch",
                                    "& input.Mui-disabled": {
                                      textAlign: "right",
                                    },
                                  }}
                                  disabled
                                  id="standard-disabled"
                                  // label="Disabled"
                                  variant="standard"
                                  // value={
                                  //   document?.type == 1
                                  //     ? `-${percentAmount.toFixed(2)}`
                                  //     : percentAmount.toFixed(2)
                                  // }
                                  value={
                                    document?.type === 1
                                      ? "-" +
                                        Math.abs(
                                          parseFloat(percentAmount)
                                        ).toLocaleString("de-DE", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : !percentAmount
                                      ? ""
                                      : parseFloat(
                                          percentAmount
                                        ).toLocaleString("de-DE", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                    // document?.type === 1
                                    //   ? `-${percentAmount.toLocaleString(
                                    //       "de-DE",
                                    //       {
                                    //         minimumFractionDigits: 2,
                                    //         maximumFractionDigits: 2,
                                    //       }
                                    //     )}`
                                    //   : percentAmount.toLocaleString("de-DE", {
                                    //       minimumFractionDigits: 2,
                                    //       maximumFractionDigits: 2,
                                    //     })
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}

                        <TableRow>
                          <TableCell rowSpan={3}></TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="h6"
                              ml="16px"
                              sx={{
                                fontWeight: "700",
                                textTransform: "uppercase",
                              }}
                            >
                              {t("totale")}
                            </Typography>
                          </TableCell>

                          <TableCell align="right">
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "700", padding: "0 0 0 0px" }}
                            >
                              {/* {document?.type === 1 ? `-${totalTax.toFixed(2)}` : totalTax.toFixed(2)} */}

                              {document?.type === 1
                                ? "-" +
                                  parseFloat(Math.abs(totalTax)).toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : parseFloat(totalTax).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                            </Typography>
                          </TableCell>

                          <TableCell align="right" colSpan={1}>
                            <Typography variant="h6" sx={{ fontWeight: "700" }}>
                              {/* {document?.type === 1 ? `-${total.toFixed(2)}` : `${total.toFixed(2)}`} */}
                              {document?.type === 1
                                ? "-" +
                                  parseFloat(Math.abs(total)).toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : parseFloat(total).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={1} align="right">
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "700",
                                textTransform: "uppercase",
                              }}
                            >
                              {t("totale") + " " + t("fattura")}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" colSpan={2}>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "700", paddingLeft: "88px" }}
                            >
                              {/* {document?.type == 1
                                ? `-${finalTotal.toFixed(2)}`
                                : finalTotal.toFixed(2)} */}

                              {document?.type === 1
                                ? "-" +
                                  parseFloat(
                                    Math.abs(finalTotal)
                                  ).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : parseFloat(finalTotal).toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: "12px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h4">
                      {t("reg") + " " + t("contabile")}
                    </Typography>
                    <Button
                      // href="/add_fatture_vendita"
                      sx={{
                        marginLeft: "16px",
                        // width: "18pc"
                        textAlign: "end",
                      }}
                      variant="contained"
                      size="small"
                      color="success"
                      startIcon={<AddIcon />}
                      onClick={addSubaccountRow}
                    >
                      {t("aggiungi")}
                    </Button>
                  </Box>

                  <Divider sx={{ marginY: "16px" }}></Divider>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: "0%" }}></TableCell>
                          <TableCell>
                            {t("conto") + "/" + t("sottoConto")}
                          </TableCell>
                          <TableCell align="right" sx={{ width: "16%" }}>
                            {t("importo") + " " + t("dare")}
                          </TableCell>
                          <TableCell align="right" sx={{ width: "16%" }}>
                            {"importo" + " " + t("avere")}
                          </TableCell>
                          {/* <TableCell align="right">{('iVAdetraibile')}</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {subaccountRows.map((row, index) => {
                          return (
                            <TableRow key={`subrow-${index}`}>
                              <TableCell sx={{ width: "8%" }}>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="error"
                                  sx={{
                                    padding: "0px",
                                    minWidth: "0px",
                                    color: "#fff",
                                    padding: "8px",
                                  }}
                                  onClick={() => removeSubaccountRow(index)}
                                >
                                  <DeleteIcon />
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Autocomplete
                                  size="small"
                                  value={row.accountId}
                                  onChange={(event, newValue) => {
                                    handleSubaccountAutocompleteChange(
                                      index,
                                      newValue
                                    );
                                  }}
                                  inputValue={row.inputAccount}
                                  onInputChange={(event, newInputValue) =>
                                    handleSubaccountInputChange(
                                      index,
                                      newInputValue
                                    )
                                  }
                                  readOnly={
                                    row?.isClient === true &&
                                    row?.accountId?.accountId?.code == "3"
                                      ? //   ||
                                        // (row?.type == "1" &&
                                        //   row?.accountId?.accountId?.code == "11" &&
                                        //   row?.code == "1") ||
                                        // (row?.type == "2" &&
                                        //   row?.accountId?.accountId?.code == "1" &&
                                        //   row?.code == "1")
                                        true
                                      : false
                                  }
                                  getOptionLabel={(option) =>
                                    option
                                      ? ` ${option.type}.${option?.accountId?.code}.${option.code} - ${option.name}`
                                      : ""
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option.name === value.name
                                  }
                                  id={`controllable-states-demo-${index}`}
                                  options={listOfSottoConto.sort(customSort)}
                                  // sx={{ width: 240 }}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      {...params}
                                      label={"*" + t("sotto conto")}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <TextField
                                  size="small"
                                  id="outlined-basic"
                                  sx={{ width: "10ch" }}
                                  label=""
                                  placeholder=""
                                  variant="standard"
                                  value={
                                    !row?.amountGive
                                      ? ""
                                      : editingIndexGive == index
                                      ? Math.abs(row?.amountGive)
                                      : Math.abs(
                                          row?.amountGive
                                        ).toLocaleString("de-DE", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                  }
                                  onChange={(event) => {
                                    handleAmountGiveChange(
                                      index,
                                      event.target.value
                                    );
                                  }}
                                  InputProps={{
                                    inputComponent: EuroNumberFormat,
                                  }}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <TextField
                                  size="small"
                                  id="outlined-basic"
                                  sx={{ width: "10ch" }}
                                  label=""
                                  placeholder=""
                                  variant="standard"
                                  value={
                                    !row?.amountHave
                                      ? ""
                                      : editingIndexHave == index
                                      ? Math.abs(row?.amountHave)
                                      : Math.abs(
                                          row?.amountHave
                                        ).toLocaleString("de-DE", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                  }
                                  onChange={(event) => {
                                    handleAmountHaveChange(
                                      index,
                                      event.target.value
                                    );
                                  }}
                                  InputProps={{
                                    inputComponent: EuroNumberFormat,
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}

                        <TableRow>
                          <TableCell rowSpan={3}></TableCell>
                          <TableCell
                            align="right"
                            sx={{ textTransform: "uppercase" }}
                          >
                            <Typography variant="h6" sx={{ fontWeight: "700" }}>
                              {t("totale")}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="h6" sx={{ fontWeight: "700" }}>
                              {/* {ccyFormat(totalAmountGive)} */}
                              {Math.abs(totalAmountGive).toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="h6" sx={{ fontWeight: "700" }}>
                              {/* {ccyFormat(totalAmountHave)} */}
                              {Math.abs(totalAmountHave).toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="right"
                            sx={{ textTransform: "uppercase" }}
                          >
                            <Typography variant="h6" sx={{ fontWeight: "700" }}>
                              {t("squadratura")}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" colSpan={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                color: squaring == 0 ? "#4caf50" : "#EB5353",
                                fontWeight: "700",
                                paddingLeft: "88px",
                              }}
                            >
                              {/* {ccyFormat(squaring)} */}
                              {squaring == -0
                                ? Math.abs(parseFloat(squaring)).toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : parseFloat(squaring).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <Box
                sx={{
                  marginTop: "24px",
                }}
              >
                <Typography variant="caption" display="block">
                  {t("note")}
                </Typography>

                <TextField
                  size="small"
                  id="outlined-basic"
                  sx={{ marginLeft: "16px", mb: "32px", width: "98%" }}
                  label=""
                  placeholder="Aggiungi la tua nota qui"
                  variant="standard"
                  value={note}
                  multiline
                  onChange={(event) => {
                    setNote(event.target.value);
                  }}
                />

                {/* <Typography display="block">{t("note")}</Typography> */}
                {/* <Divider sx={{ marginY: "16px" }}></Divider> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <AddClienti
        open={openAddUser}
        isEdit={false}
        // stateObj={[]}
        handleClose={(data) => {
          if (data?.reload) {
            // setIsComeFromClient(true);
            getCustomerList(data.clientId);
          }
          // setEdit(false)
          // setClientObj(null)
          setOpenAddUser(!openAddUser);
        }}
      />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
