/***
 @author Lokesh Desai
 @description
 */
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Button,
  FormHelperText,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import { revisoftLogo, welcomeImage } from "../../assets/image/";
import { AddUser } from "../users/Add";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Grid from "@mui/material/Grid";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import apiURL from 'apiURL/apiURL';
import apiStatusCode from 'constants/apiStatusCode';
import axios from "axios";
import defaultString from 'constants/defaultString.json';
import { AuthContext } from 'context/AuthContext';
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import {saveToken} from "service/AxiosService";
import {getDataFromStorage, saveDataToStorageWithKey} from "storage/StorageData";
import { resetToken } from 'apiURL/commonFunctions';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {

  const { i18n, t } = useTranslation();
  let navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [open, setOpen] = useState(false);
  const [severity,setSeverity]=useState('success')
  const { dispatch } = useContext(AuthContext);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('nonValidoEmail'))
      .required(t('email')+" "+t('richiede')),
    password: Yup.string()
      .min(8, t('passwordLength'))
      .required(t('password')+" "+t('richiede')),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async(values) => {
      console.log('Form values:', values);
      //
      // if(values.rememberMe) {
      //   saveDataToStorageWithKey('email', values.email)
      //   saveDataToStorageWithKey('password', values.password)
      //   saveDataToStorageWithKey('rememberMe', true)
      // } else {
      //   saveDataToStorageWithKey('email', null)
      //   saveDataToStorageWithKey('password', null)
      //   saveDataToStorageWithKey('rememberMe', false)
      // }
      await fnLoginUser();
    }
  });

  useEffect(()=> {
    // getRemebermeData()
  },[])

  // const getRemebermeData = async () => {
  //   let isRemeberme = await getDataFromStorage("rememberMe")
  //   console.log("isRemeberme = ", isRemeberme)
  //   if(isRemeberme) {
  //     formik.setValues({email: await getDataFromStorage("email") ,password: await getDataFromStorage("password") })
  //   }
  // }

  // const onRemebermeChange = () => {
  //   formik.setValues({
  //     rememberMe: !formik.values.rememberMe
  //   })
  // }

  const handleAlertClose = (event, reason) => {
    setIsLoading(false)
    if (reason === 'clickaway') {
        return;
    }

    setOpen(false);
  };

  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  const fnLoginUser = async () => {

    // e.preventDefault();
    console.log(formik.values)

    if (!!formik.values) {

        setIsLoading(true)
        const objUser = {}

        objUser.email = formik.values.email
        objUser.password = formik.values.password

        axios.post(apiURL.service_login, objUser, {
            'headers': {
                'Accept': 'application/json',
            }
        })
        .then((res) => {
        setIsLoading(false)
        console.log(res.data);
        if (res.status === apiStatusCode.SUCCESS) {

            setOpen(true);
            setErrorMsg(t("common.addSuccess"))
            setSeverity("success")
            dispatch({ type: "LOGIN", payload: res.data.data.user })
            localStorage.setItem("userToken", JSON.stringify(res.data.data.token))
            saveToken(res.data.data.token)
            formik.resetForm();
            navigate('/')
        }
        else {
            setOpen(true);
            setSeverity("error")
            setErrorMsg(t("common.internalServerError"))
        }
        }).catch((error) => {
          setIsLoading(false)
          setOpen(true);
          setSeverity("error")
          if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
            else if (error.code === defaultString.bad_request) {
              if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
                doLogout()
              }
              else{
                setErrorMsg(t("common.badRequest"));
              }
            } 
        });
    }
  }

  const LoginModal= (
    <Box
      sx={{
        display: 'flex', width: '100%', height: '100vh',
      }}
    >
      <Grid container>
      <Grid item
            xs={12}
            sm={12}
            md={5}
            lg={4}
            sx={{ height:"100vh" ,display: { xs: "none", sm: "none", md: "flex" } }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Box
                alt="logo"
                height="100vh"
                width="100%"
                style={{
                  backgroundImage: `url(${welcomeImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "top right",
                }}
              />

              <Box
                sx={{
                  position: "absolute",
                  padding: "32px",
                  maxWidth: "400px",
                  borderRadius: "16px",
                }}
              >
                <Typography color="#fff" variant="h3">
                  La contabilità sarebbe semplice se non ci fosse I'IVA
                </Typography>
                <Typography
                  color="#fff"
                  paragraph
                  mt={1}
                >
                  <li>Stefano Borelli</li>
                </Typography>
              </Box>
            </Box>
          </Grid>

        <Grid item xs={12} sm={12} md={7} lg={8} sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Box sx={{
            flexBasis: '70%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}>
            <Box sx={{
              px: 3,
              py: '100px',
              maxWidth: 600,
              width: '100%',
            }}
            component="form"
            onSubmit={formik.handleSubmit}>
              <Stack
                spacing={3}
                sx={{ mb: 3 }}>
                <Box
                  component={'img'}
                  sx={{
                    width: {
                      xs: 140,
                      sm: 140,
                      md: 140,
                      lg: 250,
                    },
                    height: {
                      xs: 40,
                      sm: 40,
                      md: 50,
                      lg: 60,
                    }
                  }}
                  src={revisoftLogo} />
                <TextField
                  fullWidth
                  id="outlined-basic3" label="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  variant="outlined" required />

                <TextField
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  id="outlined-basic4" label={t('password')} variant="outlined" required />

                {/*<FormGroup>*/}
                {/*  <FormControlLabel*/}
                {/*      control={<Checkbox />}*/}
                {/*      label={t('ricordami')}*/}
                {/*      checked={formik.values.rememberMe}*/}
                {/*      onChange={onRemebermeChange} />*/}
                {/*</FormGroup>*/}

                <Button
                  type='submit'
                  variant="contained" size="large"
                >
                  {t('logIn')}
                </Button>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <AddUser
        open={openAddUser}
      />
      {/* <DeleteDialog open={openAddUser}/> */}
      </Grid>
    </Box>
  );

  return (
    <>
      { isLoading ? <LoadingSpinner/> : LoginModal}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose} sx={{ width: '100%' }}
       anchorOrigin={{horizontal:'right', vertical: 'top'}}>
        <Alert onClose={handleAlertClose} sx={{color:"#fff"}} severity={severity} >
            {errorMsg}
        </Alert>
    </Snackbar>
    </>
  );


};

export default Login;
