import * as React from "react";
import { useTranslation } from "react-i18next";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  Autocomplete,
  InputAdornment,
  IconButton,
} from "@mui/material";
// import { t } from 'i18next';

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useState } from "react";
import { useEffect } from "react";
import { getDataFromStorage } from "storage/StorageData";
import defaultString from "constants/defaultString.json";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { itIT } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import apiURL from "apiURL/apiURL";
import { API } from "service/AxiosService";
import apiStatusCode from "constants/apiStatusCode";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";

import logo from "../../../../assets/image/revisoft_logo.png";

import helveticaNeueBold from "../../../../fonts/HelveticaNeuBold.ttf";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { resetToken } from "apiURL/commonFunctions";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import { useContext } from "react";
import { saveAs } from "file-saver";

Font.register({
  family: "Helvetica Neue",
  fonts: [{ src: helveticaNeueBold, fontWeight: "600" }],
});

const TAX_RATE = 0.07;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, aliquota, imponibile, imposta, totale) {
  const price = priceRow(desc, aliquota, imponibile, imposta, totale);
  return { desc, aliquota, imponibile, imposta, totale };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  createRow(
    "Assoggettate all'aliquota del",
    "22%",
    "100.00",
    "10.00",
    "110.00"
  ),
  createRow(
    "Art.17 ter DPR 633/72 Split payment",
    "22%",
    "200.00",
    "20.00",
    "220.00"
  ),
];

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;

export default function SpanningTable() {
  const { i18n, t } = useTranslation();

  const [listOfSaldi, setListOfSaldi] = React.useState([]);
  const [clientName, setClientName] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs().format("YYYY"));
  const [errorMsg, setErrorMsg] = useState("");

  const [salesInvoiceVats, setSalesInvoiceVats] = useState([]);
  const [reverseChargeSalesInvoiceVats, setReverseChargeSalesInvoiceVats] =
    useState([]);
  const [purchaseInvoiceVats, setPurchaseInvoiceVats] = useState([]);
  const [
    reverseChargePurchaseInvoiceVats,
    setReverseChargePurchaseInvoiceVats,
  ] = useState([]);

  const [
    totalTaxableIncomeSalesInvoiceVats,
    setTotalTaxableIncomeSalesInvoiceVats,
  ] = useState(0);
  const [totalTaxSalesInvoiceVats, setTotalTaxSalesInvoiceVats] = useState(0);
  const [totalSalesInvoiceVats, setTotalSalesInvoiceVats] = useState(0);

  const [
    totalTaxableIncomeReverseChargeSalesInvoiceVats,
    setTotalTaxableIncomeReverseChargeSalesInvoiceVats,
  ] = useState(0);
  const [
    totalTaxReverseChargeSalesInvoiceVats,
    setTotalTaxReverseChargeSalesInvoiceVats,
  ] = useState(0);
  const [
    totalReverseChargeSalesInvoiceVats,
    setTotalReverseChargeSalesInvoiceVats,
  ] = useState(0);
  const [finalTtlTxbleIncmSlsInvcVats, setFinalTtlTxbleIncmSlsInvcVats] =
    useState(0);
  const [finalTtlTaxSlsInvcVats, setFinalTtlTaxSlsInvcVats] = useState(0);
  const [finalTtlSlsInvcVats, setFinalSlsInvcVats] = useState(0);

  const [
    totalTaxableIncomePurchaseInvoiceVats,
    setTotalTaxableIncomePurchaseInvoiceVats,
  ] = useState(0);
  const [
    totalDeductibleTaxableIncomePurchaseInvoiceVats,
    setTotalDeductibleTaxableIncomePurchaseInvoiceVats,
  ] = useState(0);
  const [
    totalNonDeductibleTaxableIncomePurchaseInvoiceVats,
    setTotalNonDeductibleTaxableIncomePurchaseInvoiceVats,
  ] = useState(0);
  const [
    totalDeductibleVatPurchaseInvoiceVats,
    setTotalDeductibleVatPurchaseInvoiceVats,
  ] = useState(0);
  const [
    totalNonDeductibleVatPurchaseInvoiceVats,
    setTotalNonDeductibleVatPurchaseInvoiceVats,
  ] = useState(0);
  const [totalPurchaseInvoiceVats, setTotalPurchaseInvoiceVats] = useState(0);

  const [
    totalTaxableIncomeReverseChargePurchaseInvoiceVats,
    setTotalTaxableIncomeReverseChargePurchaseInvoiceVats,
  ] = useState(0);
  const [
    totalDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats,
    setTotalDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats,
  ] = useState(0);
  const [
    totalNonDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats,
    setTotalNonDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats,
  ] = useState(0);
  const [
    totalDeductibleVatReverseChargePurchaseInvoiceVats,
    setTotalDeductibleVatReverseChargePurchaseInvoiceVats,
  ] = useState(0);
  const [
    totalNonDeductibleVatReverseChargePurchaseInvoiceVats,
    setTotalNonDeductibleVatReverseChargePurchaseInvoiceVats,
  ] = useState(0);
  const [
    totalReverseChargePurchaseInvoiceVats,
    setTotalReverseChargePurchaseInvoiceVats,
  ] = useState(0);
  const [
    finalTtlTxbleIncmRvrseChrgPrchseInvcVats,
    setFinalTtlTxbleIncmRvrseChrgPrchseInvcVats,
  ] = useState(0);
  const [
    finalTtlDeductibileTxbleIncmRvrseChrgPrchseInvcVats,
    setFinalTtlDeductibileTxbleIncmRvrseChrgPrchseInvcVats,
  ] = useState(0);
  const [
    finalTtlNonDeductibileTxbleIncmRvrseChrgPrchseInvcVats,
    setFinalTtlNonDeductibileTxbleIncmRvrseChrgPrchseInvcVats,
  ] = useState(0);
  const [
    finalTtlDductibleVatRvrseChrgPrchseInvcVats,
    setFinalTtlDductibleVatRvrseChrgPrchseInvcVats,
  ] = useState(0);
  const [
    finalTtlNonDductibleVatRvrseChrgPrchseInvcVats,
    setFinalTtlNonDductibleVatRvrseChrgPrchseInvcVats,
  ] = useState(0);
  const [finalTtlRvrseChrgPrchseInvcVats, setFinalTtlRvrseChrgPrchseInvcVats] =
    useState(0);
  const [finalPuchaseDeductible, setFinalPuchaseDeductible] = useState(0);
  const [isPrinting, setIsPrinting] = useState(false);
  const [vat17, setVat17] = useState([]);
  const [monthwiseInvoice, setMonthwiseInvoice] = useState([]);
  const [taxDueOrCredit, setTaxDueOrCredit] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [monthNames, setMonthNames] = useState([
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ]);
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [saldoAnnoCorrente, setSaldoAnnoCorrente] = useState(0);
  const [ivaCredito, setIvaCredito] = useState(0);

  useEffect(() => {
    const newListSaldi = [];
    for (let index = 0; index < 10; index++) {
      newListSaldi.push({
        name: "FEBBRAIO",
        price1: "110.00",
        price2: "110.00",
      });
    }
    getYearWiseReport();
    if (isPrinting) {
      window.print();
      setIsPrinting(false); // Reset the printing status after printing
    }
  }, [selectedDate, isPrinting]);

  const handlePrintClick = () => {
    setIsPrinting(true);
  };

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
      navigate("/", { state: { isModalPopup: true } });
    }
  };

  const getYearWiseReport = async () => {
    setIsLoading(true);
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    setClientName(accountingSubject.name);
    setVatNumber(accountingSubject.vatNumber);
    const requestObject = {};
    requestObject.accountingSubjectId = accountingSubject?._id;
    requestObject.registrationDate = selectedDate;
    API.post(apiURL.service_year_wise_vat_report, requestObject)
      .then((response) => {
        setIsLoading(false);
        console.log("response", response.data.data.report);

        setSalesInvoiceVats(response?.data?.data?.report?.salesInvoiceVats);
        setReverseChargeSalesInvoiceVats(
          response?.data?.data?.report?.reverseChargeSalesInvoiceVats
        );
        setPurchaseInvoiceVats(
          response?.data?.data?.report?.purchaseInvoiceVats
        );
        setReverseChargePurchaseInvoiceVats(
          response?.data?.data?.report?.reverseChargePurchaseInvoiceVats
        );
        setVat17(response?.data?.data?.report?.vat17);

        setMonthwiseInvoice(response?.data?.data?.report?.monthwiseInvoice);
        setIvaCredito(response?.data?.data?.report?.vatCredit);
      })
      .catch((error) => {
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout();
          } else {
            setErrorMsg(t("common.badRequest"));
          }
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let newTotalTaxableSalesInvoiceVats = 0;
    let newTotalTaxSalesInvoiceVats = 0;
    let newTotalTotalSalesInvoiceVats = 0;

    let newTotalTaxableReverseChargeSalesInvoiceVats = 0;
    let newTotalTaxReverseChargeSalesInvoiceVats = 0;
    let newTotalReverseChargeSalesInvoiceVats = 0;
    let finalTtlTxbleIncmSlsInvcVats = 0;
    let finalTtlTaxSlsInvcVats = 0;
    let finalTtlSlsInvcVats = 0;

    let newTaxableIncomePurchaseInvoiceVats = 0;
    let newTotalDeductibleVatPurchaseInvoiceVats = 0;
    let newTotalNonDeductibleVatPurchaseInvoiceVats = 0;
    let newTotalPurchaseInvoiceVats = 0;

    let newTaxableIncomeReverseChargePurchaseInvoiceVats = 0;
    let newTotalDeductibleVatReverseChargePurchaseInvoiceVats = 0;
    let newTotalNonDeductibleVatReverseChargePurchaseInvoiceVats = 0;
    let newTotalReverseChargePurchaseInvoiceVats = 0;
    let finalTtlTxbleIncmRvrseChrgPrchseInvcVats = 0;
    let finalTtlDductibleVatRvrseChrgPrchseInvcVats = 0;
    let finalTtlNonDductibleVatRvrseChrgPrchseInvcVats = 0;
    let finalTtlRvrseChrgPrchseInvcVats = 0;

    let newDeductibleTaxableIncomePurchaseInvoiceVats = 0;
    let newNonDeductibleTaxableIncomePurchaseInvoiceVats = 0;
    let newDeductibleTaxableIncomeReversePurchaseInvoiceVats = 0;
    let newNonDeductibleTaxableIncomeReversePurchaseInvoiceVats = 0;

    let finalTtlDeductibileTxbleIncmRvrseChrgPrchseInvcVats = 0;
    let finalTtlNonDeductibileTxbleIncmRvrseChrgPrchseInvcVats = 0;

    let finalPuchaseDeductible = 0;

    // Calculate the new total rate
    salesInvoiceVats.forEach((row) => {
      newTotalTaxableSalesInvoiceVats += row.totalTaxableIncome;
      newTotalTaxSalesInvoiceVats += row.totalTax;
      newTotalTotalSalesInvoiceVats += row.total;
    });
    reverseChargeSalesInvoiceVats.forEach((row) => {
      newTotalTaxableReverseChargeSalesInvoiceVats += row.totalTaxableIncome;
      newTotalTaxReverseChargeSalesInvoiceVats += row.totalVat;
      newTotalReverseChargeSalesInvoiceVats += row.total;
    });

    finalTtlTxbleIncmSlsInvcVats =
      newTotalTaxableSalesInvoiceVats +
      newTotalTaxableReverseChargeSalesInvoiceVats;
    finalTtlTaxSlsInvcVats =
      newTotalTaxSalesInvoiceVats + newTotalTaxReverseChargeSalesInvoiceVats;
    finalTtlSlsInvcVats =
      newTotalTotalSalesInvoiceVats + newTotalReverseChargeSalesInvoiceVats;

    purchaseInvoiceVats.forEach((row) => {
      newTaxableIncomePurchaseInvoiceVats += row.totalTaxableIncome;
      newTotalDeductibleVatPurchaseInvoiceVats += row.totalDeductibleVat;
      newTotalNonDeductibleVatPurchaseInvoiceVats += row.totalNonDeductibleVat;
      newTotalPurchaseInvoiceVats += row.total;
      newDeductibleTaxableIncomePurchaseInvoiceVats +=
        row.deductibleTaxableIncome;
      newNonDeductibleTaxableIncomePurchaseInvoiceVats +=
        row.nonDeductibleTaxableIncome;
    });

    reverseChargePurchaseInvoiceVats.forEach((row) => {
      newTaxableIncomeReverseChargePurchaseInvoiceVats +=
        row.totalTaxableIncome;
      newTotalDeductibleVatReverseChargePurchaseInvoiceVats +=
        row.totalDeductibleVat;
      newTotalNonDeductibleVatReverseChargePurchaseInvoiceVats +=
        row.totalNonDeductibleVat;
      newTotalReverseChargePurchaseInvoiceVats += row.total;
      newDeductibleTaxableIncomeReversePurchaseInvoiceVats +=
        row.deductibleTaxableIncome;
      newNonDeductibleTaxableIncomeReversePurchaseInvoiceVats +=
        row.nonDeductibleTaxableIncome;
    });

    finalTtlTxbleIncmRvrseChrgPrchseInvcVats =
      newTaxableIncomePurchaseInvoiceVats +
      newTaxableIncomeReverseChargePurchaseInvoiceVats;
    finalTtlDductibleVatRvrseChrgPrchseInvcVats =
      newTotalDeductibleVatPurchaseInvoiceVats +
      newTotalDeductibleVatReverseChargePurchaseInvoiceVats;
    finalTtlNonDductibleVatRvrseChrgPrchseInvcVats =
      newTotalNonDeductibleVatPurchaseInvoiceVats +
      newTotalNonDeductibleVatReverseChargePurchaseInvoiceVats;
    finalTtlRvrseChrgPrchseInvcVats =
      newTotalPurchaseInvoiceVats + newTotalReverseChargePurchaseInvoiceVats;

    finalTtlDeductibileTxbleIncmRvrseChrgPrchseInvcVats =
      newDeductibleTaxableIncomePurchaseInvoiceVats +
      newDeductibleTaxableIncomeReversePurchaseInvoiceVats;
    finalTtlNonDeductibileTxbleIncmRvrseChrgPrchseInvcVats =
      newNonDeductibleTaxableIncomePurchaseInvoiceVats +
      newNonDeductibleTaxableIncomeReversePurchaseInvoiceVats;

    finalPuchaseDeductible =
      finalTtlDductibleVatRvrseChrgPrchseInvcVats +
      finalTtlNonDductibleVatRvrseChrgPrchseInvcVats;
    // Update the totalRate state
    setTotalTaxableIncomeSalesInvoiceVats(
      isNaN(newTotalTaxableSalesInvoiceVats)
        ? 0
        : newTotalTaxableSalesInvoiceVats
    );
    setTotalTaxSalesInvoiceVats(
      isNaN(newTotalTaxSalesInvoiceVats) ? 0 : newTotalTaxSalesInvoiceVats
    );
    setTotalSalesInvoiceVats(
      isNaN(newTotalTotalSalesInvoiceVats) ? 0 : newTotalTotalSalesInvoiceVats
    );

    setTotalTaxableIncomeReverseChargeSalesInvoiceVats(
      isNaN(newTotalTaxableReverseChargeSalesInvoiceVats)
        ? 0
        : newTotalTaxableReverseChargeSalesInvoiceVats
    );
    setTotalTaxReverseChargeSalesInvoiceVats(
      isNaN(newTotalTaxReverseChargeSalesInvoiceVats)
        ? 0
        : newTotalTaxReverseChargeSalesInvoiceVats
    );
    setTotalReverseChargeSalesInvoiceVats(
      isNaN(newTotalReverseChargeSalesInvoiceVats)
        ? 0
        : newTotalReverseChargeSalesInvoiceVats
    );

    setFinalTtlTxbleIncmSlsInvcVats(
      isNaN(finalTtlTxbleIncmSlsInvcVats) ? 0 : finalTtlTxbleIncmSlsInvcVats
    );
    setFinalTtlTaxSlsInvcVats(
      isNaN(finalTtlTaxSlsInvcVats) ? 0 : finalTtlTaxSlsInvcVats
    );
    setFinalSlsInvcVats(isNaN(finalTtlSlsInvcVats) ? 0 : finalTtlSlsInvcVats);

    setTotalTaxableIncomePurchaseInvoiceVats(
      isNaN(newTaxableIncomePurchaseInvoiceVats)
        ? 0
        : newTaxableIncomePurchaseInvoiceVats
    );
    setTotalDeductibleTaxableIncomePurchaseInvoiceVats(
      isNaN(newDeductibleTaxableIncomePurchaseInvoiceVats)
        ? 0
        : newDeductibleTaxableIncomePurchaseInvoiceVats
    );
    setTotalNonDeductibleTaxableIncomePurchaseInvoiceVats(
      isNaN(newNonDeductibleTaxableIncomePurchaseInvoiceVats)
        ? 0
        : newNonDeductibleTaxableIncomePurchaseInvoiceVats
    );
    setTotalDeductibleVatPurchaseInvoiceVats(
      isNaN(newTotalDeductibleVatPurchaseInvoiceVats)
        ? 0
        : newTotalDeductibleVatPurchaseInvoiceVats
    );
    setTotalNonDeductibleVatPurchaseInvoiceVats(
      isNaN(newTotalNonDeductibleVatPurchaseInvoiceVats)
        ? 0
        : newTotalNonDeductibleVatPurchaseInvoiceVats
    );
    setTotalPurchaseInvoiceVats(
      isNaN(newTotalPurchaseInvoiceVats) ? 0 : newTotalPurchaseInvoiceVats
    );

    setTotalTaxableIncomeReverseChargePurchaseInvoiceVats(
      isNaN(newTaxableIncomeReverseChargePurchaseInvoiceVats)
        ? 0
        : newTaxableIncomeReverseChargePurchaseInvoiceVats
    );
    setTotalDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats(
      isNaN(newDeductibleTaxableIncomeReversePurchaseInvoiceVats)
        ? 0
        : newDeductibleTaxableIncomeReversePurchaseInvoiceVats
    );
    setTotalNonDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats(
      isNaN(newNonDeductibleTaxableIncomeReversePurchaseInvoiceVats)
        ? 0
        : newNonDeductibleTaxableIncomeReversePurchaseInvoiceVats
    );
    setTotalDeductibleVatReverseChargePurchaseInvoiceVats(
      isNaN(newTotalDeductibleVatReverseChargePurchaseInvoiceVats)
        ? 0
        : newTotalDeductibleVatReverseChargePurchaseInvoiceVats
    );
    setTotalNonDeductibleVatReverseChargePurchaseInvoiceVats(
      isNaN(newTotalNonDeductibleVatReverseChargePurchaseInvoiceVats)
        ? 0
        : newTotalNonDeductibleVatReverseChargePurchaseInvoiceVats
    );
    setTotalReverseChargePurchaseInvoiceVats(
      isNaN(newTotalReverseChargePurchaseInvoiceVats)
        ? 0
        : newTotalReverseChargePurchaseInvoiceVats
    );
    setFinalTtlTxbleIncmRvrseChrgPrchseInvcVats(
      isNaN(finalTtlTxbleIncmRvrseChrgPrchseInvcVats)
        ? 0
        : finalTtlTxbleIncmRvrseChrgPrchseInvcVats
    );
    setFinalTtlDductibleVatRvrseChrgPrchseInvcVats(
      isNaN(finalTtlDductibleVatRvrseChrgPrchseInvcVats)
        ? 0
        : finalTtlDductibleVatRvrseChrgPrchseInvcVats
    );
    setFinalTtlNonDductibleVatRvrseChrgPrchseInvcVats(
      isNaN(finalTtlNonDductibleVatRvrseChrgPrchseInvcVats)
        ? 0
        : finalTtlNonDductibleVatRvrseChrgPrchseInvcVats
    );
    setFinalTtlRvrseChrgPrchseInvcVats(
      isNaN(finalTtlRvrseChrgPrchseInvcVats)
        ? 0
        : finalTtlRvrseChrgPrchseInvcVats
    );
    setFinalPuchaseDeductible(
      isNaN(finalPuchaseDeductible) ? 0 : finalPuchaseDeductible
    );

    setFinalTtlDeductibileTxbleIncmRvrseChrgPrchseInvcVats(
      isNaN(finalTtlDeductibileTxbleIncmRvrseChrgPrchseInvcVats)
        ? 0
        : finalTtlDeductibileTxbleIncmRvrseChrgPrchseInvcVats
    );

    setFinalTtlNonDeductibileTxbleIncmRvrseChrgPrchseInvcVats(
      isNaN(finalTtlNonDeductibileTxbleIncmRvrseChrgPrchseInvcVats)
        ? 0
        : finalTtlNonDeductibileTxbleIncmRvrseChrgPrchseInvcVats
    );

    let finalTaxValue = 0;
    let finalsaldoAnnoCorrente = 0;
    let vat17Amt = vat17?.totalV17Vat;
    finalTaxValue =
      finalTtlTaxSlsInvcVats -
      vat17Amt -
      finalTtlDductibleVatRvrseChrgPrchseInvcVats;
    // console.log("finalTaxValue",finalTaxValue)
    finalsaldoAnnoCorrente = finalTaxValue + ivaCredito;

    setSaldoAnnoCorrente(isNaN(finalTaxValue) ? 0 : finalTaxValue);

    setTaxDueOrCredit(
      isNaN(finalsaldoAnnoCorrente) ? 0 : finalsaldoAnnoCorrente
    );
  }, [
    salesInvoiceVats,
    reverseChargeSalesInvoiceVats,
    purchaseInvoiceVats,
    reverseChargePurchaseInvoiceVats,
    ivaCredito,
  ]);

  function customSort(a, b) {
    const aliquotaA = a.rate;
    const aliquotaB = b.rate;

    if (aliquotaA < aliquotaB) {
      return -1;
    } else if (aliquotaA > aliquotaB) {
      return 1;
    } else {
      return 0;
    }
  }
  const generatePDFDocument = async () => {
    const blob = await pdf(
      <Document>
        <Page size="A3" orientation="landscape" style={{ padding: "24px" }}>
          <View>
            <View fixed>
              <View
                style={{
                  fontSize: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <View
                >
                  <Image src={logo} style={{ width: "320px" }} />
                </View> */}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: "10px",
                      textTransform: "uppercase",
                    }}
                  >
                    {t("soggetto") + " " + t("contabile")} :{" "}
                  </Text>

                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: "10px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                      textTransform: "uppercase",
                    }}
                  >
                    {clientName} - {""}
                  </Text>

                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: "10px",
                    }}
                  >
                    {vatNumber}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: "10px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {t("printDate")}
                  </Text>

                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: "10px",
                      marginLeft: "16px",
                    }}
                  >
                    {dayjs().format("DD/MM/YYYY")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  fontSize: "10px",
                  alignItems: "left",
                }}
              >
                <View
                  style={{
                    width: "80%",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "16px",
                      marginTop: "16px",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "left",
                        fontSize: "10px",
                      }}
                    >
                      {t("riassuntoAnno")}
                    </Text>
                    <Text
                      style={{
                        textAlign: "left",
                        fontSize: "10px",
                        fontFamily: "Helvetica Neue",
                        fontWeight: "600",
                        textTransform: "uppercase",
                        marginLeft: "8px",
                      }}
                    >
                      {selectedDate}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {/* Table 1 */}

            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px solid black",
                  width: "70%",
                  fontSize: "10px",
                }}
              >
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "40%",
                    padding: "2px",
                    textAlign: "center",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("regDelle")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "15%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("aliquota")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "15%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("imponibile")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "15%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("imposta")}
                </Text>
                <Text
                  style={{
                    width: "15%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("totale")}
                </Text>
              </View>
              <View>
                {salesInvoiceVats.length > 0 ? (
                  salesInvoiceVats.sort(customSort).map((row, index) => {
                    // salesInvoiceVats.map((row, index) => {
                    return (
                      <View
                        key={row?._id}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          border: "1px solid black",
                          width: "70%",
                          fontSize: "10px",
                          borderTop: "none",
                          textAlign: "right",
                        }}
                      >
                        <Text
                          style={{
                            borderRight: "1px solid black",
                            width: "40%",
                            padding: "2px",
                          }}
                        >{`${row?.code} - ${row?.name}`}</Text>
                        <Text
                          style={{
                            borderRight: "1px solid black",
                            width: "15%",
                            padding: "2px",
                          }}
                        >
                          {row?.rate?.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Text>
                        <Text
                          style={{
                            borderRight: "1px solid black",
                            width: "15%",
                            padding: "2px",
                          }}
                        >
                          {row?.totalTaxableIncome == 0
                            ? null
                            : row?.totalTaxableIncome?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text>
                        <Text
                          style={{
                            borderRight: "1px solid black",
                            width: "15%",
                            padding: "2px",
                          }}
                        >
                          {row?.totalTax == 0
                            ? null
                            : row?.totalTax?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text>
                        <Text style={{ width: "15%", padding: "2px" }}>
                          {row?.total == 0
                            ? null
                            : row?.total?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text>
                      </View>
                    );
                  })
                ) : (
                  <View style={{ width: "70%" }}>
                    <Text
                      style={{
                        border: "1px solid black",
                        borderTop: "0px solid black",
                        fontSize: 16,
                        textAlign: "center",
                        padding: 2,
                        minHeight: "16px",
                      }}
                    ></Text>
                  </View>
                )}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    border: "1px solid black",
                    width: "70%",
                    fontSize: "10px",
                    borderTop: "none",
                    textAlign: "right",
                  }}
                >
                  {/* <TableCell></TableCell> */}
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "40%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {t("totale")}
                  </Text>
                  <Text style={{ width: "15%" }}></Text>
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "15%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {totalTaxableIncomeSalesInvoiceVats == 0
                      ? null
                      : totalTaxableIncomeSalesInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text>
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "15%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {totalTaxSalesInvoiceVats == 0
                      ? null
                      : totalTaxSalesInvoiceVats.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Text>

                  <Text
                    style={{
                      width: "15%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {totalSalesInvoiceVats == 0
                      ? null
                      : totalSalesInvoiceVats.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Text>
                </View>
              </View>
            </View>

            {/* Table 2 */}

            <View style={{ marginTop: "24px" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px solid black",
                  width: "70%",
                  fontSize: "10px",
                }}
              >
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "40%",
                    padding: "2px",
                    textAlign: "center",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("regDelleRev")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "15%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("aliquota")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "15%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("imponibile")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "15%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("imposta")}
                </Text>
                <Text
                  style={{
                    width: "15%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("totale")}
                </Text>
              </View>
              <View>
                {reverseChargeSalesInvoiceVats.length > 0 ? (
                  reverseChargeSalesInvoiceVats
                    .sort(customSort)
                    .map((row, index) => {
                      // reverseChargeSalesInvoiceVats.map((row, index) => {
                      return (
                        <View
                          key={row?._id}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            border: "1px solid black",
                            width: "70%",
                            fontSize: "10px",
                            borderTop: "none",
                            textAlign: "right",
                          }}
                        >
                          <Text
                            style={{
                              borderRight: "1px solid black",
                              width: "40%",
                              padding: "2px",
                            }}
                          >{`${row?.code} - ${row?.name}`}</Text>
                          <Text
                            style={{
                              borderRight: "1px solid black",
                              width: "15%",
                              padding: "2px",
                            }}
                          >
                            {row?.rate?.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Text>
                          <Text
                            style={{
                              borderRight: "1px solid black",
                              width: "15%",
                              padding: "2px",
                            }}
                          >
                            {row?.totalTaxableIncome == 0
                              ? null
                              : row?.totalTaxableIncome?.toLocaleString(
                                  "de-DE",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                          </Text>
                          <Text
                            style={{
                              borderRight: "1px solid black",
                              width: "15%",
                              padding: "2px",
                            }}
                          >
                            {row?.totalVat == 0
                              ? null
                              : row?.totalVat?.toLocaleString("de-DE", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Text>
                          <Text style={{ width: "15%", padding: "2px" }}>
                            {row?.total == 0
                              ? null
                              : row?.total?.toLocaleString("de-DE", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Text>
                        </View>
                      );
                    })
                ) : (
                  <View style={{ width: "70%" }}>
                    <Text
                      style={{
                        border: "1px solid black",
                        borderTop: "0px solid black",
                        fontSize: 16,
                        textAlign: "center",
                        padding: 2,
                        minHeight: "16px",
                      }}
                    ></Text>
                  </View>
                )}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    border: "1px solid black",
                    width: "70%",
                    fontSize: "10px",
                    borderTop: "none",
                    textAlign: "right",
                  }}
                >
                  {/* <TableCell></TableCell> */}
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "40%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {t("totale")}
                  </Text>
                  <Text style={{ width: "15%" }}></Text>
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "15%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {totalTaxableIncomeReverseChargeSalesInvoiceVats == 0
                      ? null
                      : totalTaxableIncomeReverseChargeSalesInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text>
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "15%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {totalTaxReverseChargeSalesInvoiceVats == 0
                      ? null
                      : totalTaxReverseChargeSalesInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text>

                  <Text
                    style={{
                      width: "15%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {totalReverseChargeSalesInvoiceVats == 0
                      ? null
                      : totalReverseChargeSalesInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text>
                </View>
              </View>
            </View>

            {/* Table 3 */}

            <View style={{ marginTop: "24px" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: "2px solid black",
                  width: "70%",
                  fontSize: "10px",
                  textAlign: "right",
                }}
              >
                {/* <TableCell></TableCell> */}
                <Text
                  style={{
                    borderRight: "2px solid black",
                    width: "40%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("totale") + " " + t("vendite")}
                </Text>
                <Text style={{ width: "15%" }}></Text>
                <Text
                  style={{
                    borderRight: "2px solid black",
                    width: "15%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {finalTtlTxbleIncmSlsInvcVats == 0
                    ? null
                    : finalTtlTxbleIncmSlsInvcVats.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>
                <Text
                  style={{
                    borderRight: "2px solid black",
                    width: "15%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {finalTtlTaxSlsInvcVats == 0
                    ? null
                    : finalTtlTaxSlsInvcVats.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>

                <Text
                  style={{
                    width: "15%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {finalTtlSlsInvcVats == 0
                    ? null
                    : finalTtlSlsInvcVats.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>
              </View>
            </View>

            {/* Table 4 */}

            <View style={{ marginTop: "24px" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px solid black",
                  fontSize: "10px",
                }}
              >
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "32%",
                    padding: "2px",
                    textAlign: "center",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("regAcquisti")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "12%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("aliquota")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "12%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("detraibilità")} %
                </Text>
                {/* <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "12%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("imponibile")}
                </Text> */}
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "15%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("taxableIncomeDeductible")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "15%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("taxableIncomeInDeductible")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "12%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("taxDeductible")}
                </Text>
                <Text
                  style={{
                    width: "14%",
                    borderRight: "1px solid black",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("taxableNonDeductible")}
                </Text>
                <Text
                  style={{
                    width: "14%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("totale")}
                </Text>
              </View>

              <View>
                {purchaseInvoiceVats.length > 0 ? (
                  purchaseInvoiceVats.sort(customSort).map((row, index) => {
                    // purchaseInvoiceVats.map((row, index) => {
                    return (
                      <View
                        key={row?._id}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          border: "1px solid black",
                          fontSize: "10px",
                          borderTop: "none",
                          textAlign: "right",
                        }}
                      >
                        <Text
                          style={{
                            borderRight: "1px solid black",
                            width: "32%",
                            padding: "2px",
                          }}
                        >{`${row?.code} - ${row?.name}`}</Text>
                        <Text
                          style={{
                            borderRight: "1px solid black",
                            width: "12%",
                            padding: "2px",
                          }}
                        >
                          {row?.rate?.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Text>
                        <Text
                          style={{
                            borderRight: "1px solid black",
                            width: "12%",
                            padding: "2px",
                          }}
                        >
                          {row?.deductibility == 0
                            ? null
                            : row?.deductibility?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text>
                        {/* <Text
                          style={{
                            borderRight: "1px solid black",
                            width: "12%",
                            padding: "2px",
                          }}
                        >
                          {row?.totalTaxableIncome == 0
                            ? null
                            : row?.totalTaxableIncome?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text> */}
                        <Text
                          style={{
                            borderRight: "1px solid black",
                            width: "15%",
                            padding: "2px",
                          }}
                        >
                          {row?.deductibleTaxableIncome == 0
                            ? null
                            : row?.deductibleTaxableIncome?.toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </Text>
                        <Text
                          style={{
                            borderRight: "1px solid black",
                            width: "15%",
                            padding: "2px",
                          }}
                        >
                          {row?.nonDeductibleTaxableIncome == 0
                            ? null
                            : row?.nonDeductibleTaxableIncome?.toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </Text>
                        <Text
                          style={{
                            width: "12%",
                            padding: "2px",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row?.totalDeductibleVat == 0
                            ? null
                            : row?.totalDeductibleVat?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text>
                        <Text
                          style={{
                            width: "14%",
                            padding: "2px",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row?.totalNonDeductibleVat == 0
                            ? null
                            : row?.totalNonDeductibleVat?.toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </Text>
                        <Text style={{ width: "14%", padding: "2px" }}>
                          {row?.total == 0
                            ? null
                            : row?.total?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text>
                      </View>
                    );
                  })
                ) : (
                  <View style={{ width: "100%" }}>
                    <Text
                      style={{
                        border: "1px solid black",
                        borderTop: "0px solid black",
                        fontSize: 16,
                        textAlign: "center",
                        padding: 2,
                        minHeight: "16px",
                      }}
                    ></Text>
                  </View>
                )}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    border: "1px solid black",
                    fontSize: "10px",
                    borderTop: "none",
                    textAlign: "right",
                  }}
                >
                  {/* <TableCell></TableCell> */}
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "32%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {t("totale")}
                  </Text>
                  <Text
                    style={{
                      width: "12%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  ></Text>
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "12%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  ></Text>
                  {/* <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "36%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {totalTaxableIncomePurchaseInvoiceVats == 0
                      ? null
                      : totalTaxableIncomePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text> */}
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "15%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {totalDeductibleTaxableIncomePurchaseInvoiceVats == 0
                      ? null
                      : totalDeductibleTaxableIncomePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text>
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "15%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {totalNonDeductibleTaxableIncomePurchaseInvoiceVats == 0
                      ? null
                      : totalNonDeductibleTaxableIncomePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text>
                  <Text
                    style={{
                      width: "12%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                      borderRight: "1px solid black",
                    }}
                  >
                    {totalDeductibleVatPurchaseInvoiceVats == 0
                      ? null
                      : totalDeductibleVatPurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text>

                  <Text
                    style={{
                      width: "14%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                      borderRight: "1px solid black",
                    }}
                  >
                    {totalNonDeductibleVatPurchaseInvoiceVats == 0
                      ? null
                      : totalNonDeductibleVatPurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text>

                  <Text
                    style={{
                      width: "14%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                      // borderRight:'1px solid black',
                    }}
                  >
                    {totalPurchaseInvoiceVats == 0
                      ? null
                      : totalPurchaseInvoiceVats.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Text>
                </View>
              </View>
            </View>

            {/* Table 5 */}

            <View style={{ marginTop: "24px" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px solid black",
                  fontSize: "10px",
                }}
              >
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "32%",
                    padding: "2px",
                    textAlign: "center",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("regAcquistiRev")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "12%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("aliquota")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "12%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("detraibilità")} %
                </Text>
                {/* <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "12%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("imponibile")}
                </Text> */}
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "15%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("taxableIncomeDeductible")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "15%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("taxableIncomeInDeductible")}
                </Text>
                <Text
                  style={{
                    borderRight: "1px solid black",
                    width: "12%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("taxDeductible")}
                </Text>
                <Text
                  style={{
                    width: "14%",
                    borderRight: "1px solid black",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("taxableNonDeductible")}
                </Text>
                <Text
                  style={{
                    width: "14%",
                    padding: "2px",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("totale")}
                </Text>
              </View>

              <View>
                {reverseChargePurchaseInvoiceVats.length > 0 ? (
                  reverseChargePurchaseInvoiceVats
                    .sort(customSort)
                    .map((row, index) => {
                      // reverseChargePurchaseInvoiceVats.map((row, index) => {
                      return (
                        <View
                          key={row?._id}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            border: "1px solid black",
                            fontSize: "10px",
                            borderTop: "none",
                            textAlign: "right",
                          }}
                        >
                          <Text
                            style={{
                              borderRight: "1px solid black",
                              width: "32%",
                              padding: "2px",
                            }}
                          >{`${row?.code} - ${row?.name}`}</Text>
                          <Text
                            style={{
                              borderRight: "1px solid black",
                              width: "12%",
                              padding: "2px",
                            }}
                          >
                            {row?.rate?.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Text>
                          <Text
                            style={{
                              borderRight: "1px solid black",
                              width: "12%",
                              padding: "2px",
                            }}
                          >
                            {row?.deductibility == 0
                              ? null
                              : row?.deductibility?.toLocaleString("de-DE", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Text>
                          {/* <Text
                            style={{
                              borderRight: "1px solid black",
                              width: "12%",
                              padding: "2px",
                            }}
                          >
                            {row?.totalTaxableIncome == 0
                              ? null
                              : row?.totalTaxableIncome?.toLocaleString(
                                  "de-DE",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                          </Text> */}
                          <Text
                            style={{
                              borderRight: "1px solid black",
                              width: "15%",
                              padding: "2px",
                            }}
                          >
                            {row?.deductibleTaxableIncome == 0
                              ? null
                              : row?.deductibleTaxableIncome?.toLocaleString(
                                  "de-DE",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                          </Text>
                          <Text
                            style={{
                              borderRight: "1px solid black",
                              width: "15%",
                              padding: "2px",
                            }}
                          >
                            {row?.nonDeductibleTaxableIncome == 0
                              ? null
                              : row?.nonDeductibleTaxableIncome?.toLocaleString(
                                  "de-DE",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                          </Text>
                          <Text
                            style={{
                              width: "12%",
                              padding: "2px",
                              borderRight: "1px solid black",
                            }}
                          >
                            {row?.totalDeductibleVat == 0
                              ? null
                              : row?.totalDeductibleVat?.toLocaleString(
                                  "de-DE",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                          </Text>
                          <Text
                            style={{
                              width: "14%",
                              padding: "2px",
                              borderRight: "1px solid black",
                            }}
                          >
                            {row?.totalNonDeductibleVat == 0
                              ? null
                              : row?.totalNonDeductibleVat?.toLocaleString(
                                  "de-DE",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                          </Text>
                          <Text style={{ width: "14%", padding: "2px" }}>
                            {row?.total == 0
                              ? null
                              : row?.total?.toLocaleString("de-DE", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Text>
                        </View>
                      );
                    })
                ) : (
                  <View style={{ width: "100%" }}>
                    <Text
                      style={{
                        border: "1px solid black",
                        borderTop: "0px solid black",
                        fontSize: 16,
                        textAlign: "center",
                        padding: 2,
                        minHeight: "16px",
                      }}
                    ></Text>
                  </View>
                )}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    border: "1px solid black",
                    fontSize: "10px",
                    borderTop: "none",
                    textAlign: "right",
                  }}
                >
                  {/* <TableCell></TableCell> */}
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "32%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {t("totale")}
                  </Text>
                  <Text
                    style={{
                      width: "12%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  ></Text>
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "12%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  ></Text>
                  {/* <Text style={{ width: "32%" }}></Text> */}
                  {/* <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "36%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {totalTaxableIncomeReverseChargePurchaseInvoiceVats == 0
                      ? null
                      : totalTaxableIncomeReverseChargePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text> */}
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "15%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {totalDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats ==
                    0
                      ? null
                      : totalDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text>
                  <Text
                    style={{
                      borderRight: "1px solid black",
                      width: "15%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                    }}
                  >
                    {totalNonDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats ==
                    0
                      ? null
                      : totalNonDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text>
                  <Text
                    style={{
                      width: "12%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                      borderRight: "1px solid black",
                    }}
                  >
                    {totalDeductibleVatReverseChargePurchaseInvoiceVats == 0
                      ? null
                      : totalDeductibleVatReverseChargePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text>

                  <Text
                    style={{
                      width: "14%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                      borderRight: "1px solid black",
                    }}
                  >
                    {totalNonDeductibleVatReverseChargePurchaseInvoiceVats == 0
                      ? null
                      : totalNonDeductibleVatReverseChargePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text>

                  <Text
                    style={{
                      width: "14%",
                      padding: "2px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                      // borderRight:'1px solid black',
                    }}
                  >
                    {totalReverseChargePurchaseInvoiceVats == 0
                      ? null
                      : totalReverseChargePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Text>
                </View>
              </View>
            </View>

            {/* Table 6 */}

            <View style={{ marginTop: "24px", border: "2px solid black" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // border: "2px solid black",
                  fontSize: "10px",
                  textAlign: "right",
                }}
              >
                <Text
                  style={{
                    borderRight: "2px solid black",
                    width: "32%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("totale") + " " + t("acquisti")}
                </Text>
                <Text
                  style={{
                    width: "12%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                ></Text>
                <Text
                  style={{
                    borderRight: "2px solid black",
                    width: "12%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                ></Text>
                {/* <Text
                  style={{
                    borderRight: "2px solid black",
                    width: "36%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {finalTtlTxbleIncmRvrseChrgPrchseInvcVats == 0
                    ? null
                    : finalTtlTxbleIncmRvrseChrgPrchseInvcVats.toLocaleString(
                        "de-DE",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                </Text> */}
                <Text
                  style={{
                    borderRight: "2px solid black",
                    width: "15%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {finalTtlDeductibileTxbleIncmRvrseChrgPrchseInvcVats == 0
                    ? null
                    : finalTtlDeductibileTxbleIncmRvrseChrgPrchseInvcVats.toLocaleString(
                        "de-DE",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                </Text>
                <Text
                  style={{
                    borderRight: "2px solid black",
                    width: "15%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {finalTtlNonDeductibileTxbleIncmRvrseChrgPrchseInvcVats == 0
                    ? null
                    : finalTtlNonDeductibileTxbleIncmRvrseChrgPrchseInvcVats.toLocaleString(
                        "de-DE",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                </Text>
                <Text
                  style={{
                    width: "12%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    borderRight: "2px solid black",
                  }}
                >
                  {finalTtlDductibleVatRvrseChrgPrchseInvcVats == 0
                    ? null
                    : finalTtlDductibleVatRvrseChrgPrchseInvcVats.toLocaleString(
                        "de-DE",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                </Text>

                <Text
                  style={{
                    width: "14%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    borderRight: "2px solid black",
                  }}
                >
                  {finalTtlNonDductibleVatRvrseChrgPrchseInvcVats == 0
                    ? null
                    : finalTtlNonDductibleVatRvrseChrgPrchseInvcVats.toLocaleString(
                        "de-DE",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                </Text>

                <Text
                  style={{
                    width: "14%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {finalTtlRvrseChrgPrchseInvcVats == 0
                    ? null
                    : finalTtlRvrseChrgPrchseInvcVats.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{ position: "absolute", bottom: "20px", left: "50%" }}
            render={({ pageNumber, totalPages }) => (
              <Text style={{ fontSize: 12 }}>
                Pagina {pageNumber} di {totalPages}
              </Text>
            )}
            fixed
          />
        </Page>

        <Page size="A3" orientation="landscape" style={{ padding: "24px" }}>
          <View fixed>
            <View
              style={{
                fontSize: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <View
                style={{
                  width: "80%",
                }}
              >
                <Image src={logo} style={{ width: "320px" }} />
              </View>  */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("soggetto") + " " + t("contabile")} :{" "}
                </Text>

                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {clientName} -{" "}
                </Text>

                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                  }}
                >
                  {vatNumber}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("printDate")}
                </Text>

                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                    marginLeft: "16px",
                  }}
                >
                  {dayjs().format("DD/MM/YYYY")}
                </Text>
              </View>
            </View>

            <View
              style={{
                fontSize: "10px",
                alignItems: "left",
              }}
            >
              <View
                style={{
                  width: "80%",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "16px",
                    marginTop: "16px",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: "10px",
                    }}
                  >
                    {t("riassuntoAnno")}
                  </Text>
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: "10px",
                      fontFamily: "Helvetica Neue",
                      fontWeight: "600",
                      textTransform: "uppercase",
                      marginLeft: "8px",
                    }}
                  >
                    {selectedDate}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: "10px",
              }}
            >
              <Text
                style={{
                  // border: "1px solid black",
                  width: "27.90%",
                  padding: "2px",
                  textAlign: "center",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                }}
              >
                {/* REGISTRO DEGLI ACQUISTI */}
              </Text>
              <Text
                style={{
                  border: "1px solid black",
                  width: "10.6%",
                  padding: "2px",
                  textAlign: "center",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  borderBottom: "none",
                }}
              >
                {t("imponibile")}
              </Text>
              <Text
                style={{
                  border: "1px solid black",
                  width: "10.5%",
                  padding: "2px",
                  textAlign: "center",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  borderLeft: "none",
                  borderBottom: "none",
                }}
              >
                {t("imposta")}
              </Text>
            </View>

            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "10px",
                  textAlign: "right",
                }}
              >
                <Text
                  style={{
                    border: "1px solid black",
                    width: "28%",
                    padding: "2px",
                    borderBottom: "none",
                  }}
                >
                  {t("salesOfTheYear")}
                </Text>
                <Text
                  style={{
                    border: "1px solid black",
                    width: "10.5%",
                    padding: "2px",
                    borderBottom: "none",
                    borderLeft: "none",
                  }}
                >
                  {finalTtlTxbleIncmSlsInvcVats == 0
                    ? null
                    : finalTtlTxbleIncmSlsInvcVats.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>
                <Text
                  style={{
                    border: "1px solid black",
                    width: "10.5%",
                    padding: "2px",
                    borderBottom: "none",
                    borderLeft: "none",
                  }}
                >
                  {finalTtlTaxSlsInvcVats == 0
                    ? null
                    : finalTtlTaxSlsInvcVats.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "10px",
                  textAlign: "right",
                }}
              >
                <Text
                  style={{
                    border: "1px solid black",
                    width: "28%",
                    padding: "2px",
                    borderBottom: "none",
                  }}
                >{`${vat17?.vatCode} - ${vat17?.vatName}`}</Text>
                <Text
                  style={{
                    border: "1px solid black",
                    width: "10.5%",
                    padding: "2px",
                    borderBottom: "none",
                    borderLeft: "none",
                  }}
                >
                  {/* {vat17?.totalV17TaxableIncome == 0
                  ? null
                  : vat17?.totalV17TaxableIncome?.toLocaleString(
                      "de-DE",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )} */}
                </Text>
                <Text
                  style={{
                    border: "1px solid black",
                    width: "10.5%",
                    padding: "2px",
                    borderBottom: "none",
                    borderLeft: "none",
                  }}
                >
                  {vat17?.totalV17Vat == 0
                    ? null
                    : vat17?.totalV17Vat?.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "10px",
                  textAlign: "right",
                }}
              >
                <Text
                  style={{
                    border: "1px solid black",
                    width: "28%",
                    padding: "2px",
                    borderBottom: "none",
                  }}
                >
                  {t("purchaseOfThePeriod")}
                </Text>
                <Text
                  style={{
                    border: "1px solid black",
                    width: "10.5%",
                    padding: "2px",
                    borderBottom: "none",
                    borderLeft: "none",
                  }}
                >
                  {finalTtlTxbleIncmRvrseChrgPrchseInvcVats == 0
                    ? null
                    : finalTtlTxbleIncmRvrseChrgPrchseInvcVats.toLocaleString(
                        "de-DE",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                </Text>
                <Text
                  style={{
                    border: "1px solid black",
                    width: "10.5%",
                    padding: "2px",
                    borderBottom: "none",
                    borderLeft: "none",
                  }}
                >
                  {finalTtlDductibleVatRvrseChrgPrchseInvcVats == 0
                    ? null
                    : finalTtlDductibleVatRvrseChrgPrchseInvcVats.toLocaleString(
                        "de-DE",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "10px",
                  textAlign: "right",
                }}
              >
                <Text
                  style={{
                    border: "1px solid black",
                    width: "28%",
                    padding: "2px",
                    borderBottom: "none",
                  }}
                >
                  {t("saldoAnnoCorrente")}
                </Text>
                <Text
                  style={{
                    border: "1px solid black",
                    width: "10.5%",
                    padding: "2px",
                    borderBottom: "none",
                    borderLeft: "none",
                  }}
                ></Text>
                <Text
                  style={{
                    border: "1px solid black",
                    width: "10.5%",
                    padding: "2px",
                    borderBottom: "none",
                    borderLeft: "none",
                  }}
                >
                  {saldoAnnoCorrente == 0
                    ? null
                    : saldoAnnoCorrente.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "10px",
                  textAlign: "right",
                }}
              >
                <Text
                  style={{
                    border: "1px solid black",
                    width: "28%",
                    padding: "2px",
                    borderBottom: "none",
                  }}
                >
                  {t("vatCreditAnno")}
                </Text>
                <Text
                  style={{
                    border: "1px solid black",
                    width: "10.5%",
                    padding: "2px",
                    borderBottom: "none",
                    borderLeft: "none",
                  }}
                ></Text>
                <Text
                  style={{
                    border: "1px solid black",
                    width: "10.5%",
                    padding: "2px",
                    borderBottom: "none",
                    borderLeft: "none",
                  }}
                >
                  {ivaCredito == 0
                    ? null
                    : ivaCredito.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "10px",
                  textAlign: "right",
                }}
              >
                {/* <TableCell></TableCell> */}
                <Text
                  style={{
                    border: "1px solid black",
                    width: "28%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("taxDueCredit")}
                </Text>
                <Text
                  style={{
                    width: "10.5%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    border: "1px solid black",
                    borderLeft: "none",
                  }}
                ></Text>
                <Text
                  style={{
                    border: "1px solid black",
                    width: "10.5%",
                    padding: "2px",
                    fontFamily: "Helvetica Neue",
                    border: "1px solid black",
                    fontWeight: "600",
                    borderLeft: "none",
                  }}
                >
                  {taxDueOrCredit == 0
                    ? null
                    : taxDueOrCredit.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>
              </View>
            </View>

            <View style={{ marginTop: "24px" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "10px",
                  textAlign: "left",
                }}
              >
                <Text
                  style={{
                    width: "100%",
                    padding: "2px",
                    textAlign: "left",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                  }}
                >
                  {t("saldi")}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "10px",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  marginTop: "8px",
                }}
              >
                <Text
                  style={{
                    border: "1px solid black",
                    borderBottom: "none",
                    width: "12%",
                    textAlign: "center",
                    padding: "2px 6px 2px 2px",
                  }}
                >
                  {t("month")}
                </Text>
                <Text
                  style={{
                    border: "1px solid black",
                    width: "12%",
                    padding: "2px",
                    borderBottom: "none",
                    borderLeft: "none",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {t("saldo")}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "10px",
                  textAlign: "right",
                }}
              >
                {monthwiseInvoice.map((item, index) => {
                  const monthIndex = item?.monthIndex - 1;
                  return (
                    <View
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        fontSize: "10px",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          border: "1px solid black",
                          width: "12%",
                          padding: "2px 6px 2px 2px",
                          borderBottom:
                            index === monthwiseInvoice.length - 1
                              ? "1px solid black"
                              : "none",
                        }}
                      >
                        {monthNames[monthIndex]}
                      </Text>
                      <Text
                        style={{
                          border: "1px solid black",
                          borderBottom:
                            index === monthwiseInvoice.length - 1
                              ? "1px solid black"
                              : "none",
                          borderLeft: "none",
                          width: "12%",
                          padding: "2px",
                          textAlign: "right",
                        }}
                      >
                        {item?.monthNewSaldo == 0
                          ? null
                          : item?.monthNewSaldo?.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>

          <View
            style={{ position: "absolute", bottom: "20px", left: "50%" }}
            render={({ pageNumber, totalPages }) => (
              <Text style={{ fontSize: 12 }}>
                Pagina {pageNumber} di {totalPages}
              </Text>
            )}
            fixed
          />
        </Page>
      </Document>
    ).toBlob();
    saveAs(blob, "Print_Liquidazione_IVA_Anno_" + selectedDate);
  };
  return (
    <Box>
      {isLoading && (
        <Box>
          <LoadingSpinner></LoadingSpinner>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "48px",
        }}
      >
        <Typography variant="h3">{t("anno")}</Typography>
        {/*       
          <Button
            // href="/add_fatture_vendita"
            sx={{
              marginLeft: "16px",
              // width: "18pc"
              textAlign: "end",
            }}
            // onClick={() => {
            //   props.onAddOpen()
            // }}
            variant="contained"
            size="large"
            startIcon={<LocalPrintshopIcon />}
            onClick={handlePrintClick}
          >
            {t("stampa")}
          </Button> */}

        <Button
          size="small"
          variant="contained"
          startIcon={<LocalPrintshopIcon />}
          onClick={() => generatePDFDocument("doc name")}
        >
          {t("stampa")}
        </Button>
      </Box>

      <Divider sx={{ marginTop: "24px" }} />

      <Grid container mt={5}>
        <Grid item xs={4}>
          <Table aria-label="spanning table" size="small">
            <TableHead>
              {/* <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Details
                  </TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow> */}
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    textTransform: "uppercase",
                  }}
                >
                  {t("soggetto") + " " + t("contabile")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    textTransform: "uppercase",
                  }}
                >
                  {clientName}
                </TableCell>
                <TableCell align="center">{vatNumber}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Table aria-label="spanning table" size="small">
            <TableHead>
              {/* <TableRow>
                <TableCell align="center">
                  {t("data") + " " + t("stampa")}
                </TableCell>
                <TableCell align="center">gg/mm/aaaa</TableCell>
              </TableRow> */}
            </TableHead>
          </Table>
        </Grid>
      </Grid>

      <Table sx={{ maxWidth: 1000 }} aria-label="spanning table" size="small">
        <TableHead>
          {/* <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Details
                  </TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow> */}
          <TableRow>
            <TableCell align="center">
              <Box align="center">{t("riassuntoAnno")}</Box>
            </TableCell>
            <TableCell>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={
                  itIT.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
                adapterLocale={"it"}
              >
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    slotProps={{ textField: { size: "small" } }}
                    label={t("year")}
                    views={["year"]}
                    value={dayjs(selectedDate)}
                    onChange={(newValue) => {
                      const val = dayjs(newValue).format("YYYY");
                      setSelectedDate(val);
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>

      <Grid
        container
        sx={{
          marginTop: "24px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            paddingRight: "12px",
          }}
        >
          {/* <TableContainer component={Paper}> */}

          <Table
            sx={{ maxWidth: "80%" }}
            aria-label="spanning table"
            size="small"
          >
            <TableHead>
              {/* <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Details
                  </TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow> */}
              <TableRow>
                <TableCell width="40%" align="center">
                  {t("regDelle")}
                </TableCell>
                <TableCell align="right" width="10%">
                  {t("aliquota")}
                </TableCell>
                <TableCell
                  align="right"
                  width="10%"
                  sx={{ textTransform: "uppercase" }}
                >
                  {t("imponibile")}
                </TableCell>
                <TableCell
                  align="right"
                  width="10%"
                  sx={{ textTransform: "uppercase" }}
                >
                  {t("imposta")}
                </TableCell>
                <TableCell
                  align="right"
                  width="10%"
                  sx={{ textTransform: "uppercase" }}
                >
                  {t("totale")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {salesInvoiceVats.length > 0 ? (
                salesInvoiceVats.sort(customSort).map((row, index) => {
                  // salesInvoiceVats.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell align="right">{`${row?.code} - ${row?.name}`}</TableCell>
                      <TableCell align="right">
                        {row?.rate?.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {row?.totalTaxableIncome == 0
                          ? null
                          : row?.totalTaxableIncome?.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </TableCell>
                      <TableCell align="right">
                        {row?.totalTax == 0
                          ? null
                          : row?.totalTax?.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </TableCell>
                      <TableCell align="right">
                        {row?.total == 0
                          ? null
                          : row?.total?.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan="5"></TableCell>
                </TableRow>
              )}

              <TableRow>
                {/* <TableCell></TableCell> */}
                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {t("totale")}
                  </Typography>
                </TableCell>
                <TableCell align="right" colSpan={2}>
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalTaxableIncomeSalesInvoiceVats == 0
                      ? null
                      : totalTaxableIncomeSalesInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalTaxSalesInvoiceVats == 0
                      ? null
                      : totalTaxSalesInvoiceVats.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalSalesInvoiceVats == 0
                      ? null
                      : totalSalesInvoiceVats.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Table
            sx={{ maxWidth: "80%", marginTop: "32px" }}
            aria-label="spanning table"
            size="small"
          >
            <TableHead>
              {/* <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Details
                  </TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow> */}
              <TableRow>
                <TableCell align="center" width="40%">
                  {t("regDelleRev")}
                </TableCell>
                <TableCell align="right" width="10%">
                  {t("aliquota")}
                </TableCell>
                <TableCell
                  align="right"
                  width="10%"
                  sx={{ textTransform: "uppercase" }}
                >
                  {t("imponibile")}
                </TableCell>
                <TableCell
                  align="right"
                  width="10%"
                  sx={{ textTransform: "uppercase" }}
                >
                  {t("imposta")}
                </TableCell>
                <TableCell
                  align="right"
                  width="10%"
                  sx={{ textTransform: "uppercase" }}
                >
                  {t("totale")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <TableRow>sdv</TableRow> */}
              {reverseChargeSalesInvoiceVats.length > 0 ? (
                reverseChargeSalesInvoiceVats.sort(customSort).map((row) => {
                  // reverseChargeSalesInvoiceVats.map((row) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell align="right">{`${row?.code} - ${row?.name}`}</TableCell>
                      <TableCell align="right">
                        {row?.rate?.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {row?.totalTaxableIncome == 0
                          ? null
                          : row?.totalTaxableIncome?.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </TableCell>
                      <TableCell align="right">
                        {row?.totalVat == 0
                          ? null
                          : row?.totalVat?.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </TableCell>
                      <TableCell align="right">
                        {row?.total == 0
                          ? null
                          : row?.total?.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan="5"></TableCell>
                </TableRow>
              )}

              <TableRow>
                {/* <TableCell></TableCell> */}
                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {t("totale")}
                  </Typography>
                </TableCell>
                <TableCell align="right" colSpan={2}>
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalTaxableIncomeReverseChargeSalesInvoiceVats == 0
                      ? null
                      : totalTaxableIncomeReverseChargeSalesInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalTaxReverseChargeSalesInvoiceVats == 0
                      ? null
                      : totalTaxReverseChargeSalesInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalReverseChargeSalesInvoiceVats == 0
                      ? null
                      : totalReverseChargeSalesInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow sx={{}}>{/* <TableCell></TableCell> */}</TableRow>

              <TableRow
                sx={{
                  border: "2px solid black",
                }}
              >
                {/* <TableCell></TableCell> */}
                <TableCell
                  align="right"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {t("totale") + " " + t("vendite")}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  colSpan={2}
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {finalTtlTxbleIncmSlsInvcVats == 0
                      ? null
                      : finalTtlTxbleIncmSlsInvcVats.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {finalTtlTaxSlsInvcVats == 0
                      ? null
                      : finalTtlTaxSlsInvcVats.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Typography>
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {finalTtlSlsInvcVats == 0
                      ? null
                      : finalTtlSlsInvcVats.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {/* </TableContainer> */}
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          marginTop: "80px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            paddingRight: "12px",
          }}
        >
          {/* <TableContainer component={Paper}> */}

          <Table
            sx={{ maxWidth: "100%" }}
            aria-label="spanning table"
            size="small"
          >
            <TableHead>
              {/* <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Details
                  </TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow> */}
              <TableRow>
                <TableCell align="center" width="30%">
                  {t("regAcquisti")}
                </TableCell>
                <TableCell align="right" width="10%">
                  {t("aliquota")}
                </TableCell>
                <TableCell align="right" width="10%">
                  {t("detraibilità")} %
                </TableCell>
                {/* <TableCell align="right" width="10%">{t("taxableDeductible")}</TableCell> */}
                <TableCell align="right" width="10%">
                  {t("taxableIncomeDeductible")}
                </TableCell>
                <TableCell align="right" width="10%">
                  {t("taxableIncomeInDeductible")}
                </TableCell>
                <TableCell align="right" width="10%">
                  {t("taxDeductible")}
                </TableCell>
                <TableCell align="right" width="10%">
                  {t("taxableNonDeductible")}
                </TableCell>
                <TableCell
                  align="right"
                  width="10%"
                  sx={{ textTransform: "uppercase" }}
                >
                  {t("totale")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <TableRow>sdv</TableRow> */}

              {purchaseInvoiceVats.length > 0 ? (
                purchaseInvoiceVats.sort(customSort).map((row, index) => {
                  // purchaseInvoiceVats.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell align="right">{`${row?.code} - ${row?.name}`}</TableCell>
                      <TableCell align="right">
                        {row?.rate?.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {row?.deductibility == 0
                          ? null
                          : row?.deductibility?.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </TableCell>
                      {/* <TableCell align="right">
                        {row?.totalTaxableIncome == 0
                          ? null
                          : row?.totalTaxableIncome?.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </TableCell> */}
                      <TableCell align="right">
                        {row?.deductibleTaxableIncome == 0
                          ? null
                          : row?.deductibleTaxableIncome?.toLocaleString(
                              "de-DE",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                      </TableCell>
                      <TableCell align="right">
                        {row?.nonDeductibleTaxableIncome == 0
                          ? null
                          : row?.nonDeductibleTaxableIncome?.toLocaleString(
                              "de-DE",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                      </TableCell>
                      <TableCell align="right">
                        {row?.totalDeductibleVat == 0
                          ? null
                          : row?.totalDeductibleVat?.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </TableCell>
                      <TableCell align="right">
                        {row?.totalNonDeductibleVat == 0
                          ? null
                          : row?.totalNonDeductibleVat?.toLocaleString(
                              "de-DE",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                      </TableCell>
                      <TableCell align="right">
                        {row?.total == 0
                          ? null
                          : row?.total?.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan="8"></TableCell>
                </TableRow>
              )}

              <TableRow>
                {/* <TableCell></TableCell> */}
                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {t("totale")}
                  </Typography>
                </TableCell>
                {/* <TableCell align="right" colSpan={3}>
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalTaxableIncomePurchaseInvoiceVats == 0
                      ? null
                      : totalTaxableIncomePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell> */}
                <TableCell align="right" colSpan={3}>
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalDeductibleTaxableIncomePurchaseInvoiceVats == 0
                      ? null
                      : totalDeductibleTaxableIncomePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalNonDeductibleTaxableIncomePurchaseInvoiceVats == 0
                      ? null
                      : totalNonDeductibleTaxableIncomePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalDeductibleVatPurchaseInvoiceVats == 0
                      ? null
                      : totalDeductibleVatPurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalNonDeductibleVatPurchaseInvoiceVats == 0
                      ? null
                      : totalNonDeductibleVatPurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalPurchaseInvoiceVats == 0
                      ? null
                      : totalPurchaseInvoiceVats.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Table
            size="small"
            sx={{ maxWidth: "100%", marginTop: "32px" }}
            aria-label="spanning table"
          >
            <TableHead>
              {/* <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Details
                  </TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow> */}
              <TableRow>
                <TableCell align="center" width="30%">
                  {t("regAcquistiRev")}
                </TableCell>
                <TableCell align="right" width="10%">
                  {t("aliquota")}
                </TableCell>
                <TableCell align="right" width="10%">
                  {t("detraibilità")} %
                </TableCell>
                {/* <TableCell align="right" width="10%">{t("taxableDeductible")}</TableCell> */}
                <TableCell align="right" width="10%">
                  {t("taxableIncomeDeductible")}
                </TableCell>
                <TableCell align="right" width="10%">
                  {t("taxableIncomeInDeductible")}
                </TableCell>
                <TableCell align="right" width="10%">
                  {t("taxDeductible")}
                </TableCell>
                <TableCell align="right" width="10%">
                  {t("taxableNonDeductible")}
                </TableCell>
                <TableCell
                  align="right"
                  width="10%"
                  sx={{ textTransform: "uppercase" }}
                >
                  {t("totale")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <TableRow>sdv</TableRow> */}

              {reverseChargePurchaseInvoiceVats.length > 0 ? (
                reverseChargePurchaseInvoiceVats
                  .sort(customSort)
                  .map((row, index) => {
                    // reverseChargePurchaseInvoiceVats.map((row, index) => {
                    return (
                      <TableRow key={row?._id}>
                        <TableCell align="right">{`${row?.code} - ${row?.name}`}</TableCell>
                        <TableCell align="right">
                          {row?.rate?.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell align="right">
                          {row?.deductibility == 0
                            ? null
                            : row?.deductibility?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </TableCell>
                        {/* <TableCell align="right">
                          {row?.totalTaxableIncome == 0
                            ? null
                            : row?.totalTaxableIncome?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </TableCell>
                         */}
                        <TableCell align="right">
                          {row?.deductibleTaxableIncome == 0
                            ? null
                            : row?.deductibleTaxableIncome?.toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </TableCell>
                        <TableCell align="right">
                          {row?.nonDeductibleTaxableIncome == 0
                            ? null
                            : row?.nonDeductibleTaxableIncome?.toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </TableCell>
                        <TableCell align="right">
                          {row?.totalDeductibleVat == 0
                            ? null
                            : row?.totalDeductibleVat?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </TableCell>
                        <TableCell align="right">
                          {row?.totalNonDeductibleVat == 0
                            ? null
                            : row?.totalNonDeductibleVat?.toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </TableCell>
                        <TableCell align="right">
                          {row?.total == 0
                            ? null
                            : row?.total?.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan="8"></TableCell>
                </TableRow>
              )}

              <TableRow>
                {/* <TableCell></TableCell> */}
                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {t("totale")}
                  </Typography>
                </TableCell>
                {/* <TableCell align="right" colSpan={3}>
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalTaxableIncomeReverseChargePurchaseInvoiceVats == 0
                      ? null
                      : totalTaxableIncomeReverseChargePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell> */}
                <TableCell align="right" colSpan={3}>
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats ==
                    0
                      ? null
                      : totalDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalNonDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats ==
                    0
                      ? null
                      : totalNonDeductibleTaxableIncomeReverseChargePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalDeductibleVatReverseChargePurchaseInvoiceVats == 0
                      ? null
                      : totalDeductibleVatReverseChargePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalNonDeductibleVatReverseChargePurchaseInvoiceVats == 0
                      ? null
                      : totalNonDeductibleVatReverseChargePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {totalReverseChargePurchaseInvoiceVats == 0
                      ? null
                      : totalReverseChargePurchaseInvoiceVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow sx={{}}>{/* <TableCell></TableCell> */}</TableRow>

              <TableRow
                sx={{
                  border: "2px solid black",
                }}
              >
                {/* <TableCell></TableCell> */}
                <TableCell
                  align="right"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {t("totale") + " " + t("acquisti")}
                  </Typography>
                </TableCell>
                {/* <TableCell
                  align="right"
                  colSpan={3}
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {finalTtlTxbleIncmRvrseChrgPrchseInvcVats == 0
                      ? null
                      : finalTtlTxbleIncmRvrseChrgPrchseInvcVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell> */}
                <TableCell
                  align="right"
                  colSpan={3}
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {finalTtlDeductibileTxbleIncmRvrseChrgPrchseInvcVats == 0
                      ? null
                      : finalTtlDeductibileTxbleIncmRvrseChrgPrchseInvcVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {finalTtlNonDeductibileTxbleIncmRvrseChrgPrchseInvcVats == 0
                      ? null
                      : finalTtlNonDeductibileTxbleIncmRvrseChrgPrchseInvcVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {finalTtlDductibleVatRvrseChrgPrchseInvcVats == 0
                      ? null
                      : finalTtlDductibleVatRvrseChrgPrchseInvcVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {finalTtlNonDductibleVatRvrseChrgPrchseInvcVats == 0
                      ? null
                      : finalTtlNonDductibleVatRvrseChrgPrchseInvcVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {finalTtlRvrseChrgPrchseInvcVats == 0
                      ? null
                      : finalTtlRvrseChrgPrchseInvcVats.toLocaleString(
                          "de-DE",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {/* </TableContainer> */}
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          marginTop: "80px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12}>
          {/* <TableContainer component={Paper}> */}

          <Table
            sx={{ maxWidth: "60%" }}
            aria-label="spanning table"
            size="small"
          >
            <TableHead>
              {/* <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Details
                  </TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow> */}
              <TableRow>
                <TableCell width="40%"></TableCell>
                <TableCell align="right" width="10%">
                  {t("imponibile")}
                </TableCell>
                <TableCell align="right" width="10%">
                  {t("imposta")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <TableRow>sdv</TableRow> */}

              <TableRow>
                <TableCell align="right">{t("salesOfTheYear")}</TableCell>
                <TableCell align="right">
                  {finalTtlTxbleIncmSlsInvcVats == 0
                    ? null
                    : finalTtlTxbleIncmSlsInvcVats.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </TableCell>
                <TableCell align="right">
                  {finalTtlTaxSlsInvcVats == 0
                    ? null
                    : finalTtlTaxSlsInvcVats.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="right">{`${vat17?.vatCode} - ${vat17?.vatName}`}</TableCell>
                <TableCell align="center">
                  {/* {vat17?.totalV17TaxableIncome == 0
                    ? null
                    : vat17?.totalV17TaxableIncome?.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} */}
                </TableCell>
                <TableCell align="center">
                  {vat17?.totalV17Vat == 0
                    ? null
                    : vat17?.totalV17Vat?.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">{t("purchaseOfThePeriod")}</TableCell>
                <TableCell align="right">
                  {finalTtlTxbleIncmRvrseChrgPrchseInvcVats == 0
                    ? null
                    : finalTtlTxbleIncmRvrseChrgPrchseInvcVats.toLocaleString(
                        "de-DE",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                </TableCell>
                <TableCell align="right">
                  {/* {finalPuchaseDeductible.toLocaleString('de-DE', {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })} */}
                  {finalTtlDductibleVatRvrseChrgPrchseInvcVats == 0
                    ? null
                    : finalTtlDductibleVatRvrseChrgPrchseInvcVats.toLocaleString(
                        "de-DE",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">{t("saldoAnnoCorrente")}</TableCell>

                <TableCell></TableCell>
                <TableCell align="right">
                  {saldoAnnoCorrente == 0
                    ? null
                    : saldoAnnoCorrente.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="right">{t("vatCreditAnno")}</TableCell>

                <TableCell></TableCell>
                <TableCell align="right">
                  {ivaCredito == 0
                    ? null
                    : ivaCredito.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </TableCell>
              </TableRow>
              <TableRow>
                {/* <TableCell></TableCell> */}
                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {t("taxDueCredit")}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "700" }}
                  ></Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    {/* {(finalTtlTaxSlsInvcVats -
                      (vat17?.totalV17Vat) -
                      finalPuchaseDeductible).toLocaleString('de-DE', {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })} */}
                    {taxDueOrCredit == 0
                      ? null
                      : taxDueOrCredit.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {/* </TableContainer> */}
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          marginTop: "80px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12}>
          {/* <TableContainer component={Paper}> */}

          <Table
            sx={{ maxWidth: "30%" }}
            aria-label="spanning table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  width="15%"
                  align="left"
                  colSpan={2}
                  sx={{ fontWeight: "700" }}
                >
                  {t("saldi")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="15%"
                  align="center"
                  sx={{ fontWeight: "700" }}
                >
                  {t("month")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: "50%",
                    fontWeight: "700",
                    textTransform: "uppercase",
                  }}
                >
                  {t("saldo")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <TableRow>sdv</TableRow> */}

              {monthwiseInvoice.map((item, index) => {
                const monthIndex = item?.monthIndex - 1;
                return (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {monthNames[monthIndex]}
                    </TableCell>
                    <TableCell align="right">
                      {item?.monthSaldo == 0 && item.monthNewSaldo < 0
                        ? null
                        : item?.monthNewSaldo == 0
                        ? null
                        : item?.monthNewSaldo?.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </TableCell>
                  </TableRow>
                );
              })}

              {/* <TableRow>
                  
                  <TableCell  align="right">
                    
                      <Typography variant='h6'  sx={{ fontWeight:"700"}}>
                        {t('imposta')+" "+"DOVUTA / A CREDITO"}
                      </Typography>
                    
                  </TableCell>
                  <TableCell align="right" colSpan={2}>
                    
                      <Typography variant='h6'  sx={{ fontWeight:"700"}}>
                      999,999,999.99
                      </Typography>
                    
                  </TableCell>

                </TableRow> */}
            </TableBody>
          </Table>
          {/* </TableContainer> */}
        </Grid>
      </Grid>

      {/* <Box sx={{
        marginTop: "24px"
      }}>
        <Typography variant="caption" display="block">{t('note')}</Typography>
        <Typography display="block">{t('note')}</Typography>
        <Typography display="block">{t('note')}</Typography>
        <Divider sx={{ marginY: "16px" }}></Divider>
        <Box>
        <Button
              href="/add_fatture_vendita"
              sx={{
                // marginLeft: "16px",
                // width: "18pc"
                textAlign: "end"
              }}
              // onClick={() => {
              //   props.onAddOpen()
              // }}
              variant="contained"
              size='large'
            
              >
                            {t('salva')+" "+t('EProsegui')}

            </Button>

            <Button
              href="/add_fatture_vendita"
              sx={{
                marginLeft: "16px",
                // width: "18pc"
                textAlign: "end"
              }}
              // onClick={() => {
              //   props.onAddOpen()
              // }}
              variant="contained"
              size='large'
            
              >
              {t('salva')+" "+t('EdEsci')}

            </Button>

            <Button
              href="/add_fatture_vendita"
              sx={{
                marginLeft: "16px",
                // width: "18pc"
                textAlign: "end"
              }}
              // onClick={() => {
              //   props.onAddOpen()
              // }}
              variant="contained"
              size='large'
            
              >
                            {t('esci')+" "+t('senza'+" "+t('salvare'))}

            </Button>

            <Button
              href="/add_fatture_vendita"
              sx={{
                marginLeft: "16px",
                // width: "18pc"
                textAlign: "end"
              }}
              // onClick={() => {
              //   props.onAddOpen()
              // }}
              variant="contained"
              color='error'
              size='large'
            
              >
                            {t('elimina')}

            </Button>
        </Box>

      </Box> */}
    </Box>
  );
}
