/***
 @author Lokesh Desai
 @description
 */
import React, {useContext, useEffect, useState} from 'react';
import {
  Box, Button, Modal, Typography, Divider, TextField, Stack,
  IconButton, InputAdornment, Grid, MenuItem, Select
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {API} from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import AppLogger from "../../../../constants/AppLogger";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Snackbar from "@mui/material/Snackbar";
import {DeleteDialog} from "components/DeleteDialog";
import MuiAlert from "@mui/material/Alert";
import apiStatusCode from "../../../../constants/apiStatusCode";
import defaultString from "../../../../constants/defaultString.json";
import axios from "axios";
import {getDataFromStorage} from "storage/StorageData";
import { AuthContext } from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { resetToken } from 'apiURL/commonFunctions';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AddUser = (props) => {


  const { i18n, t } = useTranslation();
  const [isSaveNConti, setIsSaveNConti] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listOfTypes, setTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [reload, setReload] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [subjectType, setSubjectType] = useState("Persona Fisica");
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()

  const validationSchema = Yup.object().shape({
    nomeECognome:  Yup.string()
      .required(subjectType=="Persona Fisica" ? t('nomeRequired'): t("denominazioneRequired")),
  
      indirizzo: Yup.string()
      .required(t('addressRequired')),
      cap: Yup.string()
      .required(t('postalCodeRequird')),
      città: Yup.string()
      .required(t('cityRequired')),
      provincia: Yup.string()
      .required(t('provinceRequired')),
      partita: Yup.string()
      .required(t('vatNumberRequired')),
      cognome:subjectType=="Persona Fisica" && Yup.string()
      .required(t('congnomeRequired')),
      country:Yup.string()
      .required(t('countryRequired')),
  });

  const formik = useFormik({
    initialValues: {
      nomeECognome: '',
      indirizzo: '',
      cap: '',
      città: '',
      provincia: '',
      partita: '',
      codiceFiscale: '',
      country:'ITALIA',
      // sdi: '',
      note: '',
      cognome: '',
    },
    validationSchema,
    onSubmit: async(values) => {
      if(props?.isEdit){
        await editSupplier()
      }else{

        await addSupplier();
      }
    },
  });

  useEffect(() => {
    getListOfTypes()
  }, [])

  useEffect(() => {
    // getListOfTypes()

    if (props && props?.stateObj) {
      formik.setValues({
        nomeECognome: props?.stateObj?.name ? props?.stateObj?.name : "",
        cognome: props?.stateObj?.lastName ? props?.stateObj?.lastName : "",
        indirizzo: props?.stateObj?.address ? props?.stateObj?.address : "",
        cap: props?.stateObj?.postalCode ? props?.stateObj?.postalCode : "",
        città: props?.stateObj?.city ? props?.stateObj?.city : "",
        provincia: props?.stateObj?.province ? props?.stateObj?.province : "",
        partita: props?.stateObj?.vatNumber ? props?.stateObj?.vatNumber : "",
        codiceFiscale: props?.stateObj?.taxIdCode ? props?.stateObj?.taxIdCode : "",
        country: props?.stateObj?.country ? props?.stateObj?.country : "",
        // sdi: props?.stateObj?.sdi ? props?.stateObj?.sdi : "",
        note: props?.stateObj?.note ? props?.stateObj?.note : "",
      })
    }
  }, [props, props?.stateObj])

  const handleAlertClose = (event, reason) => {
    setIsLoading(false)
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  const addSupplier = async () => {
    const userId = JSON.parse(localStorage.getItem('user'))?._id;
    const subType=listOfTypes.filter((obj,i)=>obj.type==subjectType)
    const accountingSubject  = await getDataFromStorage(defaultString.accountingSubject)
    if (!!formik.values) {
      setIsLoading(true)

      const requestObject = {}
      requestObject.subjectTypeId = subType[0]._id
      requestObject.accountingSubjectId = accountingSubject._id;
      requestObject.name = formik.values.nomeECognome ? formik.values.nomeECognome : "";
      requestObject.lastName = formik.values.cognome ? formik.values.cognome : "";
      requestObject.address = formik.values.indirizzo ? formik.values.indirizzo : "";
      requestObject.postalCode = formik.values.cap ? formik.values.cap : "";
      requestObject.city = formik.values.città ? formik.values.città : "";
      requestObject.province = formik.values.provincia ? formik.values.provincia : "";
      requestObject.vatNumber = formik.values.partita ? formik.values.partita : "";
      requestObject.taxIdCode = formik.values.codiceFiscale ? formik.values.codiceFiscale : "";
      // requestObject.sdi = formik.values.sdi ? formik.values.sdi : "";
      requestObject.fiscalCode = "";
      requestObject.country = formik.values.country ? formik.values.country : "";
      requestObject.note = formik.values.note ? formik.values.note : "";
      requestObject.createdBy = userId;

      API.post(apiURL.service_add_supplier, requestObject).then((response) => {
        setIsLoading(false)
        setReload(true)
        if (response.status === apiStatusCode.CREATED) {

          setOpen(true);
          setErrorMsg(t("common.addSuccess"))
          setSeverity("success")
          formik.resetForm();

          if (listOfTypes.length > 0) {
            // setSubjectType( listOfTypes[0]._id);
            setSubjectType(listOfTypes[0].type);
          }

          if (isSaveNConti) {
            setIsSaveNConti(false)
          } else {
            props.handleClose({reload: true, supplierId: response?.data?.data?.supplier?._id});
          }
        }
        else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"))
          setSeverity("error")
        }
      }).catch((error) => {
        setIsLoading(false)
        setOpen(true);
        setSeverity("error")
        if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout()
          }
          else{
            setErrorMsg(t("common.badRequest"));
          }
        } 
      })

    }
  }

  const backToList = (reload) => {
    props.handleClose({reload: reload});
    formik.resetForm();
    setIsSaveNConti(false);
  }

  const onSaveAndContinueClicked = (e, isContinue) => {
    e.preventDefault();
    setIsSaveNConti(isContinue);
    formik.handleSubmit();
  }

  const confirmDelete = async () => {
    await fnDelete();
    setOpenDelete(!openDelete)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDelete(!openDelete)
  };

  const getListOfTypes = () => {
    API.get(apiURL.service_get_subject_types).then((response) => {
      if (response && response.data && response.data.success && response?.data?.data?.accountSubjects && response?.data?.data?.accountSubjects?.length > 0) {
        setTypes(response?.data?.data?.accountSubjects ? response?.data?.data?.accountSubjects : []);
        if (props?.isEdit) {
           // setSubjectType(props?.stateObj?.subjectTypeId?._id);
           setSubjectType(props?.stateObj?.subjectTypeId?.type);
        } else {
          // setSubjectType(response?.data?.data?.accountSubjects[0]._id);
          setSubjectType( response?.data?.data?.accountSubjects[0].type);
        }
      }
    }).catch((error) => {
      AppLogger.logError(JSON.stringify(error))
      setIsLoading(false)
      setOpen(true);
      setSeverity("error")
      if (error?.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
      else if (error?.code === defaultString.bad_request) {
        if (error?.response?.data?.status === apiStatusCode.NOTFOUND) {
          setOpen(false);
        }
        else if (error?.response?.data?.status === apiStatusCode.UNAUTHORIZED) {
         doLogout()
        }
      }
    })
  }

  const editSupplier = async () => {

    const accountingSubject  = await getDataFromStorage(defaultString.accountingSubject)
    if (!!formik.values) {
      setIsLoading(true)
      const subType=listOfTypes.filter((obj,i)=>obj.type==subjectType)
      const requestObject = {}
      requestObject.subjectTypeId = subType[0]._id;
      requestObject.accountingSubjectId = accountingSubject._id;
      requestObject.name = formik.values.nomeECognome ? formik.values.nomeECognome : "";
      requestObject.lastName = formik.values.cognome ? formik.values.cognome : "";
      requestObject.address = formik.values.indirizzo ? formik.values.indirizzo : "";
      requestObject.postalCode = formik.values.cap ? formik.values.cap : "";
      requestObject.city = formik.values.città ? formik.values.città : "";
      requestObject.province = formik.values.provincia ? formik.values.provincia : "";
      requestObject.vatNumber = formik.values.partita ? formik.values.partita : "";
      requestObject.taxIdCode = formik.values.codiceFiscale ? formik.values.codiceFiscale : "";
      // requestObject.sdi = formik.values.sdi ? formik.values.sdi : "";
      requestObject.fiscalCode = "";
      requestObject.country = formik.values.country ? formik.values.country : "";
      requestObject.note = formik.values.note ? formik.values.note : "";
      requestObject.subaccountId = props.stateObj?.subaccountId;

      API.put(`${apiURL.service_update_supplier}/${props.stateObj?._id}`, requestObject).then((response) => {
        setIsLoading(false)
        setReload(true)
        if (response.status === apiStatusCode.SUCCESS) {
          setOpen(true);
          setErrorMsg(t("common.updateSuccess"))
          setSeverity("success")
          formik.resetForm();
          if (listOfTypes.length > 0) {
            // setSubjectType("listOfTypes[0]._id);
            setSubjectType(listOfTypes[0].type);
          }
          props.handleClose({reload: true});
        } else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"))
          setSeverity("error")
        }

      }).catch((error) => {
        setIsLoading(false)
        setOpen(true);
        setSeverity("error")
        if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout()
          }
          else{
            setErrorMsg(t("common.badRequest"));
          }
        } 
      })

    }
  }

  const fnDelete = async () => {

    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken))

    const idsToBeDeleted = JSON.stringify({
      'supplierIds': [props.stateObj?._id]
    });

    await axios.post(apiURL.service_delete_supplier, idsToBeDeleted, {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
        .then(async (res) => {
          console.log(res.data);
          if (res.data.status === apiStatusCode.DELETED) {
            setOpen(true);
            setErrorMsg(t("common.deleteSuccess"))
            setSeverity("success")
            formik.resetForm();
            props.handleClose({reload: true});
          }
          else {
            setOpen(true);
            setErrorMsg(t("common.internalServerError"))
            setSeverity("error")
          }

        }).catch((error) => {
          console.log(error.response)
          setIsLoading(false)
          setOpen(true);
          setSeverity("error")
          if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
            else if (error.code === defaultString.bad_request) {
              if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
                doLogout()
              }
              else{
                setErrorMsg(t("common.badRequest"));
              }
            } 
        });
  }

  const customHandleChange = (event) => {
    setSubjectType(event.target.value)
      console.log(event.target)    
    };

  const AddSupplier = (
      <Modal
          sx={{
            display: 'flex', alignItems: 'center', justifyContent: 'center'
          }}
          open={props.open}
          // onClose={props.handleClose}
      >
        <Box sx={{
          width: '70%',
          backgroundColor: 'white',
          borderRadius: 2,
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            
            borderRadius: 2,

          padding: 2,
            // position: "fixed"
          }}>
            <Typography sx={{
              fontSize: 24, color: 'black',
            }}>
              {props?.isEdit ? t('changeSupplier') : t('creaAnagraficaFornitori')}
            </Typography>

            <Button size='small' variant="contained" onClick={()=> {backToList(reload)}}>{t('indietro')}</Button>
          </Box>
          <Divider/>

          <Box sx={{
           
           alignItems: "center",
           height: "80vh",
           overflowY: "scroll",
           padding:2,
           '&::-webkit-scrollbar': {
              width: '16px',
              borderRadius:"10px",

            },
            '&::-webkit-scrollbar-track': {
              background: "#fff",
              borderRadius:"8px",

            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#4d96ff',
              borderRadius:"10px",
              border: "4px solid #ffffff",
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#2c70cf'
            }
          }}>
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{
                  maxWidth: 550,
                  width: '100%',
                  marginX:"auto",
                }}>
              <Stack
                  spacing={2}>

                <Select
                size='small'
                    id="outlined-basic"
                    onChange={(event) => customHandleChange(event)}
                    name="personaFisica"
                    variant="outlined"
                    value={subjectType}
                    label={t('personaFisica')}
                    // onBlur={formik.handleBlur}
                    // error={formik.touched.personaFisica && Boolean(formik.errors.personaFisica)}
                    // helperText={formik.touched.personaFisica && formik.errors.personaFisica}
                    // required
                >
                  {listOfTypes?.map((item) => {
                    return (
                        <MenuItem key={item?._id} value={item?.type}>
                          {item?.type}
                        </MenuItem>
                    )
                  })}
                </Select>


                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                label={
                  subjectType== "Persona Fisica"
                    ? t("nome")
                    : t("giuridicaDenominazione")
                }
                name="nomeECognome"
                value={formik.values.nomeECognome}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.nomeECognome && Boolean(formik.errors.nomeECognome)}
                helperText={formik.touched.nomeECognome && formik.errors.nomeECognome}
                variant="outlined"
                required
              />

{
              subjectType == 'Persona Fisica' &&
               
              <TextField
              size='small'
                fullWidth
                id="outlined-basic" label={t('cognome')}
                name="cognome"
                value={formik.values.cognome}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.cognome && Boolean(formik.errors.cognome)}
                helperText={formik.touched.cognome && formik.errors.cognome}
                variant="outlined"
                required />
            }
                <TextField
                size='small'
                    fullWidth
                    name="indirizzo"
                    value={formik.values.indirizzo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.indirizzo && Boolean(formik.errors.indirizzo)}
                    helperText={formik.touched.indirizzo && formik.errors.indirizzo}
                    id="outlined-controled" label={t('indirizzo')} variant="outlined" required/>

                <TextField
                size='small'
                    fullWidth
                    name="cap"
                    value={formik.values.cap}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.cap && Boolean(formik.errors.cap)}
                    helperText={formik.touched.cap && formik.errors.cap}
                    id="outlined-controled" label={t('cap')} variant="outlined" required/>

                <TextField
                size='small'
                    fullWidth
                    name="città"
                    value={formik.values.città}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.città && Boolean(formik.errors.città)}
                    helperText={formik.touched.città && formik.errors.città}
                    id="outlined-controled" label={t('città')} variant="outlined" required/>

                <TextField
                size='small'
                    fullWidth
                    name="provincia"
                    value={formik.values.provincia}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.provincia && Boolean(formik.errors.provincia)}
                    helperText={formik.touched.provincia && formik.errors.provincia}
                    id="outlined-controled" label={t('provincia')} variant="outlined" required/>
                <TextField
                size='small'
                    fullWidth
                    name="partita"
                    value={formik.values.partita}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.partita && Boolean(formik.errors.partita)}
                    helperText={formik.touched.partita && formik.errors.partita}
                    id="outlined-controled" label={t('partita') + " " +t('iva')} variant="outlined" required/>
                <TextField
                size='small'
                    fullWidth
                    name="codiceFiscale"
                    value={formik.values.codiceFiscale}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.codiceFiscale && Boolean(formik.errors.codiceFiscale)}
                    helperText={formik.touched.codiceFiscale && formik.errors.codiceFiscale}
                    id="outlined-controled" label={t('codiceFiscale')} variant="outlined" />
                {/* <TextField
                    fullWidth
                    name="sdi"
                    value={formik.values.sdi}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.sdi && Boolean(formik.errors.sdi)}
                    helperText={formik.touched.sdi && formik.errors.sdi}
                    id="outlined-controled" label={t('sdi')} variant="outlined" /> */}

<TextField
            size='small'
              fullWidth
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
              id="outlined-controled" label={t('stato')} variant="outlined" required />

                <TextField
                size='small'
                    fullWidth
                    name="note"
                    value={formik.values.note}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.note && Boolean(formik.errors.note)}
                    helperText="0/100"
                    id="outlined-controled" label={t('note')} variant="outlined" multiline
                    rows={5} />
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>

                  {!props?.isEdit && <Button size='small' variant="contained" type="submit" onClick={(e) => {
                    onSaveAndContinueClicked(e, true)
                  }}>{t('salvaEProsegui')}</Button>}

                  <Button variant="contained" size='small'
                  // onClick={(e) => {
                  //   props?.isEdit? editSupplier() : onSaveAndContinueClicked(e, false)
                // }}
                    onClick={(e) => {
                      onSaveAndContinueClicked(e, false)
                  }}>{t('salvaEdESCI')}</Button>

                  {
                      props?.isEdit &&
                    <Button variant="contained" color="error" size='small'
                          onClick={() => {
                            setOpenDelete(!openDelete)
                          }}>{t('elimina')}</Button>
                  }

                </Box>

              </Stack>
            </Box>
          </Box>
        </Box>

      </Modal>
  )

  return (
      <>
    {isLoading ? <LoadingSpinner/> : AddSupplier}
    <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose} sx={{width: '100%'}}
              anchorOrigin={{horizontal: 'right', vertical: 'top'}}>
      <Alert onClose={handleAlertClose} sx={{color:"#fff"}} severity={severity}>
        {errorMsg}
      </Alert>
    </Snackbar>
    <DeleteDialog open={openDelete} handleClose={handleCloseDeleteDialog} onConfirm={confirmDelete}/>
  </>);
}


export default AddUser;
